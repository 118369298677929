<template>
  <div
    class="is-flex is-flex-direction-column is-align-items-center
              mt-5 main bottom-margin "
  >
    <div
      class="is-flex 
            is-flex-direction-column 
            
            add-reading
            page-content
            
            mt-6
            mx-2
            px-2 "
    >
      
        <div
          class="is-flex 
            is-flex-direction-column 
            
            is-size-3 mt-0  "
        >
          <h4 align="left"  class=" is-size-4 has-text-weight-bold">Kan şekeri değerinizi girin</h4>
          <hr class="mt-1 mb-4" />
          <label for="glycosis-value" class="has-text-left is-size-5"
            >Kan şekeri</label
          >
          <input
            id="glycosis-value"
            class=" glyco-input input is-normal"
            v-model="value"
            min="30"
            max="150"
            type="number"
            maxlength="4"
            placeholder="Buradan şekeri değeri girebilirsiniz"
          />
          <b-field  label="Tarih seçimi" class="mt-4 has-text-left ">
            <b-radio-button v-model="dateOption"
                native-value="quick"
                type="is-info is-light is-outlined">
                <b-icon icon="close"></b-icon>
                <span>Hızlı seç</span>
            </b-radio-button>

            <b-radio-button v-model="dateOption"
                native-value="datepick"
                type="is-info is-light is-outlined">
                <b-icon icon="check"></b-icon>
                <span>Takvimden seç</span>
            </b-radio-button>
          </b-field>
          
          <div v-if="dateOption=='quick'" class="is-flex is-flex-direction-column is-align-items-flex-start my-2">
            <p class="is-size-5 has-text-weight-light pb-0 mb-0">Hızlı seçim</p>
          <div class="is-flex is-align-items-baseline ">  <b-field class="mt-0">
            <b-radio-button @input="handleDay()" v-model="day"
                native-value="yesterday"
                type="is-info is-light is-outlined" 
                 >
                
                <span>Dün</span>
            </b-radio-button>

            <b-radio-button v-model="day"
            @input="handleDay()"
                native-value="today"
                type="is-info is-light is-outlined">
                
                <span>Bugün</span>
            </b-radio-button>
          </b-field>
          <!-- <b-button class="ml-2" @click="handleDay()">Ekle</b-button> --></div>
            <!-- <p>{{day}}</p> -->
           
             <div class="is-flex is-align-items-baseline ">
               <b-field class="mt-4">
            <b-radio-button v-model="time"
            @input="handleTime(time)"
                native-value="morning"
                type="is-info is-light is-outlined">
                
                <span>Sabah</span>
            </b-radio-button>

            <b-radio-button v-model="time"
            @input="handleTime(time)"
                native-value="noon"
                type="is-info is-light is-outlined">
                
                <span>Öğle</span>
            </b-radio-button>
            <b-radio-button  v-model="time"
            @input="handleTime(time)"
                native-value="evening"
                type="is-info is-light is-outlined">
                
                <span>Akşam</span>
            </b-radio-button>
            <b-radio-button  v-model="time"
            @input="handleTime(time)"
                native-value="night"
                type="is-info is-light is-outlined">
                
                <span>Gece</span>
            </b-radio-button>
          </b-field>
          
            
             </div>
          <!-- <b-button class="mt-2 is-info" @click="handleTime(time)">Seç</b-button> -->
          </div>
          <b-tooltip
          v-else-if="dateOption=='datepick'"
            label="Ölçüm tarihinizi kendiniz girebilirsiniz, boş bırakırsanız ölçüm zamanı olarak şu an eklenir."
            position="is-bottom"
            type="is-light"
            multilined
          >
            <b-field  class="has-text-left is-size-5" label="Ölçüm tarihi">
              <b-datetimepicker
                placeholder="Kan şekeri ölçüm tarih saatinizi giriniz"
                icon="calendar-today"
                v-model="date"
                editable
              >
              </b-datetimepicker>
            </b-field>
          </b-tooltip>
          <!-- <p>{{dateInfo| moment('Do MMMM YYYY, h:mm a')}}</p> -->
          
          <hr class="mt-1">
          <h4 class="has-text-left is-size-5">Açlık durumu</h4>
          <hr class="mt-1 mb-2">
          <b-field class="mt-1">
            <b-radio-button v-model="mealStatus"
            @input="handleMealStatus()"
                native-value="before"
                type="is-info is-light is-outlined">
                
                <span>Açlık</span>
            </b-radio-button>

            <b-radio-button v-model="mealStatus"
            @input="handleMealStatus()"
                native-value="after"
                type="is-info is-light is-outlined">
                
                <span>Tokluk</span>
            </b-radio-button>
            
          </b-field>
          <p class="has-text-left is-size-5 mt-2" v-if="day">Seçilen Tarih</p>
          <hr class="mt-1">
          <b-tag v-if="date" closable @close="date=null" class="has-text-left is-size-5 mb-4">{{date| moment('Do MMMM YYYY, h:mm a')}}</b-tag>
          <!-- <p>{{date}}</p> -->
          <b-tag class="is-size-5 " v-if="value">Kan şekeri değeri: {{value}}</b-tag>

          <label for="glycosis-note" class="has-text-left is-size-5">Not</label>
          <input
            id="glycosis-note"
            class=" input is-normal"
            v-model="glyNote"
            type="text"
            maxlength="50"
            placeholder="Eklemek istediğiniz not varsa"
          />
          <hr class="mb-0">
          <button class="button is-info ml-0 mt-2" @click="addReading">
            Ekle
          </button>
          <button
            class="button is-info ml-0 mt-2 is-outlined"
            @click="navigateToReadings"
          >
            Ölçüm değerlerime git
          </button>
        </div>
        <hr />
        
        <div v-if="readings24hour.data.length && treatmentInfo[0].treatmentType && treatmentInfo[0].treatmentType =='INSULIN' && readings24hour.data[0].mealStatus == 'before'" class="box has-background-grey-lighter	mb-6">
          <!-- <p>{{treatmentInfo.data[0].treatmentType}}</p> -->
          <!-- <p>treatmentInfo.data[0].treatmentType</p> -->
          <p>Insülin değeri</p>
          <hr>
          <p>{{readings24hour.data[0].date | moment('Do MMMM YYYY, h:mm a')}}</p> tarihli  
        <p>{{readings24hour.data[0].value}}</p>  değerli ölçümünüz için   
        <p>{{readings24hour.data[0].unit}}</p> ünite <span class="has-text-weight-bold">{{treatmentInfo[0].regime.at(-1).frInsulin}}</span> (hızlı salınımlı) uygulayabilirsiniz
        </div> 
    </div>
  </div>
</template>

<script>
import moment from 'moment'
//import axios from "axios";
import {mapState, mapActions} from 'vuex';

export default {
  name: "ReadingDetail",
  components: {},
  data() {
    return {
      text: "Meeting",
      value: null,
      date: null,
      glyNote: "",
      readings: [],
      dateInfo: "",
      dateOption:null,
      day:null,
      time:null,
      mealStatus:null,
      patientId:this.$route.params.id,
      unit:null,

    };
  },
  computed:{
            ...mapState(['readings24hour','treatmentInfo']),
    /*          morningAfterValue() {
      const time = "morning";
      const meal = "after";
      
      return _.filter(this.reading24hour, function(data) {
        if (_.isNull(time && meal )) {
          return true;
        } else {
          
          return data.isHungry == hungry ;
        }
      });
    }, */
        },
  methods: {
     ...mapActions(['get24hourGlycosisDataById','getTreatmentInfo']),
    async addReading() {
      
      if(this.date && dayTime == null){
        if(moment(this.date).hour()>5 && moment(this.date).hour() < 12){
          this.time="morning"
        } else if(moment(this.date).hour()>=12&&moment(this.date).hour()<17){
          this.time="noon"
        } else if(moment(this.date).hour()>=17&&moment(this.date).hour()<23){
          this.time="evening"
        } else {
          console.log(moment(this.date).hour())
          this.time="night"
        }

      }





      const value = this.value;
      const note = this.glyNote;
      const patientId = this.$route.params.id;
      const mealStatus = this.mealStatus
      const dayTime = this.time
      
      let date;

      
                  if(mealStatus == "before"){
                    if(value >= 80 && value <= 130){ 
                      this.unit = 8
                      console.log("standart",value)
                    } else if(value > 130){
                     this.unit = Math.floor((parseInt(value) - 130) / 20) + 8
                    console.log("more",value,Math.floor((parseInt(value) - 130) / 20) + 8)
                   }  
                   else if(value < 80){
                     this.unit = 8 - Math.floor(80 - (parseInt(value)) / 20) 
                    console.log("less",8 - Math.floor(80 - (parseInt(value)) / 20) )
                   }  
                  } 

        const unit= this.unit
      if (this.date == null) {
        date = new Date().toISOString();
        
      } else {
        date = this.date;
      }

      console.log("unit", unit);

      if (parseInt(this.value) < 30) {
        this.showRangeWarning();
      } else if (!this.value) {
        this.showNoDataWarning();
      }
      else if (this.mealStatus==null) {
        alert("Lütfen açlık veya tokluk kan şekeri değeri seçimi yapınız!.");
      } else {
      await    this.$store.dispatch("addGlycosisReading", {value,date,patientId,note,mealStatus,dayTime,unit})
          await this.$store.dispatch('get24hourGlycosisDataById', this.patientId)
          await this.$store.dispatch('getTreatmentInfo')
          this.toastGlycosisReadingConfirmation();
          console.log(this.mealStatus)
      }

      this.value = null;
      this.glyNote = "";
      this.mealStatus = null;
      this.dayTime = null;
      this.unit=null

    },

    /* editReading() {}, */
    handleDay(){
 /*      let day = this.day;
      console.log(day) */
      if(this.day=="yesterday"){
        this.date = moment(new Date().toISOString()).subtract(1, "days").startOf('day');
      console.log(this.date)
      } else{
        this.date = moment().startOf('day');
        console.log(this.date)
      }
      

    },
    handleMealStatus(){
      console.log(this.mealStatus)
      

    },
    handleTime(time){
      this.handleDay();
      if(this.day==null){
        console.log(this.day)
        alert("Lütfen önce gün seçimi yapınız!")
        return
      } else if (time == "morning") {
        this.date = moment(this.date).add(9,'hours')
      } else if (time == "noon") {
        this.date = moment(this.date).add(12,'hours')
      } else if (time == "night") {
        this.date = moment(this.date).add(3,'hours')
      } else if (this.day == "yesterday" && time == "night") {
        this.date = moment(this.date).add(21,'hours')
      } else {
        this.date = moment(this.date).add(18,'hours')
      }
    console.log(this.date)
    },

    showNoDataWarning() {
      this.$buefy.toast.open({
        message: "Lütfen bir kan şekeri değer giriniz.",
        type: "is-warning",
        duration: 2000,
      });
    },
    showRangeWarning() {
      this.$buefy.toast.open({
        message: "Kan şekeri değeri 30'dan düşük olmamalı",
        type: "is-warning",
        duration: 2000,
      });
    },
    toastGlycosisReadingConfirmation() {
      this.$buefy.toast.open({
        message: "Kan şekeri değeri başarıyla eklendi",
        type: "is-success",
        duration: 2000,
      });
    },
    navigateToReadings() {
      this.$router.push({
        path: `/user/${this.$route.params.id}/read`,
      });
    },
    get24hourReadingData(){
                console.log(this.patientId)
                this.$store.dispatch('get24hourGlycosisDataById', this.patientId)
            },
  },
  async created() {
    await this.getTreatmentInfo();
    await this.get24hourReadingData();
    
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
}



@media screen and (min-width: 712px) {
  .glyco-input{
  width: 50%;
}
}


@media screen and (min-width: 712px) {
  .page-content{
  width: 85vw;
  
  }
}
</style>
