<template>
    <div class="main  ">
        <div class="is-flex is-flex-direction-column mt-6 mx-4">
            <h3 class="has-text-left mt-3" >{{this.patientName}} için insülin değerleri</h3>
            <hr>
            <div class="is-flex is-align-items-baseline">
                <div class="is-flex is-flex-wrap-wrap">
                <b-field v-if="customRanges" class="mr-4 has-text-left" label="Zaman" position="left" >
            <b-select v-model="dayTime" placeholder="Zaman seçiniz">
                <option value="Sabah">Sabah</option>
                <option value="Öğle">Öğle</option>
                <option value="Akşam">Akşam</option>
                <option value="Akşam">Gece</option>
            </b-select>
        </b-field>
                
                <b-field v-if="customRanges" class="mr-4 has-text-left" label="Açlık durumu" position="left" >
            <b-select v-model="isHungry" placeholder="İsim seçiniz">
                <option value="Aç">Aç</option>
                <option value="Tok">Tok</option>
            </b-select>
        </b-field>
        <b-field class=" mr-4 has-text-left" label="Insulin (Hızlı salınım)" position="left" >
            <b-select v-model="frInsulin" placeholder="Insulin seçiniz">
                <option value="Novarapid">Novarapid</option>
                <option value="Apidra">Apidra</option> 
                <option value="Humolog">Humolog</option> 
                <option value="Humolog Mix">Humolog Mix</option> 
            </b-select>
        </b-field>
        <b-field class=" has-text-left" label="Insulin (Yavaş salınım)" position="left" >
            <b-select v-model="srInsulin" placeholder="Insulin seçiniz">
                <option value="Touje">Touje</option>
                <option value="Lantus">Lantus</option> 
                <option value="Levemir">Levemir</option> 
                <option value="Ryzadeg">Ryzadeg</option> 
            </b-select>
        </b-field>
            </div>
            <hr>
        <div class="is-flex">
                <b-button type="is-text ml-4"  @click="standartRanges = !standartRanges">Standart aralık-doz bilgisi ekle</b-button>
            <b-button type="is-text"  @click="customRanges = !customRanges">Özel aralık doz bilgisi ekle</b-button>
        </div>
            </div>
            <div v-if="standartRanges" class="standartRanges">
                <hr>
                <b-table :data="standartData" :columns="standartColumns"></b-table>
            <p class="has-text-left my-6">Her 20 mg/dl için 1 ünite arttır.</p>
             <div v-if="frInsulin" class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Insulin (Hızlı Salınım)</p>
                <b-tag  class="is-success is-light" size="is-medium">{{frInsulin}}</b-tag>
                
            </div>
            <div v-if="srInsulin" class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Insulin (Yavaş Salınım)</p>
                <b-tag  class="is-success is-light" size="is-medium">{{srInsulin}}</b-tag>
                
            </div>
            <b-button @click="addStandartValues" class="is-info mt-6">Standart doz bilgisi ekle</b-button>
            </div>
            <div v-if="customRanges" class="custom-ranges">
                            <div  >
                <p class="mb-6 has-text-left">Aralık değerleri</p>
            <b-slider  tooltip-always size="is-large" tooltip-type="is-info " v-model="numbers" :min="50" :max="700" class="is-info mr-6" lazy></b-slider>
            <b-field  label="Alt sınır" class="has-text-left"  >
      <b-numberinput type="is-info is-light" v-model="numbers[0]"></b-numberinput>
    </b-field>
            <b-field label="Üst sınır" class="has-text-left"  >
      <b-numberinput type="is-info is-light" v-model="numbers[1]"></b-numberinput>
    </b-field>
            <p class="has-text-left mr-2 mb-3">Doz değeri</p>
            <b-input v-model='dose' required  min="1"  type="number"></b-input>
        </div>
        <div class="is-flex mt-4 mb-2 box is-justify-content-space-between is-flex-wrap-wrap">
            <div class="is-flex is-justify-content-flex-start">
               
            <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Zaman</p>
                <b-tag  class="is-light" size="is-medium">{{dayTime}}</b-tag>
            </div>
             <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Açlık durumu</p>
                <b-tag  class=" is-light" size="is-medium">{{isHungry}}</b-tag>
                
            </div>
            <div v-if="frInsulin" class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Insulin (Hızlı Salınım)</p>
                <b-tag  class="is-success is-light" size="is-medium">{{frInsulin}}</b-tag>
                
            </div>
            <div v-if="srInsulin" class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Insulin (Yavaş Salınım)</p>
                <b-tag  class="is-success is-light" size="is-medium">{{srInsulin}}</b-tag>
                
            </div>
                <div class="mr-2 is-flex is-flex-direction-column is-justify-content-flex-start">
                <p class="has-text-left">Aralık alt değer</p>
                <b-tag class="is-info is-light" size="is-medium">{{numbers[0]}}</b-tag>
                <!-- <p>{{numbers[0]}}</p> -->
            </div>
            <div class="mr-2 is-flex is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left"> Aralık üst değer</p>
                <b-tag class="is-info is-light" size="is-medium">{{numbers[1]}}</b-tag>
                <!-- <p>{{numbers[1]}}</p> -->
            </div>
            <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Insulin dozu</p>
                <b-tag  class="is-success is-light" size="is-medium">{{dose}}</b-tag>
                
            </div>
            
            </div>
            <div class="ml-2 is-flex is-justify-content-center is-align-items-center">
                <b-button @click="pushValues()" class="is-info">Ekle</b-button>
            </div>
        </div>
        <hr v-if="this.ranges.length && showPreview">
        <div v-if="this.ranges.length && showPreview" class="preview box">
            <p class="has-text-left has-textweight-bold" >Önizle</p>
            <hr class="mt-1">
        <div class="mt-2 is-flex is-align-items-baseline "
                  v-for="range in this.ranges"
                  v-bind:key="range._id">
                  <!-- <b-tag class="is-info is-light is-medium">{{}}</b-tag>  -->
                  <b-tag class="is-info is-light is-medium">{{range.dayTime}}</b-tag> 
                  <b-tag class="is-info mx-1  is-light is-medium">{{range.isHungry}}</b-tag> 
                  <b-tag v-if="range.srInsulin" class="is-info mx-1  is-light is-medium">{{range.srInsulin}}</b-tag> 
                  <b-tag v-if="range.frInsulin" class="is-info mx-1  is-light is-medium">{{range.frInsulin}}</b-tag> 
                  <b-tag class="is-info mx-1  is-light is-medium">{{range.lower}}</b-tag> 
                 <b-tag class="is-info mx-1 is-light is-medium">{{range.upper}}</b-tag> 
                 
                 <b-tag class="is-success mx-1 is-light is-medium">{{range.value}}</b-tag> 
                  
                  </div>
                  <b-button @click="confirmDoses()" v-if="this.ranges.length>1" class="is-info">Onayla</b-button>
                  <!-- <b-button v-if="this.ranges.length>1" class="is-info ml-2" outlined @click="$router.go(-1)">Geri dön</b-button> -->

        </div>
            </div>
            </div>
        <hr>
        <div v-if="doseInfo.length && afterMealValues.length">
            <!-- <p>{{doseInfo}}</p> -->
            <div class="is-flex">
                <p class="has-text-left is-size-5 mb-2" >Mevcut değerler</p>
            <p class="has-text-left is-size-5 mb-2" > - Tok</p>
            </div>
        <b-table class=" range-table"  :data="afterMealValues" :columns="columns"></b-table>
        </div>
        <div v-if="doseInfo.length && hungryValues.length">
            <!-- <p>{{doseInfo}}</p> -->
            <div class="is-flex">
                <p class="has-text-left is-size-5 mb-2" >Mevcut değerler - </p>
                <p class="has-text-left is-size-5 mb-2" > (Aç)</p>

            </div>
        <b-table class=" range-table"  :data="hungryValues" :columns="columns"></b-table>
        </div>
        

        
                
        
        
        
    
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import _ from "lodash";
export default {
    name: 'SetDose',
    data() {
    return {
        patientId: this.$route.params.patientId,
        customRanges:false,
        standartRanges:false,
        numbers: [80,120],
        value:20,
        rangeCount:3,
        dose:1,
        srInsulin:null,
        frInsulin:null,
        isHungry:null,
        dayTime:null,
        addedBy:null,
        showPreview:false,
        range:{
            lower:null,
            upper:null,
            value:null
        }, 
        standartRegime:[],

        ranges:[],
        columns:[
                    
                    {
                        field: 'lower',
                        label: 'Alt sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'upper',
                        label: 'Üst sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'value',
                        label: 'Değer (Unit)',
                        centered: true
                    },
                    {
                        field: 'dayTime',
                        label: 'Zaman',
                        centered: true
                    },
                    
                ] ,
                standartColumns:[
                    {
                        field: 'dayTime',
                        label: 'Zaman',
                        centered: true
                    },
                     {
                        field: 'mealStatus',
                        label: 'Açlık durumu',
                        centered: true
                    },
                    
                    {
                        field: 'lower',
                        label: 'Alt sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'upper',
                        label: 'Üst sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'value',
                        label: 'Değer (Unit)',
                        centered: true
                    },
                    
                    
                ] ,
                standartData:[
                    {
                        lower: 80,
                        upper: 130,
                        value: 8,
                        dayTime:"Sabah",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 80,
                        upper: 130,
                        value: 8,
                        dayTime:"Öğle",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 80,
                        upper: 130,
                        value: 8,
                        dayTime:"Akşam",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 80,
                        upper: 130,
                        value: 20,
                        dayTime:"Gece",
                        mealStatus:"Açlık"
                    },
                    
                ]
                    

                
      
    };
  },
  computed:{
            ...mapState(['doseInfo', 'doctorName','patientName']),
            hungryValues() {
      var hungry = "Aç";
      
      return _.filter(this.doseInfo[0].dose, function(data) {
        if (_.isNull(hungry)) {
          return true;
        } else {
          
          return data.isHungry == hungry ;
        }
      });
    },
    afterMealValues() {
      var hungry = "Tok";
      
      return _.filter(this.doseInfo[0].dose, function(data) {
        if (_.isNull(hungry)) {
          return true;
        } else {
          
          return data.isHungry == hungry ;
        }
      });
    },
        },
        
  methods:{
      ...mapActions(['getDoseInfoById']),
      handleCustomRanges(){
        
      },
      pushValues(){
            console.log(this.numbers[0],this.ranges[this.ranges.length-1])
          if(this.dayTime | this.isHungry == null ){
          alert("Zaman ve açlık durumu seçimi boş olmamalı!")
          } else if (this.ranges.length == 0 ){
                    this.ranges.push({
                dayTime:this.dayTime,
                    isHungry:this.isHungry,
              lower:this.numbers[0],
              upper:this.numbers[1],
              value:this.dose,
              srInsulin:this.srInsulin,
              frInsulin:this.frInsulin,
              addedBy:this.doctorName
          }) 
          this.showPreview = true;
           } else {
              if(this.ranges[this.ranges.length-1].upper >= this.numbers[0]){
                 console.log(this.ranges[this.ranges.length-1].upper, this.numbers[0])
                 this.showRangeError();
              } else if (this.ranges.length == 1 && this.dose <= this.ranges[0].value  ){
               console.log(this.ranges,this.ranges[0].value)
               this.showDoseInfoError();
              }
              else if (this.ranges.length > 1 && this.dose <= this.ranges[this.ranges.length-1].value){
               console.log(this.ranges,this.dose,this.ranges[this.ranges.length-1].value)
               // this.showRangeError();
              }
              
              else if (this.ranges.length > 1 && this.numbers[0] - this.ranges[this.ranges.length-1].upper > 1 ){
              this.showRangeGapError();
              console.log("hayır",this.numbers[0],this.ranges[this.ranges.length-1].upper)
               // this.showRangeError();
              }
                else {
                  this.ranges.push({
                    dayTime:this.dayTime,
                    isHungry:this.isHungry,
              lower:this.numbers[0],
              upper:this.numbers[1],
              value:this.dose,
              srInsulin:this.srInsulin,
              frInsulin:this.frInsulin,  
              addedBy:this.doctorName
          }) 
       
              }
            
          }
          
          
      },
      confirmDoses(){
          let rangesInfo = this.ranges;
          let patientId = this.patientId;
            this.$store.dispatch("addInsulinDoseInfo", {patientId,rangesInfo}); 
            console.log("confirm")
            this.showPreview = false;
            this.numbers[0] = 100,
            this.numbers[1] = 200,
            this.dose = 1

      },
      addStandartValues(){
        this.standartRegime.push({
            srInsulin:this.srInsulin,
            frInsulin:this.frInsulin,
            standartRanges:this.standartRanges
        });
          let insulinRegime = this.standartRegime;
          let patientId = this.patientId;

            this.$store.dispatch("addInsulinRegimeInfo", {patientId,insulinRegime}); 
            console.log("confirm",insulinRegime,patientId)
            

      },
      
      showDoseInfoError(){
         this.$buefy.dialog.alert({
                    title: 'İnsulin dozu',
                    type:'is-danger',
                    message: 'Aralıkların insulin dozu değeri aynı olmamalı.',
                    confirmText: 'Tamam'
                     
                })
      },
      showRangeError(){
         this.$buefy.dialog.alert({
                    title: 'Aralık hatası',
                    type:'is-danger',
                    message: 'Aralıkların alt ve üst sınır değerleri birbirleri ile aynı olmamalı.',
                    confirmText: 'Tamam'
                     
                })
  },
  toastInsulinDosesAddedConfirmation() {
      this.$buefy.toast.open({
        message: "İnsulin dozu değerleri başarıyla eklendi",
        type: "is-success",
        duration: 3000,
      });
    },
    showRangeGapError() {
      this.$buefy.dialog.alert({
        title: 'Aralık hatası',
        message: "Aralık değerleri arasında boşluk olmamalı.",
        type: "is-danger",
        duration: 3000,
        confirmText: 'Tamam'
      });
    },},
  created(){
      this.getDoseInfoById();
  }
  }
</script>

<style scoped >
.main{
    margin-top:10vh;
    margin-right:3%;
    margin-left:3%;
    margin-bottom:5% ;
}

@media screen and (max-width: 820px) {
  .main {
    
    margin-top: 10%;
  }
}
</style>