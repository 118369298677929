<template>
  <div class="mt-2 pt-2 mx-2">
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon>
            </b-loading>
          
    <div class="is-flex is-justify-content-space-between mx-2-desktop is-hidden-deskto is-align-content-baseline">
      
      <PatientSidebarNew></PatientSidebarNew>
      <div>
        <h3 class="is-size-5 mt-1  has-text-weight-bold">
          Kan şekeri değerleri
        </h3>
      </div>
      <div class="mr-2 mt-2">
        <b-button class="is-small" @click="navigateToReadingDetail">
          <i class="fas fa-plus" ></i>
        </b-button>
      </div>
    </div>
    <hr class="mb-1">
    <section class="is-flex dailyNumber-container mb-4">
        <div   
      v-for="dailyNumber in readingsToPresent"
      v-bind:key="dailyNumber.id"
   
        >
            <div class="box dailyNumber px-0 py-  m-1 mb-2"  :style="{ 'border-width': borderWidth(dailyNumber.times ) }" >
<p>{{dailyNumber.day | moment("D[ ]MMM")}}</p>
                <p>{{dailyNumber.times}}</p>
            </div>
                
             <!-- <Plotly
      
        :data="readingsData"
        :displaylogo="false"
        :layout="readingsLayout"
        
        :display-mode-bar="false"
      

      ></Plotly>
 -->            </div>
           

      </section>
    <div class="mt-5-desktop mt-2">
      <Plotly
      
        :data="data"
        :displaylogo="false"
        :layout="layout"
        :modeBarButtonsToRemove="modeBarButtonsToRemove"
        :display-mode-bar="true"
        :config="config"

      ></Plotly>
    </div>
      
   <!--  <div v-if="this.statusPositive & this.readings.length>=5" class="is-flex   is-align-items-flex-start  ml-4 mb-2 mb-4-desktop last-five is-flex-wrap-wrap">
      <b-tag class="mb-2 mr-2" size="is-medium" type="is-success is-light">Son 5 ölçümünüz istenen aralıkta.</b-tag>
      <b-tag size="is-medium" type="is-success is-light">İşler yolunda görünüyor! 👍</b-tag>
      </div>
      <div v-if="this.statusNegative & this.readings.length>=5" class="is-flex   is-align-items-flex-start  ml-4 mb-2 mb-4-desktop last-five is-flex-wrap-wrap">
      <b-tag class="mb-2 mr-2" size="is-medium" type="is-danger is-light">Son 5 ölçümünüz istenen aralığın dışında.</b-tag>
      <b-tag size="is-medium" type="is-danger is-light">Önerilere uymaya devam ediyor musunuz?</b-tag>
      </div> -->
    <div
      class="mx-4 is-flex is-justify-content-space-between is-align-items-flex-end"
    >
      <div class=" is-flex is-flex-direction-column is-align-items-flex-start">
        <div class=" is-flex is-flex-direction-colum is-align-items-flex-end mb-1">
          <div class="k is-flex is-flex-direction-column is-flex-direction-row-desktop mb-1 ">
            <b-tooltip
                label="Bugün veya bu haftaya ait değerleri görmek butonlara tıklayabilirsiniz."
                position="is-right"
                class="is-info"
                multilined
              >
               
            <p class="has-text-left mr-1">Filtrele</p>
            </b-tooltip>
            <div class="is-flex is-flex-wrap-wrap b ml-1-desktop">
              <b-button size="is-small" @click="setToday()" class="mr-1 mb-1"
                >Bugün</b-button
              >
              <b-tooltip
                label="Son 7 gündeki ölçümlerinizi görmek için tıklayabilirsiniz."
                position="is-bottom"
                class="is-info is-hidden-deskto"
                multilined
              >
              <b-button size="is-small" @click="setThisWeek()" class="mr-2 mb-1"
                >Bu hafta</b-button
              >
              </b-tooltip>
              <b-tooltip
                label="Bu butona tıklayarak başlangıç ve bitiş tarihlerini belirlediğiniz bir filtre oluşturabilirsiniz."
                position="is-bottom"
                class="is-info is-hidden-deskto"
                multilined
              >
              <b-button

                :class="{ active: showCustomFilter }"
                @click="showCustomFilter = !showCustomFilter"
                size="is-small"
                >Özel</b-button
              >
              <b-button
                class="ml-1"
                @click="showAllData"
                size="is-small"
                >Tümünü göster</b-button
              >
              </b-tooltip>
              <b-tooltip

                label="Filtreyi temizlemek için tıklayınız."
                position="is-left"
                class="is-info"
                multilined
              >
              <b-button v-if="this.filterExists" class="is-small is-light ml-1" @click="clearDates">
                <i class="fas fa-times"></i>
              </b-button>
              </b-tooltip>
            </div>
          </div>

          <div v-show="showCustomFilter">
            <div class="is-flex mb-1 is-justify-content-space-between is-align-items-baseline">
              <p class="has-text-left mr-2 mt-">Başlangıç</p>
              <b-input
                id="start-date"
                type="date"
                v-model="startDate"
              ></b-input>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <p class="has-text-left mr-6">Bitiş</p>
              <b-input
                id="end-date"
                type="date"
                @change="refresh"
                v-model="endDate"
              ></b-input>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="px-5-desktop k mb-2 is-flex is-align-items-flex-end is-flex-direction-colum ">
          <b-tooltip
                label="Grafiği yenilemek için tıklayabilirsiniz."
                position="is-left"
                class="is-info is-hidden-deskto"
                multilined
              >
          <b-button class="is-small  mr-2" @click="refresh" size="is-small" >
         <span>
         <i class="fas fa-redo"></i>  
         </span>
         <span class="is-hidden-mobile">
            Grafiği yenile
          </span>
        
         </b-button>
          </b-tooltip>
        <!--   <b-tooltip
                label="Ölçüm eklemek için tıklayabilirsiniz. Bu eylem sizi başka bir sayfaya yönlendirecek."
                position="is-left"
                class="is-info is-hidden-deskto"
                multilined
              >
        <b-button
          class="is-small mr-2"
          @click="navigateToReadingDetail"
          size="is-small"
        >
          <span>
            <i class="fas fa-plus"></i>
          </span>
          <span class="is-hidden-mobile">
            Ölçüm ekle
          </span>
        </b-button>
        </b-tooltip> -->
      </div>
    </div>
    <p v-if="!this.readings.length" class="has-text-left mt-4 pt-4 ml-4">
      Bu kullanıcıya ait kan şekeri değeri bulunmamaktadır.
    </p>
    <p v-if="!this.filteredData.length" class="has-text-left mt-4 pt-4 ml-4">
      Seçilen koşula uygun kan şekeri değeri yok. Eklemek ister misiniz?
    <span @click="navigateToReadingDetail">Ekle</span> </p>
    <div class="is-flex is-flex-wrap-wrap readings mt-5">
      <div
        v-for="reading in filteredData"
        v-bind:key="reading.id"
        v-on:mouseover="active = !active"
        class="a "
      >
        <div class="box mx-4-desktop mx-1-desktop ml-1 p-5-desktop my-2 ">
          <div class="is-flex is-flex-direction-column">
            <div class="is-flex is-justify-content-space-between mb-1">
              <b-tag class="mr-2 is-warning ">Kan şekeri</b-tag>
              <b-tag class="mr- is-danger">{{ reading.value }}</b-tag>
            </div>
            <div class="is-flex is-justify-content-space-between mb-1">
              <b-tag class="mr-2 ">Tarih</b-tag>
              <p class="is-right-aligned">{{ reading.date | moment("ll") }}</p>
            </div>
            <div v-if="reading.note" class="is-flex is-justify-content-space-between">
              <b-tag class="mr-2 is-info">Not</b-tag>
              <p>{{ reading.note }}</p>
            </div>
            <div v-if="reading.mealStatus=='after'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Tür</b-tag>
              <p>Tokluk</p>
            </div>
            <div v-if="reading.mealStatus=='before'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Tür</b-tag>
              <p>Açlık</p>
            </div>
            <div v-if="reading.dayTime=='morning'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Zamanı</b-tag>
              <p>Sabah</p>
            </div>
            <div v-if="reading.dayTime=='noon'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Zamanı</b-tag>
              <p>Öğle</p>
            </div>
            <div v-if="reading.dayTime=='evening'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Zamanı</b-tag>
              <p>Akşam</p>
            </div>
            <div v-if="reading.dayTime=='night'" class="is-flex is-justify-content-space-between mt-1">
              <b-tag class="mr-2 is-info is-light">Zamanı</b-tag>
              <p>Gece</p>
            </div>
            
          </div>
<div class="is-flex is-justify-content-flex-end y">
              <b-tooltip
                label="Silmek için tıklayınız."
                position="is-left"
                class="is-info"
              >
                <b-button
                  @click="deleteReading(reading)"
                  size="is-small"
                  class="is-rounded x"
                  ><i class="fas fa-times"></i
                ></b-button>
              </b-tooltip>
            </div>
        </div>
      </div>
    </div>
    
    <div
      @click="scrollToTop"
      class="sticky-button is-flex is-justify-content-center is-align-items-center "
    >
      <b-tooltip
      
        label="Yukarı gitmek için buraya tıklayabilirsiniz"
        position="is-left"
      >
        <b-icon pack="fas" icon="chevron-up" size="is-large is-medium-tablet">
        </b-icon>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { Plotly } from "vue-plotly";
import PatientSidebarNew from '@/components/PatientSidebarNew.vue';

export default {
  name: "Reading",
  components: {
    Plotly,
    PatientSidebarNew
  },
  data() {
    return {
      filterExists:null,
      isLoading: true,
      isFullPage: true,
      startDate: null,
      lowerLimit: 100,
      upperLimit: 125,
      endDate: null,
      active: false,
      daysArray:[],
      dailyNumberOfReadings:[],
      readingsToPresent:[],
      dailyNumbers:[],
      showCustomFilter: false,
      id: this.$route.params.id,
      data: [
        {
          x: [],
          y: [],
          type: "scatter",
          mode: 'lines+markers',
          marker: {
    /* color: 'rgb(128, 0, 128)', */
    size: 10
  },
          line: { shape: "line", width: 3 },
          
        },
      ],
      config: { locale: 'fr' },
      locale: 'tr',
      layout: {
        /* height:280, */
        
        title: {
         // text: `Ölçümler`,
          /* xanchor: "left",
          pad: 4, */
          font: {
            // family: 'Courier New, monospace',
            size: 24,
          },
          xref: "paper",
          x: 0.00,
          y: .97
        },
        xaxis: {
          type: "date",
          
          autorange: false,
          range: ["2022-04-04", "2022-04-04"],
        },

       shapes: [
          {
            type: "rect",
            x0: "2032-04-04",
            y0: 80,
            x1: "2017-04-04",
            y1: 130,
            fillcolor: "rgba(50, 171, 96, 0.6)",
            opacity: 0.2,
           
          },
           {
            type: "rect",
            x0: "2032-04-04",
            y0: 70,
            x1: "2017-04-04",
            y1: 160,
            fillcolor: "rgba(90, 171, 96, 0.6)",
            opacity: 0.2,
           
          },
          
        ],
        margin: {
          l: 50,
          r: 80,
          t: 5,
          /* b:-5, */
          pad: 4,
        },
      },
      modeBarButtonsToRemove: [
        "autoScale2d",
        "resetScale2d",
        "hoverClosestCartesian",
        "zoomIn2d",
        "zoomOut2d",
        /* "toggleSpikelines", */
        "sendDataToCloud",
        "resetViewMapbox",
        "resetViews",
        "select2d",
        "lasso2d",
        "pan2d",
        "zoom2d",
        /* "toImage", */
        "hoverCompareCartesian"
      ],
      /* readingsData: [
        {
          
          type: "pie",
           hole: .4,
          values:[],automargin:false,
         labels: ["df"],
  textinfo: "label+percent",
  textposition: "outside",
          
        },
      ],
      
      readingsLayout: {
        height: 80,
  width: 80,
    margin: {"t": 0, "b": 0, "l": 2, "r": 2},
     showlegend: false
        
       
      }, */
      
    };
  },
  computed: {
    ...mapState(["readings"]),
    borderWidth() {
    return (percentage) => {
      return `${percentage}px`;
    }
  },
    
    filteredData() {
      var startDate = this.startDate;
      var endDate = this.endDate;
      return _.filter(this.readings, function(data) {
        if (_.isNull(startDate) && _.isNull(endDate)) {
          return true;
        } else {
          var date = data.date;
          return date >= startDate && date <= endDate;
        }
      });
    },
    statusPositive(){
       const positiveStatus = this.readings.slice(0,5).map(x=>x.value).every(x=>x > 100 && x<125 ); 
       /* console.log(this.readings.slice(0,5).map(x=>x.value).every(x=>100<x<125); */
       console.log(positiveStatus)
       return positiveStatus;
    },
    statusNegative(){
       const negativeStatus = this.readings.slice(0,5).map(x=>x.value).every(x=> x>125 ); 
       /* console.log(this.readings.slice(0,5).map(x=>x.value).every(x=>100<x<125); */
       console.log("negative",negativeStatus)
       return negativeStatus;
    }
    
  },
  methods: {
    ...mapActions(["getGlycosisDataById", "deleteReading"]),
    scrollToTop(){
                 window.scrollTo(0,0)
            },
    prep() {
      this.filteredData.forEach((reading) => {
        this.data[0].y.push(reading.value);
        this.data[0].x.push(reading.date);
        //console.log(reading.date)
      });
    },
    setToday() {
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
        this.filterExists = true;
      // console.log(this.endDate)
      this.refresh();
      

    },
    showAllData() {
      this.startDate = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1]).subtract(1, "days").format("YYYY-MM-DD");
      
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
       console.log(this.startDate,this.endDate)
         this.refresh();
         this.layout.xaxis.range[0] = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1]).subtract(1, "days").format("YYYY-MM-DD");
         this.layout.xaxis.range[1] = moment()
        .add(2, "days")
        .format("YYYY-MM-DD"); 
    },
    setCustomDay(start){
      this.startDate= moment(start).format("YYYY-MM-DD"); 
      this.endDate=moment(start).add(2,"days").format("YYYY-MM-DD");
      this.refresh();
      this.filterExists=true;
      console.log(this.startDate,this.endDate)
    },
    setThisWeek() {
      this.startDate = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
      // console.log(this.endDate)
      this.refresh();
      this.layout.xaxis.range[0] = moment().subtract(7, "days").format("YYYY-MM-DD")
      /* this.layout.xaxis.range[1] = moment().add(1, "days").format("YYYY-MM-DD") */
       this.filterExists = true; 
   },
    clearDates() {
      this.startDate = null;
      this.endDate = null;
      // console.log(this.startDate, this.endDate)
      this.refresh();
      this.filterExists = false;
    },
    navigateToReadingDetail() {
      this.$router.push({
        path: `/user/patient/${this.$route.params.id}/read-details`,
      });
    },
    deleteReading(reading) {
      this.$buefy.dialog.confirm({
        title: "Kan şekeri silme işlemi",
        message: `Bu glikoz değerinizi <b>silmek</b> istediğinizden emin misiniz? - ${reading.value}`,
        confirmText: "Glikoz değerini sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          //console.log("r",reading)
          await this.$store.dispatch("deleteReading", reading);

          this.refresh();

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    refresh() {
      this.data[0].y = [];
      this.data[0].x = [];
   //this.layout.xaxis.range[0] = moment(this.filteredData[this.filteredData.length-1].date).subtract(1, "days").format("YYYY-MM-DD")
   console.log("filteredData", Object.entries(this.filteredData[0])[3][1]);
     this.layout.xaxis.range[0] = moment().subtract(15, "days").format("YYYY-MM-DD")
     this.layout.xaxis.range[1] = moment(Object.entries(this.filteredData[0])[3][1])
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.prep();
      console.log("refresh finished")
    },
    isLoad(){
        this.isLoading = false
        console.log("load finished", this.isLoading)
    },
    async a() {
      await this.getGlycosisDataById();
     
      if(this.readings.length>0){
        console.log(this.readings.length)
        await this.isLoad();
      }
       await this.refresh();
      await this.showTrends();
      await this.scrollTo();
      await this.getDaysArray(new Date(this.readings[this.readings.length-1].date),new Date());
      await this.testFunc()

    },
    showTrends(){
      let groups = _.groupBy(this.readings, function (reading) {
  return moment(reading.date).startOf('day').format();
  
});
/* console.log("trends",groups) */

let result = _.map(groups, function(group, day){

    return {
        day: moment(day).format("YYYY-MM-DD"),
        times: group.length
    }
});



this.dailyNumberOfReadings = result;
/* console.log("result", result) */
    },

    testFunc(){
     
const mergedArray = this.daysArray.concat(this.dailyNumberOfReadings);

     const objectArray = this.dailyNumberOfReadings.map(item => {
  // Use the Object.assign() method to create a new object with the same
  // date as the current item and a value of 0
  return Object.assign({}, {day: item.day, times: 0});
});

/* console.log("oa",objectArray) */
// Use the filter() and some() methods to remove any elements from
// mergedArray that have the same date and value properties as any element
// in objectArray

const filteredArray = mergedArray.filter(item => {
  // Use the some() method to check if objectArray includes at least one
  // element with the same date and value properties as the current element
  const includes = objectArray.some(
    otherItem => otherItem.day === item.day && otherItem.times === item.times
  );
  // If objectArray includes at least one element with the same date and
  // value properties, return false to remove the element from mergedArray
  return !includes;
});

filteredArray.sort((a, b) => {
  if (a.day < b.day) {
    // If the day property of the first element is less than the day property
    // of the second element, return a negative value to sort the elements
    // in ascending order
    return -1;
  }
})

this.readingsToPresent = filteredArray.slice(-30).reverse()

console.log(this.readingsToPresent.map(x => x.times))
this.readingsData[0].values = this.readingsToPresent.map(x => x.times);
    }, 
getDaysArray(start, end) {
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push({day:moment(new Date(dt)).format("YYYY-MM-DD"),times:0});
    }
    this.daysArray = arr;
    //console.log("days",this.daysArray)
    return arr;
} ,
merge(a, b, p){
  //const sortedArr = [];
  const mergedArray =   a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b)
  
 // console.log("sorted",mergedArray.map(x => sortedArr.push({day:Date.parse(x.day),times:x.times})))
//  console.log("merge",typeof mergedArray[0].day, mergedArray)
return mergedArray;
},
test(){
  const myArrayFiltered = this.daysArray.filter( el => {
  return this.dailyNumberOfReadings.some( f => {
    return f.day == el.day ;
  });
  
});
console.log("test",myArrayFiltered)
},

setDaysArray(){
  
  const values = this.merge(this.daysArray, this.dailyNumberOfReadings,  "day")
  /* console.log("union", [...new Set(_.unionBy( this.dailyNumberOfReadings,this.daysArray,  "day").sort((a,b)=>{Number(b.day) - Number(a.day)}))])  */
  const varray = [];
  values.map(x => varray.push({day:new Date(x.day), times:x.times}));
  const sortedV = varray.sort((a,b)=>{Number(b.day) - Number(a.day)});
  /* this.dailyNumbers = _.unionBy(this.dailyNumberOfReadings,this.daysArray, 'day').sort((a,b)=>{Number(a.day) - Number(b.day)}) */
  console.table("values", sortedV)  
} ,
 scrollTo() {
  /* console.log(document.getElementsByClassName('dailyNumber-container')[0]) */
  document.getElementsByClassName('dailyNumber-container')[0].scroll(100,0)
    /* document.getElementsByClassName('dailyNumber')[item].scrollIntoView(); */    
}
  },
  mounted() {
    this.a();
  },
  created(){
   // this.showTrends();
   /* this.currentStatus(); */
  },
};
</script>

<style scoped>
.b {
  /* flex-basis: 10%; */
  min-width: 200px;
}

.a {
  flex-basis: 50%;
}

.dailyNumber-container{
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y:hidden;
  
}


.dailyNumber{
  height: 5rem;
  width: 5rem;
  border: solid antiquewhite;
  
  border-radius: 50px;
  /* background: */ /* conic-gradient(antiquewhite var(--progress), white 80deg); */ /* linear-gradient(135deg, red, blue); */

}

/* 
.dailyNumber::after{
  content: attr(data-progress) '%';
  
} */

.x {
  width: 15px;
  height: 25px;
  border: black;
  border-top-style: solid;
  border-left-style: solid;
  /* border-top-left-radius: 20%; */
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  /* border-left-color: grey ;
  border-top-color: grey ; */
  /* border-top: 1px ; */
  
}

.y{
  position: relative;
  bottom: -20px;
  right: -20px;
  /* height: 15px; */
}

.is-right-aligned {
  text-align: right;
}

.readings {
  margin-bottom: 60px;
}

@media screen and (max-width: 374px) {
  .a {
    flex-basis: 40%;
  }
}



.sticky-button {
  position: -webkit-sticky;
  position: sticky;
  bottom: 5vw;
  left: 85vw;
  height: 5rem;
  width: 5rem;
  border: solid antiquewhite 2px;
  border-radius: 50px;
  background-color: antiquewhite;
}


@media screen and (max-width: 415px) {
  .sticky-button {
    bottom: 18vw;
    left: 75vw;
  }
}


@media screen and (max-width: 415px) {
  .last-five {
    width: 98%;
  }
}


@media screen and (max-width: 415px) {
  .b {
    width: 70%;
  }
}
</style>
