<template>
<div >
<div class="desktop-intro-bg  ">
        <section class="info-text ">
    <div class="a info-text px-6-desktop py-4-desktop part columns is-flex is-align-items-center is-flex-direction-column-mobile has-background-info-light">
    <div class="b column mx-4 is-size-6 is-hidden-mobile is-flex">
        <img src="@/assets/medicine-ill.svg" alt="Doctors" class="c">
    </div>
    <div class="column c is-flex is-align-items-flex-start is-justify-content-center is-flex-direction-column ml-6-desktop mx-2 pt-0 pb-0  ">
       <div class="mt-2-desktop d px-6-desktop p-1 has-text-left mt-4 ">
           <img src="@/assets/medicine-ill.svg" alt="Doctors" class="is-128x128 mobile-svg is-hidden-desktop is-hidden-tablet">
       </div>
        <h1 class="ml-5-desktop my-1 is-size-3-desktop is-size-4 has-text-left has-text-weight-bold pb-3 my-6-desktop my-2 mb-1">
            Doktorunuzu seçin
            <br>Randevunuzu alın. 
            <br>Görüşmenizi gerçekleştirin.
        </h1>
        <p  class="  ml-5-desktop  pb-3 my-3 is-size-4-desktop is-size-6 has-text-left">
            Hastaneye gelmeden doktorunuzla görüşün.
        </p>
         <b-button type="" class="ml-5-desktop my-3 is-size-4-desktop is-size-5 has-text-left intro-button" tag="router-link" to="/mock-login">
             Şimdi randevunuzu alın
         </b-button>
    </div>
    
</div>
</section>
</div>

<section class="mt-6 pt-2 mb part  is-hidden-desktop">
     <div class="">
         <h3 class="has-text-left  is-size-3 is-size-3-desktop ml-5 pl-3 my-5">Sorular</h3>
     <!-- <hr> -->
    <b-collapse
            class="card"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index">
            <template #trigger="props">
                <div
                    class="card-header"
                    role="button"
                >
                    <p class="ml-2 card-header-title has-text-left">
                        {{ collapse.title }}
                    </p>
                    <a class="card-header-icon">
                        <b-icon
                            :icon="props.open ? 'fas fa-sort-down' : 'fas fa-sort-up'">
                        </b-icon>
                    </a>
                </div>
            </template>
            <div class="card-content">
                <div class="content">
                   <p class="is-size-7 has-text-left">{{ collapse.text }}</p> 
                </div>
            </div>
        </b-collapse>
     </div>
</section>
<div class=" has-background-info-dark paper-background bottom-border ">
<section class="research-info part has-background-info">
    <div>
        <p class=" has-text-left pt-4 ml-6 is-size-4 has-text-white">
            Araştırmalar şunu söylüyor
        </p>
        <b-carousel :interval="interval">
        <b-carousel-item v-for="(carousel, i) in carousels" :key="i">
            <section :class="`hero is-medium is-${carousel.color}`">
                <div class="hero-body has-text-centered">
                    <h1  class="title is-size-6 is-size-4-desktop">{{carousel.text}}</h1>
                </div>
               
            </section>
        </b-carousel-item>
        
    </b-carousel>
    
    </div>
    <div class="is-flex is-justify-content-flex-end mr-4 pb-5">
        <a href="https://journals.sagepub.com/doi/full/10.1177/19322968211064633" target="blank" class="is-underlined has-text-white is-size-7 source">Kaynak</a>
    </div>
    
</section>

</div>
<div class="has-background-info-light  ">

    <section class="is-hidden-touch part questions  has-shadow-info">
   
    <div   @mouseover="questionTooltipState = true"  
     @mouseleave="questionTooltipState = false" class="columns part is-flex is-align-items-center has-background-info-dark px-4" >
        <div class=" qs column is-8 tabs is-flex is-align-items-flex-start is-flex-direction-column is-justify-content-space-evenly  p-4-desktop m-6-desktop">
            <p data-target="q1" class="is-size-6-mobile is-size-4 has-text-left has-text-white multiline-text ml-6-desktop my-6" @click="showAnswer" >Ludi'de hangi hizmetleri alabilirim? </p>
            <p data-target="q2" class="is-size-6-mobile is-size-4 has-text-left has-text-white multiline-text ml-6-desktop my-6" @click="showAnswer">Bir görüşme neleri içeriyor?</p> 
            <p data-target="q4" class="is-size-6-mobile is-size-4 has-text-left has-text-white ml-6-desktop multiline-text my-6" @click="showAnswer">Görüşmeye katılmak için ne yapmam gerekir?</p>    
            <p data-target="q3" class="is-size-6-mobile is-size-4 has-text-left has-text-white multiline-text ml-6-desktop my-6" @click="showAnswer">Görüşmeye katılmak için hangi cihazlara ihtiyaç duyacağım?</p>
            
            <p data-target="q7" class="is-size-6-mobile is-size-4 has-text-left has-text-white ml-6-desktop multiline-text mr-2 my-6" @click="showAnswer">Teknik bir aksaklık yaşanması halinde yeni bir görüşme isteyebilir miyim?</p>         
            <!-- <p data-target="q5" class="is-size-6-mobile is-size-4 has-text-left has-text-white ml-6-desktop my-6" @click="showAnswer">Görüşmeye katılmak için hangi cihazlara ihtiyaç duyacağım?</p>        -->
            <!-- <p data-target="q6" class="is-size-6-mobile is-size-4 has-text-left has-text-white ml-6-desktop multiline-text mx-2 my-6" @click="showAnswer">Görüşmeye başka kimler katılabilir?</p>      -->   
        </div>
        <div id="answers" class="column  is-4 is-flex is-align-items-center is-flex-direction-column is-justify-content-center p-4 mr-4">
            <p id="q1" class="is-size-4 has-text-white-ter has-text-left my-2">Diyabetinizle ilgili verileri kaydedebilir, bunlarla ilgili profesyoneller ile görüntülü görüşebilirsiniz.</p>
            <p id="q2" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2">Diyabet ile ilgili genel sorularınızı iletebilir, kendi deneyiminizi paylaşabilir, profesyonellerin önerilerini alabilirsiniz. </p>
            <p id="q4" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2"> Yaş, boy, kilo, kan şekeri değerleri gibi görüşme sırasında gerekli olabilecek verileri kaydettikten sonra, randevu alarak görüşmeye katılabilirsiniz. </p>
            <p id="q3" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2"> Kamera, mikrofon ve internet bağlantısı olan ve bir web tarayıcısını çalıştırabilen herhangi bir cihazla ile görüşmeye katılabilirsiniz.</p>
             <p id="q7" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2">  Teknik bir aksaklık yaşanması halinde tekrar görüşme talep edebilirsiniz.</p>
             <!-- <p id="q5" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2">  </p> -->
            <!-- <p id="q6" class="is-hidden is-size-4 has-text-white-ter has-text-left my-2">  Görüşmeye bir yakınınızı da davet edebilirsiniz.</p> -->
        </div>
    </div>
    <transition name="fade">
    <b-notification type="is-info is-light" v-if="questionTooltipState" @click="questionTooltip" aria-close-label="Close notification">
            Soruların üzerine tıklayarak cevapları görüntüleyebilirsiniz        
        </b-notification>
    </transition>
</section>

    </div>
    <div class="has-background-info-dark">
    <section   class="staff part  is-flex is-flex-direction-column   has-background-info-light pb-6">
    <h3 @click="navigateToDoctors" class="has-text-left has-text-weight-large is-size-3 is-size-3-desktop ml-6 mt-5 pt-6">Doktorlar</h3>
    <hr>
    <div class="doctors part is-flex is-flex-wrap-wrap is-justify-content-center px-5 " >
<!--    <div v-for="doctor in doctors.data.slice(0,4)" v-bind:key="doctor.id" class="card pos-fm  mx-4 my-4">
        <figure class="image">
      <img :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${doctor.photoId}.jpg`" alt="Placeholder image">
    </figure>
        <div class="card-content">
            <div class="media">
            
            <div class="media-content alignment">
                <router-link :to="`/user/doctor/${doctor.id}`" class="title is-4 mb-3">{{doctor.name}} {{doctor.surname}}</router-link>
                
                
            </div>
            </div>
            <div class="content  is-flex is-flex-direction-column ">
                <b-tag class="mt-2">Endokrinoloji</b-tag>
               
            </div>
        </div>


         
</div> -->

      <div v-for="doctor in doctorInfo.data.slice(0,4)" v-bind:key="doctor.id" v-show="doctor.photoId" class="card  mx-4 my-4">
       
            
            <!-- <figure class=""> -->
      <img class="doctor-image" v-if="!doctor.photoId && doctor.gender == 'FEMALE'" src="@/assets/female-doctor-avatar.png" alt="Placeholder image">
       <img class="doctor-image" v-else-if="!doctor.photoId && doctor.gender == 'MALE'" src="@/assets/male-doctor-avatar.png" alt="Placeholder image">
        <img class="doctor-image" v-else :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${doctor.photoId}.jpg`" alt="Placeholder image">
    <!-- </figure> -->
        
        <div class="card-content">
            <div class="medi">
            
            <div class="media-content alignment">
                <!-- <router-link :to="`/user/doctor/${doctor.id}`" class="title is-4 mb-3">{{doctor.name}} {{doctor.surname}}</router-link> -->
                <p class="title is-4 mb-3">{{doctor.name}} {{doctor.surname}}</p>
                
                
            </div>
            </div>
            <div class="content  is-flex is-flex-direction-column ">
                <b-tag class="mt-2">Endokrinoloji</b-tag>
               
            </div>
        </div> 
      

</div> 
</div>
  
</section>

</div>
<div class="trick has-background-info-dark"></div>
<section>
     <footer class="footer has-background-info-dark">
  <div class="is-flex is-flex-direction-column-mobile is-flex-direction-row-desktop  is-justify-content-space-around is-align-items-center  content">
      
      <div class="is-flex-direction-column is-align-items-flex-start"> 
          <div class="is-flex is-flex-direction-column is-align-items-center">
          <!-- <li  class="is-size-4 has-text-light has-text-left">Doktorlar</li> -->
          <router-link :to="'/doctors'" class="is-size-4 has-text-light has-text-left">Doktorlar</router-link>
          <router-link :to="'/faq'" class="is-size-4 has-text-light has-text-left">Sık Sorulan Sorular</router-link>
          <router-link :to="'/services'" class="is-size-4 has-text-light has-text-left">Hizmetler</router-link>
          <router-link :to="'/communication'" class="is-size-4 has-text-light has-text-left">İletişim</router-link>
             
          </div>
           
           <b-button @click="scrollToTop" type="is-warning" class="is-large ml-5 my-3 has-text-left is-rounded">
             En yukarı git 
         </b-button>
         <h2 class="has-text-weight-bold is-size-2 has-text-white" >Ludi</h2>
      </div>
      
    
  </div>
  
</footer>
</section>

</div>
</template>    


<script>
import {mapState, mapActions} from 'vuex';
//import axios from 'axios'

export default {
    name: 'Intro',
    data(){
          return{
              isOpen: 0,
              questionTooltipState:false,
               carousels: [
                { text: 'Eğitim, izlem ve danışmanlık faaliyetlerini içeren', color: 'info' },
                { text: 'hastaların günlük, haftalık veya aylık periyotlarda uzaktan görüşmeler ile takip edildiği', color: 'info' },
                { text: '6 ay(medyan) sürdürülen kontrollü çalışmalarda', color: 'info' },
                { text: "HbA1c değerinin %2,2'ye kadar düşürülebileceği belirtilmektedir", color: 'info' },
                
                
            ],
                collapses: [
                {
                    title: "Ludi'de hangi hizmetleri alabilirim?",
                    text: 'Diyabetinizle ilgili verileri kaydedebilir, bunlarla ilgili profesyonellerle görüntülü görüşebilirsiniz.'
                },
                {
                    title: 'Bir görüşme neleri içeriyor?',
                    text: 'Diyabet ile ilgili genel sorularınızı iletebilir, kendi deneyiminizi paylaşabilir, profesyonellerin önerilerini alabilirsiniz.'
                },
                {
                    title: 'Görüşmeye katılmak için ne yapmam gerekir?',
                    text: 'Yaş, boy, kilo, kan şekeri değerleri gibi görüşme sırasında gerekli olabilecek verileri kaydettikten sonra, randevu alarak görüşmeye katılabilirsiniz.'
                },
                {
                    title: 'Görüşmeye katılmak için hangi cihazlara ihtiyaç duyacağım?',
                    text: 'Kamera, mikrofon ve internet bağlantısı olan ve bir web tarayıcısını çalıştırabilen herhangi bir cihazla ile görüşmeye katılabilirsiniz.'
                },
                {
                    title: 'Teknik bir aksaklık yaşanması halinde yeni bir görüşme isteyebilir miyim?',
                    text: 'Teknik bir aksaklık yaşanması halinde tekrar görüşme talep edebilirsiniz.'
                },
                {
                    title: 'Görüşmeye başka kimler katılabilir?',
                    text: 'Görüşmeye bir yakınınızı da davet edebilirsiniz.'
                }
                ],
            
              
              interval:6000,
              
              
              
              
          }; 
        },
        computed:{
            ...mapState(['doctorInfo'])
        },
        methods:{
            navigateToDoctors(){
                this.$router.push('/doctors')
            },
            questionTooltip(){
                this.questionTooltipState = !this.questionTooltipState
                
            },
             ...mapActions(['getBasicDoctorInfo']),
            showAnswer(){
                const questions = document.querySelectorAll('.qs p')
                const answers = document.querySelectorAll('#answers > p')

                

                questions.forEach(
                    (question) => {
                         question.addEventListener('click', () => {
                             questions.forEach(item => item.classList.remove('is-active'));
                             question.classList.add('is-active');
                    const target = question.dataset.target
                          
                   
                    answers.forEach(answer => {
                        if(answer.getAttribute('id')===target){
                            answer.classList.remove('is-hidden')
                        } else {
                            answer.classList.add('is-hidden')
                        }
                    })
                   

                   })   

                    
                    }
                )
            },
            scrollToTop(){
                 window.scrollTo(0,0)
            },
            /* async getRandomUsers(){
        
                 let response = await axios.get(this.endpoint +this.number,{
                     "Content-Type":"application/json",
                     "Access-Control-Allow-Origin": "*"
                 })
                 //.then(response => response.json())
                //.then(response => this.people = response.data.results).then(console.log(this.people))
                //.catch(function (error) {
                  //  console.log(error);
             //})
              this.people = response.data.results 
              console.log(response.data.results)
            } */ 

        
        },
        //created: function(){
          //  this.getRandomUsers();
        //}
        async mounted(){
             /* this.fetchDoctors(); */
            await this.getBasicDoctorInfo();
         }     
        
    
}
</script>

<style scoped>


html {
    margin-top: 0px;
    box-sizing: border-box;
   
}

/* 
.questions {
    opacity: 0.9;
} */
/* .gradient-bg{
    background: rgb(255,255,255);
background: linear-gradient(56deg, rgba(255,255,255,1) 21%, rgba(62,142,208,0.6699054621848739) 65%);
} */

/* .info-text{
    background-image: linear-gradient(to right, #edf1f5, #0d68ce);
} */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


.columns {
    
    margin: 0px;
    
}

.desktop-intro-bg{
    
    border-bottom: 0d68ce;
    
}
.doctors{
    width: 100vw;
    margin: 0 auto;
    height: 100%;
}

@media screen and (min-width: 1024px) {
  .desktop-intro-bg{
  background-color: #167df0;
  }
}


@media screen and (max-width: 769px) {
  .paper-background{
  background-color: #ecf4fe !important;
  }
}

.doctor {
border: 2px solid blue;
border-radius: 25px;
flex-basis:40%;
    
}
/* 
.research-info{
    border: 2px solid 167df0;
border-bottom-right-radius: 25px;
} */

.mobile-svg{
    height: 40vh;
    width: 100%;
}

.footer {
  
    margin: 0 auto;
    position: absolute;
    width: 100%;
    
}

.bottom-border{
    border-bottom: 2px  #0d68ce;
}

@media screen and (max-width: 821px) {
  .bottom-border{
  background-color: #ecf4fe !important;
  }
}

.info-text{
    min-height: 88vh;
    /* max-height: 100vh; */
    
}

.part{
border-bottom-left-radius: 0px;
    border-bottom-right-radius: 50px;
}

.intro-button {
    background-color: #0d68ce ;
    color: white;
}

.doctor-image{
      width:40vh;
    max-height:30vh;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .doctor-image{
    width:40vw;
    max-height:30vh;
  }
}
/* .doctor-card:has(.image){
    display: visible;
    background-color: green;
} */
</style>