<template>
    <div class="mb-5" >
<b-navbar id="nav" class="pt-0  mb-0" :fixed-top="true" >
      
        <template #brand>
             <div style="margin: auto 0" class="ml-4-desktop ml-1 ml-3-tablet" >
        <PatientSidebarNew class="mt-1" v-show="$route.meta.patientSidebarShow"></PatientSidebarNew>
        <DoctorSidebar  v-show="$route.meta.doctorSidebarShow" ></DoctorSidebar>
        <AdminSidebar v-show="$route.meta.adminSidebarShow" ></AdminSidebar>
        <PreviousPage></PreviousPage>

         </div> 
            <b-navbar-item  class="m-4-desktop pad">
                <h2 class="has-text-weight-bold is-size-3-desktop is-size-3-tablet is-size-4  has-text-info" >Ludi</h2>
            </b-navbar-item>
        </template>
       <template #start>
            <!-- <b-navbar-item class="is-size-5" v-if="runningLocally" href="/user/doctor/629a054f04aed1144a29fd4e">
                Hekim Profili
            </b-navbar-item>
            <b-navbar-item class="is-size-5" v-else href="/user/doctor/61e2b8c44a62f50850f5237d">
                Hekim Profili
            </b-navbar-item>
            <b-navbar-item class="is-size-5" v-if="runningLocally" href="/user/629a047004aed1144a29fd39" >
                  Hasta Profili 
            </b-navbar-item>
            <b-navbar-item class="is-size-5" v-else href="/user/61e434cb9fbe751224c65e24" >
                  Hasta Profili 
            </b-navbar-item> 
            
             
            <b-navbar-item class="is-size-5" >
              <router-link to="/admin">Admin Profili</router-link>  
            </b-navbar-item> -->
            <!-- <b-navbar-item class="is-size-5"  v-show="$route.meta.userProfileNewTab" @click="navigateToUserProfileOnNewTab"  >
                  
                  Profili göster 
                  
            </b-navbar-item>  -->
          
        </template>  
      
        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                   <b-button v-if="!$route.meta.hideRegister" href="" class=" is-size-5 has-text-white register-button" tag="router-link" to="/register"> Kaydol</b-button> 
                   <b-button v-if="!$route.meta.hideLogin" href="" class="is-size-5" tag="router-link" to="/mock-login"> Giriş Yap</b-button>
                   <b-button v-if="!$route.meta.hideLogout" href="" class="is-size-5 has-text-weight-bold"  @click="logoutUser">Çıkış Yap</b-button> 
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
    </div>
</template>

<script>
    import PatientSidebarNew from '@/components/PatientSidebarNew.vue';
    import DoctorSidebar from '@/components/DoctorSidebar.vue';
    import AdminSidebar from '@/components/AdminSidebar.vue';
    import PreviousPage from '@/components/PreviousPage.vue';
import { mapState } from 'vuex';

    export default {
        components:{
            PatientSidebarNew,
            DoctorSidebar,
            AdminSidebar,
            PreviousPage
        },
        name: 'Navbar',
        methods:{
            logoutUser() {
      this.$store.dispatch("logoutUser");
      this.$router.push({path:`/login`})
    },
    navigateToUserProfileOnNewTab() {
      let route = this.$router.resolve({ path: `/user/${this.patientIdForMeeting}` });
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
       /* this.$router.push({ path: `/user/${this.patientIdForMeeting}` }); */
    },
        },
        computed: {
          ...mapState('patientName','patientIdForMeeting'),
    runningLocally() {
      return window?.location?.origin.includes("localhost");
    }
    
    
  },
  
  created(){
    
  }
  }
</script>

<style lang="scss" scoped>


#nav {
   //  height: 6rem;
    
    padding-left: 30px;
    padding-right: 30px; 
}



/* @media screen and (max-width: 800px) {
  #nav {
    height: 5rem; 
  }
} */

@media screen and (max-width: 414px) {
  .pad{
    padding-bottom: 0;
    /* padding-top: 0; */
    /* padding-left: 15px; */
}
}


@media screen and (max-width: 414px) {
  #nav {
    padding-left: 10px;
    padding-right: 10px;
    /* background: #ecf4fe; */
    
    /* height: 10px; */
    
}
}

.register-button {
     background-color: #0d68ce ;
    color: white;
}

</style>