<template>
    <div class="card is-flex is-justify-content-space-between px-2 py-2"  >
          <div class="is-flex">
            <h3 class="is-size-6 has-text-weight-bold has-text-left text-pointer">Mevcut oda adı: </h3>
            <b-tag class="ml-1">{{singleUser.data.meetingRoom}}</b-tag>
          </div>
          <b-button class="is-info ml-1 is-small" @click="setRoom" >Oda edin</b-button>
        </div>
</template>


<script>



import {mapState} from 'vuex';




export default {
  name: 'Home',
  data(){
    
    return {
      doctor:{
       doctorId:this.$route.params.id, 
      }
    }
  },
  components: {
    


  },
  methods: {
   async setRoom() {
      let doctor= this.doctor;
      console.log(doctor)
      if(this.singleUser.data.meetingRoom){
        alert("Zaten bir odanız var.")
      } else {
        this.$store.dispatch("setRoom", {doctor});
      }

      /* await axios.post('http://localhost:3000/set-room-to-doctor', doctor)
                    .then((response) => {
                        console.log(response.data)
                    } ).catch((error) =>
                    {console.log(error)}) */
    }
    
  },
  computed:{
    ...mapState(['singleUser']),
  }

}
</script>