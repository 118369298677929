<template>
    <div class="">
        
        
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-baseline">
                <h3 @click="navigateToNotesEdit" class="is-size-5 mr-1">Genel Notlar</h3>
                <span @click="navigateToNotesEdit" class="icon is-medium ">
                    <i class="fas fa-edit edit-icon"></i>
                  </span>
            
              </div>
             <div>
               <b-button type="is-text" class="is-small"  @click="isHidden = !isHidden"><span v-if="isHidden">Gizle</span>  <span v-if="!isHidden">Göster</span></b-button>
                <!-- <b-button @click="navigateToNotesEdit" type="is-info" aria-label="patient-notes" outlined> -->
                  
                  <!-- <span class="button-text">Düzenle</span>
                </b-button> -->
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>
                
                
                <div v-if="isHidden"> 
                  
                  
                
                  <div v-if="!notes.data.length">
                  Henüz not eklemediniz.
                </div>
                  <div
               
                  class="is-flex mr-6"
                  v-for="note in notes.data"
                  v-bind:key="note.id"
                >
                  <!-- <p>{{ note.content }}</p>
                  <b-button @click="deleteNote(note)" size="is-small" type="is-ghost" icon-left="times">
                  </b-button> -->
                  <p class="multiline-text mb-2" size="is-medium" >{{ note.content }}</p>
                  <!-- <p @click="deleteNote(note)">Sil</p> -->
                  <b-button @click="deleteNote(note)" size="is-small" class="delete-button-about p-2 mt-1 ml-1"
                ><i class="fas fa-times"></i
              ></b-button>
                </div>
                </div>
              </div>
             
            </div>
          
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  name: 'GeneralNotes',
  data(){
     return {

        id: this.$route.params.id,
        isHidden: true
     } 
  },
  computed:{
            ...mapState(['notes'])
        },
        methods:{
            ...mapActions(['getGeneralNotesById','deleteNote']),
            navigateToNotesEdit() {
       this.$router.push({ path: `/user/patient/${this.id}/info-edit` });
    },
    deleteNote(note) {
      this.$buefy.dialog.confirm({
        title: "Not silme işlemi",
        message: `Bu notu <b>silmek</b> istediğinizden emin misiniz? - ${note.content}`,
        confirmText: "Notu sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteNote", note);

          await this.$store.dispatch("getGeneralNotesById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
        },
        mounted(){
          this.getGeneralNotesById()
        },
        created(){
            //this.getGeneralNotesById()
        }
};
</script>

<style scoped>
.edit-icon {
  color: #167cf0;
}

.delete-button-about {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
</style>
