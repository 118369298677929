<template>
   <div class="a columns px-5-desktop px-0-mobile is-flex is-flex-direction-column is-justify-content-center mt-6-desktop mt-0  mx-2 ">
      <div class=" y column mx-0 px-0" >

         <div class="x column mx-0 px-0 pt-0 "> 
         <PatientProfileInfo class="  is-flex is-align-items-center is-justify-content-space-between " ></PatientProfileInfo>
        <!--  <router-view class="mt-6"></router-view> -->
        
      
         </div>
          
      </div>
     
   </div>
</template>

<script>

import PatientProfileInfo from '@/components/PatientProfileInfo.vue';
//import LatestActions from '@/components/LatestActions.vue'; 
//import UpcomingMeetings from '@/components/UpcomingMeetings.vue';




export default {
  components: {
    
    PatientProfileInfo,
   // LatestActions,
   // UpcomingMeetings
 
  
    
 
    
  },
  data(){
    return {
      messageNoticed: false,
      id: this.$route.params.id,
      }
  },
  methods:{
    isMessageNoticed(){
      let x = localStorage.getItem('a');
      let id = localStorage.getItem('id');
      if (id===this.id) {
        this.messageNoticed = x;
      }
      
      /* console.log("noticed?",x, this.messageNoticed) */
    },
     warning() {
                if(!this.messageNoticed){
                  this.$buefy.snackbar.open({
                    message: 'Sayfanın sol tarafında seçenekler butonuna tıklayarak profilinizi doldurmaya başlayabilirsiniz.',
                    type: 'is-warning',
                    position: 'is-top',
                    actionText: 'Tamam',
                    
                    indefinite: true,
                    onAction: () => {
                        localStorage.setItem('a', true);
                        localStorage.setItem('id', this.id);      
                      
                    }
                })
                }
            },
   
  },
  created(){
    this.isMessageNoticed();
    this.warning();
    
  },
  
  
  }
     
                
  
</script>

<style scoped>

.a {
position: relative;
top: 100px;
backdrop-filter: blur(25%);
}


.s {
  margin-right: 0px;
}

.focuse {
  border: solid 2px blue;
}



@media screen and (max-width: 1024px) {
  .x{
    padding-top: 12px;
    /* padding-top: 0; */
    /* padding-left: 15px; */
}
}








