<template>
    <div class=" ">
  
    <b-button @click="$router.go(-1)" v-show="$route.meta.previousPageShow" class="mt-2">
        <span class="icon">
            <i class="fas fa-chevron-left"></i>
        </span>
            
    </b-button>
 
    </div>    
</template>

<script>
 export default {
        name: 'PatientSidebar',
        data(){
          return{
          append: true,
          replace: true
          }
  },
  methods:{

navigateToReading(){
                this.$router.push("reading")
            },
  


    }}
</script>

<style>


</style>