<template>
    <div  class="home  columns mx-1 mx-3-desktop  is-flex-mobile-only is-flex-direction-column-mobile-only is-justify-content-center doctor-home ">
     <div class="column mx-0-mobile mx-3-desktop  is-4 info-card pb-0 ">
       
        <DoctorProfileInfo></DoctorProfileInfo>
        <!-- <DoctorRoomInfo></DoctorRoomInfo> -->
        <!-- <div class="card is-flex is-justify-content-space-between px-4 py-2"  >
          <div class="is-flex">
             <h3 class="is-size-6 text-pointer">Mevcut oda adı: </h3> 
             <b-tag class="ml-2">{{singleUser.data.meetingRoom}}</b-tag> 
          </div>
          <b-button class="is-info is-small" @click="setRoom" > Oda edin</b-button>
        </div> -->
        <DoctorRoomInfo class="is-hidden-mobile"></DoctorRoomInfo>
        
     </div>
     <div class="column is-8 ml-0 brief">
      <DoctorMeetings></DoctorMeetings>
       <DoctorPatients marg="mt-6" class="ml-0"></DoctorPatients>
       <DoctorRoomInfo  class="room"></DoctorRoomInfo>
       
     </div>
      
    </div>
</template>

<script>
import axios from 'axios'

import DoctorProfileInfo from '@/components/DoctorProfileInfo.vue'
import DoctorMeetings from '@/components/DoctorMeetings.vue'
import DoctorPatients from '@/components/DoctorPatients.vue'
import {mapState} from 'vuex';
import DoctorRoomInfo from '@/components/DoctorRoomInfo.vue'



export default {
  name: 'Home',
  data(){
    return {
      doctor:{
       doctorId:this.$route.params.id, 
      }
    }
  },
  components: {
    
DoctorProfileInfo,
DoctorMeetings,
DoctorPatients,
DoctorRoomInfo,



  },/*,
  computed: {
    ...mapState(['doctors'])
  },
 */
  methods: {
   async setRoom() {
      let doctor= this.doctor;
      console.log(doctor)
      

      await axios.post(/* "https://backend-tez.herokuapp.com/set-room-to-doctor" */'http://localhost:3000/set-room-to-doctor', doctor)
                    .then((response) => {
                        console.log(response.data)
                    } ).catch((error) =>
                    {console.log(error)})
    },
 
  },
  computed:{
    ...mapState(['singleUser']),
           isMobile() {
   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
     console.log("mobil")
     return true
   } else {
     console.log("desktop")
     return false
   }
 } ,
  },
  created(){
    /* console.log(this.isMobile()) */
  }

}
</script>


<style scoped >

.doctor-home {
  margin-top: 64px;
}

@media screen and (min-width: 769px) {
    .brief {
        padding-left: 4px;
    }
}

@media screen and (min-width: 769px) {
    .info-card {
        padding-right: 4px;
    }
}

 @media screen and (min-width: 768px)  {
.room {
        display:none !important;
    }    
} 





</style>