<template>
    <div class="notExist">
  <div class="icon-text" style="font-size: 2.1rem;">
  <span class="icon has-text-danger">
    <i class="fas fa-info-circle-lg"></i>
  </span>
  <span class="is-size-2">Bilgi</span>
</div>

<p class="block has-text-centered is-size-3">
  Aradığınız içerik malesef bulunamadı
</p>
<b-button @click="$router.go(-1)" class="is-info is-size-4"> Geri dön</b-button>
    </div>    
</template>

<script>
 export default {
        name: 'NotExist',
        data(){
          return{
              
          }; 
        }
        
        
    }
</script>

<style>
.notExist {
    position: relative;
    top: calc(50vh - 6rem);
}
</style>