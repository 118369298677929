import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

/* const url = "http://localhost:3000"; */

const url = "https://backend-tez.herokuapp.com/";

/* const url = "https://backend-tez.ngrok.app/"; */

axios.defaults.baseURL = url;
axios.defaults.withCredentials = true

export default new Vuex.Store({
  state: {
    doctors: [],
    doctorInfo: [],
    visits: [],
    chronics: [],
    chronicsForDoctor: [ ],
    motherChronicsForDoctor:[ ],
    fatherChronicsForDoctor:[ ],
    motherChronics:[ ],
    fatherChronics:[ ],
    compsForDoctor:[ ],
    notes: [],
    patients: [],
    singleUser: [],
    patientData:[],
    isLoggedIn: false,
    userGeneralNotes: [],
    patientNotesForDoctor: [],
    meetings: [],
    doctorMeetings: [],
    readings: [],
    bps: [],
    currentUser: [],
    singleMeeting: [],
    files: [],
    readings24hour: [],
    meetingUrl: "",
    doctorName: "",
    rooms: [],
    treatmentInfo: [],
    tInfoForDoctor:"",
    doctorPatientMeetings:[],
    doseInfo:[],
    patientName:"",
    mockLoginData:[],
    doctorId:"",
    drugs:[],
    patientDrugs:[],
    insulinRegimeInfo:[],
    comps:[]
    /* doctorIdForMeeting:"" */

  },
  mutations: {
    SET_DOCTORS(state, data) {
      state.doctors = data;
    },
    SET_BASIC_DOCTOR_INFO(state, data) {
      state.doctorInfo = data;
    },
    SET_CHRONICS(state, data) {
      state.chronics = data;
    },
    SET_MOTHER_CHRONICS(state, data) {
      state.motherChronics = data;
    },
    SET_FATHER_CHRONICS(state, data) {
      state.fatherChronics = data;
    },
    SET_COMPS(state, data) {
      state.comps = data;
    },
    SET_DRUGS(state, data) {
      state.patientDrugs = data;
    },
    SET_CHRONICS_FOR_DOCTOR(state,data){
      state.chronicsForDoctor = data;
    },
    SET_MOTHER_CHRONICS_FOR_DOCTOR(state,data){
      state.motherChronicsForDoctor = data;
    },
    SET_FATHER_CHRONICS_FOR_DOCTOR(state,data){
      state.fatherChronicsForDoctor = data;
    },
    SET_COMPS_FOR_DOCTOR(state,data){
      state.compsForDoctor = data;
    },
    SET_MEETINGURL(state, data) {
      state.meetingUrl = data;
    },
    SET_PATIENT_ID_FOR_MEETING(state, data){
      state.patientIdForMeeting = data;
    },
    SET_DOCTOR_ID_FOR_MEETING(state, data){
      state.doctorIdForMeeting = data;
    },
    SET_DOCTOR_ID(state, data){
      state.doctorId = data;
    },
    SET_MEETING_DOCTOR_URL(state, data) {
      state.doctorName = data;
    }, 
    SET_PATIENT_NAME(state, data) {
      state.patientName = data;
    },
    SET_PATIENTS(state, data) {
      state.patients = data;
    },
    SET_VISITS(state, data) {
      state.visits = data;
    },
    SET_ROOMS(state, data) {
      state.rooms = data;
    },
    SET_MOCK_LOGIN(state, data) {
      state.mockLoginData = data;
    },
    SET_MEETINGS(state, data) {
      state.meetings = data;
    },
    SET_DOCTOR_PATIENT_MEETINGS(state,data){
      state.doctorPatientMeetings = data;
    },
    SET_DOCTOR_MEETINGS(state, data) {
      state.doctorMeetings = data;
    },
    SET_SINGLE_MEETING(state, data) {
      state.singleMeeting = data;
    },
    SET_READINGS(state, data) {
      state.readings = data;
    },
    SET_24_HOUR_READINGS(state, data) {
      state.readings24hour = data;
    },
    SET_BPs(state, data) {
      state.bps = data;
    },
    SET_FILES(state, data) {
      state.files = data;
    },
    SET_SINGLE_USER(state, singleUser) {
      if (singleUser) {
        state.singleUser = singleUser;
        /* state.isLoggedIn = true; */
      } else {
        state.singleUser = {};
        state.isLoggedIn = false;
      }
    },
    SET_USER_LOGGED_IN(state, data) {
      state.isLoggedIn = data;
    },
    
    SET_USER_GENERAL_NOTES_FOR_DOCTOR(state, data){
      state.patientNotesForDoctor = data;
    },
    SET_USER_GENERAL_NOTES(state, data){
      state.notes = data;
    },
    SET_TREATMENT_TYPE(state, data) {
      state.treatmentInfo = data;
    },
    SET_DOSE_INFO(state, data){
      state.doseInfo = data 
    },
    SET_INSULIN_REGIME_INFO(state, data){
      state.insulinRegimeInfo = data 
    },
    SET_TREATMENT_TYPE_FOR_DOCTOR(state, data){
      state.tInfoForDoctor = data;
    },
    SET_PATIENT_DATA(state, data){
      state.patientData = data;
    },
    SET_CURRENT_USER(state, user) {
      state.currentUser = user;
      window.localStorage.currentUser = JSON.stringify(user);
      state.isLoggedIn = true;
    },
    LOGOUT_USER(state) {
      state.currentUser = {};
      window.localStorage.currentUser = JSON.stringify({});
      state.isLoggedIn = false;
    },
    DELETE_READING(state, readingId) {
      let readings = state.readings.filter((r) => r._id != readingId);
      state.readings = readings;
    },
    DELETE_BP(state, bpId) {
      let bps = state.bps.filter((bp) => bp._id != bpId);
      state.bps = bps;
    },
    DELETE_MEETING(state, meetingId) {
      let meetings = state.meetings.filter((m) => m._id != meetingId);
      state.meetings = meetings;
    },
    DELETE_ROOM(state, roomId) {
      let rooms = state.rooms.rooms.filter((r) => r._id != roomId);
      state.rooms = rooms;
    },
    DELETE_CHRONIC(state, chronicId) {
      let chronics = state.chronics.filter(
        (chronic) => chronic._id != chronicId
      );
      state.chronics = chronics;
    },
    DELETE_MOTHER_CHRONIC(state, chronicId) {
      let chronics = state.motherChronics.filter(
        (chronic) => chronic._id != chronicId
      );
      state.motherChronics = chronics;
    },
    DELETE_FATHER_CHRONIC(state, chronicId) {
      let chronics = state.fatherChronics.filter(
        (chronic) => chronic._id != chronicId
      );
      state.fatherChronics = chronics;
    },
    DELETE_COMP(state, compId) {
      let comps = state.comps.filter(
        (comp) => comp._id != compId
      );
      state.comps = comps;
    },
    DELETE_NOTE(state, noteId) {
      let notes = state.notes.filter((note) => note._id != noteId);
      state.notes = notes;
    },
    EDIT_TREATMENTINFO(state, data) {
      console.log(data);
      state.treatmentInfo = data.treatmentType[0];
    },
  },
  actions: {
    async fetchDoctors({ commit }) {
      const result = await axios.get("/doctors");
      commit("SET_DOCTORS", result.data);
      console.log(result);
    },
    async getBasicDoctorInfo({ commit }) {
      const result = await axios.get("/doctor-info");
      commit("SET_BASIC_DOCTOR_INFO", result.data);
      console.log(result);
    },
    async fetchPatients({ commit }) {
      const result = await axios.get("/patient");
      commit("SET_PATIENTS", result.data);
      console.log(result);
    },
    async fetchVisits({ commit }) {
      const result = await axios.get("/book");
      commit("SET_VISITS", result.data);
      console.log(result);
    },
    async fetchRooms({ commit }) {
      const result = await axios.get("/rooms");
      commit("SET_ROOMS", result.data);
      console.log(result);
    },
    async mockLogin({ commit }) {
      const result = await axios.get("/fake-login");
      commit("SET_MOCK_LOGIN", result.data);
      /* console.log(result); */
    },
    async createDoctor(commit) {
      const doctor = await axios.post("/register");
      commit("CREATE_DOCTORS", doctor);
      console.log(doctor);
    },
    async getSingleUserById({ commit }) {
      const singleUserId = router.currentRoute.params.id;
      //const userRole = router.currentRoute.params.role
      await axios
        .get(`/user/${singleUserId}`)
        .then((user) => {
          commit("SET_SINGLE_USER", user.data);
          console.log(user);
        })
        .catch(function(error) {
          console.log(error);
        });
      // context.commit('SET_SINGLE_USER', userByName.data)
      //console.log(userByName)
    },
    async getPatientDataById({ commit }) {
      const patientId = router.currentRoute.params.patientId || router.currentRoute.params.id ;
      console.log(patientId)
      
      await axios
        .get(`/user/${patientId}`)
        .then((patient) => {
          commit("SET_PATIENT_DATA", patient.data);
          console.log(patient);
        })
        .catch(function(error) {
          console.log(error);
        });
      
    },
    async getMeetingsById({ commit }) {
      const userIdForMeetings = router.currentRoute.params.id;
      console.log(userIdForMeetings);
      await axios
        .get(`/meeting/${userIdForMeetings}`)
        .then((meeting) => {
          commit("SET_MEETINGS", meeting.data);
          console.log(meeting);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getSingleMeetingById({ commit }) {
      const meetingId = router.currentRoute.params.id;
      console.log("a", meetingId);
      await axios
        .get(`/single-meeting/${meetingId}`)
        .then((meeting) => {
          commit("SET_SINGLE_MEETING", meeting.data);
          console.log(meeting.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getMeetingsByDoctorId({ commit }) {
      const doctorIdForMeetings = router.currentRoute.params.id;
      console.log(doctorIdForMeetings);
      await axios
        .get(`/meetings/${doctorIdForMeetings}`)
        .then((meeting) => {
          commit("SET_DOCTOR_MEETINGS", meeting.data);
          console.log(meeting);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getMeetingsByDoctorAndPatientId({ commit }) {
      const doctorId = router.currentRoute.params.id;
      const patientId = router.currentRoute.params.patientId;
      console.log(doctorId,patientId,router.currentRoute.params);
      await axios
        .get(`/doctor-patient-meetings/${patientId}/${doctorId}`)
        .then((meeting) => {
          commit("SET_DOCTOR_PATIENT_MEETINGS", meeting.data);
          console.log("meet",meeting);
        })
        .catch(function(error) {
          console.log(error);
        });
    },

    async setMeetingUrl({ commit }, url) {
      await commit("SET_MEETINGURL", url);
      console.log("meet", this.state.meetingUrl);
    },
    async setPatientIdForMeeting({ commit }, patientIdForMeeting) {
      await commit("SET_PATIENT_ID_FOR_MEETING", patientIdForMeeting);
      console.log("PatientIdForMeeting", this.state.patientIdForMeeting);
    },
    async setDoctorIdForMeeting({ commit }, doctorIdForMeeting) {
      await commit("SET_DOCTOR_ID_FOR_MEETING", doctorIdForMeeting);
      console.log("doctorIdForMeeting", this.state.doctorIdForMeeting);
    },
    async setDoctorId({ commit }, doctorId) {
      await commit("SET_DOCTOR_ID", doctorId);
      console.log("doctorIdSetted", this.state.doctorId);
    },
    async setMeetingDoctorName({ commit }, doctorName) {
      await commit("SET_MEETING_DOCTOR_URL", doctorName);
      console.log("meet", this.state.doctorName);
    },
    
    async setLoggedInValue({ commit }, isLoggedIn) {
      await commit("SET_USER_LOGGED_IN", isLoggedIn);
      console.log("meet", this.state.isLoggedIn);
    },
    async setPatientName({ commit }, patientName) {
      await commit("SET_PATIENT_NAME", patientName);
      console.log("meet", this.state.patientName);
    },
    async getGlycosisDataById({ commit }) {
      const patId = router.currentRoute.params.id;
      console.log("a", router.currentRoute.params);
      await axios
        .get(`/reading-glycosis/${patId}`)
        .then((reading) => {
          commit("SET_READINGS", reading.data);
          console.log(reading);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async get24hourGlycosisDataById({ commit }, patId) {
      await axios
        .get(`/glycosis/${patId}`)
        .then((readings24hour) => {
          commit("SET_24_HOUR_READINGS", readings24hour.data);
          console.log("message - ",readings24hour.data.message);
          console.log("gr",readings24hour.data)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async get24hourGlycosisDataForDoctor({ commit }) {
      const patId = router.currentRoute.params.id;
      await axios
        .get(`/glycosis/${patId}`)
        .then((readings24hour) => {
          commit("SET_24_HOUR_READINGS", readings24hour.data);
          console.log("message - ",readings24hour.data.message);
          console.log("gr",readings24hour.data)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    /*  async getChronicsById({ commit }, patId) {
      await axios
        .get(`/chronics/${patId}`)
        .then((chronic) => {
          commit("SET_CHRONICS", chronic.data);
          console.log(chronic.data);
          
        })
        .catch(function(error) {
          console.log(error);
        });
    }, */
    async getGeneralNotesById({ commit }) {
      const patId = router.currentRoute.params.id;
      await axios
        .get(`/patient-general-notes/${patId}`)
        .then((note) => {
          commit("SET_USER_GENERAL_NOTES", note.data);
          console.log("1", note.data);
          //console.log(typeof reading.data[0].value)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getPatientNotesbyIdForDoctor({ commit }) {
      const patId = router.currentRoute.params.patientId;
      await axios
        .get(`/patient-general-notes/${patId}`)
        .then((note) => {
          commit("SET_USER_GENERAL_NOTES_FOR_DOCTOR", note.data);
          console.log("1", note.data);
          //console.log(typeof reading.data[0].value)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getTreatmentInfo({ commit }) {
      const patId = router.currentRoute.params.id;
      await axios
        .get(`/treatment-type/${patId}`)
        .then((treatmentInfo) => {
          commit("SET_TREATMENT_TYPE", treatmentInfo.data);
          console.log("get treatmentInfo", treatmentInfo.data);
          //console.log(typeof reading.data[0].value)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    
    async getTreatmentInfoForDoctor({ commit }) {
      const patId = router.currentRoute.params.patientId;
      await axios
        .get(`/treatment-type/${patId}`)
        .then((treatmentInfo) => {
          commit("SET_TREATMENT_TYPE_FOR_DOCTOR", treatmentInfo.data);
          console.log("get treatmentInfo", treatmentInfo.data);
          //console.log(typeof reading.data[0].value)
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getFiles({ commit }) {
      // const patId = router.currentRoute.params.id
      // console.log("a",router.currentRoute.params)
      await axios
        .get(`/get-files`)
        .then((files) => {
          commit("SET_FILES", files.data);
          console.log(files);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getBloodPressureDataById({ commit }) {
      const patId = router.currentRoute.params.id;
      //console.log("a",router.currentRoute.params)
      await axios
        .get(`/bp-reading/${patId}`)
        .then((bp) => {
          commit("SET_BPs", bp.data);
          console.log(bp);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getChronicsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("userId", patientId);
      await axios
        .get(`/patient-chronics/${patientId}`)
        .then((chronics) => {
          commit("SET_CHRONICS", chronics.data);
          console.log(chronics);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getMotherChronicsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("get-mother", patientId);
      await axios
        .get(`/mother-chronics/${patientId}`)
        .then((motherChronics) => {
          commit("SET_MOTHER_CHRONICS", motherChronics.data);
          console.log("mc",motherChronics);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getFatherChronicsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("get-father", patientId);
      await axios
        .get(`/father-chronics/${patientId}`)
        .then((fatherChronics) => {
          commit("SET_FATHER_CHRONICS", fatherChronics.data);
          console.log("fc",fatherChronics);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getCompsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("userId", patientId);
      await axios
        .get(`/patient-comps/${patientId}`)
        .then((comps) => {
          commit("SET_COMPS", comps.data);
          console.log("comps",comps);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getDrugsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("userId", patientId);
      await axios
        .get(`/get-drugs/${patientId}`)
        .then((drugs) => {
          commit("SET_DRUGS", drugs.data);
          console.log(drugs);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getPatientDrugsById({ commit }) {
      const patientId = router.currentRoute.params.id;
      console.log("userId", patientId);
      await axios
        .get(`/get-drugs/${patientId}`)
        .then((drugs) => {
          commit("SET_DRUGS", drugs.data);
          console.log(drugs);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async chronicsForDoctorByPatientId({ commit }) {
      const patientId = router.currentRoute.params.patientId;
      console.log("userId", patientId);
      await axios
        .get(`/patient-chronics/${patientId}`)
        .then((chronics) => {
          commit("SET_CHRONICS_FOR_DOCTOR", chronics.data);
          console.log(chronics);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async motherChronicsForDoctorByPatientId({ commit }) {
      const patientId = router.currentRoute.params.patientId;
      console.log("userId", patientId);
      await axios
        .get(`/mother-chronics/${patientId}`)
        .then((motherChronicsForDoctor) => {
          commit("SET_MOTHER_CHRONICS_FOR_DOCTOR", motherChronicsForDoctor.data);
          console.log(motherChronicsForDoctor);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async fatherChronicsForDoctorByPatientId({ commit }) {
      const patientId = router.currentRoute.params.patientId;
      console.log("userId", patientId);
      await axios
        .get(`/father-chronics/${patientId}`)
        .then((fatherChronicsForDoctor) => {
          commit("SET_FATHER_CHRONICS_FOR_DOCTOR", fatherChronicsForDoctor.data);
          console.log(fatherChronicsForDoctor);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async compsForDoctorByPatientId({ commit }) {
      const patientId = router.currentRoute.params.patientId;
      console.log("userId", patientId);
      await axios
        .get(`/patient-comps/${patientId}`)
        .then((comps) => {
          commit("SET_COMPS_FOR_DOCTOR", comps.data);
          console.log(comps);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async deleteReading({ commit, dispatch }, reading) {
      let response = await axios.delete(`/reading-glycosis/${reading._id}`);

      console.log(response);
      commit("DELETE_READING", reading._id);
      await dispatch("getGlycosisDataById");
    },
    async deleteInstruction({ dispatch }, index) {
      const meetingId = router.currentRoute.params.id;
      console.log(index,meetingId)
      let response = await axios.delete(`/delete-instruction/${index.index}/${meetingId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleMeetingById"); 
    },
    async deleteICDCode({ dispatch }, index) {
      const meetingId = router.currentRoute.params.id;
      console.log(index,meetingId)
      let response = await axios.delete(`/delete-icd-code/${index.index}/${meetingId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleMeetingById"); 
    },
    async deleteOutcome({ dispatch }, index) {
      const meetingId = router.currentRoute.params.id;
      console.log(index,meetingId)
      let response = await axios.delete(`/delete-outcome/${index.index}/${meetingId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleMeetingById"); 
    },
    async deleteSuggestion({ dispatch }, index) {
      const meetingId = router.currentRoute.params.id;
      console.log(index,meetingId)
      let response = await axios.delete(`/delete-suggestion/${index.index}/${meetingId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleMeetingById"); 
    },
    async deleteTest({ dispatch }, index) {
      const meetingId = router.currentRoute.params.id;
      console.log(index,meetingId)
      let response = await axios.delete(`/delete-test/${index.index}/${meetingId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleMeetingById"); 
    },
    async deleteDoctorAbout({ dispatch }, index) {
      const doctorId = router.currentRoute.params.id;
      console.log(index,doctorId)
      let response = await axios.delete(`/delete-text-about-doctor/${index.index}/${doctorId}`);

      console.log(response,index);
      /* commit("DELETE_INSTRUCTION", rea._id); */
      await dispatch("getSingleUserById"); 
    },
    async deleteNote({ commit }, note) {
      let response = await axios.delete(`/patient-general-notes/${note._id}`);
      console.log(response);
      commit("DELETE_NOTE", note._id);
      //await dispatch("getGeneralNotesById");
    },
    async deleteBp({ commit, dispatch }, bp) {
      let response = await axios.delete(`/bp-reading/${bp._id}`);

      console.log(response);
      commit("DELETE_BP", bp._id);
      await dispatch("getBloodPressureDataById");
    },
    async deleteMeeting({ commit }, meeting) {
      let response = await axios.delete(`/meeting/${meeting._id}`);
      console.log(response);
      commit("DELETE_MEETING", meeting._id);
      await this.dispatch("getMeetingsById")
    },
    async deleteRoomById({ commit, dispatch }, room) {
      await axios.delete(`/room/${room._id}`)
      .then((response)=>console.log(response));
      /* console.log(response); */
      commit("DELETE_ROOM", room._id);
      await dispatch("fetchRooms")
    },
    async deleteChronic({ commit }, chronic) {
      let response = await axios.delete(`/patient-chronics/${chronic._id}`);
      console.log(response);
      commit("DELETE_CHRONIC", chronic._id);
    },
    async deleteFatherChronic({ commit }, chronic) {
      let response = await axios.delete(`/father-chronics/${chronic._id}`);
      console.log(response);
      commit("DELETE_FATHER_CHRONIC", chronic._id);
    },
    async deleteMotherChronic({ commit }, chronic) {
      let response = await axios.delete(`/mother-chronics/${chronic._id}`);
      console.log(response);
      commit("DELETE_MOTHER_CHRONIC", chronic._id);
    },
    async deleteComp({ commit }, comp) {
      let response = await axios.delete(`/patient-comps/${comp._id}`);
      console.log(response);
      commit("DELETE_COMP", comp._id);
    },
    async loginUser({ commit }, userCred) {
      try {
        let response = await axios.post("/login", userCred);
        let user = response.data;
        console.log(userCred);
        console.log("id", user.loggedinUser.id, user.loggedinUser.role);

        commit("SET_CURRENT_USER", user);
        if (user.loggedinUser.role === "PATIENT") {
          router.push({ path: `/user/${user.loggedinUser.id}` });
        } else if (user.loggedinUser.role === "DOCTOR") {
          router.push({ path: `/user/doctor/${user.loggedinUser.id}` });
        }

        return user;
      } catch {
        return {
          error: "Email veya şifre hatalı lütfen tekrar deneyiniz.",
        };
      }
    },
    logoutUser({ commit }) {
      commit("LOGOUT_USER");
    },
    async addGlycosisReading({ dispatch }, { value, date, patientId, note, mealStatus,dayTime,unit }) {
      console.log("gly", value, date, patientId, note);
      const response = await axios.post("/reading-glycosis", {
        value,
        date,
        patientId,
        note,
        mealStatus,
        dayTime,
        unit

      });
      await dispatch("getGlycosisDataById");
      return response.data;
    },
    async addUser({dispatch}, {name, surname,title,email,password,role,gender}) {
      
      const response = await axios.post("/register", {
        name, surname,title,email,password,role,gender

      });
      await dispatch("getGlycosisDataByI");
      return response.data;
    },
    async addOutcomeForMeeting({dispatch},{date, meetingId, outcome} ) {
      /* console.log("outcome", date, meetingId, outcome); */
      const response = await axios.put("/add-outcome", {
        date, meetingId, outcome
      });
      /* await dispatch("getOutcomesByMeetingId"); */
      await dispatch("getSingleMeetingById");
      return response.data;
    },
    async addLabTestsForMeeting({dispatch},{date, meetingId, labTests} ) {
      /* console.log("outcome", date, meetingId, outcome); */
      const response = await axios.put("/add-lab-tests", {
        date, meetingId, labTests
      });
      /* await dispatch("getOutcomesByMeetingId"); */
      await dispatch("getSingleMeetingById");
      return response.data;
    },
    async addSuggestionAfterMeeting({dispatch},{date, meetingId, suggestion} ) {
      /* console.log("outcome", date, meetingId, outcome); */
      const response = await axios.put("/add-suggestion", {
        date, meetingId, suggestion
      });
      /* await dispatch("getOutcomesByMeetingId"); */
      await dispatch("getSingleMeetingById");
      return response.data;
    },
    async addICDCodeForMeeting({dispatch},{date, meetingId,definition,code} ) {
      /* console.log("icd", date, meetingId, code); */
      const response = await axios.put("/add-icd-code", {
        date, meetingId, code, definition
      });
      
      await dispatch("getSingleMeetingById");
      return response.data;
    },
    
    async addInstructionForMeeting({dispatch},{date, meetingId, instruction} ) {
      /* console.log("outcome", date, meetingId, outcome); */
      const response = await axios.put("/add-instruction", {
        date, meetingId, instruction
      });
      /* await dispatch("getOutcomesByMeetingId"); */
      await dispatch("getSingleMeetingById");
      return response.data;
    },
    async addTextAboutDoctor({dispatch},{date, doctorId, text}) {
      /* console.log("outcome", date, meetingId, outcome); */
      const response = await axios.put("/add-text-about-doctor", {
        date, doctorId, text
      });
      /* await dispatch("getOutcomesByMeetingId"); */
      await dispatch("getSingleUserById");
      return response.data;
    },
    async addAppointment({ dispatch }, {newVisit}) {
      await axios
      .post("/book"
      , newVisit)
      .then((response) => {
        console.log(response);
        /* this.toastAppoinmentConfirmation(); */
        
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getMeetingsByDoctorId")
    },
    
    
    async addRoom({ dispatch }, {roomName}) {
      await axios
      .post("/add-room"
      , roomName)
      .then((response) => {
        console.log(response);
        /* this.toastAppoinmentConfirmation(); */
        
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("fetchRooms")
    },
    async setRoom({ dispatch }, {doctor}) {
      await axios
      .post("/set-room-to-doctor"
      , doctor)
      .then((response) => {
        console.log(response);
        /* this.toastAppoinmentConfirmation(); */
        
      })
      .catch((error) => {
        console.log(error);
      });
       await dispatch("fetchRooms") 
    },

    async setMeetingDuration({ dispatch }, {meetingDuration}) {
      console.log("md",meetingDuration);
      await axios
      .post("/meeting-duration"
      , meetingDuration)
      .then((response) => {
        console.log(response);
        /* this.toastAppoinmentConfirmation(); */
        
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getMeetingsByDoctorId")
      await dispatch("getSingleUserById")
    },
    async setDoctorAvailable(
      { dispatch }, {doctorAvailable}) {
      await axios
      .post("/doctor-available"
      , doctorAvailable)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getMeetingsByDoctorId")
      await dispatch("getSingleUserById")
    },
    async addEyeVisitDate(
      { dispatch }, {eyeVisitDate}) {
      await axios
      .post("/eye-visit"
      , eyeVisitDate)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addKidneyVisitDate(
      { dispatch }, {kidneyVisitDate}) {
      await axios
      .post("/kidney-visit"
      , kidneyVisitDate)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addHeartVisitDate(
      { dispatch }, {heartVisitDate}) {
      await axios
      .post("/heart-visit"
      , heartVisitDate)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addBrainVisitDate(
      { dispatch }, {brainVisitDate}) {
      await axios
      .post("/brain-visit"
      , brainVisitDate)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addHypoglycemicEventInfo(
      { dispatch }, {hypoglycemicEventInfo}) {
      await axios
      .put("/hypoglycemic-event"
      , hypoglycemicEventInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addKetoasidozInfo(
      { dispatch }, {ketoasidozInfo}) {
      await axios
      .put("/ketoasidoz"
      , ketoasidozInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async addTrombozInfo(
      { dispatch }, {trombozInfo}) {
      await axios
      .put("/tromboz"
      , trombozInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getTreatmentInfo")
      
    },
    async cancelMeetingById(
      { dispatch }, {cancelInfo}) {
      await axios
      .post("/cancel-meeting"
      , cancelInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getSingleMeetingById")
      /* await dispatch("getSingleUserById") */
    },
    async confirmMeetingById(
      { dispatch }, {confirmInfo}) {
      await axios
      .post("/confirm-meeting"
      , confirmInfo)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
      await dispatch("getSingleMeetingById")
      /* await dispatch("getSingleUserById") */
    },
    async addAppointmentDays(
      { dispatch }, 
      {appointmentDays}) {
        console.log("ad",appointmentDays)
      await axios
      .post("/doctor-appointment-days"
      , appointmentDays)
      .then((response) => {
        console.log(response);
        /* this.toastAppoinmentConfirmation(); */
        
      })
      .catch((error) => {
        console.log(error);
      });
    await dispatch("getMeetingsByDoctorId")
      await dispatch("getSingleUserById") 
    },

    async addBloodPressureReading(
      { dispatch },
      { systolic, diastolic, date, patientId, note }
    ) {
      console.log("bp", systolic, diastolic, date, patientId, note);
      const response = await axios.post("/bp-reading", {
        systolic,
        diastolic,
        date,
        patientId,
        note, 
      });
      await dispatch("getBloodPressureDataById");
      return response.data;
    },
    async addInsulinDoseInfo(
      { dispatch },
      { patientId, rangesInfo  }
    ) {
      console.log("doses added", patientId, rangesInfo);
      const response = await axios.post("/dose-info", {
        
        patientId,
        rangesInfo 
      });
      await dispatch("getDoseInfoById");
      return response.data;
    },
    async addInsulinRegimeInfo(
      { dispatch },
      { patientId, insulinRegime  }
    ) {
      console.log("doses added", patientId, insulinRegime);
      const response = await axios.post("/insulin-regime-info", {
        patientId,
        insulinRegime 
      });
      await dispatch("getInsulinRegimeInfoById");
      return response.data;
    },
    async getDoseInfoById({ commit }) {
      const id = router.currentRoute.params.patientId;
      await axios
        .get(`/dose-info/${id}`)
        .then((doseInfo) => {
          commit("SET_DOSE_INFO", doseInfo.data);
          console.log("get doseInfo", doseInfo);
          
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getInsulinRegimeInfoById({ commit }) {
      const id = router.currentRoute.params.patientId;
      await axios
        .get(`/insulin-regime-info/${id}`)
        .then((insulinRegimeInfo) => {
          commit("SET_DOSE_INFO", insulinRegimeInfo.data);
          console.log("get doseInfo", insulinRegimeInfo);
          
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async addPhotoId(
      { dispatch },
      { photo_id }
    ) {
      console.log("photo_id",photo_id)
      const id = router.currentRoute.params.id;
      const response = await axios.post(`/user/${id}/photo-id`, {
        photo_id,
        
      });
      await dispatch("");
      return response.data;
    },

    async addChronic({ dispatch }, { value, patientId }) {
      console.log("chronic", patientId, value);
      const response = await axios.post("/patient-chronics", {
        value,
        patientId,
      });
      await dispatch("getChronicsById");
      return response.data;
    },
    async addMotherChronic({ dispatch }, { value, patientId }) {
      console.log("mother", patientId, value);
      const response = await axios.post("/mother-chronics", {
        value,
        patientId,
      });
      await dispatch("getMotherChronicsById");
      return response.data;
    },
    async addFatherChronic({ dispatch }, { parent,value, patientId }) {
      console.log("father", patientId, value, parent);
      const response = await axios.post("/father-chronics", {
        value,
        patientId
      
      });
      await dispatch("getFatherChronicsById");
      return response.data;
    },
    async addComp({ dispatch }, { value, patientId }) {
      console.log("comp", patientId, value);
      const response = await axios.post("/patient-comps", {
        value,
        patientId,
      });
      await dispatch("getCompsById");
      return response.data;
    },
    async addDrugInfo({ dispatch }, { drugs, patientId }) {
      /* console.log("chronic", patientId, drugs); */
      const response = await axios.post("/add-drugs", {
        drugs,
        patientId,
      });
      await dispatch("getDrugsById");
      return response.data;
    },
    async addPatientNote({ dispatch }, { content, patientId }) {
      console.log("content", patientId, content);
      const response = await axios.post("/patient-notes", {
        content,
        patientId,
      });
      await dispatch("getGeneralNotesById");
      return response.data;
    },
    async addPatientBaseInfo({dispatch},
      
      { age, heigth, weight, telephone, patientId }
    ) {
      console.log("content", patientId);
      const response = await axios.put("/patient-base-info", {
        age,
        heigth,
        weight,
        telephone,
        patientId,
      });
      await dispatch("getGeneralNotesById");
      return response.data;
    },
    async addPatientHeight({dispatch},
      
      { date, height, patientId }
    ) {
      console.log("content", patientId);
      const response = await axios.post("/patient-height", {
        date,
        height,
        patientId,
      });
      await dispatch("getSingleUserById");
      return response.data;
    },
    async addPatientWeight({dispatch},
      
      {  weight, patientId,date }
    ) {
      console.log("content", patientId);
      const response = await axios.post("/patient-weight", {
        
        weight,
        date,
        patientId,
      });
      await dispatch("getSingleUserById");
      return response.data;
    },
    async addPatientAge({dispatch},
      
      {  age, patientId }
    ) {
      console.log("content", patientId);
      const response = await axios.post("/patient-age", {
        
        age,
        
        patientId,
      });
      await dispatch("getSingleUserById");
      return response.data;
    },
    async addTreatmentInfo({ dispatch }, { treatmentType, patientId }) {
      console.log("tt", patientId, treatmentType);
      const response = await axios.post("/treatment-type", {
        treatmentType,
        patientId,
      });
      await dispatch("getTreatmentInfo");
      return response.data;
    },
    async updateTreatmentInfo(
      { commit, dispatch },
      { treatmentType, patientId }
    ) {
      console.log("userId", patientId);
      await axios
        .put("/treatment-type", { treatmentType, patientId })
        .then((updateTreatmentInfo) => {
          commit("EDIT_TREATMENTINFO", updateTreatmentInfo.data.data);
          console.log("uti", updateTreatmentInfo);
          dispatch("getTreatmentInfo");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
});
