<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
    >
      <div class="p-3 mt-5">
       
        <b-menu>
          <b-menu-list class="is-size-5" label="Menu">
            <b-menu-item  class="is-size-5" @click="navigateToProfile" label="Profil"></b-menu-item>
            
              
              <b-menu-item icon="" class="is-size-5" @click="navigateToPatients" label="Hastalar"></b-menu-item>
              <!-- <b-menu-item icon="cash-multiple" label="Payments" disabled></b-menu-item> -->
            
            <b-menu-item  class="is-size-5" @click="navigateToDoctorMeetings" label="Görüşmeler"></b-menu-item>
            <b-menu-item  class="is-size-5" @click="navigateToAppointmentPreferences" label="Randevu tercihleri"></b-menu-item>
            <!-- <b-menu-item  class="is-size-5"  label="Odam"></b-menu-item> -->
          </b-menu-list>
          <b-menu-list class="is-size-5" label="Actions">
            <b-menu-item label="Çıkış Yap" class="is-size-5"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
    
    <b-button @click="open = true"  icon-left="user"></b-button>
  </section>
</template>

<script>
import {mapState} from 'vuex';
export default {
  data() {
    return {
      open: false,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,
      /* id: this.$route.params.id, */
      
    };
  },
  ...mapState(['doctorId']),
  methods:{
    navigateToDoctorMeetings(){
                this.$router.push({path:`/user/doctor/${this.$route.params.id}/meetings`})
            },
    navigateToPatients(){
                this.$router.push({path:`/user/doctor/${this.$route.params.id}/patients`})
            },
    navigateToProfile(){
                this.$router.push({path:`/user/doctor/${this.$route.params.id}`})
            },
      navigateToAppointmentPreferences(){
              this.$router.push({path:`/doctor/${this.$route.params.id}/appointment-preferences-edit`})
            },
  }
};
</script>

<style>
.p-1 {
  padding: 1em;
}
</style>