<template>
    <div class="bp-main box mr-2 ml-2-tablet">
        
       <div class="is-flex is-justify-content-space-between">
          <b-tooltip
          label="Son eklediğiniz kan basıncı değerlerinizi burada görebilirsiniz. Daha fazlası için Kan Basıncı Değerlerim'e tıklayabilirsiniz."
          position="is-bottom"
          class="is-light"
          multilined
        >
          <h3 class="px-1 is-size-5 has-text-weight-bold" @click="navigateToBloodPressure">
            Kan basıncı değerlerim 🌡️
          </h3>
        </b-tooltip>
        <span class="icon is-medium mr-2">
               <i class="fa-solid fa-heart-rate"></i>
               </span>
        
       </div>
        <hr />
        <div
          class="px-1 is-flex is-flex-direction-column is-justify-content-space-between"
        >
          <div v-if="!bps.length">
            Henüz kan basıncı değeri eklemediniz.
          </div>
          <div >
          <div class="a is-flex is-justify-content-space-between" >
            <div v-show="!showBigger" class="b">
                <div
                
            class="is-flex mb-3 is-flex-direction-column"
            v-for="bpReading in bps.slice(
              0,5
            )"
            v-bind:key="bpReading.id"
          >
            <div class="is-flex">
              <b-tag type="is-dark"  size="is-medium is-size-7  ">
                #{{ bps.indexOf(bpReading) + 1 }}
              </b-tag>
              <b-tag
                type="is-success"
                class="ml-1 is-size-7"
                v-if="bpReading.systolic < 140"
                size="is-medium"
                >{{ bpReading.systolic }}</b-tag
              >
              <b-tag type="is-danger" v-else class="ml-1 is-size-7" size="is-medium">{{
                bpReading.systolic
              }}</b-tag>
              <b-tag
                type="is-success"
                class="ml-1 is-size-7"
                v-if="bpReading.diastolic < 90"
                size="is-medium"
                >{{ bpReading.diastolic }}</b-tag
              >
              <b-tag type="is-danger" class="ml-1 is-size-7" v-else size="is-medium">{{
                bpReading.diastolic
              }}</b-tag>
            </div>
          </div>
            </div>
            
          <div v-show="showBigger" class="b">
                <div
            
            class="is-flex mb-3 is-flex-direction-column"
            v-for="bpReading in bps.slice(
              0,5
            )"
            v-bind:key="bpReading.id"
          >
            <div  class="is-flex">
              <b-tag type="is-dark"  size="is-medium is-size-6  ">
                #{{ bps.indexOf(bpReading) + 1 }}
              </b-tag>
              <b-tag
                type="is-success"
                class="ml-1 is-size-6"
                v-if="bpReading.systolic < 140"
                size="is-medium"
                >{{ bpReading.systolic }}</b-tag
              >
              <b-tag type="is-danger" v-else class="ml-1 is-size-6" size="is-medium">{{
                bpReading.systolic
              }}</b-tag>
              <b-tag
                type="is-success"
                class="ml-1 is-size-6"
                v-if="bpReading.diastolic < 90"
                size="is-medium"
                >{{ bpReading.diastolic }}</b-tag
              >
              <b-tag type="is-danger" class="ml-1 is-size-6" v-else size="is-medium">{{
                bpReading.diastolic
              }}</b-tag>
            </div>
          </div>
          </div>
            <div v-if="!showBigger && bps.length" @click="showBigger = !showBigger"> <p class="is-underlined">Büyüt</p></div>
            <div v-if="showBigger" @click="showBigger = !showBigger"> <p class="is-underlined">Küçült</p></div>
          </div>
          

          </div>
        </div>
        <div class="is-flex  mt-2 is-justify-content-space-between">
          <div>
            <a
              class="has-text-black is-underlined"
              @click="navigateToAddBloodPressure"
              >Ekle</a
            >
          </div>
          <div>
            <a class="has-text-black" @click="navigateToBloodPressure()"
              >Daha fazla</a>
    </div>
    </div>
    </div>

</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  name: 'BloodSugar',
  data(){
     return {

        id: this.$route.params.id,
        isHidden: false,
        showBigger:false
     } 
  },
  computed:{
            ...mapState(['bps'])
        },
        methods:{
            ...mapActions(['getBloodPressureDataById',]),
            navigateToBloodPressure() {
      this.$router.push({
        path: `/user/patient/${this.id}/blood-pressure-details`,
      });
      this.$store.dispatch("getBloodPressureDataById");
    },
    navigateToAddBloodPressure() {
      this.$router.push({ path: `/user/patient/${this.id}/blood-pressure` });
      // this.$store.dispatch('getBloodPressureDataById')
    },
             
    
        },
        mounted(){
          this.getBloodPressureDataById();
          console.log(this.bps.length,this.showBigger)

        },
        created(){
            //this.getGeneralNotesById()
        }
};
</script>

<style scoped>
@media screen and (max-width: 769px) {
  .bp-main{
    margin-right: 0px;
  }
}


@media screen and (max-width: 416px) {
  .bp-main{
    margin-left: 0;
    min-width: 95vw;
  }
}
</style>
