<template>
    <div class=" mt-6-desktop mx-4 is-flex ">
  <form class="register-form is-flex is-flex-direction-column p-4 mt-6">
     
        <b-field label="Adınız" value="ekin">
            <b-input placeholder="Adınızı giriniz" required value="ekin" v-model="user.name"></b-input>
        </b-field>
        <b-field label="Soyadınız" value="ekinci">
            <b-input placeholder="Soyadınızı giriniz" required v-model="user.surname"></b-input>
        </b-field>
        <b-field
            label="Aşağıdakilerden hangisi olarak kaydolmak istiyorsunuz?">
            <b-field>
            <b-radio 
                name="DOCTOR"
                native-value="DOCTOR"
                v-model="user.role"
                @click="toggleDoctor()"
                 >
                Doktor
            </b-radio>
             </b-field>
            <b-field>
                <b-radio 
                name="PATIENT"
                native-value="PATIENT"
                required
                v-model="user.role">
                Hasta 
            </b-radio>
            </b-field>
            
        </b-field>
        <b-field
            v-if="user.role=='DOCTOR'"
            label="Alanınızı seçiniz">
            <b-field>
            <b-radio 
                name="Endokrinoloji"
                native-value="Endokrinoloji"
                v-model="user.field"
                
                 >
                Endokrinoloji
            </b-radio>
             </b-field>
            <b-field>
                <b-radio 
                name="İç hastalıkları"
                native-value="İç hastalıkları"
                
                v-model="user.field">
                İç hastalıkları
            </b-radio>
            </b-field>
            
        </b-field>
       
        
        
        
        <!-- <b-field class="is-size-4" label="Unvanınız" message="Dr., Prof.Dr., Op.Dr. gibi" value="Dr." v-if="user.role==='DOCTOR'">
            <b-input placeholder="Unvanınızı giriniz"  v-model="user.title"></b-input>
        </b-field> -->
          <b-dropdown
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="300"
                    trap-focus
                    v-if="user.role=='DOCTOR'"
                    v-model="user.title"
                    
                >
                    <template #trigger>
                        <a
                            class="navbar-item pt-0 mr-2 pl-1"
                            role="button">
                            <span class="has-text-weight-bold">Unvanınız</span>
                            <!-- <b-icon icon="menu-down"></b-icon> -->
                        </a>
                    </template>
  
                    <b-dropdown-item
                v-for="(menu, index) in titles"
                :key="index"
                :value="menu" aria-role="listitem">
                <div class="media">
                    <!-- <b-icon class="media-left" :icon="menu.icon"></b-icon> -->
                    <div class="media-content">
                        
                        <h3>{{menu}}</h3>
                    </div>
                </div>
            </b-dropdown-item>

            
                </b-dropdown>
        <p class="is-size-5 mb-2 has-text-weight-light" v-if="user.role=='DOCTOR'">{{user.title}}</p>
         <b-field
            label="Cinsiyetiniz">
            <b-field>
            <b-radio 
                name="FEMALE"
                native-value="FEMALE"
                v-model="user.gender"
                
                 >
                Kadın
            </b-radio>
             </b-field>
            <b-field>
                <b-radio 
                name="MALE"
                native-value="MALE"
                required
                v-model="user.gender">
                Erkek
            </b-radio>
            </b-field>
            
        </b-field>
            <b-field label="Email" value="doktor@email.com">
            <b-input type="email"
                placeholder="doktor@email.com"
                maxlength="30" 
                v-model="user.email"
                required
                >
            </b-input>
        </b-field>
        <b-field label="Şifre"   
        >
            <b-input placeholder="Bir şifre giriniz" type="password" maxlength="30" value= 123456 v-model="user.password" password-reveal required></b-input>
            <template #message>
                <div  v-show="user.password.length < 8 && user.password.length > 0">Şifre en az 8 karakter olmalı</div>
                <!-- <div v-show="user.password.length < 8 && user.password.length > 0">Şifre en az 8 karakter olmalı</div> -->
            </template>
        </b-field>
<b-field label="Şifre tekrar"   
        >
            <b-input placeholder="Bir şifre giriniz" type="password" maxlength="30" value= 123456 v-model="passwordRepeat" password-reveal required></b-input>
            <template #message>
                <div  v-show="passwordRepeat.length < 8 && passwordRepeat.length > 0">Şifre en az 8 karakter olmalı</div>
                <!-- <div  v-show="user.password =! passwordRepeat">Girdiğiniz şifreler eşleşmeli!</div> -->
                <!-- <div v-show="user.password.length < 8 && user.password.length > 0">Şifre en az 8 karakter olmalı</div> -->
            </template>
            
        </b-field>
         <!-- <b-button
            class="has-text-left mb-2"
                label="Kvkk metni"
                type="is-ghost"
                size="is-small"
                @click="confirmCustom" /> -->
                <p class="has-text-weight-light is-size-6 mb-2" @click="confirmCustom" >KVKK onay metni</p>
       <b-field position="is-left">
           <b-button :disabled="!this.user.isConfirmedTermsAndConditions"  class="is-info" @click="addUser()">Kaydet</b-button>
           <b-button  class="ml-2" @click="navigateToLogin()" type="is-text">Giriş Yap</b-button>

       </b-field>
        <b-notification
            type="is-success"
            has-icon
            aria-close-label="Close notification"
            class="registerFeedback is-hidden">
            Kaydınız başarı ile alınmıştır.
            </b-notification>

    
        
  </form>
  <div class=" p-4 m-2 is-hidden-touch">
      
           <img src="@/assets/medicine-ill.svg"  class="visual m-6" alt="Doctors">
      
     
  </div>
    </div>    
</template>

<script>
/* import axios from 'axios' */
/* import router from 'router'; */

 export default {
        name: 'Register',
        data(){
          return{
              text:'Meeting',

              userId:null,
              radio: "",
              isDoctor: false,
              passwordRepeat:"",
              titles:[
                "Dr.", "Prof.Dr.","Doç.Dr.","Dr.Öğretim Üyesi","Uzm.Dr.",
              ],
              user: {
                  name: "",
                  surname: "",
                  title:"",
                  email: "",
                  password:"",
                  role: "",
                  gender:"",
                  field:"",
                  isConfirmedTermsAndConditions:null
              }
          }; 
        },
        methods:{
             confirmCustom() {
                this.$buefy.dialog.confirm({
                    title: 'Kişisel verilerin korunması hakkında',
                    message: `KİŞİSEL VERİLERİN ELDE EDİLMESİ, İŞLENMESİ VE KORUNMASINA YÖNELİK BİLGİLENDİRME
                    
                   DİKKAT! AŞAĞIDAKİ METİN VE ONAY BUTONU BİR YÜKSEK LİSANS TEZİ KAPSAMINDA GELİŞTİRİLEN WEB UYGULAMASININ ALTYAPISINI OLUŞTURMAK AMACIYLA EKLENMİŞTİR.

Ludi.care, kişisel verilerinizin güvenliği konusunda azami standartlara uymayı hedefler. Bu kapsamda ilgili güvenlik önlemlerini alır.

Kişisel Verilerin Elde Edilmesi, İşlenmesi;
Ludi.care tarafından işlenen kişisel verileriniz verilen sağlık hizmetlerine bağlı olarak web uygulaması aracılığıyla olarak toplanmaktadır. 

Kimlik Bilgileriniz:Adınız, soyadınız, T.C. Kimlik numaranız, pasaport numaranız veya geçici TC Kimlik numaranız, doğum yeri ve tarihiniz, medeni haliniz, cinsiyetiniz, sigorta ve/veya hasta protokol numaranız ve sizi tanımlayabilecek diğer kimlik verileriniz;
İletişim Bilgileriniz: Adresiniz, telefon numaranız, elektronik posta adresiniz ve sair iletişim verileriniz, müşteri temsilcileri ya da hasta hizmetleri tarafından çağrı merkezi standartları gereği tutulan sesli görüşme kayıtlarınız ile elektronik posta, mektup veya sair vasıtalar aracılığı ile tarafımızla iletişime geçtiğinizde elde edilen kişisel verileriniz;
Sağlık Bilgileriniz: Hasta tıbbi raporları, tanı verileri, biyometrik veriler, Laboratuvar sonuçlarınız, test sonuçlarınız, muayene verileriniz, randevu bilgileriniz, reçete bilgileriniz dahil ancak bunlarla sınırlı olmaksızın tıbbî teşhis, tedavi ve bakım hizmetlerinin yürütülmesi sırasında veya bunların bir sonucu olarak elde edilen her türlü sağlık verileriniz,
Hasta İlişkileri Yönetimi: Hastaların doldurdukları anketler, teşekkür ve şikâyet mektupları, memnuniyet sonuçları, Ludi.care’i değerlendirmek için yaptığınız bildirimler,
Muhasebesel Bilgileriniz: Banka hesap numaranız, IBAN numaranız, kredi kartı bilginiz, faturalama ve fatura bilgileriniz gibi finansal verileriniz;
Sağlık hizmetlerinin finansmanı ve planlaması amacıyla özel sağlık sigortasına ilişkin verileriniz ve Sosyal Güvenlik Kurumu verileriniz;

Kişisel Verilerin İşleme Amaçları;
Ludi.care tarafından elde edilen her türlü kişisel veriniz (Özel nitelikli kişisel veriler de dâhil fakat bunlarla sınırlı olmamak kaydıyla) aşağıdaki amaçlar ile işlenebilecektir:
Sağlık bakım hizmetlerinin sürdürülmesi, 
Gerekli hatırlatmalar için e-mail yoluyla ulaşılması,
Randevularınıza ilişkin bilgilerin e-mail yoluyla iletilmesi,

Kişisel Verilerin Elde Edilmesi, İşlenmesi;
Kişisel Veri Toplamanın Yöntemi 
Kişisel verileriniz, her türlü sözlü, yazılı ya da elektronik ortamda, yukarıda yer verilen amaçlar kapsamında ve Ludi.care’in sözleşmesel ve kanuni yükümlülüklerini yerine getirebilmesi amacıyla toplanır. 


Veri Güvenliği;
Ludi.care olarak, kişisel verilerinizin teknik ve idari bütün imkanlar dahilinde titizlikle korunmasına ve güvenliğine en üst düzeyde önem vermekteyiz. Bilgi güvenliği standartları ve prosedürleri gereğince alınması gereken tüm güvenlik denetimlerini tam uygunlukla korumakta olduğumuzu, sizlere sunduğumuz tüm ürün ve hizmetlerimizde, kişisel veri güvenliğinin ön planda olduğu bilincinde hareket ettiğimizi, söz konusu güvenlik tedbirlerinin, teknolojik imkanlar da göz önünde bulundurularak her türlü riski dikkate alıp bertaraf etmek suretiyle uygulandığını, bu hususta gerekli tüm hassasiyetin en itinalı şekilde gösterilmekte olduğunu belirtmek isteriz.

 
Kişisel Verilerinize İlişkin Haklarınız;
Kişisel verilerinizin Ludi.care tarafından veri sorumlusu sıfatı ile işlendiği ölçüde eekinci367@gmail.com adresine e-posta ile göndererek kişisel verilerinizin;

Herhangi bir kişisel verinizin işlenip işlenmediğini öğrenme;
Kişisel verilerinizin işlenme faaliyetlerine ilişkin olarak bilgi talep etme;
Kişisel verilerinizin işlenme amaçlarını öğrenme;
Kişisel verilerin yurt içinde veya yurt dışında üçüncü kişilere aktarılmış olması durumunda bu kişileri öğrenme;
Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme;
Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması veya Ludi.care söz konusu verileri işleyebilmek için hukuki dayanağı veya meşru menfaatinin bulunmaması halinde kişisel verilerin silinmesini veya yok edilmesini isteme;
Kişisel verilerin otomatik sistemler vasıtasıyla işlenmesi sonucu ortaya çıkabilecek aleyhte sonuçlara itiraz etme ve;
Kişisel verilerinizin kanuna aykırı bir şekilde işlenmesi sebebiyle zarara uğramanız halinde bu zararın tazmin edilmesini isteme hakkına sahipsiniz.

Saygılarımızla

Ludi.care 

`,
                    cancelText: 'Reddet',
                    confirmText: 'Onayla',
                    type: 'is-success',
                    onConfirm: () => {this.user.isConfirmedTermsAndConditions=true;console.log("TaC",this.user.isConfirmedTermsAndConditions)}
                })
            },
            navigateToLogin(){
              this.$router.push({path:'/mock-login'})  
            },
            toggleDoctor(){
              if(this.user.role=="DOCTOR"){
                 this.isDoctor = true
              } else {
                 this.user.role = false
              }
                
            },
             alert() {
                this.$buefy.dialog.alert('Kaydınız başarıyla alındı. Şimdi giriş yaparak profilinize ulaşabilirsiniz. Sizi giriş sayfasına yönlendiriyorum!')
                
            }  ,
            warnPassword(){
                this.$buefy.dialog.alert('Girdiğiniz şifreler aynı değil. Lütfen kontrol ediniz!')
            },
              confirm() {
                this.$buefy.dialog.alert({
                    message: 'Kaydınız başarıyla alındı. Şimdi giriş yaparak profilinize ulaşabilirsiniz. Sizi giriş sayfasına yönlendiriyorum!',
                    confirmText: 'Tamam',
                    onConfirm: () => this.navigateToLogin()
                })
            },
           async addUser(){
             
                  const  name  =  this.user.name;
                  const  surname =  this.user.surname;
                  const  title =  this.user.title;
                  const  email =  this.user.email;
                  const  password  =  this.user.password;
                  const  role =  this.user.role;
                  const  gender = this.user.gender;
                  const  field = this.user.field
                
                if(email.length == 0 ){
                    alert("Lütfen formu doldurunuz.");
                }
                else if(password == this.passwordRepeat){
                   

                await    this.$store.dispatch("addUser", {name, surname,title,field,email,password,role,gender}) 
              /*  await axios.post('https://backend-tez.herokuapp.com/register', {name, surname,title,field,email,password,role,gender})
                    .then((response) => {
                        
                        this.userId = response.data.id
                        console.log("try",response.data.id)
                          
                    } ).catch((error) =>
                    {console.log(error)}) */

               setTimeout(this.confirm(), 5000);
                } else {
                    this.warnPassword()
                }
            
                
            }
        },
        mounted(){
        
        }
        
    }
</script>

<style scoped>

p{
    font-size: 1.5rem;
}

.register-form{
    text-align: left;
    /* position: absolute;
    top: 45%; */
    /* left: 30%;
    right: 30%; 
    
    border: solid thin rgb(204, 202, 202);
    border-radius: 20px; */
    /* width: 50vw; */
    /* height: auto; */
    /* background-color: rgba(240, 173, 173,0.5);
    background-image: linear-gradient(rgb(189, 159, 159), white); */
}

@media screen and (max-width: 1023px) {
  .register-form {
    width: 100vw;
  }
}

@media screen and (min-width: 1024px) {
  .register-form {
    width: 70%;;
  }
}

.visual{
    max-width: 50vw;
    max-height: 80vh;
}

</style>