<template>
    <div class="box bs-main ml-2-desktop">
        
        <div class="is-flex is-justify-content-space-betwee">

           <b-tooltip
          label="Son eklediğiniz kan şekeri değerlerinizi burada görebilirsiniz. Daha fazlası için Kan Şekeri Değerlerim'e tıklayabilirsiniz."
          position="is-bottom"
          class="is-light"
          multilined
        >
          <h3 class="px-1 is-size-5 has-text-weight-bold" @click="navigateToGlycosis">
            Kan şekeri değerlerim 🩸
          </h3>
          </b-tooltip>
  <!--         <div class="chart mt-5-desktop mt-">
      <Plotly
      
        :data="data"
        :displaylogo="false"
        :layout="layout"
        :modeBarButtonsToRemove="modeBarButtonsToRemove"
        :display-mode-bar="false"
        :config="config"

      ></Plotly>
    </div> -->
        
        </div>
        <hr />
        <div
          class="px-1 is-flex is-flex-direction-colum is-justify-content-space-"
        >
          <div class="h">
            <div v-if="!readings.length">
            Henüz kan şekeri değeri eklemediniz.
          </div>
          <div class="is-flex is-justify-content-space-between ">
          <div>
            <div v-show="!showBigger">
              <div
            class="is-flex mb-3 is-flex-direction-column"
            v-for="reading in readings.slice(
              0, 5
            )"
            v-bind:key="reading.id"
          >
            <div class="is-flex">
              <b-tag type="is-dark" class="is-size-7" size="is-medium">
               <span>#{{ readings.indexOf(reading) + 1 }}</span> 
              </b-tag>
              <!-- <p class="ml-1">-</p> -->
              <b-tag
                type="is-success"
                class="ml-1 is-size-7"
                closable
                v-if="reading.value < 125"
                size="is-medium"
                @close="deleteReading(reading)"
                
                >{{ reading.value }}</b-tag
              >
              <b-tag type="is-danger" class="ml-1 is-size-7" closable @close="deleteReading(reading)" v-else size="is-medium">{{
                reading.value
              }}</b-tag>
            </div>
          </div>
          </div>
          <div v-show="showBigger">
            <div
            class="is-flex mb-3 is-flex-direction-column"
            v-for="reading in readings.slice(
              0, 5
            )"
            v-bind:key="reading.id"
          >
            <div  class="is-flex">
              <b-tag type="is-dark" class="is-size-6" size="is-medium">
               <span>#{{ readings.indexOf(reading) + 1 }}</span> 
              </b-tag>
              <!-- <p class="ml-1">-</p> -->
              <b-tag
                type="is-success"
                class="ml-1 is-size-6"
                closable
                v-if="reading.value < 125"
                size="is-medium"
                @close="deleteReading(reading)"
                
                >{{ reading.value }}</b-tag
              >
              <b-tag type="is-danger" class="ml-1 is-size-6" closable @close="deleteReading(reading)" v-else size="is-medium">{{
                reading.value
              }}</b-tag>
            </div>
          </div>
          </div>
          </div>
          <div>
            <div v-show="!showBigger & readings.length" @click="showBigger = !showBigger"><p class="is-underlined">Büyüt</p></div>
          <div v-show="showBigger" @click="showBigger = !showBigger"><p class="is-underlined">Küçült</p></div>
          </div>
          </div>
          
          </div>
          
        </div>
        <div class="is-flex is-justify-content-space-between">
          <div>
            <a
              class="has-text-black is-underlined"
              @click="navigateToAddReading"
              >Ekle</a
            >
          </div>
          <div>
            <a class="has-text-black" @click="navigateToGlycosis">Daha fazla</a>
          </div>
        </div>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
/* import { Plotly } from "vue-plotly"; */
export default {
  name: 'BloodSugar',
   components: {
    /* Plotly, */
    
  },
  data(){
     return {

        id: this.$route.params.id,
        isHidden: false,
        showBigger:false,
        /* data: [
        {
          x: [],
          y: [],
          type: "scatter",
          mode: 'lines+markers',
          marker: {
    
    size: 1
  },
          
          
        },
      ],
      config: { locale: 'fr' },
      locale: 'tr',
      layout: {
        autosize:false,
        height:50,
        width:150,
        
      
        xaxis: {
          type: "date",
                "visible": false,
          showgrid:false,
          autorange: true,
          range: ["2022-04-04", "2022-04-04"],
        },
        yaxis:{
          showgrid:false,
          "visible": false,
          autorange: true
        },

        
        margin: {
          l: 5,
          r: 5,
          t: 5,
          b:5,
          
          
          pad: 0,
        },
      },
      modeBarButtonsToRemove: [
        
      ] */
     } 
  },
  computed:{
            ...mapState(['readings'])
        },
        methods:{
            ...mapActions(['getGlycosisDataById',]),
             navigateToGlycosis() {
      this.$router.push({ path: `/user/${this.id}/read` });
      this.$store.dispatch("getGlycosisDataById");
    },
    navigateToAddReading() {
      this.$router.push({ path: `/user/patient/${this.id}/read-details` });
      //this.$store.dispatch('getGlycosisDataById')
    },
    deleteReading(reading) {
      this.$buefy.dialog.confirm({
        title: "Kan şekeri silme işlemi",
        message: `Bu glikoz değerinizi <b>silmek</b> istediğinizden emin misiniz? - ${reading.value}`,
        confirmText: "Glikoz değerini sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          //console.log("r",reading)
          await this.$store.dispatch("deleteReading", reading);

          this.getGlycosisDataById();

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
        
      });
      
    },
/* 
    setData() {
      this.readings.slice(0,5).forEach((reading) => {
        this.data[0].y.push(reading.value);
        console.log("setData",this.data[0].y)
        this.data[0].x.push(reading.date);
      });
    } */
            
    
        },
        mounted(){
          this.getGlycosisDataById();
          /* setTimeout(this.setData(), 250)  */
        },
        created(){
            //this.getGeneralNotesById()
        }
};
</script>

<style scoped >
.chart{
  width: 50%;
  height: 20%;
}


@media screen and (max-width: 415px) {
  .bs-main {
   min-width: 95vw;
  }
  
}

.h{
  width: 100%;
}
</style>
