import Vue from 'vue'
import VueRouter from 'vue-router'
import DoctorHome from '../views/DoctorHome.vue'
import Appointment1 from '../components/Appointment1.vue'
import Register from '../views/Register.vue'
/* import Login from '../views/Login.vue' */
import MockLogin from '../views/MockLogin.vue'
import Landing from '../views/Landing.vue'
import NotExist from '../components/NotExist.vue'
import Admin from '../views/Admin.vue'
import MustBeLoggedIn from '../components/MustBeLoggedIn.vue'
import PatientHome from '../views/PatientHome.vue'
import PatientHomeOption2 from '../views/PatientHomeOption2.vue'
import Reading from '../components/Reading.vue'
import ReadingForDoctor from '../components/ReadingForDoctor.vue'
import ReadingDetail from '../components/ReadingDetail.vue'
import BloodPressureDetail from '../components/BloodPressureDetail.vue'
import BloodPressure from '../components/BloodPressure.vue'
import PatientMeetings from '../components/PatientMeetings.vue'
import Patients from '../components/Patients.vue'
import Doctors from '../components/Doctors.vue'
import DoctorsFull from '../components/DoctorsFull.vue'
import Visits from '../components/Visits.vue'
import PatientMeeting from '../components/PatientMeeting.vue'
import DoctorMeeting from '../components/DoctorMeeting.vue'
import UploadPhotoCloud from '../components/UploadPhotoCloud.vue'
/* import DoctorMeetings from '../components/DoctorMeetings.vue' */
import DoctorMeetingsWrapped from '../components/DoctorMeetingsWrapped.vue'
import DoctorPatientsWrapped from '../components/DoctorPatientsWrapped.vue'
import PatientGeneralNotesEdit from '../components/PatientGeneralNotesEdit.vue'
import PatientGeneralNotes from '../components/PatientGeneralNotes.vue'
import ChronicEdit from '../components/ChronicEdit.vue'
import AppointmentPreferencesEdit from '../components/AppointmentPreferencesEdit.vue'
import PatientBaseInfoEdit from '../components/PatientBaseInfoEdit.vue'
import MeetingDetail from '../components/MeetingDetail.vue'
import TreatmentInfoEdit from '../components/TreatmentInfoEdit.vue'
import Video from '../components/Video.vue'
import PatientForDoctor from '../components/PatientForDoctor.vue'
import AddRoom from '../components/AddRoom.vue'
import SetDose from '@/components/SetDose.vue';
import SetMedicine from '@/components/SetMedicine.vue';



Vue.use(VueRouter)



const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta:{
      hideLogout:true,
      hideBottomNav:true
    }
  },

  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta:{
      hideLogout:false,
      hideRegister: true,
      hideLogin:true,
      adminSidebarShow:true,
      hideBottomNav:true
    }
  },
  {
    path: '/admin/patients',
    name: 'AdminPatients',
    component: Patients,
    meta:{
      hideLogout:false,
      hideRegister: true,
      hideLogin:true,
      adminSidebarShow:true,
      hideBottomNav:true
    }
  },
  {
    path: '/admin/doctors',
    name: 'AdminDoctors',
    component: Doctors,
    meta:{
      hideLogout:false,
      hideRegister: true,
      hideLogin:true,
      adminSidebarShow:true,
      hideBottomNav:true
    }
  },
  {
    path: '/admin/visits',
    name: 'AdminVisits',
    component: Visits,
    meta:{
      hideLogout:false,
      hideRegister: true,
      hideLogin:true,
      adminSidebarShow:true,
      hideBottomNav:true
    }
  },
  {
    path: '/admin/rooms',
    name: 'AdminRooms',
    component: AddRoom,
    meta:{
      hideLogout:false,
      hideRegister: true,
      hideLogin:true,
      adminSidebarShow:true,
      hideBottomNav:true
    }
  },
  {
    path: '/user/:id',
    name: 'Profile',
    component: PatientHome,
    /* beforeEnter: (to,from,next) => {
      
      let currentUser = JSON.parse(window.localStorage.currentUser)
     // let id = JSON.parse(window.localStorage.id)
      console.log("user",currentUser.loggedinUser)
      if(currentUser.loggedinUser.id) {
        next();
      } else {
        next('/mustbeloggedin')
      }
      
    }, */
    meta:{
      patientSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      previousPageShow:false
    },
    
      },
      {
        path: '/user/:id/option-2',
        name: 'ProfileOption-2',
        component: PatientHomeOption2,
        /* beforeEnter: (to,from,next) => {
          
          let currentUser = JSON.parse(window.localStorage.currentUser)
         // let id = JSON.parse(window.localStorage.id)
          console.log("user",currentUser.loggedinUser)
          if(currentUser.loggedinUser.id) {
            next();
          } else {
            next('/mustbeloggedin')
          }
          
        }, */
        meta:{
          patientSidebarShow: true,
          hideRegister: true,
          hideLogin:true,
          previousPageShow:false
        },
        
          },
  {
    path: '/user/doctor/:id',
    name: 'DoctorProfile',
    component: DoctorHome,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
    },
    
  },
  {
    path: '/user/doctor/:id/patient/:patientId',
    name: 'PatientForDoctor',
    component: PatientForDoctor,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
    },
  },
  {
    path: '/user/doctor/:id/patient/:patientId/set-dose',
    name: 'SetDose',
    component: SetDose,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
    },
  },
  {
    path: '/user/doctor/:doctorId/patient/:id/set-medicine',
    name: 'SetMedicine',
    component: SetMedicine,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true,
      showToDoctor:true
    },
  },
  {
    path: '/user/doctor/:doctorId/patient/:id/reading',
    name: 'ReadingForDoctor',
    component: ReadingForDoctor,
    meta:{
      doctorSidebarShow: false,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true,
      previousPageShow:true
    },
  },
  {
    path: '/user/doctor/:id/patients',
    name: 'DoctorPatients',
    component: DoctorPatientsWrapped,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true,
      /* patientDetailShow:true */
      //hideAtItself:true
    },
  },

  {
    path: '/setDose',
    name: 'Dose',
    component: SetDose,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true,
      /* patientDetailShow:true */
      //hideAtItself:true
    },
  },
  {
    path: '/user/doctor/:id/meetings',
    name: 'DoctorMeetings',
    component: DoctorMeetingsWrapped,
    meta:{
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
      //hideAtItself:true
    },
  },
  {
    path: '/doctor/:id/photo',
    name: 'photo',
    component: UploadPhotoCloud,
    meta:{
      
      doctorSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
    }
    
  },
  {
    path: '/patient/:id/photo',
    name: 'patientPhoto',
    component: UploadPhotoCloud,
    meta:{
      
      patientSidebarShow: true,
      hideRegister: true,
      hideLogin:true,
      hideBottomNav:true
    }
    
  },
  
  {
    path: '/notfound',
    name: 'NotExist',
    component: NotExist,
    meta: {
      hideRegister: true,
      hideLogin:true,
      hideLogout: true,
      hideBottomNav:true
     }
  },
  {
    path: '/mustbeloggedin',
    name: 'MustBeLoggedIn',
    component: MustBeLoggedIn,
    meta: {
      hideRegister: false,
      hideLogin:true,
      hideLogout: true,
      hideBottomNav:true
     }
  },
  
  
  
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      hideRegister: true,
      hideLogin:true,
      hideLogout: true,
      hideBottomNav:true
     }
  },
  {
    path: '/login',
    name: 'Login',
    component: MockLogin,
    meta:{
      hideNavbar: true,
      hideBottomNav:true

    }
  },
  {
    path: '/mock-login',
    name: 'Loginn',
    component: MockLogin,
    meta:{
      hideNavbar: true,
      hideBottomNav:true

    }
  },
  {
    path: '/user/patient/:id/info-edit',
    name: 'GeneralNotesEdit',
    component: PatientGeneralNotesEdit,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true
    }
  },
  {
    path: '/user/patient/:id/chronic-edit',
    name: 'ChronicEdit',
    component: ChronicEdit,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true
    }
  },
  {
    path: '/user/patient/:id/notes',
    name: 'PatientGeneralNotes',
    component: PatientGeneralNotes,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true
    }
  },
  {
    path: '/user/patient/:id/patient-base-info-edit',
    name: 'PatientBaseInfoEdit',
    component: PatientBaseInfoEdit,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true
    }
  },
  {
    path: '/user/patient/:id/patient-meetings',
    name: 'PatientMeetings',
    component: PatientMeetings,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      previousPageShow:false
    }
  },
  {
    path: '/user/patient/:id/appointment1',
    name: 'Appointment',
    component: Appointment1,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true

      
    }
  },
  {
    path: '/user/:id/read',
    name: 'Reading',
    component: Reading,
    meta:{
      patientSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      hideNavbar: true,
      previousPageShow:true
    }
  },
  {
    path: '/user/:id/treatment-info',
    name: 'Treatment',
    component: TreatmentInfoEdit,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      showToDoctor:false
      /* hideNavbar: true, */
    }
  },
  {
    path: '/doctor/:id/meeting/:meetingId',
    name: 'MeetingDetail',
    component: MeetingDetail,
    meta:{
      doctorSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true
    }
  },
  {
    path: '/doctor/:id/appointment-preferences-edit',
    name: 'AppointmentPreferencesEdit',
    component: AppointmentPreferencesEdit,
    meta:{
      doctorSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true
    }
  },
  {
    path: '/user/patient/:id/read-details',
    name: 'ReadingDetail',
    component: ReadingDetail,
    meta:{
      patientSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      previousPageShow:true
    }
  },
  {
    path: '/user/patient/:id/blood-pressure-details',
    name: 'BloodPressureDetail',
    component: BloodPressureDetail,
    meta:{
      patientSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      hideNavbar: true,
      previousPageShow:true

    }
  },
  {
    path: '/user/patient/:id/blood-pressure',
    name: 'BloodPressure',
    component: BloodPressure,
    meta:{
      patientSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      previousPageShow:true
    }
  },
  {
    path: '/patient-meeting/:id',
    name: 'PatientMeeting',
    component: PatientMeeting,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true
    }
  },
  {
    path: '/doctor-meeting/:id',
    name: 'DoctorMeeting',
    component: DoctorMeeting,
    meta:{
      doctorSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true,
      previousPageShow:true,
    }
  },

  {
    path: '/doctors',
    name: 'DoctorsFull',
    component: DoctorsFull,
    meta:{
      patientSidebarShow: false,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true,
      hideLogout:true,
      
    }
  },
  {
    path: '/patient/video',
    name: 'video',
    component: Video,
    meta:{
      patientSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true,
      hideLogout:true,
      visibleToPatient:true,
    }
  },
  {
    path: '/doctor/video',
    name: 'doctor-video',
    component: Video,
    meta:{
      doctorSidebarShow: true,
      hideRegister:true,
      hideLogin:true,
      hideBottomNav:true,
      hideLogout:true,
      visibleToDoctor:true,
      hideNavbar: true,
    }
  },
  


  { path: "*",
    component: NotExist,
    meta: {
      hideRegister: true,
      hideLogin:true,
      hideLogout: true
     }
   }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior(to, ){
    if(to.hash){
      return {
       /*  selector : to.hash,
        behavior: 'smooth',
         x: 0, y: 0  */
        /*  el:to.hash,
         behavior: 'smooth', */
      }
    }
    
  },
  
})


/*router.beforeEach((to,from,next)=>{
    next()
})*/

export default router
