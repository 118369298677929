<template>
<div class="is-flex is-flex-direction-column
            mx-5  ">
  
  <div class="is-flex 
            is-flex-direction-column 
            is-align-items-flex-start 
            is-justify-content-space-between
            is-flex-wrap-wrap mt-6 mx-6-desktop marg">
    <div class="mx-2">
<div class="is-flex 
            is-flex-direction-column 
            is-size-3 mt-2 mx-6-desktop">
        <h4 class="mt-1 mt-4-desktop has-text-left " >Kan basıncı değerlerinizi giriniz</h4>
        <hr class="mt-0">
        <label for="sistolic-value" class="has-text-left is-size-5 mt-2">Sistolik kan basıncı (Büyük tansiyon)</label>
        <input id="sistolic-value" class=" input is-normal" v-model="systolic"  min=s30 max=250 type="number" maxlength="4">
        <label for="diastolic-value" class="has-text-left is-size-5">Diastolik kan basıncı (Küçük tansiyon)</label>
        <input id="diastolic-value" class=" input is-normal" v-model="diastolic"  min="30" max="250" type="number" maxlength="4">
        <b-tooltip label="Ölçüm tarihinizi kendiniz girebilirsiniz, boş bırakırsanız ölçüm zamanı olarak şu an eklenir."
            position="is-bottom" type="is-light" multilined>
        <b-field class="has-text-left is-size-5" label="Ölçüm tarihi">
            <b-datetimepicker
                placeholder="Kan basıncı ölçüm tarih saatinizi giriniz"
                icon="calendar-today"
                v-model="date"
                editable>
            </b-datetimepicker>
        </b-field>
        </b-tooltip>
        <label for="bp-note" class="has-text-left is-size-5">Not</label>
        <input id="bp-note" class=" input is-normal" v-model="bpNote" type="text" maxlength="50">
        <hr class="mb-0">
        <button class="button is-info ml-0 mt-2"  @click="addReading">Ekle</button>
        <button class="button is-info ml-0 mt-2 is-outlined" @click="navigateToBps" >Ölçüm değerlerime git</button>
        
    </div> 
    
    
    </div>
      
</div>
  
  
</div>
</template>    


<script>

/* import axios from 'axios' */

export default {
    name: 'ReadingDetail',
    components: {
        
    },
    data(){
          return{
              text:'Meeting',
                systolic:'',
                diastolic:'',
                bpNote:'',
                date:null
          }
    },
    methods:{
        
       async addReading(){
            
          
           
            
            const systolic = this.systolic;
            const diastolic = this.diastolic;
            
            const note = this.bpNote;
          const patientId = this.$route.params.id;

          let date;


            if(this.date == null){
            date = new Date().toISOString()
            console.log("d",date)
            } else {
            date = this.date  
            }

            if (parseInt(this.systolic) < 30){
             this.showSBPWarning();
            }
             else if (this.systolic.length == 0 ||this.diastolic.length == 0){
              
              this.showSystolicOrDiastolicWarning(); 

            }
            /* else if (this.diastolic.length == 0){
              
              this.showDiastolicWarning(); 

            } */
            else if (parseInt(this.systolic) > 220){
              
              this.showSBPUpperLimitWarning(); 

            }
            else if (parseInt(this.diastolic) < 30){
              
              this.showDBPWarning(); 

            }
            else if (parseInt(this.diastolic) > 130){
              
              this.showDBPUpperLimitWarning(); 

            }
            else {
              
/* 
               await axios.post('https://backend-tez.herokuapp.com/bp-reading', newBP)
                    .then((response) => {
                    console.log(response)
                    this.toastBPConfirmation();
                    this.$store.dispatch('getBloodPressureDataById')
                    })
                    .catch((error) =>
                    {console.log(error)}) */
                    this.$store.dispatch("addBloodPressureReading", {systolic,diastolic,date,patientId,note})
          this.toastBPConfirmation();
            }
            
            this.systolic = '';
            this.diastolic = '';
            
            
           
            
        },
        
        
        showSystolicOrDiastolicWarning(){
          this.$buefy.toast.open({
                    message: 'Lütfen büyük tansiyon ve küçük tansiyon değerlerini doldurunuz.',
                    type: 'is-warning',
                    duration: 2000
                })
        },
       /*  showDiastolicWarning(){
          this.$buefy.toast.open({
                    message: 'Lütfen bir küçük tansiyon değer giriniz.',
                    type: 'is-warning',
                    duration: 2000
                })
        }, */
        showSBPWarning(){
          this.$buefy.toast.open({
                    message: "Büyük tansiyon değeri 30'dan düşük olmamalı, yanlış girmiş olabilir misiniz?",
                    type: 'is-warning',
                    duration: 2000
                })
        },
        showSBPUpperLimitWarning(){
          this.$buefy.toast.open({
                    message: "Büyük tansiyon değeri 220'dan büyük olmamalı",
                    type: 'is-warning',
                    duration: 2000
                })
        },
        showDBPWarning(){
          this.$buefy.toast.open({
                    message: "Büyük tansiyon değeri 30'dan düşük olmamalı, yanlış girmiş olabilir misiniz?",
                    type: 'is-warning',
                    duration: 2000
                })
        },
        showDBPUpperLimitWarning(){
          this.$buefy.toast.open({
                    message: "Küçük tansiyon değeri 130'dan büyük olmamalı.",
                    type: 'is-warning',
                    duration: 2000
                })
        },
         toastBPConfirmation(){
          this.$buefy.toast.open({
                    message: "Kan basıncı değerleri başarıyla eklendi",
                    type: 'is-success',
                    duration: 2000
                })
        },
        navigateToBps(){
                this.$router.push({path:`/user/patient/${this.$route.params.id}/blood-pressure-details`})
            },
    },
    created(){
     
    }

}
</script>

<style>
.marg{
  margin-bottom: 80px;
}
</style>