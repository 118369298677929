<template>
    <div class="box  mx-4-desktop mb-2 pb-2 px-4">
      <h2 class="has-text-left text-pointer is-size-4 " @click="navigateToDoctorMeetings">Görüşmeler</h2>
      <hr class="mt-1 mb-4" >
       <p class="has-text-left" v-if="!doctorMeetings.data.length"> Bu kullanıcıya ait görüşme bulunmamaktadır.</p>
      <div v-for="meeting in doctorMeetings.data.slice(0, 5)" v-bind:key="meeting.id"
      class="box box-radius outcome-status-container px-3 mb-2" 

      >
             
              <div v-show="!meeting.outcomes.length & meeting.startsAt < new Date().toISOString()" class="outcome-status" >
                <b-tooltip position="is-right" label="Bu görüşme için sonuç girilmemiş!" class="is-danger ">
                <div class="outcome">a</div>
                </b-tooltip>
                
              </div>
             
            <div class="m is-flex is-align-items-start is-justify-content-space-between">
               <!-- <b-tag class="is-rounded is-small"></b-tag>   --> 
               <div class="is-flex is-align-items-center">
                   <b-tag v-if="meeting.outcomes & meeting.startsAt < new Date().toISOString() " class="is-rounded is-danger" size="is-medium">{{meeting.patientId.name[0]}}{{meeting.patientId.surname[0]}}</b-tag>
                        <b-tag v-else class="is-rounded is-medium">{{meeting.patientId.name[0]}}{{meeting.patientId.surname[0]}}</b-tag>
               <div>
                <router-link class=" ml-2 is-flex has-text-black is-align-content-baseline" :to="`/doctor-meeting/${meeting._id}`">
                    <p class="has-text-weight-light is-size-6"  >{{meeting.patientId.name}}</p>
               <p class="ml-1 has-text-weight-light is-size-6">{{meeting.patientId.surname}}</p>
                </router-link>
                
              
                <b-tag   v-if="!meeting.isConfirmed & meeting.startsAt > new Date().toISOString()" class="is-warning is-light " @click="navigateToMeeting(meeting)">Onay Bekliyor</b-tag>
                <b-tag v-if="meeting.isConfirmed & meeting.startsAt > new Date().toISOString()" class="is-success is-light ">Onaylandı</b-tag>
               <b-tag v-if="meeting.isCancelled" class="is-danger is-light ml-2">İptal edildi</b-tag>
               </div>
               
            </div>
            <div class="is-flex starting-time-detail">
              <div class="mr-2 starts-at">
                   <b-tag v-if="dayStart < meeting.createdAt" class="is-success ml-2">{{meeting.startsAt| moment('from','now')}}</b-tag>
                </div>
                <div>
                  <b-tag v-if="dayStart < meeting.startsAt" class="is-info ml-2">{{meeting.startsAt| moment('Do MMMM YYYY')}}</b-tag>
                <b-tag  v-else class="ml-2">{{meeting.startsAt| moment('Do MMMM YYYY')}}</b-tag>
                </div>
                
              
            </div>
               
            </div>
           
           
      </div>
      <p v-if="doctorMeetings.data.length" @click="navigateToDoctorMeetings" class="is-size-7 has-text-right mt-3">Daha fazla göster</p>
      
    </div>    
</template>

<script>
import moment from 'moment'
import {mapState, mapActions} from 'vuex';
 export default {
        name: 'DoctorMeetings',
        data(){
          return{
          dayStart: moment(new Date().toISOString()).startOf('day').format(),
          dayEnd: moment(new Date().toISOString()).endOf('day').format(),
          }
          
  },
  computed:{
            ...mapState(['doctorMeetings']),
            isMeetingToday(){
              return true
            }
        },
        methods:{
            ...mapActions(['getMeetingsByDoctorId']),
            navigateToDoctorMeetings(){
                
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/meetings`})
            
            },
            navigateToMeeting(meeting){
                
              this.$router.push({path:`/doctor-meeting/${meeting._id}/`})
            
            },
      
        },
        created(){
            this.getMeetingsByDoctorId()
      
        }
        
    }
</script>

<style>

.m{/* 
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); */
}

.outcome-status{
   position: absolute;
  bottom: 73%;
  left: 5px;
  height: 0.5rem;
  width: 0.5rem;
  border: solid #f14668 2px;
  border-radius: 50px;
  background-color: #f14668;
}

.outcome-status-container{
  position: relative;
}

.outcome{
  /* position: absolute;
  bottom: 45px;
  left: 5px;
  height: 1px;
  width: 1px;
  background-color: #f14668; */
  visibility: hidden;
}

@media screen and (max-width: 450px) {
   .starting-time-detail{
 flex-direction: column;
}

}



</style>