import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import Gravatar from 'vue-gravatar';
import ProgressBar from 'vuejs-progress-bar'

Vue.use(ProgressBar)
Vue.use(Vuex)
Vue.use(Buefy, {
  defaultIconPack: 'fas'
})


Vue.component('v-gravatar', Gravatar);

const moment = require('moment')
require('moment/locale/tr')
 
Vue.use(require('vue-moment'), {
  moment
});

Vue.config.productionTip = false

/* router.beforeEach((to,from,next)=>{
  //console.log("global kontrol")
 // next();
}) */



new Vue({
  router,
  store,
  
  render: h => h(App)
}).$mount('#app')
