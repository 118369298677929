<template>
  <div class="mt-6 pt-2 mx-2">
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon>
            </b-loading>
   <!--  <div class="is-flex is-justify-content-space-between mx-2 is-hidden-deskto is-align-content-baseline">
      
      <PatientSidebarNew></PatientSidebarNew>
      <div>
        <h3 class="is-size-4 has-text-weight-bold">
          Kan şekeri değerleri
        </h3>
      </div>
      <div class="mr-2 mt-2">
        <b-button class="is-small" @click="navigateToReadingDetail">
          <i class="fas fa-plus" ></i>
        </b-button>
      </div>
    </div> -->
    <hr>
    <h3 class="ml-5 mt-1 mr-1 has-text-left mb-6 is-size-4 has-text-weight-bold">{{patientData.data.name}} {{patientData.data.surname}} için kan şekeri değerleri </h3>
    <div class="mt-5-desktop mt-2">
      
      <Plotly
      
        :data="data"
        :displaylogo="false"
        :layout="layout"
        :modeBarButtonsToRemove="modeBarButtonsToRemove"
        :display-mode-bar="true"
        :config="config"

      ></Plotly>
    </div>
    
    <div
      class="mx-4 mt-2 is-flex is-justify-content-space-between is-align-items-baseline"
    >
      <div class="is-flex is-flex-direction-column is-align-items-flex-start">
        <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <div class="is-flex is-flex-direction-column mb-1 ">
            <b-tooltip
                label="Bugün veya bu haftaya ait değerleri görmek butonlara tıklayabilirsiniz."
                position="is-right"
                class="is-info"
                multilined
              >
               
            <p class="has-text-left mr-1">Filtrele</p>
            </b-tooltip>
            <div class="is-flex is-flex-direction-column b ml-1-desktop">
        <div>
                <b-field>
               <b-radio-button v-model="mealStatus"
                native-value="before"
                type="is-info is-light"
                
                @input="setTIR">
                Açlık
            </b-radio-button>

            <b-radio-button v-model="mealStatus"
                native-value="after"
                type="is-info is-light"
                
                 @input="setAfterTIR">
                Tokluk
            </b-radio-button>
            <b-tooltip
                label="Açlık durumu filtresini temizlemek için tıklayınız."
                position="is-right"
                class="is-info"
                multilined
              >
              <b-button class="is-light ml-1" @click="clearMealStatus">
                <i class="fas fa-times"></i>
              </b-button>
              </b-tooltip>
           <!--  <b-radio-button v-model="mealStatus"
                native-value="null"
                type="is-info is-light"
                @input="handleMeal"
                size="is-medium"
                class="has-textweigth-bold"
                >
                ⨉
            </b-radio-button> -->
        </b-field>
        </div>
              <div class="is-flex is-flex-wrap-wrap mt-2">
                <b-button  @click="setToday()" class="mr-1 mb-1"
                >Bugün</b-button
              >
              <b-tooltip
                label="Son 7 gündeki ölçümlerinizi görmek için tıklayabilirsiniz."
                position="is-bottom"
                class="is-info is-hidden-deskto"
                multilined
              >
              <b-button  @click="setThisWeek()" class="mr-1 mb-1"
                >Bu hafta</b-button
              >
              </b-tooltip>
              <b-tooltip
                label="Bu butona tıklayarak başlangıç ve bitiş tarihlerini belirlediğiniz bir filtre oluşturabilirsiniz."
                position="is-bottom"
                class="is-info is-hidden-deskto"
                multilined
              >
              <b-button

                :class="{ active: showCustomFilter }"
                @click="showCustomFilter = !showCustomFilter"
                
                >Özel</b-button
              >
              <b-button
                class="ml-1"
                @click="showAllData"
                
                >Tümünü göster</b-button
              >
              </b-tooltip>
              <b-tooltip
                label="Filtreyi temizlemek için tıklayınız."
                position="is-right"
                class="is-info"
                multilined
              >
              <b-button class="is-light ml-1" @click="clearDates">
                <i class="fas fa-times"></i>
              </b-button>
              </b-tooltip>
              </div>
            </div>
          </div>

          <div v-show="showCustomFilter">
            <div class="is-flex mb-1 is-justify-content-space-between is-align-items-baseline">
              <p class="has-text-left mr-2 mt-">Başlangıç</p>
              <b-input
                id="start-date"
                type="date"
                v-model="startDate"
              ></b-input>
            </div>
            <div class="is-flex is-justify-content-space-between">
              <p class="has-text-left mr-6">Bitiş</p>
              <b-input
                id="end-date"
                type="date"
                @change="refresh"
                v-model="endDate"
              ></b-input>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div class="px-5-desktop is-flex is-flex-direction-colum ">
          <b-tooltip
                label="Grafiği yenilemek için tıklayabilirsiniz."
                position="is-left"
                class="is-info is-hidden-deskto"
                multilined
              >
          <b-button class="is-small mb-2 mr-2" @click="refresh" size="is-small" >
         <span>
         <i class="fas fa-redo"></i>  
         </span>
         <span class="is-hidden-mobile">
            Grafiği yenile
          </span>
        
         </b-button>
          </b-tooltip>
          <b-tooltip
                label="Ölçüm eklemek için tıklayabilirsiniz. Bu eylem sizi başka bir sayfaya yönlendirecek."
                position="is-left"
                class="is-info is-hidden-deskto"
                multilined
              >
      
        </b-tooltip>
      </div>
    </div>
    <p v-if="!this.readings.length" class="has-text-left mt-4 pt-4 ml-4">
      Bu kullanıcıya ait kan şekeri değeri bulunmamaktadır.
    </p>
    <p v-if="!this.filteredData.length" class="has-text-left mt-4 pt-4 ml-4">
      Seçilen koşula uygun kan şekeri değeri bulunmamaktadır.
    </p>
    <hr>
    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-around mt-4 mb-4 mx-2">
      <div class="box mr-2 mb-1 is-flex-grow-1">
        <div class="is-flex is-align-items-baseline">
          <p class="mr-2 is-size-4">Tarih</p>
        <b-tag class="is-medium" v-if="readings24hour.data.length">{{readings24hour.data[0].date | moment("Do MMMM YYYY")}}</b-tag> 
        <p v-else>Son 24 saatte veri girişi bulunmamaktadır.</p>
        </div>
        <hr>
        <div class="is-flex">
          <p class="mr-6 is-size-4">Zaman</p>
          <p class="mr-3 is-size-4">Açlık</p>
          <p class="mr-1 is-size-4">Tokluk</p>
        </div>
        <hr>
        <div class="is-flex">
          <p class="mr-6 is-size-4">Sabah</p>
          <b-tag class="mr-3 is-medium" v-if="morningHungry[0]">{{morningHungry[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
          <b-tag class="mr-1 is-medium" v-if="morningFull[0]">{{morningFull[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
        </div>
        <hr>
        <div class="is-flex">
          <p class="mr-6 is-size-4">Öğle </p>
          <b-tag class="mr-3 is-medium" v-if="noonHungry[0]">{{noonHungry[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
          <b-tag class="mr-1 is-medium" v-if="noonFull[0]">{{noonFull[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
        </div>
        <hr>
        <div class="is-flex">
          <p class="mr-6 is-size-4">Akşam</p>
          <b-tag class="mr-3 is-medium" v-if="eveningHungry[0]">{{eveningHungry[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
          <b-tag class="mr-1 is-medium" v-if="eveningFull[0]">{{eveningFull[0].value}}</b-tag>
          <p class="mr-3 is-medium" v-else>-</p>
        </div>
        <hr>
        <div class="is-flex">
          <p class="mr-6 is-size-4">Gece</p>
          
        </div>
      </div>
        <div class="box stat  mx-1-desktop is-flex-grow-1 mr-4-desktop has-background-info-light mb-2 ">
        <p v-if="mealStatus=='before'" class="is-size-4">Time in Range (80-130)</p>
        <p v-else-if="mealStatus=='after'" class="is-size-4">Time in Range (70-160)</p>
        <p v-else class="is-size-4">Time in Range</p>
        
        
        <p class="is-size-4">%{{tir}}</p>
        <hr>
         <p class="is-size-4">Ortalama</p>
        
        <p class="is-size-4">{{mean}}</p>
        <hr>
         <p class="is-size-4">Minimum değer</p>
        
        <div class="is-flex  is-align-items-baseline is-justify-content-center">

        <span class="icon"> 
        <i  class=" fas fa-arrow-down"></i>
        </span>
       
        <p class="is-size-4 ml-2 ">{{minimum}}</p>
        
        
        </div>
        <hr>
         <p class="is-size-4">Maksimum değer</p>
        
        <div class="is-flex is-align-items-baseline is-justify-content-center">
            <span class="icon"> 
        <i class="fas fa-arrow-up"></i>
        </span> 
        <p class="is-size-4 has-textweight-bold">{{maximum}}</p>
        </div>
        <hr>
         <p class="is-size-4">Standart sapma</p>
        
        <div class="is-flex  is-align-items-baseline is-justify-content-center">

       
        <p class="is-size-4 ml-2 ">{{std}}</p>
        
        
        </div>
    </div>
    <!-- <div class="has-background-info-light box mx-3-desktop mb-0 is-flex-grow-">
        <p class="is-size-4">Minimum değer</p>
        <hr>
        <div class="is-flex  is-align-items-baseline is-justify-content-center">

        <span class="icon"> 
        <i  class=" fas fa-arrow-down"></i>
        </span>
       
        <p class="is-size-4 ml-2 ">{{minimum}}</p>
        
        
        </div>
        
    </div> -->
    <div class="has-background-light box  is-flex is-flex-direction-column is-justify-content-center is-flex-grow-1 mb-2">
        <div>
            <p class="is-size-4 multiline-text ml-2 ">Hasta kaç gün önce uygulamayı kullanmaya başlamış?</p>
        <p class="is-size-4 ml-2 ">{{range}}</p>
        </div>
        <hr>
        <div>
          <p class="is-size-4 ml-2 ">Kayıt yapılmayan gün sayısı</p>
        <p class="is-size-4 ml-2 ">{{numberOfDaysWithoutReading}}</p>
        </div>
        <hr>
        <div>
          <p class="is-size-4 ml-2 ">Kayıt yapılan gün sayısı</p>
        <p class="is-size-4 ml-2 ">{{numberOfDaysWithReading}}</p>
        </div>
         <!-- <p class="is-size-4 ml-2 ">Kayıt yapılmayan gün sayısı</p> -->
        <hr>
        <div class="is-flex days-without-reading  is-align-items-baseline">
          <p class="is-size-4">veri girişi yapılmayan gün sayısı </p><b-tag class="is-medium is-info mx-2 ">{{daysWithNoReading}} </b-tag> 
        </div>
        </div>
 <!--    <div class="has-background-info-light box mx-1-desktop mb-0 is-flex-grow-">
        <p class="is-size-4">Maksimum değer</p>
        <hr>
        <div class="is-flex is-align-items-baseline is-justify-content-center">
            <span class="icon"> 
        <i class="fas fa-arrow-up"></i>
        </span> 
        <p class="is-size-4 has-textweight-bold">{{maximum}}</p>
        </div>
    </div> -->

    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { Plotly } from "vue-plotly";



export default {
  name: "Reading",
  components: {
    Plotly,
    
  },
  data() {
    return {
      range:null, 
      mealStatus:null,
      daysWithNoReading:null,
      numberOfDaysWithReading:null,
      numberOfDaysWithoutReading:null, 
      isLoading: true,
      mean:null,
      minimum:null,
      maximum:null,
      std:null,
      tir:null,
      isFullPage: true,
      startDate: null,
      lowerLimit: 100,
      upperLimit: 125,
      endDate: null,
      active: false,
      showCustomFilter: false,
      id: this.$route.params.doctorId,
      data: [
        {
          x: [],
          y: [],
          type: "scatter",
          mode: 'lines+markers',
          marker: {
    /* color: 'rgb(128, 0, 128)', */
    size: 10
  },
          line: { shape: "line", width: 3 },
          
        },
       /*  {
  x: [0, 1, 2],
  y: [3, 3, 3],
  mode: 'text',
  name: 'Lines and Text',
  text: ['Text G', 'Text H', 'Text I'],
  textposition: 'bottom',
  type: 'scatter'
} */
      ],
      config: { locale: 'fr' },
      locale: 'tr',
      layout: {
        /* height:280, */
        annotations: [
    {
      x: '2023-03-20',
      y: 160,
      xref: 'x',
      yref: 'y',
      text: 'Tokluk Kan Şekerleri',
      showarrow: true,
      arrowhead: 7,
      ax: 0,
      ay: -40   
    },
    {
      x: '2023-03-17',
      y: 130,
      xref: 'x',
      yref: 'y',
      text: 'Açlık Kan Şekerleri',
      showarrow: true,
      arrowhead: 7,
      ax: 0,
      ay: -40   
    }
  ],
        title: {
         // text: `Ölçümler`,
          /* xanchor: "left",
          pad: 4, */
          font: {
            // family: 'Courier New, monospace',
            size: 24,
          },
          xref: "paper",
          x: 0.00,
          y: .97
        },
        xaxis: {
          type: "date",
          autorange: false,
          range: ["2022-04-04", "2022-04-04"],
        },

        shapes: [
          {
            type: "rect",
            x0: "2032-04-04",
            y0: 80,
            x1: "2017-04-04",
            y1: 130,
            fillcolor: "rgba(50, 171, 96, 0.6)",
            opacity: 0.2,
           
          },
           {
            type: "rect",
            x0: "2032-04-04",
            y0: 70,
            x1: "2017-04-04",
            y1: 160,
            fillcolor: "rgba(90, 171, 96, 0.6)",
            opacity: 0.2,
           
          },
          
        ],
        margin: {
          l: 50,
          r: 80,
          t: 5,
          pad: 4,
        },
      },
      modeBarButtonsToRemove: [
        "autoScale2d",
        "resetScale2d",
        "hoverClosestCartesian",
        "zoomIn2d",
        "zoomOut2d",
        "toggleSpikelines",
        "sendDataToCloud",
        "resetViewMapbox",
        "resetViews",
        "select2d",
        "lasso2d",
        "pan2d",
        "zoom2d",
        "toImage",
        "hoverCompareCartesian"
      ],
    };
  },
  computed: {
    ...mapState(["readings","patientData","readings24hour"]),
    filteredData() {
      var startDate = this.startDate;
      var endDate = this.endDate;
      var mealStatus = this.mealStatus;
      return _.filter(this.readings, function(data) {
        if (_.isNull(startDate) && _.isNull(endDate) && _.isNull(mealStatus)) {
          return true;
        } else if (_.isNull(mealStatus)) {
          var date = data.date;
          
          return date >= startDate && date <= endDate;
         
        
        }
        else  {
          /* var meal = data.mealStatus */
         /*  console.log("here", data.mealStatus == mealStatus) */
          return data.mealStatus == mealStatus;
        }
      });
    },
    morningHungry(){
      var time = "morning";
      var meal = "before";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
        /*   console.log("log",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    morningFull(){
      var time = "morning";
      var meal = "after";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
          /* console.log("full morning",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    noonHungry(){
      var time = "noon";
      var meal = "before";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
        /*   console.log("noon hungry",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    noonFull(){
      var time = "noon";
      var meal = "after";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
          /* console.log("full morning",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    eveningHungry(){
      var time = "evening";
      var meal = "before";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
         /*  console.log("log",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    eveningFull(){
      var time = "evening";
      var meal = "after";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
         /*  console.log("full morning",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    nighthHungry(){
      var time = "night";
      var meal = "before";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
         /*  console.log("log",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    },
    nighthFull(){
      var time = "night";
      var meal = "after";
      return _.filter(this.readings24hour.data, function(data) {
        if (_.isNull(time) && _.isNull(meal) ) {
          return true;
        } 
        else  {
       /*    console.log("full morning",data.mealStatus,data.dayTime) */
          return meal == data.mealStatus && time == data.dayTime  ;
        }
      });
    }
    
  },
  methods: {
    ...mapActions(["getGlycosisDataById","getPatientDataById","get24hourGlycosisDataForDoctor"]),
    scrollToTop(){
                 window.scrollTo(0,0)
            },
    prep() {
      this.filteredData.forEach((reading) => {
        this.data[0].y.push(reading.value);
        this.data[0].x.push(reading.date);
        //console.log(reading.date)
      });
    },
    setToday() {
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
       console.log(this.endDate,this.startDate)
      this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();
      /* this.prep(); */
    },
    setMealStatus() {
      /* this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
       */
      this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();
    },
    showAllData() {
      this.startDate = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1]).subtract(1, "days").format("YYYY-MM-DD");
      
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
       console.log(this.startDate,this.endDate)
         this.refresh();
         this.layout.xaxis.range[0] = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1]).subtract(1, "days").format("YYYY-MM-DD");
         this.layout.xaxis.range[1] = moment()
        .add(2, "days")
        .format("YYYY-MM-DD"); 
        this.setMean();
        this.setMinimum();
        this.setMaximum();
    },
    setThisWeek() {
      this.startDate = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
      // console.log(this.endDate)
      this.refresh();
      this.layout.xaxis.range[0] = moment().subtract(7, "days").format("YYYY-MM-DD")
      /* this.layout.xaxis.range[1] = moment().add(1, "days").format("YYYY-MM-DD") */
      this.setMean();
      this.setMinimum();
      this.setMaximum();
    },
    clearDates() {
      this.startDate = null;
      this.endDate = null;
      // console.log(this.startDate, this.endDate)
      this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();
    },
    clearMealStatus() {
      this.mealStatus=null,
      // console.log(this.startDate, this.endDate)
      this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();
      
    },
    navigateToReadingDetail() {
      this.$router.push({
        path: `/user/patient/${this.$route.params.id}/read-details`,
      });
    },
     refresh() {
      this.data[0].y = [];
      this.data[0].x = [];
   //this.layout.xaxis.range[0] = moment(this.filteredData[this.filteredData.length-1].date).subtract(1, "days").format("YYYY-MM-DD")
   console.log("filteredData", Object.entries(this.filteredData[0])[3][1]);
     this.layout.xaxis.range[0] = moment().subtract(15, "days").format("YYYY-MM-DD")
     this.layout.xaxis.range[1] = moment(Object.entries(this.filteredData[0])[3][1])
        .add(1, "days")
        .format("YYYY-MM-DD");
      this.prep();
      console.log("refresh finished")
    },
    setMean(){
     this.mean = _.mean(this.filteredData.map(x=>x.value)).toFixed(2)
     
     /* console.log(this.mean) */
    },
    setMinimum(){
        let array = this.filteredData.map(x=>x.value)
     this.minimum = Math.min(...array)
    /*  console.log("min",this.filteredData.map(x=>x.value),this.minimum) */
     
    },
    setMaximum(){
        let array = this.filteredData.map(x=>x.value)
     this.maximum = Math.max(...array)
     
    /*  console.log("max",this.filteredData.map(x=>x.value),this.maximum) */
    },
    setDeviation(){
        const array = this.filteredData.map(x=>x.value)
        const n = array.length;
        const mean = array.reduce((a, b) => a + b) / n
  this.std = Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n).toFixed(2)
  /* console.log("std",this.filteredData.map(x=>x.value),this.std) */

    },
     setTIR(){
        const array = this.filteredData.map(x=>x.value)
        const n = array.length;
        const numberOfReadingsInRange = array.filter(x=>x>=80&&x<=130).length
  this.tir = (numberOfReadingsInRange / n * 100).toFixed(2)
  /* console.log("tir",this.tir,array,numberOfReadingsInRange) */

     this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();

    },
    setAfterTIR(){
        const array = this.filteredData.map(x=>x.value)
        const n = array.length;
        const numberOfReadingsInRange = array.filter(x=>x>=70&&x<=160).length
  this.tir = (numberOfReadingsInRange / n * 100).toFixed(2)
  /* console.log("tir-tokluk",this.tir,array,numberOfReadingsInRange) */

     this.refresh();
      this.setMean();
      this.setMinimum();
      this.setMaximum();

    },
    isLoad(){
      if(this.readings.length) {
        this.isLoading = false
        console.log("load finished", this.isLoading)
      }
    },
     handleMeal(){
      console.log(this.mealStatus)
    },
    setRange(){
      const firstReading = this.filteredData[0].date
      const lastReading = this.filteredData[this.filteredData.length-1].date
      let range = moment(firstReading).diff(moment(lastReading),"days")
      this.range = range 
      /* console.log("engage",firstReading,lastReading,this.filteredData[this.filteredData.length-1]); */
      let daysWithNoReading = moment(Date.now()).diff(moment(firstReading),"days")
      this.daysWithNoReading = daysWithNoReading 
    },
    async a() {
      await this.getGlycosisDataById();
      await this.getPatientDataById();
      await this.get24hourGlycosisDataForDoctor();
      await this.refresh();
      await this.isLoad();
      /* console.log("mean",_.mean(this.filteredData.map(x=>x.value))) */
      /* console.log("t",this.filteredData.map(x=>x.value)) */
      await this.setMean();
      await this.setMaximum();
      await this.setMinimum();
      await this.setRange();
      await this.showTrends();
      await this.setDeviation();
      await this.setTIR();
      await this.setAfterTIR();
      
    },
    showTrends(){
      let groups = _.groupBy(this.filteredData, function (reading) {
  return moment(reading.date).startOf('day').format();
  
});
console.log("trends",groups)



let result = _.map(groups, function(group, day){
    return {
        day: day,
        times: group
    }
});
console.log("result", result.length)
this.numberOfDaysWithReading = result.length
this.numberOfDaysWithoutReading = this.range-result.length
},
    testFunc(){
      console.log(this.layout.xaxis.range[1] = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1])
)
    }
  },
  mounted() {
    this.a();
  },
  created(){
   // this.showTrends();
  },
};
</script>

<style scoped>
/* .b {
  flex-basis: 50%;
  min-width: 90%;
}
 */
.a {
  flex-basis: 50%;
}

.readings {
    display: hidden;
}

.x {
  width: 15px;
  height: 25px;
  border: black;
  border-top-style: solid;
  border-left-style: solid;
  /* border-top-left-radius: 20%; */
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  /* border-left-color: grey ;
  border-top-color: grey ; */
  /* border-top: 1px ; */
  
}

.y{
  position: relative;
  bottom: -20px;
  right: -20px;
  /* height: 15px; */
}

.is-right-aligned {
  text-align: right;
}

.days-without-reading{
  margin: 0 auto ;
}

.readings {
  margin-bottom: 60px;
}

@media screen and (max-width: 374px) {
  .a {
    flex-basis: 40%;
  }
}


@media screen and (min-width: 768px) {
  .stat {
    margin-right: 1vw;
  }
}


.sticky-button {
  position: -webkit-sticky;
  position: sticky;
  bottom: 5vw;
  left: 85vw;
  height: 5rem;
  width: 5rem;
  border: solid antiquewhite 2px;
  border-radius: 50px;
  background-color: antiquewhite;
}


@media screen and (max-width: 415px) {
  .sticky-button {
    bottom: 18vw;
    left: 75vw;
  }
}
</style>
