<template>
    <div class="main  ">
        <div class="is-flex is-flex-direction-column mt- mr-4">
            <h1 v-if="this.patientName" class="has-text-weight-bol has-text-left ml-0 is-size-5 mt-3" >{{this.patientName}} için ilaç bilgileri</h1>
            <hr>
            
            <div class="is-flex is-flex-wrap-wrap">
                <b-dropdown
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="300"
                    trap-focus
                    
                    v-model="selection"
                    
                >
                    <template #trigger>
                        <a
                            class="navbar-item pt-0 mr-2 pl-1"
                            role="button">
                            <span class="has-text-weight-bold">İlaç seç</span>
                            <!-- <b-icon icon="menu-down"></b-icon> -->
                        </a>
                    </template>
  <b-dropdown-item custom aria-role="listitem">
                      <b-input v-model="searchTerm" placeholder="Arama terimi" expanded />
                  </b-dropdown-item>
                    <b-dropdown-item
                v-for="(menu, index) in filteredData"
                :key="index"
                :value="menu" aria-role="listitem">
                <div class="media">
                    <!-- <b-icon class="media-left" :icon="menu.icon"></b-icon> -->
                    <div class="media-content">
                        
                        <h3>{{menu.commercialName}}</h3>
                    </div>
                </div>
            </b-dropdown-item>

            
                </b-dropdown>
                <b-field class="mr-4 has-text-left" label="Zaman" position="left" >
            <b-select v-model="dayTime" placeholder="Zaman seçiniz">
                <option value="Sabah">Sabah</option>
                <option value="Öğle">Öğle</option>
                <option value="Akşam">Akşam</option>
            </b-select>
        </b-field>
                
                <b-field class="mr-4 has-text-left" label="Açlık durumu" position="left" >
            <b-select v-model="isHungry" placeholder="İsim seçiniz">
                <option value="Aç">Aç</option>
                <option value="Tok">Tok</option>
            </b-select>
        </b-field>
          
        <b-field class=" has-text-left" label="Doz" position="left" >
            <b-select v-model="drugDose" placeholder="Doz seçiniz">
                <option value="Yarım">Yarım</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option> 
            </b-select>
        </b-field>
            </div>
            <!-- <hr> -->
           <!--  <p class="mb-6 has-text-left">Aralık değerleri</p>
            <b-slider  tooltip-always size="is-large" tooltip-type="is-info " v-model="numbers" :min="50" :max="700" class="is-info mr-6" lazy></b-slider>
            <b-field  label="Alt sınır" class="has-text-left"  >
      <b-numberinput type="is-info is-light" v-model="numbers[0]"></b-numberinput>
    </b-field>
            <b-field label="Üst sınır" class="has-text-left"  >
      <b-numberinput type="is-info is-light" v-model="numbers[1]"></b-numberinput>
    </b-field>
            <p class="has-text-left mr-2 mb-3">Doz değeri</p>
            <b-input v-model='dose' required  min="1"  type="number"></b-input> -->
        </div>
        <div v-if="this.selection || this.dayTime || this.isHungry || this.drugDose" class="is-flex mt-4 mb-2 box is-justify-content-space-between is-flex-wrap-wrap">
            <div class="is-flex is-justify-content-flex-start">
              <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">İlaç</p>
                <b-tag  class="is-success is-light" size="is-medium">{{selection.commercialName}}</b-tag>
                
            </div> 
            <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Zaman</p>
                <b-tag  class="is-light" size="is-medium">{{dayTime}}</b-tag>
            </div>
             <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">Açlık durumu</p>
                <b-tag  class=" is-light" size="is-medium">{{isHungry}}</b-tag>
                
            </div>
          
            <div class="is-flex mr-2 is-justify-content-flex-start is-flex-direction-column ">
                <p class="has-text-left">İlaç dozu</p>
                <b-tag  class="is-success is-light" size="is-medium">{{drugDose}}</b-tag>
                
            </div>
            
            </div>
            <div class="ml-2 is-flex is-justify-content-center is-align-items-center">
                <b-button @click="pushDrugInfo" class="is-info">Ekle</b-button>
            </div>
        </div>
        <hr v-if="drugs.length && showPreview">
        <div v-if="drugs.length"  class="preview box">
            <p class="has-text-left has-textweight-bold" >Önizle</p>
            <hr class="mt-1">
        <div class="mt-2 is-flex is-align-items-baseline "
                  v-for="drug in this.drugs"
                  v-bind:key="drug._id"
                  >
                  
                    <b-tag class="is-info mx-1  is-light is-medium">{{drug.drugName.commercialName}}</b-tag> 
                  <b-tag class="is-info is-light is-medium">{{drug.dayTime}}</b-tag> 
                  <b-tag class="is-info mx-1  is-light is-medium">{{drug.drugDose}}</b-tag> 
                  
                 <b-tag  class="is-info mx-1 is-light is-medium">{{drug.mealStatus}}</b-tag> 
                 <b-button class="is-small" @click="deleteDrugPreviewByIndex(drug)" >Listeden çıkar</b-button>
                  
                  
                 
                  
                  </div>
                  <b-button @click="confirmDrugs()" v-if="this.drugs.length" class="is-info mt-2">Onayla</b-button>
                  <!-- <b-button v-if="this.ranges.length>1" class="is-info ml-2" outlined @click="$router.go(-1)">Geri dön</b-button> -->

        </div>
        <hr>
        <!-- <p v-if="patientDrugs" >{{patientDrugs[0].drugs}}</p> -->
        
        <!-- <div class="mt-2 is-flex is-align-items-baseline "
                  v-for="drug in this.patientDrugs[0].drugs"
                  v-bind:key="drug._id">
                  <b-tag class="is-info mx-1  is-light is-medium">{{drug.drugName}}</b-tag> 
                  <b-tag class="is-info is-light is-medium">{{drug.dayTime}}</b-tag> 
                  <b-tag class="is-info mx-1  is-light is-medium">{{drug.drugDose}}</b-tag> 
                 <b-tag class="is-info mx-1 is-light is-medium">{{drug.mealStatus}}</b-tag> 
                  </div> -->
                   <div  v-if="patientDrugs.length && $route.meta.showToDoctor" >
                    <h3 class="has-text-weight-bol is-size-5 has-text-left">Mevcut ilaçlar</h3>  
                    <hr class="mt-1">  
                    <div class="mt-2 is-flex is-align-items-baselin "
                  v-for="drug in this.patientDrugs"
                  v-bind:key="drug._id">
                  <div class="is-flex is-flex-direction-column">
                    <p class="has-text-left">Eklendiği tarih - {{drug.createdAt | moment('Do MMMM YYYY, h:mm a')}}</p>
                  <b-table class="mr-6 my-4 has-text-left"  :data="drug.drugs" :columns="columns"></b-table>
                  </div>
                  </div> 
                  
                   </div>
     
    
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import _ from "lodash";
export default {
    name: 'SetMedicine',
    data() {
    return {
        patientId: this.$route.params.id,
        /* doctorId: this.$route.params.id, */
        numbers: [100,200],
        value:20,
        rangeCount:3,
        drugDose:null,
        isHungry:null,
        dayTime:null,
        addedBy:null,
        showPreview:false,
        selection:null,
        searchTerm:'',
        drugInfo:{
            dayTime:null,
            mealStatus:null,
            drugDose:null,
            drugName:null
        }, 
        ranges:[],
        drugs:[],
        drugsList: [
            {
      "commercialName": "Minidiab 5 mg tb ",
      "genericName": "Glipizid ",
      "dailyDose": "2.5-40 mg ",
      "dailyTime": "Günde 2 kez kahvaltıda ve akşam yemeğinde "
    },
    {
      "commercialName": "Glucotrol XL 2.5 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Glucotrol XL 5 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Glucotrol XL 10 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Diamicron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Betanorm 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Oramikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glumikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Dismicron MR 30 mg tb ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Dismicron MR 60 mg tb",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Betanorm MR",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Efikas MR ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Hipoglis 30 mg tb ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Gliben",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Dianorm 5 mg",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diyaben 3 mg tb ",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diyaben 5 mg tb ",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Amory",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diaglin",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diameprid",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glimax",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glirid",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 1 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 2 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 3 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 4 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glutril 25 mg tb  ",
      "genericName": "Glibornurid ",
      "dailyDose": "12.5-75 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glurenorm 30 mg tb ",
      "genericName": "Glikuidon  ",
      "dailyDose": "15-120 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Diafree",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navanorm",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 0.5 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 1 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 2 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Dialix",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "teglix 120 mg",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "İncuria ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Starlix 120 mg  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Starlix 180 mg  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Naglid 60 mg tb  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Naglid 120 mg tb  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Glucophage",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 500 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 1000 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Diaformin",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glange",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Gluforce",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Matofin 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Matofin 1000 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Gluformin retard ",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glukofen retard 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glukofen 1000 mg ",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Diaformin XR",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Glifor SR ",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Matofin XR 500 mg tb ",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Actos 15 mg",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Actos 30 mg",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 15 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 30 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 45 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dropia",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Glifix",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Pioforce",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piogtan",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 15 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 30 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 45 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Acanis",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Acnor",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Arokan ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glucar",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glucobay",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glynose 50 mg tb ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glynose 100 mg tb ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glyset 100 mg tb ",
      "genericName": "Miglitol ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 1-3 kez yemeğin başlangıcında"
    },
    {
      "commercialName": "Glyset 50 mg tb ",
      "genericName": "Miglitol ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 1-3 kez yemeğin başlangıcında"
    }
    
    ],
        columns:[
                    
                    {
                        field: 'drugName.commercialName',
                        label: 'İlaç adı',
                        centered: true
                    },
                    {
                        field: 'dayTime',
                        label: 'Zaman',
                        centered: true
                    },
                    {
                        field: 'mealStatus',
                        label: 'Açlık durumu',
                        centered: true
                    },
                    {
                        field: 'drugDose',
                        label: 'Doz/adet',
                        centered: true
                    },
                    
                    {
                        field: 'addedBy',
                        label: 'Ekleyen',
                        centered: true
                    },
                    
                ] 
      
    };
  },
  computed:{
            ...mapState(['doseInfo', 'doctorName','patientName','patientDrugs']),
            
              filteredData() {
                return this.drugsList.filter((item) => item.commercialName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
            },
            hungryValues() {
      var hungry = "Aç";
      
      return _.filter(this.doseInfo[0].dose, function(data) {
        if (_.isNull(hungry)) {
          return true;
        } else {
          
          return data.isHungry == hungry ;
        }
      });
    },
    afterMealValues() {
      var hungry = "Tok";
      
      return _.filter(this.doseInfo[0].dose, function(data) {
        if (_.isNull(hungry)) {
          return true;
        } else {
          
          return data.isHungry == hungry ;
        }
      });
    },
        },
        
  methods:{
      ...mapActions(['getDoseInfoById','getDrugsById']),
      
      pushValues(){
            
          if(this.dayTime | this.isHungry == null ){
          alert("Zaman ve açlık durumu seçimi boş olmamalı!")
          } else if (this.ranges.length == 0 ){
                    this.ranges.push({
                dayTime:this.dayTime,
                    isHungry:this.isHungry,
              lower:this.numbers[0],
              upper:this.numbers[1],
              value:this.dose,
              addedBy:this.doctorName,
              addedRole:"DOCTOR"
          }) 
          this.showPreview = true;
           } else {
              if(this.ranges[this.ranges.length-1].upper >= this.numbers[0]){
                 console.log(this.ranges[this.ranges.length-1].upper, this.numbers[0])
                 this.showRangeError();
              } else if (this.ranges.length == 1 && this.dose <= this.ranges[0].value  ){
               console.log(this.ranges,this.ranges[0].value)
               this.showDoseInfoError();
              }
              else if (this.ranges.length > 1 && this.dose <= this.ranges[this.ranges.length-1].value){
               console.log(this.ranges,this.dose,this.ranges[this.ranges.length-1].value)
               
              }
              
              else if (this.ranges.length > 1 && this.numbers[0] - this.ranges[this.ranges.length-1].upper > 1 ){
              this.showRangeGapError();
              console.log("hayır",this.numbers[0],this.ranges[this.ranges.length-1].upper)
               // this.showRangeError();
              }
                else {
                  this.ranges.push({
                    dayTime:this.dayTime,
                    isHungry:this.isHungry,
              lower:this.numbers[0],
              upper:this.numbers[1],
              value:this.dose,
              addedBy:this.doctorName
          }) 
       
              }
            
          }
          
          
      },
      deleteDrugPreviewByIndex(index){
        console.log(this.drugs.indexOf(index))
        this.drugs.splice(this.drugs.indexOf(index),1)

      },
      pushDrugInfo(){
        this.drugInfo = {
            dayTime:this.dayTime,
            mealStatus:this.isHungry,
            drugDose:this.drugDose,
            drugName:this.selection,
            addedBy:this.doctorName
        }
        this.drugs.push(this.drugInfo)
        console.log(this.drugs)

      },
      confirmDrugs(){
          let drugs = this.drugs;
          let patientId = this.patientId;
            this.$store.dispatch("addDrugInfo", {patientId,drugs}); 
            console.log("confirm")
            this.showPreview = false;
           

      },
      showDoseInfoError(){
         this.$buefy.dialog.alert({
                    title: 'İnsulin dozu',
                    type:'is-danger',
                    message: 'Aralıkların insulin dozu değeri aynı olmamalı.',
                    confirmText: 'Tamam'
                     
                })
      },
      showRangeError(){
         this.$buefy.dialog.alert({
                    title: 'Aralık hatası',
                    type:'is-danger',
                    message: 'Aralıkların alt ve üst sınır değerleri birbirleri ile aynı olmamalı.',
                    confirmText: 'Tamam'
                     
                })
  },
  toastInsulinDosesAddedConfirmation() {
      this.$buefy.toast.open({
        message: "İnsulin dozu değerleri başarıyla eklendi",
        type: "is-success",
        duration: 3000,
      });
    },
    showRangeGapError() {
      this.$buefy.dialog.alert({
        title: 'Aralık hatası',
        message: "Aralık değerleri arasında boşluk olmamalı.",
        type: "is-danger",
        duration: 3000,
        confirmText: 'Tamam'
      });
    },
    async loadFunc(){
      /* await this.getDoseInfoById(); */
      await this.getDrugsById();
    }},
   created(){
      this.loadFunc();
  }
  }
</script>

<style scoped >
.main{
    /* margin-top:10vh; */
    margin-right:3%;
    margin-left:3%;
    margin-bottom:5% ;
}

/* @media screen and (max-width: 820px) {
  .main {
    
    margin-top: 10%;
  }
} */
</style>