<template class="marg">
  <div class="mt-6 mx-6-desktop">
    <section class="test ml-0">
      <b-steps
        class="s pick-dokto"
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
        type="is-info"
      >
        <b-step-item step="1" class=" " label="Doktor" :clickable="isStepsClickable">
         <div class="is-flex is-flex-direction-column top-filter ">
           <h1 class="title has-text-left ml-2">Doktorunuzu seçin</h1>
          <div class="is-flex is-flex-direction-column is-align-items-baseline mb-2 ml-2">
            <p  class="mr-2">İsme göre filtrele</p>
            <b-input v-model="searchTerm"></b-input>
            
            
          </div>
         </div>
          <section
            class=" mx-6-desktop mx-2 tarih  is-flex is-align-items-center has-background-light "
          >
            <div
              class="mx-2 doctors is-flex is-align-items-center"
            >
            
            <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
              <div
                @click="chooseDoctor(idx)"
                :class="{ 'is-selected': idx == activeIndex }"
                class="doctor px-4 my-3 "
                v-for="(doctor, idx) in filteredDoctorList"
                v-bind:key="doctor.id"
                :selectable="doctor.doctorAvailable"
                

              >
                
                  <img class="doctor-image" v-if="!doctor.photoId && doctor.gender == 'FEMALE'" src="@/assets/female-doctor-avatar.png" alt="Placeholder image">
       <img class="doctor-image" v-else-if="!doctor.photoId && doctor.gender == 'MALE'" src="@/assets/male-doctor-avatar.png" alt="Placeholder image">
       <img
                  class="mt-2 doctor-image is-rounded"
                  :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${doctor.photoId}.jpg`"  
                  alt="Doctor image"
                  v-else
                />
                <div class="ml-2 is-flex is-justify-content-center is-align-items-baseline">
                  <p class="has-text-left mt-2 ">
                   {{ doctor.name }} {{ doctor.surname }}
                </p>
                <div>
                  <b-tag type="is-small" class="is-warning  ml-2">{{doctor.doctorMeetingDuration}} dk</b-tag>
                <b-tag type="is-small" v-if="doctor.title" class="is-warning  ml-2">{{doctor.title}}</b-tag>
                </div>
                </div>
                <!-- <p class="mt-2">{{ doctor.Field }}</p> -->
                <hr class="mb-" />
                <p style="text-decoration: underline;" class="mb-2 is-size-7 text-pointer">Seçmek için tıklayın</p>
              </div>
            </div>
          </section>
        </b-step-item>

        <b-step-item
          class=" mx-6-desktop"
          step="2"
          label="Tarih-Saat"
          :clickable="isStepsClickable"
          
        >
          <h1 class="title has-text-left mx-5 mt-4">Tarih-Saat</h1>

          <div class="is-flex preview-containe is-justify-content-center">
            <section class="mx-4 pr-2 is-flex is-flex-direction- preview-container ">
            <div
              class="r is-flex column-mobile is-align-items-flex-start  mb-3 "
            >
              <!-- <div class="is-flex mb-2">
                
                <b-field class="is-flex ml-3 mb-3" label="Gün seçiniz">
                  
                  <input
                    
                    
                    @click="clickFired"
                    class="ml-3"
                    value="dd-yyyy-MM"
                    type="date"
                    @change="meetingDayIssues"
                    v-model="meetingDay"
                    id="tarih"
                    min="2000-01-01"
                    onfocus="this.min=new Date().toISOString().split('T')[0]"
                  />
        
                </b-field>
        
              </div> -->
        <div class="is-flex pick-date is-flex-direction-column">
                <b-field class="has-text-left" label="Gün seçiniz">
            <b-datepicker
                v-model="testDate"
                :unselectable-days-of-week="this.doctorAppointmentDays"
                inline
                placeholder="Seçmek için tıklayınız"
                icon="calendar-today"
                type="is-info"
                >
                <b-button class="date-button is-info is-outlined" @click="meetingDayIssues">Günü seç</b-button>
            </b-datepicker>
            
        </b-field>
        <hr>
        <div class="is-flex mt-2">
          <p >Seçilen gün:</p> 
          <b-tag class="ml-2 is-medium ">{{testDate | moment("LL")}}</b-tag>
        </div>
        <div class="is-flex">
              <b-button class="" @click="setNoteVisible" v-if="!isNoteVisible" type="is-text">Görüşmeye özel not ekle</b-button>
              <!-- <b-button class="is-text ml-2" @click="isNoteVisible =!isNoteVisible">Gizle</b-button> -->
           </div>

            <div
            v-if="isNoteVisible"
              class="note-part mt-2 is-flex is-flex-direction-column is-align-items-flex-start "
            >
              <label for="patient-note" class="has-text-left">Notunuz</label>
              <b-input
                id="patient-note"
                v-model="patientNote"
                class="mb-2"
              ></b-input>
              <b-button
                class=" mt-2"
                label="Görüşmeye özel not ekleyin"
                type="is-info"
                icon-left="plus-square"
                outlined
                @click="addPatientMeetingNoteToVisit"
              ></b-button>
            </div>
            <hr>
        </div>
       <div class="mobile-preview ml-6 is-flex is-flex-direction-column">
         <div
                v-if="this.visit.doctor"
                class="mt-2 is-flex is-flex-wrap-wrap"
              >
                
                <div  v-if="meetingDay">
                  <div class="is-flex is-flex-direction-column">
                  <p class="mr-2 ml-3 mb-3">Saat seçiniz</p>
                    <div
                  class="is-flex is-flex-wrap-wrap"
                  
                  v-for="meeting in filteredData"
                  :key="meeting.id"
                >
                  <b-tag class="is-danger mr-1 " size="is-medium"
                    >{{ meeting.startsAt | moment("HH:mm") }}
                  </b-tag>
                </div>
                </div>
                
                </div>
                <div
                  v-for="(tag, idx) in filteredTags"
                  :key="tag.id"
                  
                  @click="tagValue(idx)"
                  class="mr-1 is-flex is-flex-wrap-wrap mb-1 wrap"
                  size="is-large"
                >
                  <b-tag :class="{ 'is-warning': idx == activeIndexHour }" size="is-medium">{{ tag | moment("HH:mm") }}</b-tag>
                </div>
                <!-- <b-button @click="clearStartTime" class="is-text is-small">Saat seçimini temizle</b-button> -->
              </div>
        <section v-if="visit.doctor" class=" mt-4 preview ml-2">
          <p class="mb-3 has-text-left">Önizleme</p>
            <div  class="box mr-4 is-flex is-flex-direction-column is-align-items-flex-start">
              <h2 class="is-size-4 has-text-weight-bold has-text-left">Randevu bilgileri</h2> 
              <hr class="mt-0">
            <b-tag class="mb-1 is-medium" v-if="visit.doctor">{{visit.doctor.name}} {{visit.doctor.surname}}</b-tag>  
            <b-tag class="mb-1 is-medium" v-if="visit.startsAt">{{visit.startsAt| moment("LL HH:mm")}} </b-tag>
            <b-tag class="mb-1 is-medium" closable @close="deleteNote" v-if="visit.patientMeetingNote">{{visit.patientMeetingNote}} </b-tag>  
              </div> </section>
       
        </div> 
              
              
            </div>
           
           
        
            
            
          </section>
  
          </div>
          
          
        </b-step-item>
        

        <b-step-item
          step="3"
          :visible="showSocial"
          label="Social"
          :clickable="isStepsClickable"
        >
          <h1 class="title has-text-centered">Social</h1>
          Lorem ipsum dolor sit amet.
        </b-step-item>

        <b-step-item
          :step="showSocial ? '4' : '3'"
          label="Ödeme"
          :clickable="isStepsClickable"
          disabled
        >
          <h1 class="title has-text-centered">Ödeme</h1>
          <section class="mx-6">
        <b-field label="Ad soyad" class="has-text-left">
            <b-input placeholder="Ad soyad" value="John Doe" ></b-input>
        </b-field>
         <b-field label="Kart numarası" class="has-text-left">
            <b-input placeholder="Kart numarası"
            value="5528790000000008"
                type="number"
               >
            </b-input>
        </b-field>
         <b-field label="Yıl" class="has-text-left">
            <b-input placeholder="Yıl"
                type="number"
                value="2030"
                min="10"
                max="20">
            </b-input>
        </b-field>
         <b-field label="Ay" class="has-text-left">
            <b-input placeholder="Ay"
                type="number"
                value="12"
                min="10"
                max="20">
            </b-input>
        </b-field>
         <b-field label="CVC" class="has-text-left">
            <b-input placeholder="CVC"
            value="123"
                type="number"
                min="10"
                max="20">
            </b-input>
        </b-field>
        <div class="is-flex is-align-items-baseline">
          <p>Fiyat</p>
          <b-tag class="has-text-left ml-2 is-medium">15 TL</b-tag>
        </div>
        
         <!-- <b-field label="Fiyat" class="has-text-left">
            <b-input placeholder="Fiyat"
            value="15"
                type="number"
                min="10"
                max="20">
            </b-input>
        </b-field> -->
        
        </section>
        <div
            v-if="visit.doctor && visit.startsAt"
              class="appoint-buttons mt-2 is-flex is-flex-direction-column is-align-items-left ml-6-desktop"
            >
              <b-button
                label="Randevu Alma İşlemini Tamamla"
                type="is-info"
                icon-left="plus-square"
                class="mt-3 mr-1 ml-2 pr-2 appoint-button"
                @click="setVisit"
              ></b-button>
              <div class="is-flex my-2">
              <b-button
                label="Anasayfaya dön"
                type="is-text"
                icon-left="plus-square"
                class="mt-3 mr-1 ml-0"
                @click="navigateToProfile"
                outlined
              ></b-button>

              <b-button
                label="Görüşmelere git"
                type="is-text"
                icon-left="plus-square"
                class="mt-3 ml-0"
                @click="navigateToPatientMeetings"
                outlined
              ></b-button>
            </div>
            </div>
        </b-step-item>

        <template  v-if="customNavigation" #navigation="{previous, next}">
          <b-button
            outlined
            type="is-danger"
            icon-pack="fas"
            icon-left="backward"
            value="bf"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
          <span>Önceki</span>
          </b-button>
          <b-button
            outlined
            type="is-success"
            icon-pack="fas"
            icon-right="forward"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
          </b-button>
        
        </template>
      </b-steps>
      
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import filter from "lodash/filter";
import isNull from "lodash/isNull";

/* import axios from "axios"; */
export default {
  name: "Appointment",
  components: {
    
    
  },
  data() {
    return {
      visit: {
         cardHolderName: "John Doe",
            cardNumber: "5528790000000008",
            expireMonth: "12",
            expireYear: "2030",
            cvc: "123",
            registerCard: "0",
            price:"15",
        patientMeetingNote: "",
        doctor: null,
        patient: null,
        patientId: this.$route.params.id,
        doctorId: "",
        startsAt: "",
        lastsFor: "",
        meetingRoom: "",
      },
      testDate:new Date(),
      
      isNoteVisible:false,
      isFullPage:false,
      isLoading:true,
      doctorMeetings: [],
      doctorMeetingsToday: [],
      tags: [],
      meetingDay: null,
      meetingDayEnd: null,
      startTimes: [`${this.meetingDay}`],
      activeIndex: null,
      activeIndexHour: null,
      dateTime: null,
      patientNote: "",
      showModal: false,
      activeStep: 0,
      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: "minimalist",
      showWeekNumber: false,
      enableSeconds: false,
      hourFormat: undefined, // Browser locale
      locale: undefined, // Browser locale,
      timepicker: {
        incrementMinutes: 15,
        incrementHours: 2,
      },
      id: this.$route.params.id,
      searchTerm:"",
      readyToFinish:false,
      doctorAppointmentDays:[]
    };
  },

  methods: {
    ...mapActions(["fetchDoctors", "getMeetingsByDoctorId"]),
    testDateSelected(){
      console.log("testdate",this.testDate)
    },
    setNoteVisible(){
      this.isNoteVisible = true
    },
    /* clearStartTime(){
      this.activeIndexHour=null
    }, */
    addDatetimeToVisit() {
      this.visit.startsAt = this.dateTime;
      this.toastDateTime();
      console.log("visit", this.visit);
    },
    addPatientMeetingNoteToVisit() {
      this.visit.patientMeetingNote = this.patientNote;
      this.isNoteVisible = false
      this.patientNote = '';
      this.showMeetingNoteInfo();
    },
    deleteNote(){
      this.visit.patientMeetingNote = ''
      this.deleteMeetingNoteInfo();
    },
    toastDoctor() {
      this.$buefy.toast.open({
        message: `Doktor başarıyla seçildi, sayfanın en altına giderek bir sonraki adıma geçebilirsiniz. 🔽`,
        type: "is-success",
        duration: 5000,
      });
    },
    toastAppoinmentConfirmation() {
      this.$buefy.toast.open({
        message: "Randevu başarı ile alındı!",
        type: "is-success",
        duration: 3000,
      });
    },
    toastDateTime() {
      this.$buefy.toast.open({
        message: "Randevu tarihi başarı ile seçildi!",
        type: "is-success",
        duration: 3000,
      });
    },
    setTodaysValue() {
      document.getElementById("tarih").defaultValue = moment().format(
        "YYYY-MM-DD"
      );
    },
    clickFired(){
      console.log("click",moment(Date.now()).day())
      
    },
    meetingDayIssues() {
      this.tags = [];
      this.meetingDay = moment(this.testDate).format("YYYY-MM-DD");
      this.meetingDayEnd = moment(this.meetingDay)
        .add(1, "days")
        .format("YYYY-MM-DD");
      console.log("populated",this.meetingDay, this.meetingDayEnd, this.tags);
      this.tags.push(
        `${this.meetingDay}T07:00:00.000Z`,
        `${this.meetingDay}T08:00:00.000Z`,
        `${this.meetingDay}T09:00:00.000Z`,
        `${this.meetingDay}T10:00:00.000Z`,
        `${this.meetingDay}T11:00:00.000Z`,
        `${this.meetingDay}T12:00:00.000Z`,
        `${this.meetingDay}T13:00:00.000Z`,
        `${this.meetingDay}T14:00:00.000Z`
      );
      this.a();
    },
    a() {
      let b = this.doctorMeetings.filter((x) => {
        return (
          x.startsAt >= this.meetingDay && x.startsAt <= this.meetingDayEnd
        );
      });
      console.log("b", b);
      b.forEach((e) => {
        this.doctorMeetingsToday.push(e.startsAt);
      });
      console.log("c", this.doctorMeetingsToday);
    },
    tagValue(index) {
      this.activeIndexHour = index;
      console.log(this.filteredTags[index]);
      this.visit.startsAt = this.filteredTags[index];
    },
    isLoaded() {
      if (this.doctors.data) {
        this.isLoading = false;
        console.log("load", this.isLoading);
      }
    },

    chooseDoctor(index) {
      this.activeIndex = index;
      this.visit["doctor"] = this.doctors.data[this.activeIndex];
      this.visit.doctorId = this.doctors.data[this.activeIndex].id;
      this.visit.meetingRoom = this.doctors.data[this.activeIndex].meetingRoom;
      this.doctorMeetings = this.visit.doctor.meetings;
      console.log("doctor",this.visit.doctor);
      this.doctorAppointmentDays = [0,1,2,3,4,5,6].filter(element => !this.doctors.data[this.activeIndex].doctorAppointmentDays.includes(element));
  console.log("dad",this.doctorAppointmentDays)
      this.toastDoctor();
    },
    showDoctorWarning() {
      this.$buefy.toast.open({
        message: "Lütfen bir doktor seçtiğinizden emin olunuz!",
        type: "is-warning",
        duration: 3000,
      });
    },
    showMeetingStartsAtWarning() {
      this.$buefy.toast.open({
        message:
          'Lütfen bir görüşme tarihi seçtiğinizden emin olunuz, "Tarih Ekle" butonuna basmayı unutmuş olabilirsiniz',
        type: "is-warning",
        duration: 3000,
      });
    },
    showMeetingNoteInfo() {
      this.$buefy.toast.open({
        message: "Notunuz başarıyla eklendi!",
        type: "is-success",
        duration: 2000,
      });
    },
    deleteMeetingNoteInfo() {
      this.$buefy.toast.open({
        message: "Notunuz görüşme bilgilerinden kaldırıldı!",
        type: "is-warning",
        duration: 2000,
      });
    },
    async setVisit() {
      let newVisit = this.visit;

      if (!newVisit.doctorId) {
        this.showDoctorWarning();
      } else if (!newVisit.startsAt) {
        this.showMeetingStartsAtWarning();
      } else {
           this.$store.dispatch("addAppointment", {newVisit});
           this.toastAppoinmentConfirmation();
           for (const prop in this.visit) {
             delete this.visit[prop];
            }
           console.log("visit",this.visit)
          
      }
    },
    navigateToProfile() {
      this.$router.push({ path: `/user/${this.id}` });
    },
    navigateToPatientMeetings() {
      this.$router.push({ path: `/user/patient/${this.id}/patient-meetings` });
    },
    

    load(){
       this.fetchDoctors();
    setTimeout(this.isLoaded,700);
    
    }
  },
  computed: {
    ...mapState(["doctors",]),
    filteredDoctorList() {
      return this.doctors.data.filter(doctor => {
        return doctor.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    },

   /*  disable: 
        function() {
            // return true to disable
            return (moment(Date.).day() === 0 || moment(date).day() === 6);

        }, */

    filteredData: function() {
      var startDate = this.meetingDay;
      var endDate = this.meetingDayEnd;

      return filter(this.doctorMeetings, function(data) {
        if (isNull(startDate) && isNull(endDate)) {
          return true;
        } else {
          var date = data.startsAt;
          if (date >= startDate && date <= endDate) {
            return true;
          }
        }
      });
    },

    filteredTags: function() {
      console.log("k", this.tags);
      console.log("doctormeetingstoday", this.doctorMeetingsToday);
      return this.tags.filter((x) => !this.doctorMeetingsToday.includes(x));
    },
  },
  created() {
   /*  this.fetchDoctors();
    this.isLoaded(); */
    setTimeout(this.load())
    console.log("n",this.visit)
  },
};
</script>

<style scoped >
.doctor {
  flex-basis: 50%;
}

.doctors {
  overflow: auto;
}

.wrap {
  flex-basis: 16%;
}

.is-underlined {
  text-decoration: underline;
}
.s{
  margin-top: 80px;
}
.is-selected {
  background-color: rgb(82, 82, 175, 0.3);
}

.is-selected-hour {
  background-color: rgba(132, 175, 82, 0.3);
}

/* .pick-doktor {
  margin-bottom: 50px;
} */

@media screen and (max-width: 800px) {
  .button-text {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .pick-dokto {
    height: 70vh;
  }
}

.marg {
  margin-bottom: 0px;
  width: 100%;
}

.preview-container{
  width: 90%;
}


@media screen and (max-width: 769px) {
.column-mobile{
  flex-direction: column;
}
}

@media screen and (max-width: 769px) {
.mobile-preview{
  
  margin-left: 0px !important;
}
}


.doctors{

position: relative;
}


@media screen and (max-width: 1800px) {
  .doctors {
    flex-wrap:wrap;
  }
}


@media screen and (min-width: 1024px) {
  .doctor-image {
    width:60%;
    height:60%;
  }
}

@media screen and (min-width: 1023px) {
  .top-filter{
  flex-direction: row;
}
}

.date-button{
  width: 98%;
}

.appoint-button{
  width: 80%;
}

.pick-date {
  width: 48%;
}

.preview{
  width:98%;
  
}

@media screen and (max-width: 769px) {
 .preview {
  margin-left: 0px;
  margin-top: 2%;
}
}


@media screen and (min-width: 768px) {
 .test {
  margin-left:2.2rem ;
}
}

@media screen and (min-width: 768px) {
.appoint-buttons {
  margin-left:2.2rem ;
}
}


</style>
