<template>
    <div class="preference-edit px-5 mx-4 mx-6-desktop">
        <h3 class=" is-size-4 has-text-left my-2">Randevu tercihleri</h3>
        <hr>
        <div class="">
          <p class="has-text-left mb-2">Şu anda randevu durumunuz <b-tag class="is-success ml-2" v-if="singleUser.data.doctorAvailable">Aktif</b-tag> <b-tag class="is-danger ml-2" v-else>Pasif</b-tag> </p> 
       <div v-if="singleUser.data.doctorAppointmentDays.length" class="is-flex is-align-items-baseline">
        <div class="is-flex is-flex-direction-colum is-flex-wrap-wrap">
         <div v-for="day in appointmentDaysPresent" v-bind:key="day.id"
      class="is-flex"
      >
      
        <b-tag class="mr-1 mb-1">{{day}}</b-tag> 
        <!-- <p class="mr-1">{{day}}</p>  -->
     
      
      </div> 
      
      
        </div>
        <p class="has-text-left">günleri için randevu kabul ediyorsunuz. </p> 
       </div>
       <p v-else class="has-text-left">Henüz randevu için gün tercihi yapmadınız.</p>
        <p class="has-text-left mt-2">Bir görüşme <b-tag>{{singleUser.data.doctorMeetingDuration}} </b-tag> dakika sürüyor. </p>
        </div>
        <hr>
          <div class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-baseline">
           <div class="is-flex is-flex-direction-column my-2">
           
            <div class="mb-4 is-flex is-justify-content-space-between is-align-items-baseline">
            <div class="is-fle">
                <p class="has-text-left mr-2">Randevu süresi (dk) </p>
             <div class="is-flex" > 
              <b-input v-model="meetingDuration" label="Randevu süresi" type="number" ></b-input>
             <transition>
              <b-button class="ml-2" type="is-info" @click="setMeetingDuration" v-if="meetingDuration.length" >Kaydet</b-button>
             </transition>
             </div>
            </div>
             
            </div>
 <hr>
            <div class="mb-4 is-flex ">
            <div class="is-flex is-flex-direction-column">
                <p class="has-text-left mr-1 mb-2">Randevu günleri</p>
            
        <div class="is-flex">
              <section>
       
        <b-dropdown
        class="is-info"
        @change="handleDays"
            v-model="selectedOptions"
            multiple
            aria-role="list">
            <template #trigger>
                <b-button
                class="is-small"
                    type="is-info"
                    >
                    Günleri seç({{ selectedOptions.length }})
                </b-button>
            </template>


            <b-dropdown-item value="Pazartesi" aria-role="listitem">
                <span>Pazartesi</span>
            </b-dropdown-item>

            <b-dropdown-item value="Salı" aria-role="listitem">
                <span>Salı</span>
            </b-dropdown-item>

            <b-dropdown-item value="Çarşamba" aria-role="listitem">
                <span>Çarşamba</span>
            </b-dropdown-item>
            <b-dropdown-item value="Perşembe" aria-role="listitem">
                <span>Perşembe</span>
            </b-dropdown-item>
            <b-dropdown-item value="Cuma" aria-role="listitem">
                <span>Cuma</span>
            </b-dropdown-item>
            <b-dropdown-item value="Cumartesi" aria-role="listitem">
                <span>Cumartesi</span>
            </b-dropdown-item>
            <b-dropdown-item value="Pazar" aria-role="listitem">
                <span>Pazar</span>
            </b-dropdown-item>
        </b-dropdown>
        <!-- <p class="content"><b>seçilen günler</b>: {{ selectedOptions }}</p> -->
        </section>
        <b-button class="ml-6" type="is-info" @click="addAppointmentDays" v-if="selectedOptions.length"  >Kaydet</b-button>
        </div>
        <p class="has-text-left mb-2" v-if="selectedOptions.length" >Seçilen günler</p>
        <div v-for="day in selectedOptions" v-bind:key="day.id"
      class="is-flex mt-2"
      >
      
        <b-tag  closable @close="removeDay(day)" class="mr-1 mb-1">{{day}}</b-tag> 
        <!-- <p class="mr-1">{{day}}</p>  -->
     
      
      </div> 
  
            </div>
            
            
            </div>
             <hr>
            <div class="mb-4 is-flex is-justify-content-space-between">
            <div class="is-flex">
                <p class="has-text-left mr-3">Randevu durumu</p>
            <b-field>
            <b-switch 
            @input="setDoctorAvailable"
            v-model="doctorAvailable"
            type="is-info"
            
            size="is-medium-desktop">
            </b-switch>
          </b-field>
            </div>
            <!-- <b-button class="ml-1 " type="is-info" @click="setDoctorAvailable" >Kaydet</b-button> -->
            </div>
            
            <div class="is-flex is-align-items-baseline">
                
                <b-button type="is-info"  @click="$router.go(-1)" outlined>Geri Dön</b-button>
            </div>
            
           </div>
          </div>  
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
/* import axios from 'axios' */
import router from '@/router'
export default {
  name: 'GeneralNotesEdit',
  data(){
     return {
            meetingDuration: '',
            appointmentDays: [],
            selectedOptions:[],
            doctorAvailable: false,
            doctorId: this.$route.params.doctorId,
        daysOfWeek:{
  "Pazartesi": 1,
  "Salı": 2,
  "Çarşamba": 3,
  "Perşembe": 4,
  "Cuma": 5,
  "Cumartesi": 6,
  "Pazar": 0
},
selectedDays:[]
     } 
  },
  computed:{
            ...mapState(['singleUser']),
            appointmentDaysPresent(){
              var keys = Object.keys(this.daysOfWeek).filter(key => this.singleUser.data.doctorAppointmentDays.includes(this.daysOfWeek[key]));
              console.log(keys)
              return keys
            }
        },      
  methods: {
...mapActions(['getSingleUserById']),

handleDays(){
      var lookup = this.selectedOptions.map(day => this.daysOfWeek[day]);
      this.selectedDays = lookup
      console.log("selectedDays",this.selectedDays,lookup)
    },
removeDay(day){
  
  const index = this.selectedOptions.indexOf(day);
if (index > -1) { 
  this.selectedOptions.splice(index, 1); 
}
},
    
    async addAppointmentDays(){
      console.log("sd",this.selectedDays)
      let appointmentDays = {
        content: this.selectedDays,
        userId: router.currentRoute.params.id
      }
          this.$store.dispatch("addAppointmentDays", {appointmentDays}); 
          /* this.$store.dispatch("getSingleUserById", {doctorId: this.$route.params.id});  */
      this.selectedOptions = [];
    },
    async setDoctorAvailable(){
      
      let doctorAvailable = {
        content: this.doctorAvailable,
        userId: router.currentRoute.params.id
      } 
      
    console.log(doctorAvailable)
      

      this.$store.dispatch("setDoctorAvailable", {doctorAvailable});
      /* this.$store.dispatch("getSingleUserById");  */

    },
    async setMeetingDuration(){
      
      let meetingDuration = {
        content: this.meetingDuration,
        userId: router.currentRoute.params.id
      } 
/* 
      await axios.post("http://localhost:3000/meeting-duration", meetingDuration)
                    .then((response) => {
                        console.log(response)
                    } ).catch((error) =>
                    {console.log(error)}) */

        this.$store.dispatch("setMeetingDuration", {meetingDuration}); 
        this.meetingDuration = '';
    },
    
  },
  created(){
            this.getSingleUserById()
            
        }
  
  
  
};
</script>

<style scoped>

.preference-edit{
  margin-top: 80px;
}


</style>
