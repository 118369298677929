<template>
    <div class="info px-5-desktop mx-6-desktop mx-5 px-2">
        <h3 class="is-size-4 has-text-left has-text-weight-bold my-2">Genel Bilgiler</h3>
        <hr>
          <div class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-baseline">
           <div class="is-flex is-flex-direction-column my-2">
            <b-field class="has-text-left" label="Yaş">
             <b-input v-model="age" type="number" value=""></b-input>
            </b-field>
            <b-field class="has-text-left" label="Boy">
             <b-input v-model="height" type="number" value=""></b-input>
            </b-field>
            <b-field class="has-text-left"  label="Kilo">
             <b-input v-model="weight" type="number" value=""></b-input>
            </b-field>
            <b-field class="has-text-left" label="Telefon">
             <b-input v-model="telephone" value=""></b-input>
            </b-field>
            <div class="is-flex is-align-items-baseline mt-4">
                <b-button class="mr-1" type="is-info" @click="addPatientBaseInfo" >Ekle</b-button>
                <b-button type="is-info"  @click="$router.go(-1)" outlined>Geri Dön</b-button>
            </div>
            
           </div>
          </div>  
    </div>
</template>

<script>
/* import axios from 'axios'
import router from '@/router' */
export default {
  name: 'GeneralNotesEdit',
  data(){
     return {
                age: '',
                height:'',
                weight:'',
                telephone:'',
            id: this.$route.params.id,
        
     } 
  },
  methods: {
    toastBaseInfoConfirmation(){
        this.$buefy.toast.open({
                    message: "Değerler başarıyla eklendi!",
                    type: 'is-success',
                    duration: 2000
                })
      
      },
    
    async addPatientBaseInfo(){
      
     const telephone =  this.telephone;
     const age =  this.age;
     const weight =  this.weight;
     const heigth =  this.heigth;
     const patientId = this.id;

     console.log(patientId)

     this.$store.dispatch("addPatientBaseInfo", {telephone,age,heigth,weight,patientId})

    }
  }
};
</script>

<style scoped >
.info{
  margin-top: 80px;
}

@media screen and (min-width: 376px) and (max-width: 667px) {
  .navbar {
    display:none;
    
    
  }
}


@media screen and (max-width: 414px) {
  .info {
    /* margin-top: 85px; */
    
  }
}


@media screen and (max-width: 896px) {
  .info {
    /* margin-bottom: 85px; */
    /* margin-top: 20px; */
    
  }
}
</style>
