<template>
  <div class="mt-3 mx-6-desktop">
     <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon>
            </b-loading>
   
    <div class="is-flex is-justify-content-space-between mx-4 is-hidden-deskto is-align-content-baseline">
      
      <PatientSidebarNew class="mt-2"></PatientSidebarNew>
      <div>
        <h3 class="is-size-4 mt-2 has-text-weight-bold">
          Kan basıncı değerleri
        </h3>
      </div>
      <div class="mr-2 mt-3">
        <b-button class="is-small" @click="navigateToBloodPressure">
          <i class="fas fa-plus" ></i>
        </b-button>
      </div>
    </div>
    <hr class="mx-2">
    <div class="mt-1">
      <Plotly
        :data="data"
        :layout="layout"
        :display-mode-bar="true"
        :displaylogo="false"
        :locale="locale"
        :modeBarButtonsToRemove="modeBarButtonsToRemove"
        class="px-0-mobile"
      ></Plotly>
    </div>
    <div
      class="mx-4 is-flex  is-justify-content-space-between is-flex-direction-"
    >
      <div class="is-flex">
        <b-button
          size="is-small"
          @click="navigateToBloodPressure"
          class="mr-1 mb-1"
        >
          <span class="mr-1">
            <i class="fas fa-plus"></i>
          </span>
          <span class="is-hidden-mobile">Ölçüm ekle</span>
        </b-button>
        <b-button size="is-small" @click="refresh" >
        <span class="mr-1">
         <i class="fas fa-redo"></i>  
         </span>
        <span class="is-hidden-mobile">Grafiği yenile</span>
        </b-button> 
      </div>
      <div class="is-flex is-flex-wrap-wrap is-justify-content-flex-end ">
        <b-button size="is-small" @click="setToday" class="mr-1"
          >Bugün</b-button
        >
        <b-button size="is-small" @click="setThisWeek">Bu hafta</b-button>
        <b-button size="is-small" @click="showAllData">Tümünü göster</b-button>
        <b-button size="is-small" @click="clearDates" class="ml-1"
          >Temizle</b-button
        >
      </div>
    </div>
    <p v-if="!this.filteredData.length" class="has-text-left mt-4 pt-4 ml-4">
      Seçilen koşula uygun kan basıncı değeri yok.
    </p>
    <div class="bp-readings">
      <div
      v-for="bp in filteredData"
      v-bind:key="bp.id"
      class="is-fle is-flex-direction-row  is-justify-content-space-evenly is-flex-wrap-wrap is-align-items-flex-start"
    >
      <div class="box mx-4 my-2 ">
        <div class="is-flex is-flex-direction-column ">
          <div class="is-flex is-justify-content-space-between mb-1">
            <b-tag class="mr-2  ">SKB(Büyük Tansiyon)</b-tag>
            <b-tag v-if="bp.systolic > 140" class="mr- is-danger">{{
              bp.systolic
            }}</b-tag>
            <b-tag v-else class="mr- is-info">{{ bp.systolic }}</b-tag>
          </div>
          <div class="is-flex is-justify-content-space-between mb-1">
            <b-tag class="mr-2  ">DKB(Küçük Tansiyon)</b-tag>
            <b-tag v-if="bp.diastolic > 90" class="mr- is-danger">{{
              bp.diastolic
            }}</b-tag>
            <b-tag v-else class="mr- is-info">{{ bp.diastolic }}</b-tag>
          </div>
          <div class="is-flex is-justify-content-space-between mb-1">
            <b-tag class="mr-2">Tarih</b-tag>
            <p>{{ bp.date | moment("Do MMMM YYYY, h:mm a") }}</p>
          </div>
          <div
            v-if="bp.bpNote"
            class="is-flex is-justify-content-space-between"
          >
            <b-tag class="mr-2 is-info">Not</b-tag>
            <p>{{ bp.bpNote }}</p>
          </div>
          <div class="is-flex is-justify-content-flex-end">
            <b-tooltip
              label="Silmek için tıklayınız."
              position="is-left"
              class="is-info"
            >
              <b-button @click="deleteBp(bp)" size="is-small" class="is-rounded"
                ><i class="fas fa-times"></i
              ></b-button>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import { mapState, mapActions } from "vuex";
import { Plotly } from "vue-plotly";
import PatientSidebarNew from '@/components/PatientSidebarNew.vue';

export default {
  name: "Reading",
  components: {
    Plotly,
    PatientSidebarNew
  },
  data() {
    return {
      isLoading: true,
      isFullPage: true,
      startDate: null,
      endDate: null,
      id: this.$route.params.id,

      data: [
        {
          x: [],
          y: [],
          type: "scatter",
          line: { shape: "line" },
          name: "Büyük tansiyon",
        },
        {
          x: [],
          y: [],
          type: "scatter",
          line: { shape: "line" },
          name: "Küçük tansiyon",
        },
      ],
      locale: "fr",
      layout: {
        title: {
         /*  text: "Kan basıncı değerleri", */
          font: {
            // family: 'Courier New, monospace',
            size: 24,
          },
          xref: "paper",
          x: 0.05,
        },
        legend: {
          x: 1,
          xanchor: "center",
          y: 1,
        },
        xaxis: {
          type: "date",
          autorange: false,
          range: ["2022-04-04", "2022-04-04"],
        },

        shapes: [
          {
            type: "line",
            x0: "2032-04-04",
            y0: 90,
            x1: "2017-04-04",
            y1: 90,
            line: {
              color: "red",
              width: 3,
              dash: "dashdot",
            },
          },
          {
            type: "line",
            x0: "2032-04-04",
            y0: 140,
            x1: "2017-04-04",
            y1: 140,
            line: {
              color: "red",
              width: 3,
              dash: "dashdot",
            },
          },
        ],
        margin: {
          l: 40,
          r: 40,
          t: 10,
          pad: 4,
        },
        
      },
      modeBarButtonsToRemove: [
          "autoScale2d",
          "resetScale2d",
          "hoverClosestCartesian",
          "zoomIn2d",
          "zoomOut2d",
          "toggleSpikelines",
          "sendDataToCloud",
          "resetViewMapbox",
          "resetViews",
          "select2d",
          "lasso2d",
          "pan2d",
          "zoom2d",
          "hoverCompareCartesian",
          "toImage",
        ],
    };
  },
  computed: {
    ...mapState(["bps"]),
    filteredData() {
      var startDate = this.startDate;
      var endDate = this.endDate;
      return _.filter(this.bps, function(data) {
        if (_.isNull(startDate) && _.isNull(endDate)) {
          return true;
        } else {
          var date = data.date;
           console.log("a",date, startDate)
          return date >= startDate && date <= endDate;
        }
      });
    },
  },
  methods: {
    ...mapActions(["getBloodPressureDataById"]),
    prep() {
      this.filteredData.forEach((bp) => {
        //console.log(bp.systolic)
        this.data[0].y.push(bp.systolic);
        this.data[0].x.push(bp.date);
        //this.data[0].x = Array.from({length: this.bps.length}, (_, i) => i + 1)

        this.data[1].y.push(bp.diastolic);
        this.data[1].x.push(bp.date);
        /* console.log(this.data[1].y); */
        //this.data[1].x = Array.from({length: this.bps.length}, (_, i) => i + 1)
      });
    },
    showAllData() {
      console.log("test",moment(this.filteredData[this.filteredData.length-1].date)["_i"]);
      this.startDate = moment(Object.entries(this.filteredData[this.filteredData.length-1].date)["_i"]).subtract(1, "days").format("YYYY-MM-DD");
      console.log("startDate",this.startDate)
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
       console.log("start",this.startDate,"end",this.endDate)
         this.refresh();
         this.layout.xaxis.range[0] = moment(Object.entries(this.filteredData[this.filteredData.length-1])[3][1]).subtract(1, "days").format("YYYY-MM-DD");
         this.layout.xaxis.range[1] = moment()
        .add(2, "days")
        .format("YYYY-MM-DD"); 
    },
    setToday() {
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");

      console.log(this.layout.xaxis.range[1], this.layout.xaxis.range[0]);
      this.refresh();
      this.layout.xaxis.range[0] = moment()
        .subtract(2, "days")
        .format("YYYY-MM-DD");
      this.layout.xaxis.range[1] = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    setThisWeek() {
      this.startDate = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      this.endDate = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
      console.log(this.startDate, this.endDate);
      this.refresh();
      this.layout.xaxis.range[0] = moment()
        .subtract(9, "days")
        .format("YYYY-MM-DD");
      this.layout.xaxis.range[1] = moment()
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    clearDates() {
      this.startDate = null;
      this.endDate = null;
      console.log(this.startDate, this.endDate);
      this.refresh();
    },
    refresh() {
      this.data[0].y = [];
      this.data[0].x = [];
      this.data[1].y = [];
      this.data[1].x = [];
     // console.log("bp",this.filteredData[this.filteredData.length-1].date)
      /* this.layout.xaxis.range[0] = moment(this.filteredData[this.filteredData.length-1].date)
        .subtract(1, "days")
        .format("YYYY-MM-DD"); */

        this.layout.xaxis.range[0] = moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD");
        
      this.layout.xaxis.range[1] = moment()
        .add(2, "days")
        .format("YYYY-MM-DD");
        /* console.log(this.layout.xaxis.range[0],this.layout.xaxis.range[1])
        console.log("filteredData",this.filteredData) */
      this.prep();
    },
    navigateToBloodPressure() {
      this.$router.push({
        path: `/user/patient/${this.$route.params.id}/blood-pressure`,
      });
    },

    deleteBp(bp) {
      this.$buefy.dialog.confirm({
        title: "Kan basıncı silme işlemi",
        message:
          `Bu kan basıncı değerinizi <b>silmek</b> istediğinizden emin misiniz?"- ${bp.systolic}, ${bp.diastolic} `,
        confirmText: "Kan basıncı değerini sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          await this.$store.dispatch("deleteBp", bp);

          this.refresh();

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    isLoad(){
      if(this.bps.length) {
        this.isLoading = false
        console.log("load", this.isLoading)
      }
    },
    a() {
      this.getBloodPressureDataById();
      console.log("x");
      setTimeout(this.refresh, 50);
      console.log("y");
      setTimeout(this.isLoad, 500);
    },
  },
  mounted() {
    this.a();
  },
  created() {},
};
</script>

<style>
.bp-readings {

  margin-bottom: 60px;

}

</style>
