<template>
   <div class="a mt-6 columns px-5-desktop px-0-mobile is-flex  mt-6-desktop mt-0  mx-2 ">
      <div class=" y mt-4 column is-4  mx-0 px-0 is-flex is-justify-content-space-between" >

         <div class="x column mx-0 px-0 pt-0 "> 
         <PatientProfileInfo class="  is-flex is-align-items-center is-justify-content-space-between " ></PatientProfileInfo>
        
        
      
         </div>
         <div
      class="boxes column is-8 is-flex is-align-items-flex-start ml-0 mr-1-desktop summar px-0 is-flex-wrap-wrap"
    >
      <PatientMeetingInfo class="is-flex-grow-1 info-box meeting-info mr-2 mb-2"></PatientMeetingInfo>
      <BloodPressureInfo class="is-flex-grow-1 info-box ml-2-desktop mb-2" ></BloodPressureInfo>
      <BloodSugar class="blood-sugar is-flex-grow-1 info-box ml-1-desktop mr-0 mb-2"></BloodSugar>
    </div>
          
      </div>
     
   </div>
</template>

<script>
import PatientMeetingInfo from "@/components/PatientMeetingInfo.vue";
import PatientProfileInfo from '@/components/PatientProfileInfo.vue';
import BloodSugar from "@/components/BloodSugar.vue";
import BloodPressureInfo from "@/components/BloodPressureInfo.vue";


export default {
  components: {
    PatientMeetingInfo,
    BloodSugar,
    BloodPressureInfo,
    PatientProfileInfo
  },
  data(){
    return {
      messageNoticed: false,
      id: this.$route.params.id,
      }
  },
  methods:{
    isMessageNoticed(){
      let x = localStorage.getItem('a');
      let id = localStorage.getItem('id');
      if (id===this.id) {
        this.messageNoticed = x;
      }
    },
     warning() {
                if(!this.messageNoticed){
                  this.$buefy.snackbar.open({
                    message: 'Sayfanın sol tarafında seçenekler butonuna tıklayarak profilinizi doldurmaya başlayabilirsiniz.',
                    type: 'is-warning',
                    position: 'is-top',
                    actionText: 'Tamam',
                    
                    indefinite: true,
                    onAction: () => {
                        localStorage.setItem('a', true);
                        localStorage.setItem('id', this.id);      
                      
                    }
                })
                }
            },
   
  },
  created(){
    this.isMessageNoticed();
    this.warning();
    
  },
  
  
  }
     
                
  
</script>

<style scoped>

.a {
position: relative;
top: 100px;
backdrop-filter: blur(25%);
}


.s {
  margin-right: 0px;
}

.focuse {
  border: solid 2px blue;
}



@media screen and (max-width: 1024px) {
  .x{
    padding-top: 12px;
    /* padding-top: 0; */
    /* padding-left: 15px; */
}
}








