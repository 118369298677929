<template>
    <div class="mt-5-desktop mb-4  doctor-meeting">
      
    <div class="box  is-flex is-justify-content-space-betwee">
      
        <div class="is-flex-grow-1 meeting-box">
           <h1 class="has-text-left is-size-4" >Görüşme detayları</h1>
        <hr class="mt-0">
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-flex-direction-colum">
                <div class="is-flex is-flex-direction-column">
                     <div class="is-flex">
                
                <b-tag class="mb-1 mr-1">Hasta</b-tag>
                <p class="has-text-left mb-1">{{singleMeeting.data.patientId.name}}  {{singleMeeting.data.patientId.surname}} </p> 
                
                
            </div>
                     <div class="is-flex mb-1">
                <b-tag class="">Başlangıç</b-tag>
                <p class="ml-1 has-text-left">{{singleMeeting.data.startsAt| moment('Do MMMM YYYY, h:mm a')}}</p>
               </div>
               <div class="is-flex">
                <b-tag class="mr-1">Süre</b-tag>
                <p>{{singleMeeting.data.lastsFor}} dk</p>
            </div>
            <b-tag v-if="singleMeeting.data.isCancelled" class="is-danger">Bu görüşme iptal edildi.</b-tag>
                </div>
                 <b-tag  v-if="singleMeeting.data.startsAt > new Date().toISOString()" class="ml-2" type="is-success">{{singleMeeting.data.startsAt| moment("from","now")}}</b-tag>
                 <b-tag v-else type="is-danger ml-2">{{singleMeeting.data.startsAt| moment("from","now")}}</b-tag>
            <b-tag class="is-success ml-2" v-if="singleMeeting.data.isConfirmed ">Onaylandı</b-tag>
            </div>
            
                <div class="is-4 is-flex is-flex-direction-column ml-3">
                    <b-button :disabled="singleMeeting.data.isCancelled" @click="startMeeting(singleMeeting.data)" class="button is-small is-medium-tablet is-large-desktop is-info" v-if="singleMeeting.data.startsAt > new Date().toISOString()" outlined>
                       
 <span class="button-text is-hidden-mobile">Görüşmeyi başlat</span>

              <span class="icon is-medium">
                <i class="fas fa-video"></i>
              </span>
              <!-- <span class="button-text">Görüşmeyi başlat</span> -->
            </b-button>
            <b-button @click="cancelMeeting"   v-if="singleMeeting.data.startsAt > new Date().toISOString() && !singleMeeting.data.isCancelled" class="is-info is-small is-medium-tablet is-large-desktop mt-2">
                <span class="icon is-small">
               
                </span>
                <span class="is-hidden-mobile mr-2">Randevuyu iptal et</span> 
                 <i class="fas fa-times "></i>
                
                </b-button>
                <b-button @click="confirmMeeting"   v-if="singleMeeting.data.startsAt > new Date().toISOString() && !singleMeeting.data.isConfirmed" class="is-info is-small is-medium-tablet is-large-desktop mt-2">
                <span class="icon is-small">
               
                </span>
                <span class="is-hidden-mobile mr-2">Randevuyu onayla</span> 
                 <i class="fas fa-check "></i>
                
                </b-button>
                
                
        </div>
              </div>
        
            <!--<hr>
             <div class="is-flex is-flex-wrap-wrap">
               <b-button type="is-text" @click="navigateToSeePatientData">Kan şekeri değerlerini gör</b-button>
               <b-button type="is-text" @click="navigateToSeePatientData">Hasta bilgilerini gör</b-button>
            
    
            </div> -->
            <hr>
            <div class="is-flex is-flex-wrap-wrap">
            
        
            <div class="">
            <h3 class="has-text-left is-flex is-flex-direction-column is-align-items-flex-start">Hastanın bu görüşme için notları</h3>
            <p class="has-text-left" v-if="!singleMeeting.data.patientMeetingNote.length">Hasta tarafından eklenen bir not bulunmuyor.</p>           
            <p class="has-text-left">{{singleMeeting.data.patientMeetingNote}}</p>
            </div>
            </div>
            <hr>
            <div class="parts is-flex is-justify-content-space-betwee is-flex-wrap-wra">

              <div class="part mr- is-flex is-flex-wrap-wrap">
            
        
            <div class="">
            <div class="is-flex">
              <h3 class="has-text-left is-flex is-flex-direction-column is-align-items-flex-start mb-2">Görüşme öncesi talimatlar</h3> <div><p class="ml-3 is-underlined" v-if="!addInstruction" @click="addInstruction = !addInstruction">Ekle</p>
            <p class="ml-3 is-underlined" v-if="addInstruction" @click="addInstruction = !addInstruction">Gizle</p></div>
            </div>
            <div class="is-flex">
                <p class="has-text-left mb-2" v-if="!singleMeeting.data.instructions.length">Bir talimat eklemediniz.</p>           
            
            </div>
             <div>
               <div v-if="singleMeeting.data.startsAt > new Date().toISOString()" class="is-flex is-align-items-baseline">
                   <!-- <b-tag v-if="!addInstruction" class="mr-1">Talimat</b-tag> -->
                 <div  class="is-flex is-justify-content-space-between ">
           <b-input v-show="addInstruction" v-model="instruction" class="must-known-input"></b-input>
           <b-button :disabled="singleMeeting.data.isCancelled" v-show="addInstruction" @click="addMeetingInstruction" class="ml-2 is-small"> Talimat Ekle</b-button>
           
           
         </div> 
         <div>
            
         </div>
         
         </div> 
            <div>
                <p v-show="addInstruction" class="is-size-6 multiline my-2 has-text-left ">Eklenecek talimat: {{instruction}}</p>
                
            </div>
        <div class="mt-2" v-if="singleMeeting.data.instructions.length">
            <p class="has-text-left mb-2">Talimatlar:</p>
            <div v-for="(n) in singleMeeting.data.instructions" v-bind:key="n.id" class="is-flex">
            
            <b-message class="mb-2" >{{n.instruction}} <b-button @click="deleteInstruction(n)" size="is-small" class="delete-button-icd  mt-1 p-2"
                ><i class="fas fa-times"></i
              ></b-button></b-message>
        </div>
        </div>
        </div>
            </div>
            </div>
            <hr>
            
            <div class="part  right mr-4 is-flex is-flex-direction-column">
               <div class="is-flex ">
                 <h3 class="has-text-left mb-2">Tanı kodu</h3><div>
                  <p class="ml-3 is-underlined" v-if="!addCode" @click="addCode = !addCode">Ekle</p>
                <p class="ml-3 is-underlined" v-if="addCode" @click="addCode = !addCode">Gizle</p></div> <!-- <p>{{selection.code}}-{{selection.definition}}</p> -->
               </div> <p class="has-text-left mb-2" v-if="!singleMeeting.data.icd.length && !addCode">Henüz bir tanı kodu eklenmedi.</p>
         <div v-if="singleMeeting.data.startsAt < new Date().toISOString()" class="is-flex is-align-items-baseline">
               
                 <div  class="is-flex is-justify-content-space-between ">
                  <b-dropdown
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="300"
                    trap-focus
                    v-if="addCode"
                    v-model="selection"
                    @click="handleCode"
                >
                    <template #trigger>
                        <a
                            class="navbar-item"
                            role="button">
                            <span>Kodlar</span>
                            <!-- <b-icon icon="menu-down"></b-icon> -->
                        </a>
                    </template>
  <b-dropdown-item custom aria-role="listitem">
                      <b-input v-model="searchTerm" placeholder="Arama terimi" expanded />
                  </b-dropdown-item>
                    <b-dropdown-item
                v-for="(menu, index) in filteredData"
                :key="index"
                :value="menu" aria-role="listitem">
                <div class="media">
                    <!-- <b-icon class="media-left" :icon="menu.icon"></b-icon> -->
                    <div class="media-content">
                        <h3>{{menu.code}}</h3>
                        <h3>-{{menu.definition}}</h3>
                    </div>
                </div>
            </b-dropdown-item>

                  

                  
                </b-dropdown>
           <!-- <b-input v-show="addOutcome" v-model="outcome" class="must-known-input"></b-input> -->
         <!--   <b-button :disabled="singleMeeting.data.isCancelled" v-if="addCode" @click="addMeetingOutcome" class="ml-2 is-small">Tanı Kodu Ekle</b-button> -->
           <template>
                        <a  @click="addICDCode"
                            class="navbar-item"
                            :disabled="singleMeeting.data.isCancelled" v-if="addCode" 
                            role="button">
                            <span>Tanı Kodu ekle</span>
                            <!-- <b-icon icon="menu-down"></b-icon> -->
                        </a>
                    </template>
            
           <hr>
         </div> 
         
         <div>
            
         </div>
         
         </div>
         <div>
            
            <div>

                <p v-show="addCode" class="is-size-6 multiline mb-2 has-text-left ">Eklenecek Kod:</p>
                <p v-show="addCode" class="is-size-6 multiline mb-2 has-text-left ">{{selection.code}} - {{selection.definition}}</p>
            
            </div>
        <div v-if="singleMeeting.data.icd.length">
            <p class="has-text-left">Eklenen kod:</p>
            <div v-for="code in singleMeeting.data.icd" v-bind:key="code.id" class="is-flex">
            
            <b-message  class="mb-2">{{code.code}} - {{code.definition}}
              <b-button @click="deleteICDCode(code)" size="is-small" class="delete-button-icd  mt-1 p-2"
                ><i class="fas fa-times"></i
              ></b-button> </b-message>
        </div>
        </div>
        </div>
            </div>
            </div>
            <hr>
            <div class="parts is-flex is-justify-content-space-betwee is-flex-wrap-wra">
              <div class="3 part mr-4 is-flex is-flex-direction-column">
                <div class="is-flex">
                  <h3 class="has-text-left mb-2">Görüşme sonrası notlar </h3> <div> <p class="ml-3 is-underlined" v-if="!addOutcome" @click="addOutcome = !addOutcome">Ekle</p>
                </div>
                <p class="ml-3 is-underlined" v-if="addOutcome" @click="addOutcome = !addOutcome">Gizle</p></div>
                <p class="has-text-left mb-2" v-if="!singleMeeting.data.outcomes.length && !addOutcome && singleMeeting.data.startsAt < new Date().toISOString()">Henüz bir not eklenmedi.</p>
                <!-- <p class="has-text-left mb-2" v-if="!singleMeeting.data.outcomes.length && !addOutcome && singleMeeting.data.startsAt > new Date().toISOString()">Sonuç eklemek için görüşmenin başlamasını bekleyiniz.</p> -->
         <div v-if="singleMeeting.data.startsAt < new Date().toISOString()" class="is-flex is-align-items-baseline">
                
                
                 <div  class="is-flex is-flex-direction-column is-justify-content-space-between ">
                  <b-field label="Notlar (Yalnızca siz görebilirsiniz)"
            :label-position="labelPosition">
           <b-input size="is-large" type="textarea" v-show="addOutcome" v-model="outcome" class="must-known-input"></b-input>
           </b-field>
           <b-button :disabled="singleMeeting.data.isCancelled" v-show="addOutcome" @click="addMeetingOutcome" class="ml-">Not ekle</b-button>
           
           <hr>
         </div> 
         <div>
            
         </div>
         
         </div>
         <div>
            
            <div>
            
            </div>
        <div v-if="singleMeeting.data.outcomes.length">
            <p class="has-text-left">Notlar:</p>
            <div v-for="o in singleMeeting.data.outcomes" v-bind:key="o.id" class="is-flex">
            
            <b-message class="mb-2">{{o.outcome}} <b-button @click="deleteOutcome(o)" size="is-small" class="delete-button-icd  mt-1 p-2"
                ><i class="fas fa-times"></i
              ></b-button></b-message>
        </div>
        </div>
        </div>
            </div>
            <div class="4 part mr-4 right-second  is-flex is-flex-direction-column">
                <div class="is-flex">
                  <h3 class="has-text-left mb-2">Görüşme sonrası öneriler</h3> <div> <p class="ml-3 is-underlined" v-if="!addSuggestion" @click="addSuggestion = !addSuggestion">Ekle</p>
                </div>
                <p class="ml-3 is-underlined" v-if="addSuggestion" @click="addSuggestion = !addSuggestion">Gizle</p></div>
                <p class="has-text-left mb-2" v-if="!singleMeeting.data.suggestions.length && !addSuggestion">Henüz bir öneri eklenmedi.</p>
         <div v-if="singleMeeting.data.startsAt < new Date().toISOString()" class="is-flex is-align-items-baseline">
                
                
                 <div  class="is-flex-direction-column  ">
                  <div class="is-flex is-justify-content-space-between is-align-items-baseline">
                            <b-field label="Öneriler"
            :label-position="labelPosition">
                      <b-input v-show="addSuggestion" v-model="suggestion" class="must-known-input"></b-input>
                            </b-field>
            <b-button :disabled="singleMeeting.data.isCancelled" v-show="addSuggestion" @click="addSuggestionAfterMeeting" class="ml-2">Öneri ekle</b-button>
                  </div>
                  <hr>
           <div class="is-flex is-justify-content-space-between is-align-items-baseline">
            <div v-show="addSuggestion">
           <p class="has-text-left mb-2">Eklenecek Tetkikler</p>
           <div class="is-flex is-flex-wrap-wrap test-wrap">
            
            <b-checkbox-button v-model="labTests"
                native-value="HbA1c"
                type="is-success is-light">
                
                <span>HbA1c</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="labTests"
                native-value="TİT"
              type="is-success is-light"
                >
                
                <span>TİT</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Elekrolitler"
              type="is-success is-light"
                >
                
                <span>Elekrolitler</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Glukoz"
              type="is-success is-light"
                >
                
                <span>Glukoz</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Trigliserit"
              type="is-success is-light"
                >
                
                <span>Trigliserit</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Direkt LDL Kolesterol"
              type="is-success is-light"
                >
                
                <span>Direkt LDL Kolesterol</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Mikroalbumin"
              type="is-success is-light"
                >
                
                <span>Mikroalbumin</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="labTests"
                native-value="TSH"
                type="is-success is-light">
                TSH
            </b-checkbox-button>

            <b-checkbox-button v-model="labTests"
                native-value="Kreatinin"
                type="is-success is-light"
                >
                Kreatinin
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="ALT"
                type="is-success is-light"
                >
                ALT
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="25 OH D3"
                type="is-success is-light"
                >
                25 OH D3
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Kalsiyum"
                type="is-success is-light"
                >
                Kalsiyum
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Albumin"
                type="is-success is-light"
                >
                Albumin
            </b-checkbox-button>
            <b-checkbox-button v-model="labTests"
                native-value="Serbest T4"
                type="is-success is-light"
                >
                Serbest T4
            </b-checkbox-button>
        
           </div>
            
        <!-- <p class="content has-text-left">
            <b>Eklenecek tetkikler:</b>
            {{ labTests }}
        </p> -->
            </div>
           <b-button :disabled="singleMeeting.data.isCancelled" v-show="addSuggestion" @click="addTests" class="ml-2">Tetkik ekle</b-button>
           
           </div>
          <p class="has-text-left my-2" v-if="singleMeeting.data.labTests.length">Eklenen Tetkikler</p>
            <div v-for="test in singleMeeting.data.labTests" v-bind:key="test.id" class="is-flex is-felx-wrap-wrap">
            
            <b-message class="mb-2">{{test.labTests}} <b-button @click="deleteTest(test)" size="is-small" class="delete-button-icd  mt-1 p-2"
                ><i class="fas fa-times"></i
              ></b-button></b-message>
        </div>
           
           <hr>
         </div> 
         <div>
            
         </div>
         
         </div>
         <div>
            
            <div>

                <!-- <p v-show="addSuggestion" class="is-size-6 multiline my-2 has-text-left ">Eklenecek Sonuç: {{outcome}}</p> -->
            
            </div>
        <div v-if="singleMeeting.data.suggestions.length">
            <p class="has-text-left">Öneriler:</p>
            <div v-for="s in singleMeeting.data.suggestions" v-bind:key="s.id" class="is-flex">
            
            <b-message class="mb-2">{{s.suggestion}} <b-button @click="deleteSuggestion(s)" size="is-small" class="delete-button-icd  mt-1 p-2"
                ><i class="fas fa-times"></i
              ></b-button></b-message>
        </div>
        </div>
        </div>
            </div>
            </div>
            
            
            <hr>
            <div class="is-flex mt-2 is-justify-content-center ">
               <b-button type="is-text" class="button mr-2 is-info is-outlined" @click="navigateToProfile">Profile dön</b-button>
               <b-button type="is-text" class="button is-info is-outlined" @click="navigateToSeePatientData">Hasta bilgilerine git</b-button>
            
    
            </div>
        </div>
    </div>
    </div>    
</template>

<script>

import {mapState, mapActions} from 'vuex';
import moment from "moment";
 export default {
        name: 'DoctorMeeting',
        data(){
          return{
              patId:'',
              outcome:"",
              id: this.$route.params.id,
              /* doctorId:this.singleMeeting.data.doctorS, */
              addOutcome:false,
              addInstruction:false,
              addCode:false,
              addSuggestion:false,
              instruction:"",
              cancelInfo:true,
              confirmation:true,
              confirmedAt:null,
              cancelledAt:null,
              cancelledBy:'DOCTOR' ,
              confirmedBy:'DOCTOR' ,
              selection:'',
              selectedOptions:[],
              labelPosition: 'inside',
              suggestion:null,
              labTests:[],
     
              codes: [
    {
      "code": "E10",
      "definition": "İnsülin-bağımlı diyabetes mellitüs"
    },
    {
      "code": "E10.0",
      "definition": "İnsülin bağımlı diyabetes mellitüs, komayla birlikte"
    },
     {
      "code": "E10.1",
      "definition": "İnsülin bağımlı diyabetes mellitüs, ketoasidozla birlikte"
    },
    {
      "code": "E10.2†",
      "definition": "İnsülin bağımlı diyabetes mellitüs, böbrek komplikasyonuyla birlikte"
    },
    {
      "code": "E10.3†",
      "definition": "İnsülin bağımlı diyabetes mellitüs, göz komplikasyonuyla birlikte"
    },
    {
      "code": "E10.4†",
      "definition": "İnsülin bağımlı diyabetes mellitüs, nörolojik komplikasyonla birlikte"
    },
    {
      "code": "E10.5",
      "definition": "İnsülin bağımlı diyabetes mellitüs, periferik dolaşım komplikasyonuyla birlikte"
    },
    {
      "code": "E10.6",
      "definition": "İnsülin bağımlı diyabetes mellitüs, tanımlanmış diğer komplikasyonlarla birlikte"
    },
    {
      "code": "E10.7",
      "definition": "İnsülin bağımlı diyabetes mellitüs, birden fazla komplikasyonla birlikte"
    },
    {
      "code": "E10.8",
      "definition": "İnsülin bağımlı diyabetes mellitüs, tanımlanmamış komplikasyonlarla birlikte"
    },
    {
      "code": "E10.9",
      "definition": "İnsülin bağımlı diyabetes mellitüs, komplikasyonları olmayan"
    },
    {
      "code": "E11",
      "definition": "İnsülin-bağımlı olmayan diyabetes mellitüs"
    },
    {
      "code": "E11.0",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, komayla birlikte"
    },
    {
      "code": "E11.1",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, ketoasidozla birlikte"
    },
    {
      "code": "E11.2†",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, böbrek komplikasyonuyla birlikte"
    },
    {
      "code": "E11.3†",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, göz komplikasyonuyla birlikte"
    },
    {
      "code": "E11.4†",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, nörolojik komplikasyonla birlikte"
    },
    {
      "code": "E11.5",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, periferik dolaşım komplikasyonuyla birlikte"
    },
    {
      "code": "E11.6",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, tanımlanmış diğer komplikasyonlarla birlikte"
    },
    {
      "code": "E11.7",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, birden fazla komplikasyonla birlikte"
    },
    {
      "code": "E11.8",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, tanımlanmamış komplikasyonlarla birlikte"
    },
    {
      "code": "E11.9",
      "definition": "İnsülin bağımlı olmayan diyabetes mellitüs, komplikasyonları olmayan"
    },
    {
      "code": "E12",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs"
    },
    {
      "code": "E12.0",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, komayla birlikte"
    },
    {
      "code": "E12.1",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, ketoasidozla birlikte"
    },
    {
      "code": "E12.2†",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, böbrek komplikasyonuyla birlikte"
    },
    {
      "code": "E12.3†",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, göz komplikasyonuyla birlikte"
    },
    {
      "code": "E12.4†",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, nörolojik komplikasyonla birlikte"
    },
    {
      "code": "E12.5",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, periferik dolaşım komplikasyonuyla birlikte"
    },
    {
      "code": "E12.6",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, tanımlanmış diğer komplikasyonlarla birlikte"
    },
    {
      "code": "E12.7",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, birden fazla komplikasyonla birlikte"
    },
    {
      "code": "E12.8",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, tanımlanmamış komplikasyonlarla birlikte"
    },
    {
      "code": "E12.9",
      "definition": "Malnütrisyona bağlı diyabetes mellitüs, komplikasyonları olmayan"
    },
    {
      "code": "E13",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış"
    },
    {
      "code": "E13.0",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, komayla birlikte"
    },
    {
      "code": "E13.1",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, ketoasidozla birlikte"
    },
    {
      "code": "E13.2†",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, böbrek komplikasyonuyla birlikte"
    },
    {
      "code": "E13.3†",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, göz komplikasyonuyla birlikte"
    },
    {
      "code": "E13.4†",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, nörolojik komplikasyonla birlikte"
    },
    {
      "code": "E13.5",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, periferik dolaşım komplikasyonuyla birlikte"
    },
    {
      "code": "E13.6",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, tanımlanmış diğer komplikasyonlarla birlikte"
    },
    {
      "code": "E13.7",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, birden fazla komplikasyonla birlikte"
    },
    {
      "code": "E13.8",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, tanımlanmamış komplikasyonlarla birlikte"
    },
    {
      "code": "E13.9",
      "definition": "Diyabetes mellitüs, diğer tanımlanmış, komplikasyonları olmayan"
    },
    {
      "code": "E14",
      "definition": "Diyabetes mellitüs, tanımlanmamış"
    },
    {
      "code": "E14.0",
      "definition": "Diyabetes mellitüs, tanımlanmamış, komayla birlikte"
    },
    {
      "code": "E14.1",
      "definition": "Diyabetes mellitüs, tanımlanmamış, ketoasidozla birlikte"
    },
    {
      "code": "E14.2†",
      "definition": "Diyabetes mellitüs, tanımlanmamış, böbrek komplikasyonuyla birlikte"
    },
    {
      "code": "E14.3†",
      "definition": "Diyabetes mellitüs, tanımlanmamış, göz komplikasyonuyla birlikte"
    },
    {
      "code": "E14.4†",
      "definition": "Diyabetes mellitüs, tanımlanmamış, nörolojik komplikasyonla birlikte"
    },
    {
      "code": "E14.5",
      "definition": "Diyabetes mellitüs, tanımlanmamış, periferik dolaşım komplikasyonuyla birlikte"
    },
    {
      "code": "E14.6",
      "definition": "Diyabetes mellitüs, tanımlanmamış, tanımlanmış diğer komplikasyonlarla birlikte"
    },
    {
      "code": "E14.7",
      "definition": "Diyabetes mellitüs, tanımlanmamış, birden fazla komplikasyonla birlikte"
    },
    {
      "code": "E14.8",
      "definition": "Diyabetes mellitüs, tanımlanmamış, tanımlanmamış komplikasyonlarla birlikte"
    },
    {
      "code": "E14.9",
      "definition": "Diyabetes mellitüs, tanımlanmamış, komplikasyonları olmayan"
    }
  ],
  searchTerm:'',
          }; 
        },
        computed:{
            ...mapState(['singleMeeting','readings24hour']),
            filteredData() {
                return this.codes.filter((item) => item.code.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
            }
            
        },
        methods:{
            ...mapActions(['getSingleMeetingById','get24hourGlycosisDataById','cancelMeetingById','confirmMeetingById']),
            
            async confirmMeeting(){
      
              this.$buefy.dialog.confirm({
        title: "Randevu onaylama işlemi",
        message: `Bu randevuyu <b>onaylamak</b> istediğinizden emin misiniz? `,
        confirmText: "Onayla",
        cancelText: "Geri",
        type: "is-success",
        hasIcon: true,
        onConfirm: async () => {
          
          let confirmInfo = {
        confirmation: this.confirmation,
        meetingId: this.id,
        confirmedAt: Date.now(),
        confirmedBy:this.confirmedBy
      } 
          await this.$store.dispatch("confirmMeetingById", {confirmInfo});
          

          await this.getSingleMeetingById();

          await this.$buefy.toast.open({
            message: "Görüşme başarıyla onaylandı.",
            type: "is-success",
          }); 
        },
        
      });
    },
            async cancelMeeting(){
      
              this.$buefy.dialog.confirm({
        title: "Randevu silme işlemi",
        message: `Bu randevuyu <b>iptal etmek</b> istediğinizden emin misiniz? `,
        confirmText: "İptal et",
        cancelText: "Geri",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          let cancelInfo = {
        content: this.content,
        meetingId: this.id,
        cancelledAt: Date.now(),
        cancelledBy:this.confirmedBy
      } 
          await this.$store.dispatch("cancelMeetingById", {cancelInfo});
          

          await this.getSingleMeetingById();

          await this.$buefy.toast.open({
            message: "Görüşme başarıyla iptal edildi.",
            type: "is-success",
          }); 
        },
        
      });
    },
            getPatientData(){
                this.patId = this.singleMeeting.data.patientS 
                console.log("patientId",this.patId)
                this.$store.dispatch('get24hourGlycosisDataById', this.patId )
            },
            navigateToProfile() {
              let doctorId = this.singleMeeting.data.doctorS 
            this.$router.push({
             path: `/user/doctor/${doctorId}`,
           });
              },

            navigateToSeePatientData(){ 
              this.$router.push({ path: `/user/doctor/${this.singleMeeting.data.doctorS}/patient/${this.singleMeeting.data.patientS}` })
              },
               async startMeeting(meeting){
              let a = moment(meeting.startsAt)
              let b = moment(new Date().toISOString())
              
              let timeToMeetings = a.diff(b,'minutes')
              let doctorId = this.singleMeeting.data.doctorS 
              let roomName = meeting.doctorId.meetingRoom
              let patientName = meeting.patientId.name + ' ' + meeting.patientId.surname 
              /* let doctorIdForMeeting = this.id; */
              let url = `https://testten.daily.co/${roomName}`

              if(timeToMeetings >= 1000000){
                this.alertCustom()
              } else {
                await this.$store.dispatch("setMeetingUrl", url);
                await this.$store.dispatch("setPatientName", patientName);
                await this.$store.dispatch("setDoctorIdForMeeting",doctorId);
                this.$router.push({path:'/doctor/video'})
              }
              
            },
              async addMeetingOutcome(){

        const outcome = this.outcome.trim();
        const meetingId = this.id;
        const date = new Date()
      
      if(parseInt(this.outcome.length) == 0){
        this.showLengthWarning("bir sonuç");
      } else {
            this.$store.dispatch("addOutcomeForMeeting", {date, meetingId, outcome})
          this.toastOutcomeConfirmation()
          this.outcome = ' ';
          this.addOutcome = true;
      }  
    },
    async addTests(){
                 
        const labTests = this.labTests;
        const meetingId = this.id;
        const date = new Date()
      
      if(parseInt(labTests.length) == 0){
        this.showLengthWarning("bir tetkik");
      } else {
           await this.$store.dispatch("addLabTestsForMeeting", {date, meetingId, labTests})
          await this.toastOutcomeConfirmation()
          this.labelTests = [ ];
          /* this.add = true; */
      }  
    },
              async addSuggestionAfterMeeting(){

      
        const suggestion = this.suggestion.trim();
        const meetingId = this.id;
        const date = new Date()
      
      if(parseInt(this.suggestion.length) == 0){
        this.showLengthWarning("bir sonuç");
      } else {
            this.$store.dispatch("addSuggestionAfterMeeting", {date, meetingId, suggestion})
          /* this.toastOutcomeConfirmation() */
          this.suggestion = ' ';
          this.addSuggestion = true;
      }  
    },
              async addICDCode(){

                 console.log(this.selection) 
        const code = this.selection.code.trim();
        const definition = this.selection.definition.trim();
        const meetingId = this.id;
        const date = new Date()
      
      if(parseInt(code.length) == 0){
        this.showLengthWarning("bir sonuç");
      } else {
            this.$store.dispatch("addICDCodeForMeeting", {date, meetingId, definition, code})
          this.toastOutcomeConfirmation()
          this.code = ' ';
          this.addCode = true;
      }  
    },


    async addMeetingInstruction(){

      
        const instruction = this.instruction.trim();
        const meetingId = this.id;
        const date = new Date()
      
      if(parseInt(this.instruction.length) == 0){
        this.showLengthWarning("bir talimat");
      } else {
            this.$store.dispatch("addInstructionForMeeting", {date, meetingId, instruction})
          this.toastInstructionConfirmation()
          this.instruction = ' ';
          this.addInstruction = true;
      }

    },
    
    deleteInstruction(n) {
        const index = this.singleMeeting.data.instructions.indexOf(n)
        console.log("component",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "Talimat silme işlemi",
        message: `Bu talimatı <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Talimatı sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteInstruction", {index});

          /* this.getSingleMeetingById(); */

          this.$buefy.toast.open({
            message: "Talimat başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    
    },
    deleteICDCode(code) {
        const index = this.singleMeeting.data.icd.indexOf(code)
        console.log("component",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "ICD kodu silme işlemi",
        message: `Bu kodu <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Kodu sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteICDCode", {index});

         /* await this.getSingleMeetingById(); */

          await this.$buefy.toast.open({
            message: "Kod başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    
    },
    deleteOutcome(o) {
        const index = this.singleMeeting.data.outcomes.indexOf(o)
        console.log("component",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "Sonuç silme işlemi",
        message: `Bu sonucu <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Sonucu sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteOutcome", {index});

          this.getSingleMeetingById();

          await this.$buefy.toast.open({
            message: "Sonuç başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    
    },
    deleteSuggestion(s) {
        const index = this.singleMeeting.data.suggestions.indexOf(s)
        console.log("component",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "Öneri silme işlemi",
        message: `Bu sonucu <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Sonucu sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteSuggestion", {index});

          this.getSingleMeetingById();

          await this.$buefy.toast.open({
            message: "Öneri başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    
    },
    deleteTest(t){
       const index = this.singleMeeting.data.labTests.indexOf(t)
        console.log("component",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "Tetkik silme işlemi",
        message: `Bu tetkiği <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Tetkiği sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteTest", {index});

          this.getSingleMeetingById();

          await this.$buefy.toast.open({
            message: "Öneri başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    },
    showLengthWarning(inputData){
          this.$buefy.toast.open({
                    message: `Lütfen bir ${inputData} giriniz`,
                    type: 'is-danger',
                    duration: 3000
                })
        },
         toastOutcomeConfirmation(){
          this.$buefy.toast.open({
                    message: "Not başarıyla eklendi",
                    type: 'is-success',
                    duration: 3000
                })
        },
        toastInstructionConfirmation(){
          this.$buefy.toast.open({
                    message: "Talimat başarıyla eklendi",
                    type: 'is-success',
                    duration: 3000
                })
        },
    
            
            },
        created(){
            this.getSingleMeetingById();
            
        } 
        
    }
</script>

<style scoped>


@media screen and (min-width: 768px) {
   .right{
  margin-left: 20%;
}
}

@media screen and (min-width: 768px) {
   .right-second{
  margin-left: 20%;
}
}

@media screen and (max-width: 416px) {
   .parts{
  flex-wrap:wrap;
  
}
}

.parts{
  /* flex-basis:100%; */
}

.text {
text-align: left;
}

.multiline {
  word-break: break-word;
}

/* @media screen and (min-width: 1024px) {
  .doctor-meeting{
  margin: 0 5%;
  }
} */


@media screen and (min-width: 769px) {
    .meeting-box {
        padding-right: 5%;
        padding-left: 5%;
    }
}

.test-wrap{
  
  flex-basis: 50%;
}

@media screen and (max-width: 769px) {
    .buttons {
        width: 48%;
    }
}

.doctor-meeting {
    margin-top: 70px;
}

.delete-button-icd {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
</style>