<template>
    <div class="mustBeLoggedIn">
  <div class="is-flex is-align-items-center is-justify-content-center">
<div class="ml-5" style="font-size: 1.6rem;">
<i class="fas fa-key fa-5x"></i>
</div>
  <p class=" ml-5 has-text-centered is-size-3">Bu işlemi yapmak için giriş yapmış olmalısınız.</p>
  </div>
  <b-button @click="navigateToLogin" class="my-4 is-info is-size-3">Giriş yap</b-button>


  
    </div>    
</template>

<script>
 export default {
        name: 'MustBeLoggedIn',
        data(){
          return{
              
          }; 
        },
        methods:{
            navigateToLogin(){
                this.$router.push('/login')
            }
        }
        
        
    }
</script>

<style>
.mustBeLoggedIn {
    position: relative;
    top: calc(50vh - 6rem);
    margin : 0 auto;
}
</style>