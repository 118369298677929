<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
    >
      <div class="p-1">
        
        <b-menu>
          <b-menu-list label="Menu" class="is-size-5">
            <b-menu-item icon="user" class="is-size-5" label="Profil" @click="navigateToProfile"  ></b-menu-item>
           <b-menu-list>
             <b-menu-item label="Görüşmeler" class="is-size-5" icon="video" @click="navigateToPatientMeetings">
             <!--   <b-menu-item label="Gelecek görüşmeler" class="is-size-5" icon="arrow-right" @click="navigateToPatientMeetings"></b-menu-item> -->
              <!--  <b-menu-item label="Geçmiş Görüşmeler" class="is-size-5" icon="arrow-left" @click="navigateToFormerMeeting"></b-menu-item> -->
             </b-menu-item>
          </b-menu-list>
            <b-menu-item icon="tint" class="is-size-5" label="Değerler">
              <b-menu-item label="Kan şekeri değerlerim" class="is-size-5" icon="file-medical" @click="navigateToReading"></b-menu-item>
              <b-menu-item label="Kan şekeri değeri Ekle/Sil" class="is-size-5" icon="detail" @click="navigateToReadingDetail"></b-menu-item>
              <b-menu-item label="Kan basıncı değerlerim" class="is-size-5" icon="heartbeat" @click="navigateToBps"></b-menu-item>
              <b-menu-item label="Kan basıncı  değeri Ekle/Sil" class="is-size-5" icon="detail" @click="navigateToBloodPressure"></b-menu-item>
            </b-menu-item>
            
          </b-menu-list>
          <!-- <b-menu-list>
            <b-menu-item label="Dosyalar" class="is-size-5" icon="file-medical">
              <b-menu-item label="Yüklediğim Dosyalar" class="is-size-5" icon="file-medical" @click="navigateToFilesGenerated"></b-menu-item>
                <b-menu-item label="Oluşturduğum Dosyalar" class="is-size-5" icon="file-medical" @click="navigateToFilesUploaded"></b-menu-item>
            </b-menu-item>
          </b-menu-list> -->
          <b-menu-list>
            <b-menu-item label="Düzenlemeler" class="is-size-5" icon="edit">
              <b-menu-item label="Hastalıklar" class="is-size-5" icon="edit" @click="navigateToChronicsEdit"></b-menu-item>
              <b-menu-item label="Notlar" class="is-size-5" icon="edit" @click="navigateToNotesEdit" ></b-menu-item>
            </b-menu-item>
          </b-menu-list>

          <b-menu-list label="Actions">
            <b-menu-item class="is-size-5" label="Randevu Al" @click="navigateToAppointment" ></b-menu-item>
            <!-- <b-menu-item class="is-size-5" label="Dosya Yükle" @click="navigateToFileUpload"></b-menu-item>
            <b-menu-item class="is-size-5" label="Rapor Oluştur"></b-menu-item>
            <b-menu-item class="is-size-5" label="Görüşmeye Git" @click="navigateToPatientMeetings"></b-menu-item> -->
            <b-menu-item class="is-size-5" label="Çıkış Yap"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
    
    <b-button id="options" aria-label="sidebar" icon-left="user" @click="open = true"></b-button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,
      id:this.$route.params.id
      
    };
  },
  methods:{

      navigateToProfile(){
                this.$router.push({path:`/user/${this.id}`})
            },
      navigateToReading(){
                this.$router.push({path:`/user/${this.$route.params.id}/read`})
            },
      navigateToReadingDetail(){
                this.$router.push({path:`/user/patient/${this.id}/read-details`})
            },
      navigateToFormerMeeting(){
                this.$router.push({path:`/user/patient/${this.id}/former-meeting`})
            },
      navigateToPatientMeetings(){
                this.$router.push({path:`/user/patient/${this.id}/patient-meetings`})
            },      
      navigateToFilesUploaded(){
                this.$router.push({path:`/user/patient/${this.id}/files-uploaded`})
            },
      navigateToFilesGenerated(){
                this.$router.push({path:`/user/patient/${this.id}/files-generated`})
            },
      navigateToFileUpload(){
                this.$router.push({path:`/user/patient/${this.id}/upload-file`})
            },      
      navigateToAppointment(){
                this.$router.push({path:`/user/patient/${this.id}/appointment1`})
            },   
      navigateToBloodPressure(){
                this.$router.push({path:`/user/patient/${this.id}/blood-pressure`})
            },
      navigateToBps(){
                this.$router.push({path:`/user/patient/${this.id}/blood-pressure-details`})
            },  
      navigateToNotesEdit(){
                this.$router.push({path:`/user/patient/${this.id}/info-edit`})
            },
      navigateToChronicsEdit(){
              this.$router.push({path:`/user/patient/${this.id}/chronic-edit`})
            },  

            },
            computed:{
              
            }
};
</script>

<style>
.p-1 {
  margin-top: 6em;
  padding: 1em;
}
</style>