<template>
    <div class="admin mx-3"> 
        <h2 class="is-size-2 has-text-left ml-5 mb-1">Doktorlar</h2>
       <hr>
        <div class="doctors m-2 is-flex is-justify-content-center is-flex-wrap-wrap">
   <div v-for="doctor in doctors.data" v-bind:key="doctor.id" class="card doctor-card pos-fm m-4">
        <figure class="">
      
       <img class="doctor-image" v-if="!doctor.photoId && doctor.gender == 'FEMALE'" src="@/assets/female-doctor-avatar.png" alt="Placeholder image">
       <img class="doctor-image" v-else-if="!doctor.photoId && doctor.gender == 'MALE'" src="@/assets/male-doctor-avatar.png" alt="Placeholder image">
        <img class="doctor-image" v-else :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${doctor.photoId}.jpg`" alt="Placeholder image">
    </figure>
        <div class="card-content">
            <div class="media">
            
            <div class="media-content alignment">
                <p class="title is-4 mb-1">{{doctor.name}} {{doctor.surname}}</p>
                
                
            </div>
            </div>
            <div class="content  is-flex is-flex-direction-column">
                
                <div class="is-flex is-justify-content-flex-start">
                    <b-tag style="width:58%" class="mt-2 mr-2 is-warning">Endokrinoloji</b-tag>
                    <b-tag style="width:20%" class="mt-2  mr-2 is-small is-info">{{doctor.doctorMeetingDuration}} dk</b-tag>
                <b-tag style="width:20%" class="mt-2 is-small is-info"> DR</b-tag>
                </div>
                <div v-for="text in doctor.doctorAbout" v-bind:key="text.id" class="">
                    
                    <p  class="mt-2 mr-2 multiline-text about-text "> {{text.text}} </p>
                </div>
            <b-button outlined class="is-info is-small mt-2">Randevu al</b-button>
            </div>
        </div>


         
</div>
</div>


    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';

 export default {
        name: 'Doctors',
        data(){
            return {
             /*    columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '100',
                        numeric: true,
                        searchable: false,
                    },
                    {
                        field: 'name',
                        label: 'Adı',
                        searchable: true,
                    },
                    {
                        field: 'surname',
                        label: 'Soyadı',
                        searchable: true,
                    },
                    {
                        field: 'doctorAvailable',
                        label: 'Soyadı',
                        searchable: true,
                    },{
                        field: 'meetingRoom',
                        label: 'Oda',
                        searchable: true,
                    },
            ] */
            }},
        
        components:{
          //  Search
        },
        computed:{
            ...mapState(['doctors'])
        },
        methods:{
            ...mapActions(['fetchDoctors'])
        },
        created(){
            this.fetchDoctors()
        }
        
    }
</script>

<style scoped>
.pos-fm {
    text-align:left !important;
}

.admin {
    margin-top: 100px;
}

@media screen and (min-width: 415px) {
  .doctors {
    flex-grow:1;
  }
}

.doctor-image{
      width:40vh;
    max-height:30vh;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .doctor-image{
    width:40vw;
    max-height:30vh;
  }
}

.about-text {
    max-width: 90%;
}

@media screen and (min-width: 1024px) {
  .doctor-card {
    max-width: 20vw;
}
}


</style>