<template>
  <div  id="app">
    <Navbar 
      v-if="!$route.meta.hideNavbar">
      </Navbar>
     <transition name="slide-fade" mode="">
      <router-view/>
    </transition>  
    <BottomNav 
      id="bot-nav" 
      v-if="!$route.meta.hideBottomNav" 
      class="bot-nav is-hidden-desktop" >
    </BottomNav>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import BottomNav from '@/components/BottomNav.vue'
export default {
  components: {
    Navbar,
   BottomNav
  },
  methods:{
 
 },
 
 created(){
  window.addEventListener('scroll',this.scrollFunction)
  
  
 }}
  


</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}

#app {
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}

p {
  font-weight:600;
}

.multiline-text{
  word-break: break-word;
}

#nav {
  padding: 0px;
  margin-bottom: 80px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.slide-fade-enter-active {
  transition: all .2s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateX(5px);
  opacity: 0;
}

.text-pointer{
  cursor: pointer;
}

@media (max-width: 480px) {
  
.bottom-margin{
  margin-bottom:10vh;
}
}


@media (min-width: 480px) and (max-width: 896px) {
  .bot-nav {
    visibility: hidden; 
  }
}

.is-underlined {
  text-decoration: underline;
}

</style>