<template>
    <div  class=" main mt-6 py-5 mx-5 mb-6 bottom-margin is-flex is-flex-direction-column is-flex-wrap-wrap">
     <div class="is-flex is-flex-wrap-wrap mx-4 ">
       <div class="is-flex is-flex-direction-column mr-6">
         <div class="chronics mt-4">
          <h2 class="mr-3 is-size-4 is-size-5-mobile has-text-left ">Varsa diğer <b>kronik hastalıklarınızı</b> ekleyiniz.</h2>
            <hr>
        <div class="is-flex is-flex-direction-column mt-1">
         <div class="is-flex">
            <!-- <input class="input"
          v-model="newChronic" 
          type="text"
          @keyup.enter="addChronic"
          > -->
           <b-select
           v-model="newChronic" 
                placeholder="Hastalık seçin"
                icon="plus"
                icon-pack="fas">
                <option value="Tansiyon">Tansiyon</option>
                <option value="KOAH">KOAH</option>
                <option value="Kalp yetmezliği">Kalp yetmezliği</option>
                <option value="Obezite">Obezite</option>
                <option value="Koroner Arter Hastalık">Koroner Arter Hastalık</option>
                <option value="Böbrek Yetmezliği">Böbrek Yetmezliği</option>
                <option value="İnme">İnme</option>
                
                <option value="Diğer">Diğer</option>
            </b-select>
          
        <button class=" button is-info mx-2"  @click="addChronic">Ekle</button>
        
         </div>
        <p class="is-size-6 my-4 has-text-left ">Eklenecek hastalık: {{newChronic}}</p>
        </div>
        <h3 v-if="chronics.length" class="has-text-left has-text-black mt-4"> Eklenen hastalıklar</h3>
        <!-- <hr> -->
        <div  class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-flex-start my-1" v-for="chronic in chronics" v-bind:key="chronic.id">
           <b-tag class="is-danger mb-2" size="is-medium" closable @close="deleteChronic(chronic)">{{ chronic.value }}</b-tag>
          <!-- <div closable @close="deleteChronic(chronic)"  class="is-flex ">
            <span  class="tag is-danger is-medium is-closable">
            {{chronic.value}}
            
        </span>
          </div> -->
          
        </div>
          
       </div>
       <hr>
         <div class="father mt-4">
          <h2 class="mr-3 is-size-4 is-size-5-mobile has-text-left ">Biliyorsanız <b>babanızın</b> kronik hastalıklarınızı ekleyiniz.</h2>
            <hr>
        <div class="is-flex is-flex-direction-column mt-1">
         <div class="is-flex">
          
           <b-select
           v-model="fatherChronic" 
                placeholder="Hastalık seçin"
                icon="plus"
                icon-pack="fas">
                <option value="Tansiyon">Tansiyon</option>
                <option value="Diyabet">Diyabet</option>
                <option value="KOAH">KOAH</option>
                <option value="Kalp yetmezliği">Kalp yetmezliği</option>
                <option value="Obezite">Obezite</option>
                <option value="Koroner Arter Hastalık">Koroner Arter Hastalık</option>
                <option value="Böbrek Yetmezliği">Böbrek Yetmezliği</option>
                
                <option value="İnme">İnme</option>
                
                <option value="Diğer">Diğer</option>
            </b-select>
          
        <button class=" button is-info mx-2"  @click="addFatherChronic">Ekle</button>
        
         </div>
        <p class="is-size-6 my-4 has-text-left ">Eklenecek hastalık: {{fatherChronic}}</p>
        </div>
        <h3 v-if="fatherChronics.length" class="has-text-left has-text-black mt-4"> Eklenen hastalıklar</h3>
        
        <div  class="is-flex is-flex-direction-column is-justify-content-space-between my-1" v-for="chronic in fatherChronics" v-bind:key="chronic.id">
          <div  class="is-flex ">
           <b-tag class="is-danger is-light mb-2" size="is-medium" closable @close="deleteFatherChronic(chronic)">{{ chronic.value }}</b-tag>
            <!-- <span  class="tag is-danger  is-medium ">
            {{chronic.value}}
            
        </span> -->
          </div>
          
        </div>
          
       </div>
       
       <hr>
         <div class="mother mt-4">
          <h2 class="mr-3 is-size-4 is-size-5-mobile has-text-left ">Biliyorsanız <b>annenizin</b> kronik hastalıklarınızı ekleyiniz.</h2>
            <hr>
        <div class="is-flex is-flex-direction-column mt-1">
         <div class="is-flex">
          
           <b-select
           v-model="motherChronic" 
                placeholder="Hastalık seçin"
                icon="plus"
                icon-pack="fas">
                <option value="Tansiyon">Tansiyon</option>
                <option value="Diyabet">Diyabet</option>
                <option value="KOAH">KOAH</option>
                <option value="Kalp yetmezliği">Kalp yetmezliği</option>
                <option value="Obezite">Obezite</option>
                <option value="Koroner Arter Hastalık">Koroner Arter Hastalık</option>
                <option value="Böbrek Yetmezliği">Böbrek Yetmezliği</option>
                
                <option value="İnme">İnme</option>
                
                <option value="Diğer">Diğer</option>
            </b-select>
          
        <button class=" button is-info mx-2"  @click="addMotherChronic">Ekle</button>
        
         </div>
        <p class="is-size-6 my-4 has-text-left ">Eklenecek hastalık: {{motherChronic}}</p>
        </div>
         <h3 v-if="motherChronics.length" class="has-text-left has-text-black mt-4"> Eklenen hastalıklar</h3>
        
        <div  class="is-flex is-flex-direction-column is-justify-content-space-between my-1" v-for="chronic in motherChronics" v-bind:key="chronic.id">
          <div  class="is-flex ">
            <b-tag class="is-danger is-light mb-2" size="is-medium" closable @close="deleteMotherChronic(chronic)">{{ chronic.value }}</b-tag>
        <!--     <span  class="tag is-danger is-light is-medium ">
            {{chronic.value}}
            
        </span> -->
          </div>
          
        </div>
          
       </div>
       <hr>
              <div class="complications mt-4">
          <h2 class="mr-3 is-size-4 is-size-5-mobile has-text-left ">Varsa ve biliyorsanız <b>diyabetin komplikasyonlarını</b> ekleyiniz.</h2>
            <hr>
        <div class="is-flex is-flex-direction-column mt-1">
         <div class="is-flex">
           <b-select
           v-model="newComp" 
                placeholder="Komplikasyon seçin"
                icon="plus"
                icon-pack="fas">
                <option value="Nöropati">Nöropati</option>
                <option value="Retinopati">Retinopati</option>
                <option value="Nefropati">Nefropati</option>
                
                <option value="Diyabetik Ayak">Diyabetik Ayak</option>
                <option value="Diğer">Diğer</option>
            </b-select>
          
        <button class=" button is-info mx-2"  @click="addComp">Ekle</button>
        
         </div>
        <p class="is-size-6 my-4 has-text-left ">Eklenecek Komplikasyon: {{newComp}}</p>
        </div>
        <h3 v-if="comps.length" class="has-text-left has-text-black mt-4"> Eklenen Komplikasyonlar</h3>
        <!-- <hr> -->
        <div  class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-flex-start my-1" v-for="comp in comps" v-bind:key="comp.id">
         <b-tag class="is-warning mb-2" size="is-medium" closable @close="deleteComp(comp)">{{ comp.value }}</b-tag>
          <!-- <div  class="is-flex ">
            <span  class="tag is-danger is-medium ">
            {{comp.value}}
            
        </span>
          </div> -->
          
        </div>
          
       </div>
      </div>
       <div>
                 <div class="former-visits mt-4">
          <h2 class="mr-3 is-size-4 is-size-5-mobile has-text-left ">Aşağıdaki polikliniklerde en son ne zaman muayene oldunuz?</h2>
            <hr>
        <div class="is-flex is-flex-direction-column mt-1">
         <div class="">
          <div class="is-flex is-flex-direction-column mb-2 is-align-items-baseline">
           <div class="is-flex mb-2">
            <p class="mr-2">Göz</p>
            <b-tag  v-if="treatmentInfo.length && treatmentInfo[0].kidney.length" class="ml-2 is-medium">{{treatmentInfo[0].kidney[treatmentInfo[0].kidney.length-1].date | moment("MMMM YYYY")}}</b-tag>
           </div>
         <b-field >
        <b-datepicker
            type="month"
            @input="addEyeVisitDate"
            v-model="eye"
            placeholder="Tarih seçiniz."
            
            >
        </b-datepicker>
    </b-field> 
    <!-- <p>{{eye}}</p> -->
    <!-- <p v-if="treatmentInfo.length && treatmentInfo[0].eye.length" class="ml-2">{{treatmentInfo[0].eye[treatmentInfo[0].eye.length-1].date | moment("MMMM YYYY")}}</p> -->
            </div>
            
            <div class="is-flex is-flex-direction-column mb-2 is-align-items-baseline">
           <div class="is-flex mb-2">
            <p class="mr-2 has-text-left">Nefroloji (Böbrek)</p>
            <b-tag  v-if="treatmentInfo.length && treatmentInfo[0].kidney.length" class="ml-2 is-medium">{{treatmentInfo[0].kidney[treatmentInfo[0].kidney.length-1].date | moment("MMMM YYYY")}}</b-tag>
           </div>
         <b-field>
        <b-datepicker
            type="month"
            v-model="kidney"
            @input="addKidneyVisitDate"
            placeholder="Tarih seçiniz."
            icon="calendar-today"
            trap-focus>
        </b-datepicker>
    </b-field> 
    <!-- <p>{{kidney}}</p> -->
   
            </div>
            <div class="is-flex is-flex-direction-column mb-2 is-align-items-baseline">
           <div class="is-flex mb-2">
            <p class="mr-2">Kardiyoloji</p>
            <b-tag  v-if="treatmentInfo.length && treatmentInfo[0].heart.length" class="ml-2 is-medium">{{treatmentInfo[0].heart[treatmentInfo[0].heart.length-1].date | moment("MMMM YYYY")}}</b-tag>
           </div>
         <b-field>
        <b-datepicker
            type="month"
            v-model="heart"
            @input="addHeartVisitDate"
            placeholder="Tarih seçiniz."
            icon="calendar-today"
            trap-focus>
        </b-datepicker>
    </b-field> 
    <!-- <p v-if="treatmentInfo.length &&  treatmentInfo[0].heart.length" class="ml-2">{{treatmentInfo[0].heart[treatmentInfo[0].heart.length-1].date | moment("MMMM YYYY")}}</p> -->
            </div>
            <div class="is-flex is-flex-direction-column is-align-items-baseline mb-2">
           <div class="is-flex mb-2">
            <p class="mr-2">Nöroloji</p>
            <b-tag v-if=" treatmentInfo.length && treatmentInfo[0].brain.length" class="ml-2 is-medium">{{treatmentInfo[0].brain[treatmentInfo[0].brain.length-1].date | moment("MMMM YYYY")}}</b-tag>
           </div>
         <b-field>
        <b-datepicker
            type="month"
            v-model="brain"
            @input="addBrainVisitDate"
            placeholder="Tarih seçiniz."
            
            >
        </b-datepicker>
    </b-field> 
    <!-- <p>{{brain}}</p> -->
    <!-- <p v-if=" treatmentInfo.length && treatmentInfo[0].brain.length" class="ml-2">{{treatmentInfo[0].brain[treatmentInfo[0].brain.length-1].date | moment("MMMM YYYY")}}</p> -->
    <!-- <b-button class="is-info ml-2" @click="addOtherClinicVisits">Tarihleri ekle</b-button> -->
            </div>  
            
          
        
        
         </div>
        
        </div>
        <hr>
        <div>
          <h2 class="has-text-left is-size-4 is-size-5-mobile mb-2">Aşağıdakilerden daha önce geçirdikleriniz varsa seçiniz</h2>
          <hr>
          <div class="is-flex">
        <div>
            <div class="is-flex is-align-items-baseline">
              <p class="has-text-left mb-2">Ketoasidoz</p>
             <b-tag v-if="treatmentInfo.length && treatmentInfo[0].ketoasidoz.length" class="ml-2 mb-2 is-medium">{{treatmentInfo[0].ketoasidoz.at(-1).value}}</b-tag>
            </div>
           <b-field>
            <b-radio-button v-model="ketoasidoz"
                native-value="Evet"
                @input="addKetoasidozInfo"
                type="is-light is-outlined">
                
                <span>Evet</span>
            </b-radio-button>

            <b-radio-button v-model="ketoasidoz"
                native-value="Hayır"
                @input="addKetoasidozInfo"
                type=" is-light is-outlined">
                
                <span>Hayır</span>
            </b-radio-button>

            <b-radio-button v-model="ketoasidoz"
                native-value="Bilmiyorum"
                @input="addKetoasidozInfo"
                type=" is-light is-outlined">
                Bilmiyorum
            </b-radio-button>
        </b-field>
        </div>
        <!-- <p>{{ketoasidoz}}</p> -->
          </div>
          <hr>
          <div class="is-flex is-align-items-baseline">
         <div >
          <div class="is-flex is-align-items-baseline">
              <p class="has-text-left mb-2">Hipoglisemik atak</p>
            <!-- <b-tag v-if="hypoglycemicAttack" class="ml-4">{{hypoglycemicAttack}}</b-tag> -->
            <b-tag v-if="treatmentInfo.length && treatmentInfo[0].hypoglycemic.length" class="ml-2 is-medium mb-2">{{treatmentInfo[0].hypoglycemic.at(-1).value}}</b-tag>
            </div>
           <b-field >
            <b-radio-button v-model="hypoglycemicAttack"
                native-value="Evet"
                @input="addHypoglycemicEventInfo"
                type="is-info is-light is-outlined">
                
                <span>Evet</span>
            </b-radio-button>

            <b-radio-button v-model="hypoglycemicAttack"
                native-value="Hayır"
                @input="addHypoglycemicEventInfo"
                type=" is-light ">
                
                <span>Hayır</span>
            </b-radio-button>

            <b-radio-button v-model="hypoglycemicAttack"
                native-value="Bilmiyorum"
                @input="addHypoglycemicEventInfo"
                type=" is-light ">
                Bilmiyorum
            </b-radio-button>
        </b-field>
         </div>
        <!-- <p>{{hypoglycemicAttack}}</p> -->
          </div>
          
        </div>
        <hr>
         <div class="is-flex">
        <div>
            <div class="is-flex is-align-items-baseline">
              <p class="has-text-left mb-2">Tromboz</p>
            <!-- <b-tag v-if="tromboz" class="ml-4">{{tromboz}}</b-tag> -->
            <b-tag v-if="treatmentInfo.length && treatmentInfo[0].tromboz.length" class="ml-2 is-medium mb-2">{{treatmentInfo[0].tromboz.at(-1).value}}</b-tag>
            </div>
           <b-field>
            <b-radio-button v-model="tromboz"
                native-value="Evet"
                @input="addTrombozInfo"
                type="is-light is-outlined">
                
                <span>Evet</span>
            </b-radio-button>

            <b-radio-button v-model="tromboz"
                native-value="Hayır"
                @input="addTrombozInfo"
                type=" is-light is-outlined">
                
                <span>Hayır</span>
            </b-radio-button>

            <b-radio-button v-model="tromboz"
                native-value="Bilmiyorum"
                @input="addTrombozInfo"
                type=" is-light is-outlined">
                Bilmiyorum
            </b-radio-button>
        </b-field>
        </div>
        <!-- <p>{{tromboz}}</p> -->
          </div>
        
          
       </div>
       </div>
     </div>
     <hr>
        <div class="mt-2 is-flex is-align-self-center ">
          <b-button @click="navigateToNotesEdit" class="mr-2 is-outlined is-info">‹ Notlarıma dön</b-button>
          <b-button @click="navigateToProfile" class=" is-outlined is-info">Profile git ›</b-button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState} from 'vuex';
import router from '@/router'
/* import _ from "lodash"; */

export default {
  name: 'Chronic',
  data() {
    return {
      text: 'Meeting',
      newChronic: '',
      newComp:'',
      fatherChronic:'',
      motherChronic:'',
      /* chronics: [], */
       id: this.$route.params.id,
       eye:null,
       heart:null,
       kidney:null,
       brain:null,
       hypoglycemicAttack:null,
       ketoasidoz:null,
       tromboz:null,
    };
  },
   computed:{
            ...mapState(['chronics','comps','treatmentInfo','motherChronics','fatherChronics']),
           /*  fatherChronics() {
      var parent = "father";
      
      return _.filter(this.chronics, function(data) {
        if ( _.isNull(parent)) {
          return true;
        } else {
          
          return data.parent == parent
        
        }
      });
    },

         patientChronics() {
      var father = "father";
      var mother = "mother";
      
      return _.filter(this.chronics, function(data) {
        if ( _.isNull(parent)) {
          return true;
        } else {
          
          return data.parent =! father || mother; 
        
        }
      });
    }, */
        },
  methods: {
    ...mapActions(['getChronicsById','getMotherChronicsById','getFatherChronicsById','getCompsById','getTreatmentInfo','deleteChronic']),
    navigateToProfile(){
                this.$router.push({path:`/user/${this.id}`})
            },
   deleteChronic(chronic) {
  this.$buefy.dialog.confirm({
        title: "Hastalık silme işlemi",
        message: `Bu notu <b>silmek</b> istediğinizden emin misiniz? - ${chronic.value}`,
        confirmText: "Hastalığı sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteChronic", chronic);

          await this.$store.dispatch("getChronicsById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    deleteMotherChronic(chronic) {
  this.$buefy.dialog.confirm({
        title: "Hastalık silme işlemi",
        message: `Bu notu <b>silmek</b> istediğinizden emin misiniz? - ${chronic.value}`,
        confirmText: "Hastalığı sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteMotherChronic", chronic);

          await this.$store.dispatch("getMotherChronicsById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    deleteFatherChronic(chronic) {
  this.$buefy.dialog.confirm({
        title: "Hastalık silme işlemi",
        message: `Bu notu <b>silmek</b> istediğinizden emin misiniz? - ${chronic.value}`,
        confirmText: "Hastalığı sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteFatherChronic", chronic);

          await this.$store.dispatch("getFatherChronicsById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    deleteComp(comp) {
  this.$buefy.dialog.confirm({
        title: "Komplikasyon silme işlemi",
        message: `Bu komplikasyonu <b>silmek</b> istediğinizden emin misiniz? - ${comp.value}`,
        confirmText: "Komplikasyonu sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteComp", comp);

          await this.$store.dispatch("getCompsById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    navigateToNotesEdit() {
      this.$router.push({ path: `/user/patient/${this.id}/info-edit` });
    },
    async addChronic(){

        const value = this.newChronic.trim();
        const patientId = this.id;

      console.log(this.newChronic.length)
      
      if(parseInt(this.newChronic.length) == 0){
        this.showLengthWarning();
      } else if(this.chronics.some(e => e.value === value)){
        this.showChronicExistWarning();
      } else {
            this.$store.dispatch("addChronic", {patientId,value})
            this.$store.dispatch("getChronicsById", {patientId})
          this.toastChronicConfirmation()
          this.newChronic = ' ';
      }
         
    },
    async addFatherChronic(){

        const value = this.fatherChronic.trim();
        const patientId = this.id;
        const parent = "father"; 

      console.log(this.fatherChronic.length)
      
      if(parseInt(this.fatherChronic.length) == 0){
        this.showLengthWarning();
      } else if(this.fatherChronics.some(e => e.value === value)){
        this.showChronicExistWarning();
      } else {
            this.$store.dispatch("addFatherChronic", {parent,patientId,value})
            /* this.$store.dispatch("getFatherChronicsById", {patientId}) */
          this.toastChronicConfirmation()
          this.fatherChronic = ' ';
      }
         
    },
    async addMotherChronic(){

        const value = this.motherChronic.trim();
        const patientId = this.id;
        

      console.log(this.motherChronic.length)
      
      if(parseInt(this.motherChronic.length) == 0){
        this.showLengthWarning();
      } 
       else if(this.motherChronics.some(e => e.value === value)){
        this.showChronicExistWarning();
      } 
      else {
            this.$store.dispatch("addMotherChronic", {parent,patientId,value})
            /* this.$store.dispatch("getChronicsById", {patientId}) */
          this.toastChronicConfirmation()
          this.motherChronic = ' ';
      }
         
    },
    handleEye(){
      console.log(this.eye)
    },

    async addEyeVisitDate(){
      let eyeVisitDate = {
        content: this.eye,
        userId: router.currentRoute.params.id
      } 

    if(eyeVisitDate.content===null){
      alert("No");
    }else{
      console.log(eyeVisitDate)
      this.$store.dispatch("addEyeVisitDate", {eyeVisitDate});
    }
    
    },
    async addKidneyVisitDate(){
      let kidneyVisitDate = {
        content: this.kidney,
        userId: router.currentRoute.params.id
      } 
    console.log(kidneyVisitDate)
      this.$store.dispatch("addKidneyVisitDate", {kidneyVisitDate});
    },
    async addHeartVisitDate(){
      let heartVisitDate = {
        content: this.heart,
        userId: router.currentRoute.params.id
      } 
    console.log(heartVisitDate)
      this.$store.dispatch("addHeartVisitDate", {heartVisitDate});
    },
    async addBrainVisitDate(){
      let brainVisitDate = {
        content: this.brain,
        userId: router.currentRoute.params.id
      } 
    console.log(brainVisitDate)
      this.$store.dispatch("addBrainVisitDate", {brainVisitDate});
    },
    async addHypoglycemicEventInfo(){
      let hypoglycemicEventInfo  = {
        content: this.hypoglycemicAttack,
        userId: router.currentRoute.params.id
      } 
    console.log(hypoglycemicEventInfo)
      this.$store.dispatch("addHypoglycemicEventInfo", {hypoglycemicEventInfo});
    },
     async addKetoasidozInfo(){
      let ketoasidozInfo  = {
        content: this.ketoasidoz,
        userId: router.currentRoute.params.id
      } 
    console.log(ketoasidozInfo)
      this.$store.dispatch("addKetoasidozInfo", {ketoasidozInfo});
    },
     async addTrombozInfo(){
      let trombozInfo  = {
        content: this.tromboz,
        userId: router.currentRoute.params.id
      } 
    console.log(trombozInfo)
      this.$store.dispatch("addTrombozInfo", {trombozInfo});
    },
    
  
    handleBrain(){
      console.log(this.brain)
    },
    async addOtherClinicVisits(){

        /* const eye = this.eye; */
        const heart = this.heart;
        const kidney = this.kidney;
        const brain = this.brain;
        const patientId = this.id;

      console.log(this.eye,heart,kidney,brain,patientId)

    },
    async addComp(){

        const value = this.newComp.trim();
        const patientId = this.id;

      console.log(this.newComp.length)
      
      if(parseInt(this.newComp.length) == 0){
        this.showLengthWarning();
        console.log("here")
      } 
      else if(this.comps.length && this.comps.some(e => e.value === value)){
        console.log("no here")
        this.showChronicExistWarning();
      } 
      else {
            await this.$store.dispatch("addComp", {patientId,value})
            /* this.$store.dispatch("getCompsById", {patientId}) */
          this.toastChronicConfirmation()
          this.newComp = ' ';
      }
         
    },
    showChronicExistWarning(){
          this.$buefy.toast.open({
                    message: "Bu değer zaten var!",
                    type: 'is-danger',
                    duration: 3000
                })
        },
    showLengthWarning(){
          this.$buefy.toast.open({
                    message: "Lütfen bir hastalık giriniz",
                    type: 'is-danger',
                    duration: 2000
                })
        },
         toastChronicConfirmation(){
          this.$buefy.toast.open({
                    message: "Kronik hastalık başarıyla eklendi",
                    type: 'is-success',
                    duration: 2000
                })
        },
    /* deleteChronic(chronic){
      const chronicIndex = this.chronics.indexOf(chronic);
      this.chronics.splice(chronicIndex,1);

    } */
  },
  async created(){
    await this.getChronicsById();
    await this.getMotherChronicsById();
    await this.getFatherChronicsById();
    await this.getCompsById();
    await this.getTreatmentInfo();
  }
};
</script>

<style scoped>
 .tag {
        cursor: pointer;
    }


    @media screen and (min-width: 416px) {
  .main{
  margin-left: 15%;
  margin-right: 15%;
  
  }
}


    @media screen and (min-width: 768px) {
  .cols{
  flex-direction: column;
  
  }
}
</style>
