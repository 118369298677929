<template>
    <div class="">
    <div class="box meeting-detail mx-2 is-flex is-justify-content-space-between">
        <div class="is-flex-grow-0">
            <div class="is-flex is-flex-direction-column">
                <div class="is-flex mb-1">
                <b-tag class="mr-1">Doktor</b-tag>
                <p>{{singleMeeting.data.doctorId.name}} {{singleMeeting.data.doctorId.surname}}</p>
                <b-tag v-if="singleMeeting.data.isConfirmed" class="ml-2 is-success is-light">Onaylandı</b-tag>
                <b-tag v-if="!singleMeeting.data.isConfirmed" class="ml-2 is-warning is-light">Onay bekliyor</b-tag>
            </div>
            <div class="is-flex mb-1">
                <b-tag class="mr-1">Başlangıç</b-tag>
                <p class="has-text-left">{{singleMeeting.data.startsAt| moment('Do MMMM YYYY, h:mm a')}}</p>
                 <b-tag class="ml-2" type="is-info">{{singleMeeting.data.startsAt| moment("from","now")}}</b-tag>

            </div>
            <div class="is-flex mb-1">
                <b-tag class="mr-1">Süre</b-tag>
                <p>{{singleMeeting.data.lastsFor}} dk</p>
            </div>
            <div class="is-flex mb-1">
                <b-tag class="mr-1">Email</b-tag>
                <p>{{singleMeeting.data.doctorId.email}}</p>
            </div>
            <b-tag v-if="singleMeeting.data.isCancelled" class=" is-danger">Bu görüşme iptal edildi.</b-tag>
            </div>
            <hr>
             
                <div class="instruct is-flex">
                <div class="is-flex mb-2">
                    <b-tag v-if="!singleMeeting.data.instructions.length" class="mr-1">Talimatlar</b-tag>
                <p class="has-text-left" v-if="!singleMeeting.data.instructions.length">Henüz bir talimat eklenmedi.</p>
                </div>
                
                <div class="" v-if="singleMeeting.data.instructions.length">
            <p class="mb-1 has-text-left">Talimatlar:</p>
            <div v-for="o in singleMeeting.data.instructions" v-bind:key="o.id" class="is-flex">
            
            <b-message class="mr-2 mb-2">{{o.instruction}}</b-message>
        </div>
        </div>
            </div>
            <hr>
            <div class="icd is-flex">
                <b-tag v-if="!singleMeeting.data.icd.length" class="mr-1">Tanı Kodu</b-tag>
                <p v-if="!singleMeeting.data.icd.length">Henüz bir tanı kodu eklenmedi.</p>
                <div class="" v-if="singleMeeting.data.icd.length">
            <p class="mb-1 has-text-left">Tanı kodu:</p>
           <div v-if="singleMeeting.data.icd.length">
            
            <div v-for="code in singleMeeting.data.icd" v-bind:key="code.id" class="is-flex">
            
            <b-message  class="mb-2">{{code.code}} - {{code.definition}}
               </b-message>
        </div>
        </div>
            
        </div>
            </div>
             <hr>
            <div class="suggest is-flex">
                <b-tag v-if="!singleMeeting.data.suggestions.length" class="mr-1">Öneriler</b-tag>
                <p class="has-text-left" v-if="!singleMeeting.data.suggestions.length">Henüz bir öneri eklenmedi.</p>
                <div class="" v-if="singleMeeting.data.suggestions.length">
            <p class="mb-1 has-text-left">Öneriler:</p>
            <div v-for="o in singleMeeting.data.suggestions" v-bind:key="o.id" class="is-flex">
            
            <b-message class="mr-2 mb-2 ">{{o.suggestion}}</b-message>
        </div>
        </div>
            </div>
             <hr>
            <div class="tests is-flex">
                <b-tag v-if="!singleMeeting.data.suggestions.length" class="mr-1">Tetkikler</b-tag>
                <p v-if="!singleMeeting.data.suggestions.length">Henüz bir tetkik önerisi eklenmedi.</p>
                <div class="" v-if="singleMeeting.data.suggestions.length">
            <p class="mb-1 has-text-left">Tetkikler:</p>
            <div v-for="test in singleMeeting.data.labTests[0].labTests" v-bind:key="test.id" class="is-flex is-felx-wrap-wrap">
            
            <b-message class="mb-2">{{test}} </b-message>
        </div>
           
        </div>
            </div>

             
        </div>
        <div class="is-flex is-flex-direction-column buttons">
            <b-button   v-if="singleMeeting.data.startsAt > new Date().toISOString() && !singleMeeting.data.isCancelled" @click="cancelMeeting" class="is-info ml-2">
                <span class="icon is-small">
                <i class="fas fa-times"></i>
                </span>
                <span class="is-hidden-mobile">Randevuyu iptal et</span> 
                </b-button>
                <b-button  v-if="singleMeeting.data.startsAt > new Date().toISOString() && !singleMeeting.data.isCancelled && singleMeeting.data.isConfirmed"  @click="startMeeting(singleMeeting.data)" class="is-info  ml-">
                <span class="icon is-small">
                <i class="fas fa-video"></i>
                </span>
                <span class="is-hidden-mobile " >Görüşmeyi başlat</span> 
                </b-button>
        </div>
        
    </div>
    <div class="is-flex is-justify-content-flex-end mr-2">
        <b-tooltip class="is-info" position="is-left" label="Bu butona tıklayarak bir önceki sayfaya dönebilirsiniz.">
            <b-button class="is-info" @click="$router.go(-1)" outlined>Geri dön</b-button>
        </b-tooltip>
        
    </div>
    
    </div>    
</template>

<script>

import {mapState, mapActions} from 'vuex';
import moment from 'moment';

 export default {
        name: 'PatientMeeting',
        data(){
          return{
                id: this.$route.params.id,
              cancelInfo:true,
              cancelledAt:null,
              cancelledBy:'PATIENT' 
          }; 
        },
        computed:{
            ...mapState(['singleMeeting'])
            
        },
        methods:{
            ...mapActions(['getSingleMeetingById','cancelMeetingById']),
                  async cancelMeeting(){
      
              this.$buefy.dialog.confirm({
        title: "Randevu iptal etme işlemi",
        message: `Bu randevuyu <b>iptal etmek</b> istediğinizden emin misiniz? `,
        confirmText: "İptal et",
        cancelText: "Geri",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          let cancelInfo = {
        content: this.cancelInfo,
        meetingId: this.id,
        cancelledAt: Date.now(),
        cancelledBy:this.cancelledBy
      } 
          await this.$store.dispatch("cancelMeetingById", {cancelInfo});
          

          await this.getSingleMeetingById();

          this.$buefy.toast.open({
            message: "Görüşme başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });

      
    

      
    },
           async startMeeting(meeting){
              let a = moment(meeting.startsAt)
              let b = moment(new Date().toISOString())
              
              let timeToMeetings = a.diff(b,'minutes')

              let roomName = meeting.doctorId.meetingRoom

              let doctorName = meeting.doctorId.name + " "+meeting.doctorId.surname

              let url = `https://testten.daily.co/${roomName}`

              let patientIdForMeeting = meeting.patientId.id;
            console.log(patientIdForMeeting,doctorName)
              if(timeToMeetings >= 1000000){
                this.alertCustom()
              } else {
                await this.$store.dispatch("setMeetingUrl", url);
                await this.$store.dispatch("setMeetingDoctorName", doctorName);
                await this.$store.dispatch("setPatientIdForMeeting",patientIdForMeeting);
               /* window.open({path:`/patient/video`}) */
               this.$router.push({path:`/patient/video`})
              }
              
            },
        /*       deleteMeeting(meeting){

           
                this.$buefy.dialog.confirm({
                    title: 'Görüşme silme işlemi',
                    message: 'Bu görüşmeyi <b>silmek</b> istediğinizden emin misiniz?',
                    confirmText: 'Bu görüşmeyi sil',
                    cancelText:'İptal',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                    this.$store.dispatch('deleteMeeting', meeting)
                    this.$store.dispatch('getMeetingsById')
                    
                    this.$buefy.toast.open({
                      message:'Kayıt başarıyla silindi.',
                      type: 'is-success',
                    })}
                })
            
           
        
         
        }, */
            },
        created(){
            this.getSingleMeetingById()
        } 
        
    }
</script>

<style scoped>
.text {
text-align: left;
}

.meeting-detail{
   margin-top: 70px; 
}

@media screen and (max-width: 415px) {
  .start-button{
  margin-left: 0px;
  margin-top: 2px;
  
  }
}
</style>