<template>
    <div class="mt-5 mx-1 mx-6-desktop bottom-margin">
      <hr>
      <div class="is-flex is-flex-direction-column is-align-items-baseline">
        <div class="is-flex is-align-items-baseline">
          <h2 class="has-text-left is-size-4 ml-4 mt-4 mb-3 has-text-weight-bold">Görüşmeler</h2>
         <p @click="showFilters = !showFilters"  v-if="!showFilters" class="is-underlined mb-2 ml-2">Filtrele</p>
        <div class="is-flex ml-2">
          <p @click="showFilters = !showFilters" v-show="showFilters" class="is-underlined mb-2 mr-2">Filtreleri gizle</p>
        <p @click="clearFilters" v-show="showFilters" class="is-underlined mb-2 ml-2">Temizle</p>
        </div>
        </div>
        <b-notification 
            v-if="filteredMeetingsByName && !Object.values(this.filteredMeetingsByName).some((element) => element.startsAt > new Date().toISOString())"
            class="mx-2"
            type="is-warning" 
            aria-close-label="Close notification">
            <p class="has-text-left">Yaklaşan bir görüşmeniz yok gibi görünüyor. Randevu almak ister misiniz?
          <span @click="navigateToAppointment" class="is-underlined"> Evet, randevu almak istiyorum</span>
        </p>
        </b-notification>
       
      <div v-if="showFilters" class="is-flex ml-2">
        <b-field class="ml-4 has-text-left" label="İsme göre" position="left" >
            <b-select v-model="selection" placeholder="İsim seçiniz">
                <option value="Ekin">Ekin</option>
                <option value="Hazal">Hazal</option>
                <option value="new">new</option>
                <option value="Yeni">Yeni</option>
                <option value="Doktor">Doktor</option>
                <option value="ekin">ekin</option>
                
            </b-select>
        </b-field>
      
        <b-field class="ml-1 has-text-left" label="Zamana göre" position="left" >
            <b-select v-model="timelyStatus" placeholder="Zaman seçiniz">
                
                <option value=true>Geçmiş</option>
                <option value=false>Gelecek</option>
            </b-select>
        </b-field>
      </div>
      </div>
      <hr class="my-2">
    
      <div v-if="filteredMeetingsByName ">
        <div v-for="meeting in filteredMeetingsByName" v-bind:key="meeting.id"
      class="box box-radius is-flex is-flex-direction-column meet mb-2 mx-1" 
      >
        
          <div class="is-flex "> 
            <router-link class="has-text-left is-size-5-desktop is-info mb- mr-1 has-text-black" :to="`/patient-meeting/${meeting._id}`">Görüşme #{{meetings.data.indexOf(meeting) + 1}}</router-link>
            <b-tag v-if="meeting.isConfirmed" class="ml-2 is-success is-light">Onaylandı</b-tag>
                <b-tag v-if="!meeting.isConfirmed" class="ml-2 is-warning is-light">Onay bekliyor</b-tag>
            <hr/>
            <b-tag class="is-success" v-if="dayStart < meeting.startsAt && dayEnd > meeting.startsAt">Bugün</b-tag>
            <b-tag class="ml-2 is-warning" v-if="dayStart < meeting.createdAt && dayEnd > meeting.createdAt">Bugün eklediniz</b-tag>
          </div>
           <div class="is-flex is-justify-content-space-between"  >
               <div class="is-flex">
                 <div class="is-flex is-flex-direction-column ">
                   <div class="doctor-name is-flex is-align-items-baseline mb-1">
                   <b-tag class="mr-2">Doktor</b-tag>
                 <div class="is-flex">
                <p class="mr-2">{{meeting.doctorId.name}}</p>
                <p class="mr-2">{{meeting.doctorId.surname}}</p>
                  </div>
               </div>
               <div class="starting-time is-flex is-align-items-baseline mb-1">
               <b-tag class="mr-2">Başlangıç <span class="is-hidden-mobile">saati</span></b-tag>
               <p class="mr-2 is-hidden-mobile">{{meeting.startsAt| moment('Do MMMM YYYY, h:mm a')}}</p>
               <p class="mr-2 is-hidden-desktop is-hidden-tablet has-text-left">{{meeting.startsAt| moment('ll')}}</p>  
               </div>
               <div class="meeting-notes is-flex is-align-items-baseline mb-1" >
               <b-tag class="mr-2">Görüşme Notları</b-tag>
               <p class="is-size-6" v-if="!meeting.patientMeetingNote">-</p>
               <b-button type="is-text" class="is-small" v-if="!meeting.patientMeetingNote">Ekle</b-button>
               <p class="mr-2 has-text-left">{{meeting.patientMeetingNote}} </p>  
               </div>
        
                 </div>
               
               
               </div>
               
               <div>
                 <b-button  @click="startMeeting(meeting)" aria-label="start-meeting" class="button is-small is-info is-medium-tablet is-large-desktop is-info" :disabled="meeting.startsAt < new Date().toISOString() ||  meeting.isCancelled || !meeting.isConfirmed" outlined>
                  
 <span class="button-text">Görüşmeyi başlat</span>

              <span class=" icon is-medium">
                <i class=" fas fa-video"></i>
              </span>
              
            </b-button>
            <div class="has-text-right mt-1">
                 <b-tag v-if="meeting.startsAt < new Date().toISOString() " type="is-danger"> {{meeting.startsAt | moment("from", "now")}} </b-tag>
                 <b-tag v-if="meeting.startsAt > new Date().toISOString()" type="is-success"> {{meeting.startsAt | moment("from", "now")}} </b-tag>
                 <!-- <b-tag v-if="moment(meeting.startsAt).diff(moment(new Date().toISOString()),'days')  < 0"> a</b-tag> --> 
                 
               </div>
          <!--   <div class="is-flex is-justify-content-flex-end mt-1" >
          <b-tooltip label="Silmek için tıklayınız." position="is-left" class="is-info">
             <b-button @click="deleteMeeting(meeting)" aria-label="delete-meeting" v-if="meeting.startsAt > new Date().toISOString()" size="is-small" class="is-rounded"><i class="fas fa-times"></i></b-button>
          </b-tooltip>
          </div> -->
               </div>
            
               
           </div>
                   <b-message 
            v-if="meeting.isCancelled"
            class="mt-2 has-text-left"
            type="is-danger" 
            aria-close-label="Close message">
            Bu görüşme <span v-if="meeting.cancelledBy == 'PATIENT'">sizin tarafınızdan</span> <span v-if="meeting.cancelledBy == 'DOCTOR'"><span class="has-text-weight-bold">doktor</span> tarafından</span>   {{meeting.cancelledAt | moment('Do MMMM YYYY, h:mm a')}} tarihinde iptal edilmiştir.
        </b-message>   
      </div>

      </div>
      <div v-else class="is-flex ">
        <p class="has-text-left">Bu kullanıcıya ait görüşme bulunmamaktadır. </p>
        <button class="button is-large-desktop is-medium-tablet is-info ml-3"
            @click="navigateToAppointment">
              <span class="icon is-medium">
                <i class="far fa-calendar-alt"></i>
              </span>
              <span class="button-text">Randevu Al</span>
            </button>
      </div>
      
      </div>    
</template>

<script>
import moment from 'moment'
import {mapState, mapActions} from 'vuex';
/* import { filter, isNull } from 'lodash'; */
import _ from "lodash";
 export default {
        name: 'PatientMeetings',
        data(){
          return{
          dayStart: moment(new Date().toISOString()).startOf('day').format(),
          dayEnd: moment(new Date().toISOString()).endOf('day').format(),
          disabled: false,
          now: new Date(),
          search:'',
          selection:null,
          id:this.$route.params.id,
          showFilters:false,
          timelyStatus:null,
          outcomeStatus:null,
          
          }
          
  },
  computed:{
            ...mapState(['meetings']),
            filteredMeetingsByName() {
      var doctorName = this.selection;
      const time = this.timelyStatus;
      /* const outcome = this.outcomeStatus; */
      /* console.log("doctorName",doctorName) */
      
      return _.filter(this.meetings.data, function(data) {
        if (_.isNull(doctorName) & _.isNull(time) /* & _.isNull(outcome) */ /* || doctorName=="null" */ ) {
          console.log("here")
          return true;
        } else if(_.isNull(doctorName)){
          return ((data.startsAt < new Date().toISOString()) == (time==='true')) 
        } else if(_.isNull(time)){
          return doctorName == data.doctorId.name ;
        } else {
          
          console.log(("a",doctorName == data.doctorId.name), "b",((data.startsAt < new Date().toISOString()) & (time==='true')))
          return doctorName == data.doctorId.name & ((data.startsAt < new Date().toISOString()) == (time==='true')) ;
        }
      });
    },

          
            
    },
        methods:{
            ...mapActions(['getMeetingsById','setMeetingUrl','setMeetingDoctorName']),
          
            
          clearFilters(){
            this.selection = null,
            this.timelyStatus = null
          },
            
            
            alertCustom() {
                this.$buefy.dialog.alert({
                    title: 'Randevu saati',
                    message: 'Henüz randevu saatiniz gelmedi. Randevunuzdan 5 dakika önce tekrar deneyiniz.',
                    confirmText: 'Tamam'
                })
            },
            deleteMeeting(meeting){

           
                this.$buefy.dialog.confirm({
                    title: 'Görüşme silme işlemi',
                    message: 'Bu görüşmeyi <b>silmek</b> istediğinizden emin misiniz?',
                    confirmText: 'Bu görüşmeyi sil',
                    cancelText:'İptal',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                    this.$store.dispatch('deleteMeeting', meeting)
                    this.$store.dispatch('getMeetingsById')
                    
                    this.$buefy.toast.open({
                      message:'Kayıt başarıyla silindi.',
                      type: 'is-success',
                    })}
                })
            
           
        
         
        },
           async startMeeting(meeting){
              let a = moment(meeting.startsAt)
              let b = moment(new Date().toISOString())
              
              let timeToMeetings = a.diff(b,'minutes')

              let roomName = meeting.doctorId.meetingRoom

              let doctorName = meeting.doctorId.name + " "+meeting.doctorId.surname

              let url = `https://testten.daily.co/${roomName}`

              let patientIdForMeeting = this.id;

              if(timeToMeetings >= 1000000){
                this.alertCustom()
              } else {
                await this.$store.dispatch("setMeetingUrl", url);
                await this.$store.dispatch("setMeetingDoctorName", doctorName);
                await this.$store.dispatch("setPatientIdForMeeting",patientIdForMeeting);
               /* window.open({path:`/patient/video`}) */
               this.$router.push({path:`/patient/video`})
              }
              
            },
            /* startMeeting(meeting){
              let a = moment(meeting.startsAt)
              let b = moment(new Date().toISOString())
              
              let timeToMeetings = a.diff(b,'minutes')

              let roomName = "room-b313"

              let url = `https://testten.daily.co/${roomName}`

              if(timeToMeetings >= 5){
                this.alertCustom()
              } else {
                window.open(url)
              }
              
            }, */
             navigateToAppointment(){
                this.$router.push({path:`/user/patient/${this.id}/appointment1`})
            },
          todaysMeetings(){


          }
        
      
        },
      
         mounted(){
          this.getMeetingsById()
        },
        created(){
            console.log(this.dayStart,this.dayEnd)
            
            
          
      
        }
        
    }
</script>

<style scoped>

.button-tex{
  margin-bottom: 2%;
  color: #0d68ce;
}

.today {
  border: black solid 2px ;
}
</style>