<template>
  <div
    class="a mt-6-desktop mt-5 box-radius is-flex is-flex-direction-column is-flex-wrap-wrap is-align-items-start is-justify-content-space-between "
  >
    <div
      style="width:100%"
      class="a mb-0  box box-radius  is-flex is-align-items-start is-justify-content-space-between mt-2 m-0-mobile pt-0 px-0 "
    >
      <div class="c card-content " style="width:100%">
        <div class="media mb-">
          <div class="media-left" @click="navigateToUploadPhoto">
            <figure class="image is-48x48">
              <img v-if="singleUser.data.photoId" class="is-rounded doctor-image" :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${singleUser.data.photoId}.jpg`"   alt="Placeholder image">
              <v-gravatar
              v-if="!singleUser.data.photoId"
                class="is-rounded"
                v-bind:email="singleUser.data.email"
              />
            </figure>
            
          </div>
          <div class="media-content is-fle is-flex-direction-colum">
            <div class="is-flex is-align-items-baselin">
              <p class="title is-5 is-3-desktop mb-0 mr-1 mb-1-desktop">
                {{ singleUser.data["name"] }} {{ singleUser.data["surname"] }}
              </p>
              <b-tag v-if="singleUser.data.gender=='MALE'">E</b-tag>
              <b-tag v-else-if="singleUser.data.gender=='FEMALE'">K</b-tag>
              <!-- <b-tag rounded  class="is-dark" size="is-small">E</b-tag> -->
            </div>
            <div>
              <p class="subtitle is-6 mb-0 mb-1-desktop">
                {{ singleUser.data.email }}
              </p>
              <div class="is-flex is-align-items-baseline">
                <b-tag class="ml-0 mr-1"
                  >{{ singleUser.data.createdAt | moment("from", "now") }}
                </b-tag>
                <p class="is-size-7">katıldı</p>
              </div>
            </div>
          </div>
          <b-tooltip position="is-left" label="Randevu al" class="is-info ">
            <button
            aria-label="appointment"
            class="button is-large-desktop is-medium-tablet is-info"
            @click="navigateToAppointment"
          >
            <span class="icon is-medium mr-0">
              <i class="far fa-calendar-alt"></i>
            </span>
            <span class="button-text ">Randevu Al</span>
          </button>
          </b-tooltip>
        </div>
        <b-loading
          :is-full-page="isFullPage"
          v-model="isLoading"
          :can-cancel="true"
        >
          <b-icon
            pack="fas"
            icon="sync-alt"
            size="is-large"
            custom-class="fa-spin"
          >
          </b-icon>
        </b-loading>
        <div class="content">
          <div class=" ">
            <div>
             
              <hr />
              <div class="t">
                <div>
                  <div class="is-flex is-justify-content-start test">
                  <div
                    class="mr-4 is-flex is-flex-direction- is-justify-content-space-between is-align-items-baseline is-flex-wrap-wrap bmi"
                  >
                     <b-taglist class="mr-1" attached>
                      <b-tag  type="is-dark" size="is-medium"
                        ><span @click="promptAge"> Yaş</span></b-tag
                      >
                      <b-tag type="is-info is-light" class="mr-1" size="is-medium">
                        <span @click="promptAge" v-if="!singleUser.data.age"
                          >--</span
                        >
                        <span @click="promptAge">{{
                          singleUser.data.age
                        }}</span>
                      </b-tag>
                    </b-taglist>
                     <b-taglist class="mr-1" attached>
                      <b-tag type="is-dark" size="is-medium"
                        ><span @click="promptWeight">Kilo</span>
                      </b-tag>
                      <b-tag type="is-info is-light" class="mr-1" size="is-medium">
                        <span
                          @click="promptWeight"
                          v-if="!singleUser.data.weights.length"
                          >--</span
                        >

                        <span @click="promptWeight" v-else>{{
                          singleUser.data.weights[
                            singleUser.data.weights.length - 1
                          ].weight
                        }}</span>
                      </b-tag>
                    </b-taglist>
                    <b-taglist class="mr-1" attached>
                      <b-tag type="is-dark" size="is-medium"
                        ><span @click="promptHeight">Boy</span>
                      </b-tag>
                      <b-tag type="is-info is-light" class="mr-1" size="is-medium">
                        <span
                          @click="promptHeight"
                          v-if="!singleUser.data.heights.length"
                          >--</span
                        >

                        <span @click="promptHeight" v-else>{{
                          singleUser.data.heights[
                            singleUser.data.heights.length - 1
                          ].height
                        }}</span>
                      </b-tag>
                    </b-taglist> 
                    <b-taglist v-if="singleUser.data.heights.length" attached>
                      <b-tooltip label="Vucut kitle indeksi" class="is-info">
                        
                        <b-tag type="is-dark" size="is-medium">VKİ</b-tag>

                        <b-tag type="is-info " class="mr-1" size="is-medium">
                          <span>{{  bmi }}</span>
                        </b-tag>
                      </b-tooltip>
                    </b-taglist>
                    
                  </div>
                  <!-- <span @click="navigateToPatientBaseInfoEdit" class="icon is-medium" >
                    <i  class="fas fa-edit edit-icon"></i>
                  </span> -->
                  
                  
                </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <TreatmentInfo></TreatmentInfo>
          <hr class="mt-" />
          <PatientGeneralNotes></PatientGeneralNotes>
          <hr class="mt-" />
          <Chronics></Chronics>
          
          
    
        </div>
      </div>
    </div>
    <div
      class="boxes column is-flex is-align-items-flex-start ml-0 mr-1-desktop summar px-0 is-flex-wrap-wrap"
    >
      <PatientMeetingInfo class="is-flex-grow-1 info-box meeting-info mr-2 mb-2"></PatientMeetingInfo>
      <BloodPressureInfo class="is-flex-grow-1 info-box ml-2-desktop mb-2" ></BloodPressureInfo>
      <BloodSugar class="blood-sugar is-flex-grow-1 info-box ml-1-desktop mr-0 mb-2"></BloodSugar>
    </div>

    <!-- <div
      @click="promptNumber"
      class="sticky-button is-flex is-justify-content-center is-align-items-center "
      
    >
      <b-tooltip
        label="Glikoz değeri eklemek için buraya tıklayabilirsiniz"
        position="is-left"
      >
        <b-icon pack="fas" icon="plus" size="is-large is-medium-tablet">
        </b-icon>
      </b-tooltip>
    </div> -->
    <!-- <div
      @click="promptTension"
      class="sticky-button-bp is-flex is-justify-content-center is-align-items-center "
    >
      <b-tooltip
        label="Glikoz değeri eklemek için buraya tıklayabilirsiniz"
        position="is-left"
      >
        <b-icon pack="fas" icon="plus" size="is-large is-medium-tablet">
        </b-icon>
      </b-tooltip>
    </div> -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PatientGeneralNotes from "@/components/PatientGeneralNotes.vue";
import PatientMeetingInfo from "@/components/PatientMeetingInfo.vue";
import BloodSugar from "@/components/BloodSugar.vue";
import BloodPressureInfo from "@/components/BloodPressureInfo.vue";
import Chronics from "@/components/Chronics.vue";
import TreatmentInfo from "@/components/TreatmentInfo.vue";

export default {
  components: {
    PatientGeneralNotes,
    BloodPressureInfo,
    BloodSugar,
    PatientMeetingInfo,
    Chronics,
    TreatmentInfo,
    
  },
  name: "PatientProfileInfo",
  data() {
    return {
      isComponentModalActive: false,
      id: this.$route.params.id,
      isPatientMeetingsOpen: false,
      isLoading: true,
      isFullPage: true,
      isHiddenChronics: false,
      isHiddenTreatment: false,
    };
  },
  computed: {
    ...mapState(["singleUser"]),
    bmi: function() {
      return (
        Number(
          this.singleUser.data.weights[this.singleUser.data.weights.length - 1]
            .weight
        ) /
        (((Number(
          this.singleUser.data.heights[this.singleUser.data.heights.length - 1]
            .height
        ) /
          100) *
          Number(
            this.singleUser.data.heights[
              this.singleUser.data.heights.length - 1
            ].height
          )) /
          100)
      ).toFixed(2);
    },
  },
  methods: {
    ...mapActions(["getSingleUserById"]),
    navigateToNotesEdit() {
      this.$router.push({ path: `/user/patient/${this.id}/info-edit` });
    },
    
    navigateToChronicsEdit() {
      this.$router.push({ path: `/user/patient/${this.id}/chronic-edit` });
    },
    navigateToTreatmentInfo() {
      this.$router.push({ path: `/user/patient/${this.id}/treatment-info` });
    },
    navigateToPatientBaseInfoEdit() {
      this.$router.push({
        path: `/user/${this.id}/patient-base-info-edit`,
      });
    },
    navigateToUploadPhoto(){
              this.$router.push({path:`/patient/${this.$route.params.id}/photo`})
            },
    navigateToAppointment() {
      
      console.log(this.singleUser.data.heights.length)
    
      this.$router.push({ path: `/user/patient/${this.id}/appointment1` });
    },
    navigateToPatientMeetings() {
      this.$router.push({ path: `/user/patient/${this.id}/patient-meetings` });
    },
    navigateToGlycosis() {
      this.$router.push({ path: `/user/${this.id}/read` });
      this.$store.dispatch("getGlycosisDataById");
    },
    navigateToAddReading() {
      this.$router.push({ path: `/user/patient/${this.id}/read-details` });
      //this.$store.dispatch('getGlycosisDataById')
    },
    navigateToBloodPressure() {
      this.$router.push({
        path: `/user/patient/${this.id}/blood-pressure-details`,
      });
      this.$store.dispatch("getBloodPressureDataById");
    },
    navigateToAddBloodPressure() {
      this.$router.push({ path: `/user/patient/${this.id}/blood-pressure` });
      // this.$store.dispatch('getBloodPressureDataById')
    },
   
    promptNumber() {
      this.$buefy.dialog.prompt({
        message: `Glikoz ölçümüm`,
        confirmText: "Glikoz değerini ekle",
        cancelText: "İptal",
        inputAttrs: {
          type: "number",
          placeholder: "Glikoz değerinizi girin",
          confirmText: "Glikoz değerini ekle",
          value: "106",
          min: 18,
          max: 700,
        },
        trapFocus: true,
        onConfirm: (value) => {
          const date = new Date().toISOString();
          const patientId = this.id;
          this.$store.dispatch("addGlycosisReading", {
            value,
            date,
            patientId,
          });
          
          this.$buefy.toast.open(`Eklenen kan şekeri değeriniz: ${value}`)
        },
      });
    },
    promptAge() {
      this.$buefy.dialog.prompt({
        message: `Yaşınızı giriniz`,
        confirmText: "Yaş değerini ekle",
        cancelText: "İptal",
        inputAttrs: {
          type: "number",
          placeholder: "Yaşınızı girin",
          confirmText: "Yaş değerini ekle",
          /* value: "26", */
          min: 18,
          max:120
        },
        trapFocus: true,
        onConfirm: (age) => {
          const patientId = this.id;
          this.$store.dispatch("addPatientAge", {
            age,
            patientId,
          });
        },
      });
    },
    promptWeight() {
      this.$buefy.dialog.prompt({
        message: `Kilonuzu girin`,
        confirmText: "Kilo değerini ekle",
        cancelText: "İptal",
        inputAttrs: {
          type: "number",
          placeholder: "Kilonuzu giriniz",
          confirmText: "Kilo değerini ekle",
          /* value: "70", */
          step:"0.01",
          min: 18,
          max:270
        },
        trapFocus: true,
        onConfirm: (weight) => {
          const date = new Date().toISOString();
          const patientId = this.id;
          this.$store.dispatch("addPatientWeight", { weight, date, patientId });
        },
      });
    },
    promptHeight() {
      this.$buefy.dialog.prompt({

        message: `Boyunuzu giriniz (165 cm gibi)`,
        confirmText: "Boy değerini ekle",
        cancelText: "İptal",
        inputAttrs: {
          type: "number",
          placeholder: "Boyunuzu girin",
          confirmText: "Boy değerini ekle",
          /* value: "170", */
          step:"0.01",
          min: 50,
          max:255
        },
        trapFocus: true,
        onConfirm: (height) => {
          const date = new Date().toISOString();
          const patientId = this.id;
          this.$store.dispatch("addPatientHeight", { height, date, patientId });
        },
      });
    },

    isLoad() {
      if (this.singleUser) {
        this.isLoading = false;
        console.log("load", this.isLoading);
      }
    },
    async a() {
     await this.getSingleUserById();
     await this.isLoad();
    },
  },
  created() {
    this.a();
    
  },
};
</script>

<style lang="scss" scoped>
.box {
  text-align: left;
}

.is-underlined {
  text-decoration: underline;
}

.summar {
  flex-basis: 50%;
}



input {
  border: 1px solid white;
}

.try {
  overflow: auto;
}
.tab-content {
  display: none;
}

@media screen and (max-width: 800px) {
  .button-text {
    display: none;
  }
}

@media screen and (min-width: 711px) {
  .meeting-info{
  width: 30vw;
}
}


@media screen and (max-width: 700px) {
  .summar {
    flex-direction: column;
  }
}

.sticky-button {
  position: -webkit-sticky;
  position: sticky;
  bottom: 5vw;
  left: 85vw;
  height: 5rem;
  width: 5rem;
  border: solid antiquewhite 2px;
  border-radius: 50px;
  background-color: antiquewhite;
}

@media screen and (max-width: 1023px) {
  .sticky-button {
    bottom: 16vw;
    left: 80vw;
  }
}

@media screen and (max-width: 415px) {
  .sticky-button {
    bottom: 20vw;
    left: 75vw;
    
  }
}

.boxes {
  min-width: 100%;
  flex-basis: 50%;
}

/* .info-box{
margin-right: 0px ;
} */


</style>
