<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
    >
      <div class="p-1">
        
        <b-menu>
          <b-menu-list label="Menu" class="is-size-5">
            <b-menu-item icon="user" class="is-size-5" label="Profil" @click="navigateToAdmin"  ></b-menu-item>
           <b-menu-list>
             <b-menu-item label="Doktorlar" class="is-size-5" icon="file-medical" @click="navigateToDoctors">
             </b-menu-item>
          </b-menu-list>
            <b-menu-item icon="tint" class="is-size-5" label="Hastalar" @click="navigateToPatients">
             
            </b-menu-item>
            <b-menu-item icon="tint" class="is-size-5" label="Odalar" @click="navigateToRooms">
             
            </b-menu-item>
            
          </b-menu-list>
          
          <!-- <b-menu-list label="Actions">
            <b-menu-item class="is-size-5" label="Randevu Al" @click="navigateToAppointment" ></b-menu-item>
            <b-menu-item class="is-size-5" label="Dosya Yükle" @click="navigateToFileUpload"></b-menu-item>
            <b-menu-item class="is-size-5" label="Rapor Oluştur"></b-menu-item>
            <b-menu-item class="is-size-5" label="Görüşmeye Git" @click="navigateToPatientMeetings"></b-menu-item>
            <b-menu-item class="is-size-5" label="Çıkış Yap"></b-menu-item>
          </b-menu-list> -->
        </b-menu>
      </div>
    </b-sidebar>
    
    <b-button id="options" icon-left="bars" @click="open = true"></b-button>
  </section>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,
      id:this.$route.params.id
      
    };
  },
  methods:{

      navigateToAdmin(){
                this.$router.push({path:`/admin`})
            },
      navigateToVisits(){
                this.$router.push({path:`/admin/visits`})
            },
      navigateToPatients(){
                this.$router.push({path:`/admin/patients`})
            },
      navigateToRooms(){
                this.$router.push({path:`/admin/rooms`})
            },
      navigateToDoctors(){
                this.$router.push({path:`/admin/doctors`})
            },
      
            },
            computed:{
              
            }
};
</script>

<style>
.p-1 {
  margin-top: 6em;
  padding: 1em;
}
</style>