<template>
    <div class="box meeting-info mr-0 mr-2-desktop  ">
        <div
          class=" is-flex is-justify-content-space-between  is-align-items-baseline"
        >
          <b-tooltip
            label="Son eklediğiniz randevularınızı burada görebilirsiniz. Daha fazla bilgi için Görüşmeler'e tıklayabilirsiniz."
            position="is-right"
            class="is-light"
            multilined
          >
            <h3 class="px-1 has-text-weight-bold is-size-5" @click="navigateToPatientMeetings">
              Görüşmeler 📹
            </h3>
            
            <hr />
          </b-tooltip>
          <!-- <span>
              <i class="fas fa-video"></i>
            </span> -->
        </div>

        <div class="ml-" v-if="!meetings.data.length">
          Henüz bir randevu eklemediniz.
        </div>
        <div
          class="is-flex is-flex-direction-column is-justify-content-space-between"
        >
          <div
            class="mb-3 outcome-status-container"
            v-for="meeting in meetings.data.slice(0, 5)"
            v-bind:key="meeting.id"
          >
         <!--  <div  class="actual-meeting">
                <b-tooltip position="is-right" label="Bu görüşmeye şimdi katılabilirsiniz!" class="is-success ">
                <div class="now is-size-7 ">Şimdi</div>
                
                </b-tooltip>
              </div> -->
          
          <div v-show="meeting.outcomes.length" class="outcome-status">
                <b-tooltip position="is-right" label="Bu görüşme için bir sonuç eklenmiş!" class="is-success ">
                <div class="outcome">a</div>
                
                </b-tooltip>
              </div>
            <!-- <b-collapse
              aria-id="contentIdForA11y2"
              class="panel"
              animation="slide"
              v-model="isPatientMeetingsOpen"
            >
              <template #trigger> -->
            <div class="panel-heading ">
              <router-link
                class="has-text-left has-text-black is-size-6 is-info mb-2 mr-3"
                :to="`/patient-meeting/${meeting._id}`"
                >#{{ meetings.data.indexOf(meeting) + 1 }} -
              
              <strong>{{
                meeting.startsAt | moment("Do MMMM YYYY, h:mm a")
              }}</strong> 
              
              </router-link>
              <b-tag v-if="meeting.isConfirmed" class="ml-2 is-success is-light">Onaylandı</b-tag>
              <!-- <b-tag v-if="!meeting.isConfirmed" class="ml-2 is-warning is-light">Onay bekliyor</b-tag> -->
              <b-tag v-if="meeting.isCancelled" class="is-danger is-light ml-2">İptal edildi</b-tag>
              
                <b-tag v-if="!meeting.isConfirmed && meeting.startsAt > new Date().toISOString()" class="ml-2 is-warning is-light">Onay bekliyor</b-tag>
                <b-tag v-if="!meeting.isConfirmed && meeting.startsAt < new Date().toISOString()" class="ml-2 is-danger is-light">Onaylanmadı</b-tag>
              <!-- <b-tag  class="is-success is-light lamp">Yaklaşıyor</b-tag> -->
            </div>
            
            <!--  </template>
              <p class="mx-5">
                {{ meeting.doctorId.name }} {{ meeting.doctorId.surname }}
              </p>
            </b-collapse> -->
          </div>
          
        </div>
        <div class="is-flex is-justify-content-space-between">
          <div>
            <a
              class="has-text-black is-underlined"
              @click="navigateToAppointment"
              >Ekle</a
            >
          </div>
          <div>
            <a class="has-text-black" @click="navigateToPatientMeetings"
              >Daha fazla</a
            >
          </div>
        </div>
    </div>

</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: 'BloodSugar',
  data(){
     return {

        id: this.$route.params.id,
        isHidden: false
     } 
  },
  computed:{
            ...mapState(['meetings'])
        },
        methods:{
            ...mapActions(['getMeetingsById',]),
             navigateToAppointment() {
      this.$router.push({ path: `/user/patient/${this.id}/appointment1` });
    },
    navigateToPatientMeetings() {
      this.$router.push({ path: `/user/patient/${this.id}/patient-meetings` });
    },
    
        },
        mounted(){
          this.getMeetingsById()
        },
        created(){
            //this.getGeneralNotesById()
        }
};
</script>

<style scoped >

.outcome-status{
   position: absolute;
  bottom: 66%;
  left: 5px;
  height: 1rem;
  width: 1rem;
  border: solid antiquewhite 2px;
  border-radius: 50px;
  background-color: #48c774;
}



.actual-meeting{
   position: absolute;
  bottom: 66%;
  left: 89%;
  height: 1rem;
  width: 1rem;
  /* border: solid antiquewhite 2px; */
  /* border-radius: 50px; */
  /* background-color: #48c774; */
}

/* @media screen and (max-width: 415px) {
  .actual-meeting {
    position: absolute;
  bottom: 26%;
  left: 82%;
  height: 0,8rem;
  width: 1rem;
  border: solid antiquewhite 2px;
  border-radius: 50px;
  }
  
} */



@media screen and (max-width: 415px) {
  .actual-meeting {
    position: absolute;
  bottom: 24%;
  left: 85%;
  height: 0,8rem;
  width: 1rem;
  /* border: solid antiquewhite 2px; */
  /* border-radius: 50px; */
  }
  
}

@media screen and (max-width: 415px) {
  .meeting-info {
   min-width: 95vw;
  }
  
}

@media screen and (max-width: 415px) {
  .now {
  color: white;
  padding: 0 2px;
  background: #48c774;
  height: 0,1rem;
  width: 0,1rem;
  /* border: solid antiquewhite 2px; */
  border-radius: 25px;
  }
  
}
.outcome-status-container{
  position: relative;
}

.outcome{
  /* position: absolute;
  bottom: 45px;
  left: 5px;
  height: 1px;
  width: 1px;
  background-color: #f14668; */
  visibility: hidden;
}


</style>
