<template>
   
   <div class="d pt-0 mb-2 box box-radius is-align-content-flex-start is-justify-content-space-between is-shadowless-desktop">
     
  <div class="card-content  ">
    <div class="media">
      <div class="media-left">

        <figure rounded @click="navigateToUploadPhoto" class="image is-64x64 is-1by1">
          <!-- <img class="is-rounded doctor-image" :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${singleUser.data.photoId}.jpg`"   alt="Placeholder image"> -->
        <img class="doctor-image is-rounded " v-if="!singleUser.data.photoId && singleUser.data.gender == 'FEMALE'" src="@/assets/female-doctor-avatar.png" alt="Placeholder image">
       <img class="doctor-image is-rounded " v-else-if="!singleUser.data.photoId && singleUser.data.gender == 'MALE'" src="@/assets/male-doctor-avatar.png" alt="Placeholder image">
        <img class="doctor-image is-rounded  " v-else :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${singleUser.data.photoId}.jpg`" alt="Placeholder image">
          <i class="image-icon fas fa-edit edit-icon"></i>
        </figure>
        <!-- <img class="image-edge" src="https://bulma.io/images/placeholders/128x128.png" alt="">  -->
      </div>
      <div class="media-content">
        <div class="is-flex">
          <p class="title is-4 mr-1 mb-0">{{singleUser.data.name}} {{singleUser.data.surname}}</p>
        <b-tag>DR</b-tag>
        </div>
        <div class="is-flex is-justify-content-space-between">
          <div>
           <p class="has-text-weight-light"> {{singleUser.data.email}} </p>
           <p class="has-text-weight-light"> <b-tag>{{singleUser.data.createdAt| moment("from", "now")}} </b-tag> katıldı </p>
          
          
          </div> 
          
        </div>
       
        
      </div>
      
    </div>

    <div class="content">
        
        <!-- <PhotoUpload></PhotoUpload>   -->
       <hr>
       <div class="is-flex is-flex-direction-column">
       <div class="is-flex is-justify-content-space-between is-align-items-baseline">
         <h3 class="is-size-5 text-pointer" @click="navigateToAppointmentPreferences">Randevu Tercihleri</h3>
         <b-tooltip label="Tüm randevu tercihlerinizi bu butona tıklayarak düzenleyebilirsiniz."
            position="is-bottom" class="is-info">
          <!-- <b-button  type="is-info"   outlined>
               <span class="icon is-medium">
                <i class="fas fa-edit"></i>
              </span>
              <span class="button-text">Düzenle</span> 
              </b-button> -->
              <span class="icon is-medium " @click="navigateToAppointmentPreferences">
                <i class="fas fa-edit edit-icon"></i>
              </span>
           </b-tooltip>        
       </div>
       <div class="is-flex is-justify-content-space-between">
           
       <div >
           <h4 class="is-size-6 text-pointer" @click="navigateToAppointmentPreferences">Randevu kabul edilen günler</h4>
           <p v-if="!singleUser.data.doctorAppointmentDays.length" class="has-text-black has-text-left">Henüz randevu kabul ettiğiniz günleri düzenlemediniz.</p>
         <div class="is-flex is-align-items-flex-start is-flex-wrap-wrap">
          <div v-for="day in appointmentDays" v-bind:key="day.id" class="days "> 
           <b-tag type="is-info" class="mr-1 mb-1" size="is-small">{{day}}</b-tag>
           
         </div>
         </div>
            <!-- <hr> -->
        <h3 class="is-size-6 text-pointer" @click="navigateToAppointmentPreferences">Randevu süresi</h3>
        <b-tag type="is-info" size="is-small">{{singleUser.data.doctorMeetingDuration}} dk</b-tag>
        <h3 class="is-size-6 text-pointer" @click="navigateToAppointmentPreferences">Randevu durumu</h3>
        <b-tag type="is-info" v-if="singleUser.data.doctorAvailable" size="is-success">Aktif</b-tag>
        <b-tag type="is-info" v-if="!singleUser.data.doctorAvailable" size="is-danger">Pasif</b-tag>
       </div>
         
       </div>
       </div>
        <hr>
        <!-- <div>
          <div class="is-flex is-justify-content-space-between is-align-items-baseline">
            <h3>Genel Notlar</h3>
          </div>
          
          <div class="is-flex is-justify-content-space-between">
            <div class="is-flex is-justify-content-space-between">
            <p class="is-size-6">Henüz not eklemediniz.</p>
            <p class="ml-3 is-underlined" v-if="!addNote" @click="addNote = !addNote">Ekle</p>
            <p class="ml-3 is-underlined" v-if="addNote" @click="addNote = !addNote">Gizle</p>
          
          </div> 
          
          </div> 
          <div  class="is-flex is-justify-content-space-between ">
           <b-input v-show="addNote" class="must-known-input"></b-input>
           <b-button  class="ml-2" v-show="addNote">Ekle</b-button>
           
           
         </div> 
        </div> -->
        
        <div>
          <div class="is-flex is-justify-content-space-between is-align-items-baseline">
            <div class="is-flex">
              <h3 class="mr-2 is-size-5">Beni tanıtırken göster</h3> 
              
            <b-button
                label="Beni göster"
                type="is-info"
                size="is-small"
                @click="isCardModalActive = true" />
            </div>
          <!--   <p class="ml-3 is-underlined" v-if="!aboutMe" @click="aboutMe = !aboutMe">Ekle</p>
          
           <p class="ml-3 is-underlined" v-if="aboutMe" @click="aboutMe = !aboutMe">Gizle</p> -->
          
          </div>
          <p class="has-text-left is-underlined">Hakkınızda tanıtıcı metinler ekleyebilirsiniz.</p>
         <div class="is-flex is-justify-content-space-between">
           <div class="is-flex is-align-items-baseline" v-if="!singleUser.data.doctorAbout.length">
           <p class="is-size-6 mb-2" >Henüz ekleme yapmadınız.</p>
           <div>
          <!-- <p  class="has-text-left">Henüz bir ekleme yapmadınız.</p> -->
           <p class="ml-3 is-underlined is-size-7" v-if="!aboutMe" @click="aboutMe = !aboutMe">Ekle</p>
           <p class="ml-3 is-underlined is-size-7" v-if="aboutMe" @click="aboutMe = !aboutMe">Gizle</p>
        </div>
           
           
        </div> 
        
         </div>
        
           <div  class="is-flex is-justify-content-space-between mt-2 ">
            
           <b-input v-show="aboutMe" v-model="textAboutMe" maxlength="140" class="must-known-input"></b-input>
           <b-button class="ml-2" v-show="aboutMe" @click="addAboutMe"> Metni ekle</b-button>
         </div>
        <div class="is-flex is-align-items-baseline">
          <p v-show="aboutMe" class="mt-1 mr-2 ">Eklenecek metin: </p>
        <p  class="multiline-text">{{textAboutMe}}</p>
        </div>
        
        <div class="is-flex is-align-items-baseline">
          <div class="is-flex is-flex-direction-column " v-if="singleUser.data.doctorAbout.length">
            <!-- <p class="has-text-left">Eklediğim metinler:</p> -->
            <div v-for="o in singleUser.data.doctorAbout" v-bind:key="o.id" class="">
            <div class="is-flex is-align-items-baseline">
              <p class="mb-2 mr-2 is-info is-light is-small multiline-text" >{{o.text}}</p>
              <!-- <p @click="deleteTextAboutDoctor(o)">sil</p> -->
              
              <b-button @click="deleteTextAboutDoctor(o)" size="is-small" class="delete-button-about p-2"
                ><i class="fas fa-times"></i
              ></b-button>
            </div>
            
            
        </div>
        
        </div>
        <div v-if="singleUser.data.doctorAbout.length">
          <!-- <p  class="has-text-left">Henüz bir ekleme yapmadınız.</p> -->
           <p class="ml-3 is-underlined is-size-7" v-if="!aboutMe" @click="aboutMe = !aboutMe">Ekle</p>
           <p class="ml-3 is-underlined is-size-7" v-if="aboutMe" @click="aboutMe = !aboutMe">Gizle</p>
        </div>
        </div>
        </div>
                    
  
 <section>
        <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
            <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3 mx-0">
                       <img class="doctor-image" :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${singleUser.data.photoId}.jpg`" alt="Placeholder image">
                    </figure>
                </div>
                <div class="card-content">
                    <div class="media">
                        <div class="media-left">
                            <figure class="image is-48x48">
                                <img class="doctor-image" :src="`https://res.cloudinary.com/dpmt4nuxe/image/upload/v1654244134/${singleUser.data.photoId}.jpg`" alt="Placeholder image">
                            </figure>
                        </div>
                        <div class="media-content">
                            <p class="title is-4">{{singleUser.data.name}} {{singleUser.data.surname}}</p>
                            <p class="subtitle is-6"></p>
                        </div>
                    </div>

                    <!-- <div class="content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Phasellus nec iaculis mauris. <a>@bulmaio</a>.
                        <a>#css</a> <a>#responsive</a>
                        <br>
                        <small>11:09 PM - 1 Jan 2016</small>
                    </div> -->
                     <div class="content  is-flex is-flex-direction-column">
                
                <div class="is-flex is-justify-content-flex-start">
                    <b-tag style="width:58%" class="mt-2 mr-2 is-warning">Endokrinoloji</b-tag>
                    <b-tag style="width:20%" class="mt-2  mr-2 is-small is-info">{{singleUser.data.doctorMeetingDuration}} dk</b-tag>
                <b-tag style="width:20%" class="mt-2 is-small is-info"> DR</b-tag>
                </div>
                <div class="is-flex is-flex-direction-column">
                <div v-for="text in singleUser.data.doctorAbout" v-bind:key="text.id" class="is-flex" >
                    
                        <p  class="mt-2 mr-2 multiline-text "> {{text.text}}</p>
                    </div>
                    
                </div>
            
            <b-button outlined class="is-info is-small mt-2">Randevu al</b-button>
            </div>
            
                </div>
            </div>
        </b-modal>
    </section>
    </div>
  </div>

    </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  
   export default {
        components:{
              
          },
        name: 'DoctorProfileInfo',
        data(){
         return {
        isChronicEditModalActive: false,
        isCardModalActive: false,
        id: this.$route.params.id,
        aboutMe: false,
        addNote: false,
        textAboutMe:"",
                daysOfWeek:{
   "Pazartesi": 1,
  "Salı": 2,
  "Çarşamba": 3,
  "Perşembe": 4,
  "Cuma": 5,
  "Cumartesi": 6,
  "Pazar": 0
},
    /* showSnackbar:false */
      
        }},
        
        computed:{
            ...mapState(['singleUser']),
            appointmentDays(){
              var keys = Object.keys(this.daysOfWeek).filter(key => this.singleUser.data.doctorAppointmentDays.includes(this.daysOfWeek[key]));
              console.log(keys)
              return keys
            },
            showSnackbar(){
               var test = this.singleUser.data.doctorAppointmentDays.length == 0 || !this.singleUser.data.doctorAvailable || this.singleUser.data.photoId.length == 0;
              console.log("test",test, !this.singleUser.data.doctorAvailable )
              return test;
              
            }
        },
        methods:{
            ...mapActions(['getSingleUserById']),
             snackbar(){
               if(this.showSnackbar){
                this.$buefy.snackbar.open({
                  duration:10000,
                  message:`Merhaba, hastaların sizden randevu alabilmesi için lütfen, <br><li>randevu durumunuzu AKTİF olarak seçiniz</li></br> <br><li>randevu kabul ettiğiniz günleri seçiniz</li></br> <br><li> 'Oda edin' butonuna basarak bir oda edininiz </li></br>    `
                })
            }},
            
            
            navigateToAppointmentPreferences(){
              this.$router.push({path:`/doctor/${this.$route.params.id}/appointment-preferences-edit`})
            },
            navigateToUploadPhoto(){
              this.$router.push({path:`/doctor/${this.$route.params.id}/photo`})
            },
            
            handleMustKnown(){
              this.mustKnown = false;
            },
            handleAddNote(){
              this.addNote = false;
            },
            addAboutMe(){
              const text = this.textAboutMe.trim();
        const doctorId = this.id;
        const date = new Date();
              if(text == 0){
                alert("bir metin giriniz.")
              } else {
                
                 this.$store.dispatch("addTextAboutDoctor", {date, doctorId, text})
                this.textAboutMe = "";
                this.aboutMe=true;
              }
              
            },
            deleteTextAboutDoctor(o) {
        const index = this.singleUser.data.doctorAbout.indexOf(o)
        console.log("text",index)
        /* const meetingId = this.id */
     this.$buefy.dialog.confirm({
        title: "Metin silme işlemi",
        message: `Bu metni <b>silmek</b> istediğinizden emin misiniz? `,
        confirmText: "Metni sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
        
          await this.$store.dispatch("deleteDoctorAbout", {index});

          

          this.$buefy.toast.open({
            message: "Metin başarıyla silindi.",
            type: "is-success",
          }); 
        },
        
      });
    
    },
            
     async setDoctorId(){
      let doctorId = this.id
      await this.$store.dispatch("setDoctorId", doctorId);
      

    }
            
        },
        created(){
            this.getSingleUserById();
            this.setDoctorId();
            this.snackbar();
            
        }
        
       
        
        
    }
</script>

<style lang="scss" scoped>

.box {
    text-align:left;
    box-sizing: border-box;

}

input {
  border: 1px solid white;
}

.d {
  padding-left: 0px;
  padding-right: 0px;
  /* backdrop-filter: blur(100px); */
}


@media screen and (max-width: 1000px) {
    .button-text {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .d {
        margin-bottom:0px;
    }
}


.edit-icon {
  color: #167cf0;
}

    .doctor-image {
        opacity: 1;
        filter: alpha(opacity=40);
    }
    
.image-icon{
position: absolute;
bottom: 2vw;
left: 2vw;
z-index:2;
display: none;
}

    .doctor-image:hover {
        opacity: 0.5;
        filter: alpha(opacity=100);
        .image-icon {

display: visible;

}
    }



/* .image-icon:hover {

display: visible;

} */



.image-edge {
  border-radius:50%;
  color: #167cf0;
  width: 15px;
  height: 15px;
}


.delete-button-about {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
</style>