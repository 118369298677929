<template>
    <div class="mt-6">
    <div class="box patients-box mx-">
      <div class="a is-flex is-align-items-start is-justify-content-space-between">
          <h2 class="has-text-left is-size-4 my-2 ">Hastalar</h2>
          <!-- <b-button class="is-info is-small-mobile is-medium-tablet"  @click="a()">Hastaları Getir</b-button> -->
      </div>
      <hr>
       <p class="has-text-left" v-if="!doctorMeetings.data.length"> Henüz hasta tanımlaması yapılmamıştır.</p>
     
      <div v-for="(record,index) in t" v-bind:key="record.id + '_' + index"
      id="patient" class="box box-radius is-flex is-align-items-baseli is-justify-content-space-between mb-2" 
      > 
            <div class="patient-info ">
                <div class="is-flex is-align-items-start is-justify-content-space-between">
                <div class="media-left">
                 <figure class="image is-48x48">
                    <v-gravatar class="is-rounded" v-bind:email="record.patientId.email" /> 
                 </figure>
                </div>
            <div @click="navigateToPatientDetails(index)" class="is-flex">
               <p class="text-pointer">{{record.patientId.name}}</p>
               <p class="ml-1 text-pointer">{{record.patientId.surname}}</p>
            </div>
            </div>   
            </div>
           <!-- <div class="actions">
                <b-button class="is-info" >
                    <span class="icon is-medium">
                        <i class="far fa-calendar-alt"></i>
                    </span> 
              </b-button>
            </div> -->
            <div>
              
            </div>
               <div class="actions">
             <!--    <b-tooltip position="is-left" class="is-info" label="Randevu ekle">
                <b-button class="is-info" >
                    <span class="icon is-medium">
                        <i class="far fa-calendar-alt"></i>
                    </span> 
              </b-button>
              </b-tooltip> -->
              <b-tooltip position="is-left" class="is-info" label="Doz bilgisi ekle/güncelle">
              <b-button @click="navigateToSetDose(index)" class="ml-2 is-light" >
                    <span class="icon is-small">
                        <i class="fas fa-syringe"></i>
                    </span> 
              </b-button>
              </b-tooltip>
              <b-tooltip position="is-left" class="is-info" label="Kan şekeri değerlerini gör">
              <b-button @click="navigateToPatientBloodSugarReadings(index)" class="ml-2 is-light" >
                    <span class="icon is-small">
                        <i class="fa fa-tint"></i>
                    </span> 
              </b-button>
              </b-tooltip>
            </div>
           
      </div>
      
 
    
      
    </div>
    
    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';
import _ from 'lodash';
 export default {
        name: 'DoctorMeetings',
        data(){
          return{
          t:[]
          }
          
  },
  
  computed:{
            ...mapState(['doctorMeetings']),
        
            doctorFullName: function() {
            return this.doctorMeetings.data[0].doctorId.name + ' ' + this.doctorMeetings.data[0].doctorId.surname;
        },
           patientFullName: function() {
            return this.doctorMeetings.data[0].patientId.name + ' ' + this.doctorMeetings.data[0].patientId.surname;
        },
        },
        methods:{
            ...mapActions(['getMeetingsByDoctorId']),
            
            navigateToPatientDetails(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}`})
            
            },
            
            async navigateToSetDose(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}/set-dose`});
               await this.$store.dispatch("setMeetingDoctorName", this.doctorFullName);
                await this.$store.dispatch("setPatientName", this.patientFullName);
            
            },
            navigateToPatientBloodSugarReadings(index){
                const pid = this.t[index].patientS
                
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}/reading`})
            
            },
         
            navigateToPatients(){
                
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patients`})
            
            },
            setPatients(){
                this.t = _.uniqBy(this.doctorMeetings.data, "patientS")
                
                
            },
            async a(){
                await this.getMeetingsByDoctorId();
                await this.setPatients()
            }
      
        },
        /* computed:{
             a(){
                const v = _.uniqBy(this.doctorMeetings.data, "patientS")
                console.log("v",v)
                this.t =v
            },

        },
         */
        created(){
            
            this.a()
        
        }
        
    }
</script>

<style scoped>

</style>