<template>
   <div class="mt-6">
        <div class="box mt-6 mx-0 px-4">
          <div class="is-flex is-align-items-baseline">
            <h2 class="has-text-left is-size-4 my-2">Görüşmeler</h2>
        <p @click="showFilters = !showFilters"  v-if="!showFilters" class="is-underlined has-text-left mb-2 ml-2">Filtrele</p>
        <div class="is-flex-direction-column">
          <div class="is-flex is-align-items-baseline ml-">
          <p @click="showFilters = !showFilters" v-show="showFilters" class="is-underlined mb-2 mr-2 ml-2">Filtreleri gizle</p>
        <p @click="clearFilters" v-show="showFilters" class="is-underlined mb-2 ml-2 is-size-7">Filtreleri temizle</p>
        </div>
      
      
        </div>
          </div>
          <div v-if="showFilters" class="is-flex is-align-items-baseline ml-">
        <b-field class="ml-1 has-text-left mb-2" label="Zamana göre" position="left" >
            <b-select v-model="timelyStatus" placeholder="Zaman seçiniz">
                
                <option value=true>Geçmiş</option>
                <option value=false>Gelecek</option>
            </b-select>
        </b-field>
        <!-- <div class="is-flex is-flex-direction-column is-justify-content-  ml-2 ">
            <p  class="mr-2 has-text-left mb-2">Filtrele</p>
            <b-input placeholder="Arama terimi giriniz" v-model="searchTerm"></b-input>
            
            
          </div> -->
      </div>
      <hr>
      <!--  <p class="has-text-left" v-if="filteredMeetingsByTimelyStatus && !Object.values(this.filteredMeetingsByTimelyStatus).some((element) => element.startsAt > new Date().toISOString())"> Bu kullanıcıya ait görüşme bulunmamaktadır.</p> -->
      
      <div v-for="meeting in filteredMeetingsByTimelyStatus" v-bind:key="meeting.id"
      class="box box-radius outcome-status-container mb-2 is-flex is-flex-direction-column is-align-items-sta" 
      >
       <div v-show="!meeting.outcomes.length & meeting.startsAt < new Date().toISOString()"  class="outcome-status">
                <b-tooltip position="is-right" label="Bu görüşme için sonuç girilmemiş!" class="is-danger ">
                <div class="outcome">a</div>
                </b-tooltip>
              </div>
            <div class="m is-flex is-align-items-start is-justify-content-space-between">
               <div class="is-flex is-align-content-baseline ">
                 <div class="is-flex mr-4">
                <router-link class="has-text-black has-text-left" :to="`/doctor-meeting/${meeting._id}`">
                    
                    <span class=" is-size-5">{{meeting.patientId.name}}</span>
                    <span class="ml-1 is-size-5">{{meeting.patientId.surname}}</span>
               
                </router-link>
               
            </div>
            <div class="is-flex is-flex-direction-column mr-2">
                <b-tag v-if="meeting.startsAt > new Date().toISOString()" class="is-success is-small">{{meeting.startsAt| moment('from','now')}}</b-tag>
                <b-tag v-else class="is-danger is-small mr-2">{{meeting.startsAt| moment('from','now')}}</b-tag>
               <p class="mr-2 has-text-left is-size-7 is-hidden-mobile">{{meeting.startsAt| moment('Do MMMM YYYY, h:mm a')}}</p>
               <p class="mr-2  has-text-left is-size-7 is-hidden-desktop is-hidden-tablet">{{meeting.startsAt| moment('ll')}}</p>  
                
            </div>
               </div>
               <div class="is-flex is-flex-direction-column">
                 <b-button :disabled="meeting.isCancelled" @click="startMeeting(meeting)" class="button is-small is-medium-tablet is-large-desktop is-info start-meeting" v-if="meeting.startsAt > new Date().toISOString()" outlined>
                       
 <span class="button-text"> Başlat</span>

              <span class="icon is-medium">
                <i class="fas fa-video"></i>
              </span>
              <!-- <span class="button-text">Görüşmeyi başlat</span> -->
            </b-button>
            
            <b-button v-if="meeting.startsAt > new Date().toISOString()" class="is-success mt-2 is-small"><router-link class="has-text-white" :to="`/doctor-meeting/${meeting._id}`">Detaylar</router-link> </b-button>
            <b-button v-else  class="is-info is-small"><router-link class="has-text-white" :to="`/doctor-meeting/${meeting._id}`">Detaylar</router-link>  </b-button>
               </div>
               
            </div>
      <!-- <b-tag v-if="meeting.isCancelled" class="has-text-left is-danger mt-2">Bu görüşme iptal edildi.</b-tag>    -->
       <b-message 
            v-if="meeting.isCancelled"
            class="mt-2 has-text-left"
            type="is-danger" 
            aria-close-label="Close message">
            Bu görüşme <span v-if="meeting.cancelledBy == 'DOCTOR'">sizin tarafınızdan</span> <span v-if="meeting.cancelledBy == 'PATIENT'"><span class="has-text-weight-bold">hasta</span> tarafından</span>  {{meeting.cancelledAt | moment('Do MMMM YYYY, h:mm a')}} tarihinde iptal edilmiştir.
        </b-message>   
        <b-message 
            v-if="!meeting.isConfirmed & meeting.startsAt > new Date().toISOString()"
            class="mt-2 has-text-left"
            type="is-warning" 
            aria-close-label="Close message">
            Bu görüşme onay bekliyor.
        </b-message>   
        <b-message 
            v-if="meeting.isConfirmed & meeting.startsAt > new Date().toISOString()"
            class="mt-2 has-text-left"
            type="is-success" 
            aria-close-label="Close message">
            Bu görüşmeyi onayladınız.
        </b-message>   
      </div>
     
      
    </div>
    
   </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';
import moment from "moment";
import filter from "lodash/filter";
import isNull from "lodash/isNull";
 export default {
        name: 'DoctorMeetings',
        data(){
          return{
          id:this.$route.params.id,
          showFilters:false,
          timelyStatus:null,
          outcomeStatus:null,
          searchTerm:"",
          }
          
  },
  computed:{
            ...mapState(['doctorMeetings']),
            filteredMeetingsByTimelyStatus() {
      /* var doctorName = this.selection; */
      const time = this.timelyStatus;
      
      return filter(this.doctorMeetings.data, function(data) {
        if (isNull(time) ) {
          return true;
        } else {
          return ((data.startsAt < new Date().toISOString()) == (time==='true')) 
        }
        })},
        /* filteredDoctorMeetingsList() {
       
          return filter(meeting => {
        return meeting.patientId.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
        
        } */
    },
      
        methods:{
            ...mapActions(['getMeetingsByDoctorId']),
            clearFilters(){
            /* this.selection = null, */
            this.timelyStatus = null
          },
            
           async startMeeting(meeting){
              let a = moment(meeting.startsAt)
              let b = moment(new Date().toISOString())
              
              let timeToMeetings = a.diff(b,'minutes')

              let roomName = meeting.doctorId.meetingRoom
              let patientName = meeting.patientId.name + ' ' + meeting.patientId.surname 
              let doctorIdForMeeting = this.id;
              let url = `https://testten.daily.co/${roomName}`

              if(timeToMeetings >= 1000000){
                this.alertCustom()
              } else {
                await this.$store.dispatch("setMeetingUrl", url);
                await this.$store.dispatch("setPatientName", patientName);
                await this.$store.dispatch("setDoctorIdForMeeting",doctorIdForMeeting);
                this.$router.push({path:'/doctor/video'})
              }
              
            },
         
      
        },
        created(){
            this.getMeetingsByDoctorId()

        }
        
    }
</script>

<style scoped>

.buttton-text{
  word-break: break-word;
}

.start-meeting{
  max-width: 20vw;
}
</style>