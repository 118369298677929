<template>
    <div class="box is-shadowless-mobile mx-4-desktop px-4 pb-2 mb-2">
      <div class="a is-flex is-align-items-start is-justify-content-space-between">
          <h2 class="has-text-left is-size-4 text-pointer" @click="navigateToPatients">Hastalar</h2>
          <!-- <b-button class="is-info is-small-mobile is-medium-tablet"  @click="a()">Hastaları Getir</b-button> -->
      </div>
      <hr class="mt-1 mb-4">
       <p class="has-text-left" v-if="!doctorMeetings.data.length"> Henüz hasta tanımlaması yapılmamıştır.</p>
     
      <div v-for="(record,index) in t.slice(0, 5)" v-bind:key="record.id + '_' + index"
      id="patient" class=" box box-radius is-flex is-align-items-baseli is-justify-content-space-between mb-2 py-3" 
      > 
            <div class="patient-info ">
                <div class="is-flex is-align-items-center is-justify-content-space-around">
                <div class="media-left">
                 <figure class="image is-48x48">
                    <v-gravatar class="is-rounded" v-bind:email="record.patientId.email" /> 
                 </figure>
                </div>
            <div @click="navigateToPatientDetails(index)" class="is-flex">
               <p class="text-pointer has-text-weight-light">{{record.patientId.name}}</p>
               <p class="ml-1 text-pointer has-text-weight-light">{{record.patientId.surname}}</p>
            </div>
            <!-- <div v-show="$route.meta.patientDetailShow" class="ml-4">
                <b-tooltip label="Son glikoz değeri ölçümü - mg/dL">
                    <b-tag>{{record.patientId.glycosisReadings[record.patientId.glycosisReadings.length - 1].value}}</b-tag>
                </b-tooltip>
                <span><i class="fa-solid fa-heart"></i></span>
                <b-tooltip label="Son SKB ölçümü - mmHg">
                    <b-tag class="ml-2">{{record.patientId.bpReadings[record.patientId.bpReadings.length - 1].systolic}}</b-tag>
                </b-tooltip>
                <b-tooltip label="Son DKB  ölçümü - mmHg">
                    <b-tag class="ml-1">{{record.patientId.bpReadings[record.patientId.bpReadings.length - 1].diastolic}}</b-tag>
                </b-tooltip>
            </div> -->
            </div>   
            </div>
           
               <div class="actions">
             <!--    <b-tooltip position="is-left" class="is-info" label="Randevu ekle">
                <b-button class="is-info" >
                    <span class="icon is-medium">
                        <i class="far fa-calendar-alt"></i>
                    </span> 
              </b-button>
              </b-tooltip> -->
              <!-- <b-tooltip position="is-left" class="is-info" label="İlaç bilgisi ekle/güncelle">
              <b-button @click="navigateToSetMedicine(index)" class="ml-2 is-light" >
                    <span class="icon is-small">
                        <i class="fas fa-pills"></i>
                    </span> 
              </b-button>
              </b-tooltip> -->
              <b-tooltip position="is-left" class="is-info" label="Doz bilgisi ekle/güncelle">
              <b-button @click="navigateToSetDose(index)" class="ml-2 is-light" >
                    <span class="icon is-small">
                        <i class="fas fa-syringe"></i>
                        ⇵
                    </span> 
              </b-button>
              </b-tooltip>
              <b-tooltip position="is-left" class="is-info" label="Kan şekeri değerlerini gör">
              <b-button @click="navigateToPatientBloodSugarReadings(index)" class="ml-2 is-light" >
                    <span class="icon is-small">
                        <i class="fa fa-tint"></i>
                    </span> 
              </b-button>
              </b-tooltip>
            </div>
           
           
      </div>
      
 
    
      <p v-if="doctorMeetings.data.length" @click="navigateToPatients()" class="is-size-7 mt-4 has-text-right">Daha fazla göster</p>
    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';
import _ from 'lodash';
 export default {
        name: 'DoctorMeetings',
        data(){
          return{
          t:[]
          }
          
  },
  
  computed:{
            ...mapState(['doctorMeetings']),
            doctorFullName: function() {
            return this.doctorMeetings.data[0].doctorId.name + ' ' + this.doctorMeetings.data[0].doctorId.surname;
        },
           patientFullName: function() {
            return this.doctorMeetings.data[0].patientId.name + ' ' + this.doctorMeetings.data[0].patientId.surname;
        },
        },
        methods:{
            ...mapActions(['getMeetingsByDoctorId','setMeetingDoctorName']),
            
            navigateToPatientDetails(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}`})
            
            },
            async navigateToSetDose(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}/set-dose`})
                await this.$store.dispatch("setMeetingDoctorName", this.doctorFullName);
                await this.$store.dispatch("setPatientName", this.patientFullName);
            },
            async navigateToSetMedicine(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}/set-medicine`})
                await this.$store.dispatch("setMeetingDoctorName", this.doctorFullName);
                await this.$store.dispatch("setPatientName", this.patientFullName);
            },
            navigateToPatientBloodSugarReadings(index){
                const pid = this.t[index].patientS
                
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}/reading`})
            
            },
            /* navigateToPatientDetails(index){
                const pid = this.t[index].patientS
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patient/${pid}`})
            
            }, */
            navigateToPatients(){
                
              this.$router.push({path:`/user/doctor/${this.$route.params.id}/patients`})
            
            },
            
            setPatients(){
                this.t = _.uniqBy(this.doctorMeetings.data, "patientS")
                /* console.log(this.t) */
                console.log("t",this.t) 
                /* this.t = v */
            },
            async a(){
                await this.getMeetingsByDoctorId();
                await this.setPatients()
            }
      
        },
        /* computed:{
             a(){
                const v = _.uniqBy(this.doctorMeetings.data, "patientS")
                console.log("v",v)
                this.t =v
            },

        },
         */
        created(){
            
            this.a()
        
        }
        
    }
</script>

<style>

.actions {
    display:none;
}

#patient:hover .actions {
    display: block;

}

</style>