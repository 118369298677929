<template>
    <div class="">
        
        
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-baseline">
                <h3 class="is-size-5 mr-1" @click="navigateToChronicsEdit">Kronik hastalıklar, komplikasyon ve diğer poliklinik muayeneleri</h3>
                <span class="icon is-medium" @click="navigateToChronicsEdit">
                    <i class="fas fa-edit edit-icon" ></i>
                  </span>
            
              </div>
             <div>
                <!-- <b-button @click="navigateToChronicsEdit" type="is-info" aria-label="patient-notes" outlined> -->
                  
                  <!-- <span class="button-text">Düzenle</span>
                </b-button> -->
              </div>
               
              <b-button type="is-text" class="is-small"  @click="isHidden = !isHidden"><span v-if="isHidden">Gizle</span>  <span v-if="!isHidden">Göster</span></b-button>
              
            </div>
            <div class="is-flex is-justify-content-space-between">
              <div>
                
                <Transition> 
                <div v-if="isHidden"> 
                  
                  <div v-if="!chronics.length">
                  Henüz ekleme yapmadınız.
                </div>
                  <div
               
                  class="is-flex is-align-items-baseline "
                  v-for="chronic in chronics"
                  v-bind:key="chronic._id"
                >
                  <b-tag class="is-danger mb-2" size="is-medium" closable @close="deleteChronic(chronic)">{{ chronic.value }}</b-tag>
                  
                </div>
                </div>
                </Transition>
              </div>
             
            </div>
          
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
  name: 'Chronics',
  data(){
     return {

        id: this.$route.params.id,
        isHidden: false
     } 
  },
  computed:{
            ...mapState(['chronics'])
        },
        methods:{
            ...mapActions(['getChronicsById','deleteChronic']),
            navigateToChronicsEdit() {
       this.$router.push({ path: `/user/patient/${this.id}/chronic-edit` });
    },
    deleteChronic(chronic) {
  this.$buefy.dialog.confirm({
        title: "Hastalık silme işlemi",
        message: `Bu notu <b>silmek</b> istediğinizden emin misiniz? - ${chronic.value}`,
        confirmText: "Hastalığı sil",
        cancelText: "İptal",
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          
          await this.$store.dispatch("deleteChronic", chronic);

          await this.$store.dispatch("getChronicsById");

          this.$buefy.toast.open({
            message: "Kayıt başarıyla silindi.",
            type: "is-success",
          });
        },
      });
    },
    
        },
        mounted(){
          this.getChronicsById()
        },
        created(){
            //this.getGeneralNotesById()
        }
};
</script>

<style >
.edit-icon {
  color: #167cf0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}



</style>
