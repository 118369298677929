<template>
    <div class="admin mx-3">
<!-- 
         <h3>Görüşmeler</h3>
         <hr>   
    <div v-for="patient in patients.allPatients" v-bind:key="patient.id" class="card m-1">
        
            <div class="content">
             {{patient.field}}<a>@bulmaio</a>.
            <a href="#">#css</a> <a href="#">#responsive</a>
            <br>
            <time datetime="2016-1-1">{{patient.createdAt}}</time>
            </div>
        </div>
 -->

        <h2 class="is-size-3 has-text-left ml-5">Hastalar</h2>
<b-table
            :data="patients.patients"
            :columns="columns">
        </b-table>
   </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

 export default {
        name: 'DoctorMeetings',
        data(){
            return {
                columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '100',
                        numeric: true,
                        searchable: false,
                    },
                    {
                        field: 'name',
                        label: 'Adı',
                        searchable: true,
                    },
                    {
                        field: 'surname',
                        label: 'Soyadı',
                        searchable: true,
                    },
                    {
                        field: 'email',
                        label: 'email',
                        searchable: true,
                    },
            ]
            }},
        components:{
            
        },
        computed:{
            ...mapState(['patients'])
        },
        methods:{
            ...mapActions(['fetchPatients'])
        },
        created(){
            this.fetchPatients()
        }
        
    }
</script>

<style>
.pos-fm {
    text-align:left !important;
}
