<template>
  <div id="bot-nav" type="is-info">
            <div class=" bot-nav box is-flex is-justify-content-space-around  py-1  ">
                <b-navbar-item class="is-flex has-text-weight-bold is-size-3-tablet is-size-5-mobile" @click="navigateToProfile">
           <span class="icon is-medium mr-2">
               <i class="fa fa-user"></i>
               </span>    
                <span class="is-hidden-mobile btn-text is-size-4">Profil</span> 
            </b-navbar-item>
            <b-navbar-item class="is-flex has-text-weight-bold is-size-3 is-size-5-mobile" @click="navigateToReading">
                <span class="icon is-medium mr-2">
               <i class="fa fa-tint"></i>
               </span>    
                <span class="is-hidden-mobile btn-text is-size-4">Kan Şekeri</span> 
            </b-navbar-item>
            <b-navbar-item class="is-flex has-text-weight-bold is-size-5-tablet is-size-5-mobile " @click="navigateToPatientMeetings">
                <span class="icon is-medium mr-2">
               <i class="fas fa-video"></i>
               </span>    
                <span class="is-hidden-mobile btn-text is-size-4">Görüşmeler</span> 
            </b-navbar-item>
            </div>
            
            
        
  </div>
</template>

<script>
export default {
    data() {
    return {
     
      id: this.$route.params.id,
      
    };
  },
    methods:{
        navigateToReading(){
                this.$router.push({path:`/user/${this.$route.params.id}/read`})
            },
            navigateToPatientMeetings(){
                this.$router.push({path:`/user/patient/${this.id}/patient-meetings`})
            },  
            navigateToProfile(){
                this.$router.push({path:`/user/${this.id}`})
            },
    }

}
</script>

<style scoped>

.bot-nav {
    
    width: 100%; 
    position:fixed;
    bottom:0px;
    /* margin-top: 20px; */
    /* overflow: auto; */
    
}


@media screen and (max-width: 896px) {
  .btn-text { 
    display: none;
  }
}

</style>