<template>
    <div class="admin mx-3"> 
<!--     <div v-for="doctor in doctors.data" v-bind:key="doctor.id" class="card pos-fm m-1">
        <div class="card-content">
            <div class="media">
            <div class="media-left">
                <figure class="image is-48x48">
                <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
                </figure>
            </div>
            <div class="media-content alignment">
                <router-link :to="`/user/doctor/${doctor.id}`" class="title is-4 mb-3">{{doctor.name}} {{doctor.surname}}</router-link>
                
                
            </div>
            </div>
            <div class="content">
             {{doctor.field}}<a>@bulmaio</a>.
            <a href="#">#css</a> <a href="#">#responsive</a>
            <br>
            <time datetime="2016-1-1">{{doctor.createdAt}}</time>
            </div>
        </div>


         
</div> -->
<h2 class="is-size-3 has-text-left ml-5">Doktorlar</h2>
<b-table
            :data="doctors.data"
            :columns="columns">
            
        </b-table>
        
    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';

 export default {
        name: 'Doctors',
        data(){
            return {
                columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '100',
                        numeric: true,
                        searchable: false,
                    },
                    {
                        field: 'name',
                        label: 'Adı',
                        searchable: true,
                    },
                    {
                        field: 'surname',
                        label: 'Soyadı',
                        searchable: true,
                    },
                    {
                        field: 'doctorAvailable',
                        label: 'Randevu Kabul Ediyor mu?',
                        searchable: true,
                    },{
                        field: 'meetingRoom',
                        label: 'Oda',
                        searchable: true,
                    },
            ]
            }},
        
        components:{
          //  Search
        },
        computed:{
            ...mapState(['doctors'])
        },
        methods:{
            ...mapActions(['fetchDoctors'])
        },
        created(){
            this.fetchDoctors()
        }
        
    }
</script>

<style>
.pos-fm {
    text-align:left !important;
}

.admin {
    margin-top: 100px;
}

</style>