<template>
<div>
    <Intro></Intro>

</div>


</template>    


<script>
import Intro from '@/components/Intro.vue'


export default {
    name: 'Landing',
    components: {
        Intro
    }
}
</script>

<style scoped>

div {
    margin-top: 50px;
}

</style>