<template>
    <div class="admin"> 
        
    <div class="room ml-4 ">
        <h1 class="has-text-left mb-3 is-size-3"> Oda ekle</h1>
        <div class="is-flex">
            <p>Oda ismi</p>
        <b-input class="mr-1" type="text" v-model="room.name"></b-input>
        
        <b-button class="is-info" @click="addRoom">Oda ekle</b-button>
        </div>
    </div>
    <hr>
    <h2 class="has-text-left is-size-3 ml-4" >Odalar</h2>
    <div v-for="room in rooms.data" v-bind:key="room.id" class="mb-1 mx-1">
         <div class="box">
             <div class="is-flex">
            <p class="mr-1 ">Oda adı</p>
            <b-tag class="is-warning is-size-6">{{room.name}}</b-tag>
         </div >
          <div class="is-flex">
              <p class="mr-1"> Müsait</p>
          <p>{{room.available}}</p>
          </div >
         <div class="is-flex">
             <p class="mr-1">Başlangıç Tarihi</p>
         <p>{{room.createdAt}}</p>
         </div>
         <div class="is-flex"><b-button class="is-info" @click="deleteRoom(room)" >Sil</b-button> </div>
         </div>
    </div>
    
    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex';

/* import axios from 'axios' */
 export default {
        name: 'AddRoom',
        data(){
            return {
                room:{
                    name:''
                },
            }
        },
       
        computed:{
            ...mapState(["rooms"]),
        },
        methods:{
            ...mapActions(['fetchRooms']),
        async addRoom(){
                let roomName = this.room
                console.log(roomName)
                this.$store.dispatch("addRoom", {roomName});
               /* await axios.post('http://localhost:3000/add-room', roomName)
                    .then((response) => {
                        console.log(response.data)
                     
                        this.room.name = '';
                    } ).catch((error) =>
                    {console.log(error)}) */

            /* await this.$store.dispatch('fetchRooms') */
        
            
        },
           deleteRoom(room){

           
                this.$buefy.dialog.confirm({
                    title: 'Oda silme işlemi',
                    message: 'Bu odayı <b>silmek</b> istediğinizden emin misiniz?',
                    confirmText: 'Bu odayı sil',
                    cancelText:'İptal',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm:  () => {
                    this.$store.dispatch('deleteRoomById', room)
                    /* this.$store.dispatch('fetchRooms') */
                    
                    this.$buefy.toast.open({
                      message:'Kayıt başarıyla silindi.',
                      type: 'is-success',
                    })}
                })
            
           
        
         
        },
        },
        created(){
            this.fetchRooms();
        }
        
    }
</script>

<style>
.pos-fm {
    text-align:left !important;
}

</style>