<template>
    <div class="admin mx-3">
<h2 class="is-size-3 has-text-left ml-5">Görüşmeler</h2>
<b-table
            :data="visits.data"
            :columns="columns">
        </b-table>
    </div>    
</template>

<script>
import {mapState, mapActions} from 'vuex'

 export default {
        name: 'Visit',
        data(){
            return {
                columns: [
                    {
                        field: '_id',
                        label: 'ID',
                        width: '100',
                        numeric: true,
                        searchable: false,
                    },
                    {
                        field: 'doctorId.name',
                        label: 'Doktor adı',
                        searchable: true,
                    },
                    {
                        field: 'doctorId.surname',
                        label: 'Doktor soyadı',
                        searchable: true,
                    },
                    {
                        field: 'patientId.name',
                        label: 'Hasta adı',
                        searchable: true,
                    },{
                        field: 'patientId.surname',
                        label: 'Hasta Soyadı',
                        searchable: true,
                    },
                    {
                        field: 'startsAt',
                        label: 'Başlangıç zamanı',
                        //searchable: true,
                    },
            ]
            }},
        components:{
           //Search
        },
        computed:{
            ...mapState(['visits'])
        },
        methods:{
            ...mapActions(['fetchVisits'])
        },
        created(){
            this.fetchVisits()
        }
        
    }
</script>

<style>
.pos-fm {
    text-align:left !important;
}
