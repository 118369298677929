<template>
  <main class=" wrapper mx-2-mobile">
     <div class="  home " v-if="status === 'home'">
      <div class="is-hidden">
        <h2>Daily Prebuilt demo</h2>
      <p>Start demo with a new unique room or paste in your own room URL</p>
      </div>
      <div class="start-call-container mt-6 pt-6 ">
          <!-- <p>{{meetingUrl}}</p>
        <button @click="createAndJoinRoom" :disabled="runningLocally">
          Create room and start
        </button>
        <p v-if="roomError" class="error">Room could not be created</p>
        <p class="subtext">or</p> -->
        <p  v-if="!this.$route.path.includes('doctor') & this.initializerVisible">Dr. {{doctorName}} ile olan görüşmeniz için</p>
        <p v-else-if="this.$route.path.includes('doctor') & this.initializerVisible">Hasta {{patientName}} ile olan görüşmeniz için</p>
        <div v-if="callEnded" class="is-flex is-justify-content-center">
          <b-button @click="navigateToProfile" class="is-info">Anasayfaya dön</b-button>
        <b-button @click="navigateToMeetings" class="is-info">Görüşmelere dön</b-button>
        </div>
        <form @submit="submitJoinRoom">
         <!--  <label for="room">Daily Room URL</label> -->
          <input
            id="room"
            type="text"
            placeholder="Enter room URL..."
            v-model="meetingUrl"
            pattern="^(https:\/\/)?[\w.-]+(\.(daily\.(co)))+[\/\/]+[\w.-]+$"
            @input="validateInput"
            class="is-hidden"
          />
          <input v-if="!this.callEnded & this.initializerVisible" type="submit" @submit="handleInitializer" value="Devam" class="is-size-4"  />
        </form>
       <!-- <p>{{runningLocally}}</p> -->
      </div>
    </div> 

    <div class="call-container" >
      <!-- The Daily Prebuilt iframe is embedded in the div below using the ref -->
      <div id="call" class="mt-0" ref="callRef"></div>
      <!-- Only show the control panel if a call is live -->
      <controls
        v-if="status === 'call'"
        :roomUrl="roomUrl"
        :callFrame="callFrame"
      />
    </div>

    
  </main>
</template>

<script>
import DailyIframe from "@daily-co/daily-js";
import Controls from "./Controls.vue";
import api from "../api.js"; 
import {mapState} from 'vuex';
const IFRAME_OPTIONS = {
  height: "90vh",
  width: "100vw",
  aspectRatio: 16 / 9,
  minWidth: "400px",
  maxWidth: "1920px",
  border: "1px solid var(--grey)",
  borderRadius: "4px",
  /* position: "static",
  top: "30px", */
};
export default {
  components: { Controls },
  name: "Home",
  data() {
    return {
     // roomUrl: "",
      status: "home",
      callFrame: null,
      validRoomURL: false,
      roomError: false,
      callEnded: false,
      initializerVisible:true,
    };
  },
  computed: {
    runningLocally() {
      return window?.location?.origin.includes("localhost");
    },
    
    ...mapState(['meetingUrl','doctorName','patientName','doctorIdForMeeting','patientIdForMeeting']),
  },
  methods: {
     /*  ...mapActions(['getMeetingUrl']), */
    /* callEnds(){
      this.callEnded = true;
    }, */handleInitializer(){
      this.initializerVisible=false
      console.log(this.initializerVisible)
    },navigateToUserProfileOnNewTab() {
      let route = this.$router.resolve({ path: `/user/${this.patientIdForMeeting}` });
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
       /* this.$router.push({ path: `/user/${this.patientIdForMeeting}` }); */
    },
    createAndJoinRoom() {
      api
        .createRoom()
        .then((room) => {
          this.meetingUrl = room.url;
          this.joinRoom(room.url);
        })
        .catch((e) => {
          console.log(e);
          this.roomError = true;
        });
    },
    // Daily callframe created and joined below
    joinRoom(url) {
      if (this.callFrame) {
        this.callFrame.destroy();
      }
      // Daily event callbacks
      const logEvent = (ev) => console.log(ev);
      const goToLobby = () => (this.status = "lobby");
      const goToCall = () => (this.status = "call");
      const callEnds = () => (this.callEnded = true);
      
      const leaveCall = () => {
        if (this.callFrame) {
          this.status = "home";
          this.callFrame.destroy();
        }
      };
      // DailyIframe container element
      const callWrapper = this.$refs.callRef;
      // Create Daily call
      const callFrame = DailyIframe.createFrame(callWrapper, {
        iframeStyle: IFRAME_OPTIONS,
        showLeaveButton: true,
      });
      this.callFrame = callFrame;
      // Add event listeners and join call
      callFrame
        .on("loaded", logEvent)
        .on("started-camera", logEvent)
        .on("camera-error", logEvent)
        .on("joining-meeting", goToLobby)
        .on("joined-meeting", goToCall)
        .on("left-meeting", leaveCall)
        .on("left-meeting", callEnds);
      callFrame.join({ url, showFullscreenButton: true });
    },
    submitJoinRoom(e) {
     e.preventDefault();
     console.log(this.meetingUrl)
      this.joinRoom(this.meetingUrl);
    },
    validateInput(e) {
      this.validRoomURL = !!this.meetingUrl && e.target.checkValidity();
      console.log("valid")
    },
      navigateToProfile(){
        if(this.$route.path.includes('patient')){
 this.$router.push({path:`/user/${this.patientIdForMeeting}`})
        } else {
          this.$router.push({path:`/user/doctor/${this.doctorIdForMeeting}`})
        }
               
            },
      navigateToMeetings(){
        if(this.$route.path.includes('patient')){
           this.$router.push({path:`/user/patient/${this.patientIdForMeeting}/patient-meetings`})
        }else {
          this.$router.push({path:`/user/doctor/${this.doctorIdForMeeting}/meetings`})
        }
               
            },
  },
  created(){
    this.submitJoinRoom();
    
    console.log(this.runningLocally())
  }
};
</script>
<style scoped>
.wrapper {
  background-color: var(--grey-lightest);
  height: 100%;
  display: flex;
  align-items: center;
}
.home {
  flex: 1;
}
.error {
  color: var(--red-dark);
}
.call-container {
  

  display: flex;
  max-width: 1200px;
  
  flex: 1;
  flex-wrap: wrap;
}
.call-container.hidden {
  flex: 0;
}
#call {
  flex: 1;
  margin-top: 24px;
  /* height: 60vh; */
 

}
.start-call-container {
  display: flex;
  flex-direction: column;
  /* max-width: 200px; */
  margin: auto;
}
.start-call-container .subtext {
  margin: 8px 0 0;
}
.start-call-container input {
  margin-top: 8px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--grey);
  padding: 0 16px;
}
.start-call-container button {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
.start-call-container input[type="submit"] {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 6px 16px 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
button:disabled,
.start-call-container input[type="submit"]:disabled {
  cursor: not-allowed;
  background: var(--white);
  border: 1px solid var(--grey);
}
label {
  opacity: 0;
  font-size: 1px;
} 
</style>

