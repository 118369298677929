<template>
    <div style="height:100vh" class=" is-flex is-flex-direction-column is-align-items-center is-justify-content-center bg pb-6">
        <form style="height:70%; " v-on:submit.prevent="handleMockLogin" >
            <div  class="is-flex-direction-column is-justify-content-space-between login-form has-background-light p-4">
        <h2 class="has-text-weight-bold is-size-1-desktop is-size-3 has-text-info mb-3-desktop mb-6" >Ludi</h2>
        <b-field label="Email">
            <b-input type="email"
                placeholder="doktor@email.com"
                maxlength="30"
                
                class="is-static"
                v-model="userCred.email">
            </b-input>
        </b-field>
        <b-field  label="Şifre">
            <b-input 
                placeholder="Şifrenizi giriniz" 
                required
                type="password" 
                maxlength="30"
                password-reveal
                v-model="userCred.password"
                class="mb-4 mb-3-desktop b"></b-input>
            <template #message>
                <div  v-show="userCred.password.length < 8 && userCred.password.length > 0">Şifre çok kısa</div>
                <div v-show="userCred.password.length < 8 && userCred.password.length > 0">En az sekiz karakter olmalı</div>
            </template>
        </b-field>
        <!-- <b-field label="Şifre tekrar">
            <b-input value="" v-model="userCred.passwordRepeat" placeholder="Şifrenizi tekrar giriniz" type="password" maxlength="30"></b-input>
            <template #message>
                <div v-show="userCred.passwordRepeat.length < 8 && userCred.passwordRepeat.length > 0">Password is too short</div>
                <div v-show="userCred.passwordRepeat.length < 8 && userCred.passwordRepeat.length > 0">Password must have at least 8 characters</div>
            </template>
        </b-field> -->
        <b-field class=" ml- mt-5 is-flex is-justify-content-space-around">
           <b-input
                    aria-label="Giriş Yapın"
                    type="submit"
                    class="is-info mr-4 is-size-5-desktop" >Giriş Yapın</b-input>
            <b-button tag="router-link"
                to="/register"
                type="is-text mr-1"
                class="is-size-6-desktop is-size-7">
                Kaydolun
            </b-button>
                <b-button tag="router-link"
                to="/"
                type="is-text"
                class="is-size-6-desktop is-size-7">
                Anasayfa
            </b-button>
                 
       </b-field>
       
</div>

        </form>
    </div>    
</template>

<script>
import axios from 'axios'
import {mapState, mapActions} from 'vuex';
axios.defaults.withCredentials = false

 export default {
        name: 'Login',
        data(){
          return{
              userTyping: false,
              
              userCred : {
                  email: "",
                  password:""
                  

              }
          }; 
        },
         computed:{
            ...mapState(['mockLoginData'])},
        methods:{
            ...mapActions(['mockLogin','setLoggedInValue']),
            loginUser(){
                 this.$store.dispatch('loginUser', this.userCred )
                        
                        /* let id = JSON.parse(localStorage.getItem('currentUser')).loggedinUser.id
                        let role = JSON.parse(localStorage.getItem('currentUser')).loggedinUser.role
                        console.log(this.isLoggedIn, id)    
                         if(this.isLoggedIn && role === "PATIENT" ){
                      this.$router.push({path:`/user/${id}`})
                     } else if (this.isLoggedIn && role === "DOCTOR") {
                      this.$router.push({path:`/user/doctor/${id}`})   
                     }
             */
                    
              
            },
           handleMockLogin(){
               
   
            const userLogin = this.mockLoginData.filter(user => user.email== this.userCred.email)
   
             if(userLogin.length==0){
                window.alert("Kullanıcı adı veya şifre hatalı!")
            } else if(userLogin[0].role == "PATIENT"){
                 this.$store.dispatch("setLoggedInValue", true);
                this.$router.push({ path: `/user/${userLogin[0].id}` });
               
            } else if(userLogin[0].role == "DOCTOR") {
                this.$router.push({ path: `/user/doctor/${userLogin[0].id}` });
            }
                
                
            }
           
          ,


        
    },
    created(){
        this.mockLogin();
    }
    }
</script>

<style>
*{
box-sizing: border-box;
}
.login-form{
    text-align: left;
    
    border: solid thin rgba(252, 248, 248, 0.5);
    border-radius: 20px;
   /* position: absolute; */
    z-index:2;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
}

.bg {
  
backdrop-filter: blur( 67.5px );
-webkit-backdrop-filter: blur( 67.5px );

}


.b {
    border-color: white;
}
</style>