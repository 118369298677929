<template>
  <div class="">
    <div class="treatment  is-flex is-flex-direction-column  mr-2 mx-6-desktop">
      <h2 class="is-size-4 has-text-left has-text-weight-bold ml- mt-2">Tedavi bilgileri</h2>
      <hr />
      <!-- <div v-if="!treatmentInfo">
        <p class="has-text-left" >Henüz bir ekleme yapmadınız.</p>
        <hr />
      </div> -->
      
      <div class=" " >
    <div class="is-flex pl-1 is-align-items-baseline is-flex-direction-column">
      <div class="is-flex is-justify-content-space-between is-align-items-baseline">
        <p class="has-text-left mr-3"> Mevcut tedavi tipi :</p>
      
      <b-tag v-if="treatmentInfo.length" class="is-success" size="is-medium">{{treatmentInfo[0].treatmentType}}</b-tag>
    <hr>
      </div>
      <p  class="mr-3 mb-1"> Buradan seçebilirsiniz :</p>
      <div class="is-flex buttons ">
      <div>
          <b-field class="is-align-self-flex-start mr-2">
        <b-radio-button
          v-model="radioButton"
          native-value="INSULIN"
          type="is-info is-outlined"
          @change="handleTreatment"
        >
          İnsülin
        </b-radio-button>
        <b-radio-button
          v-model="radioButton"
          native-value="ORAL ANTIDIYABETIK"
          type="is-info is-outlined"
          @click="handleTreatment"
        >
          Oral Antidiyabetik
        </b-radio-button>
        <b-radio-button
          v-model="radioButton"
          native-value="INSULIN,ORAL ANTIDIYABETIK"
          type="is-info is-outlined"
          @click="handleTreatment"
          class="mr-2"
        >
          Her ikisi de
        </b-radio-button>
        
      </b-field>
      </div>
      
      <div class="has-text-left ml-0" >
        <b-button class="is-info mr-2" outlined v-if="!treatmentInfo.length" @click="addTreatmentInfo">Kaydet</b-button>
      <b-button class="is-info mr-2" outlined v-if="treatmentInfo.length" @click="updateTreatmentInfo" >Güncelle</b-button>
      </div>
      </div>
      <div v-if="radioButton"  class="chosen-the is-flex is-align-items-baseline is-justify-content-flex-start">
        <p class="has-text-left mr-3">Seçilen tedavi tipi  :</p>
        <b-tag type="is-warning-light" size="is-medium">{{radioButton}}</b-tag>
        <b-button class="is-small is-light ml-1" @click="clearSelection">
                <i class="fas fa-times"></i>
              </b-button>
      </div>
     <hr>  
     <!-- <div class="is-flex">
        <b-dropdown
                    append-to-body
                    aria-role="menu"
                    scrollable
                    max-height="300"
                    trap-focus
                    
                    v-model="selection"
                    
                >
                    <template #trigger>
                        <a
                            class="navbar-item pt-0 mr-2 pl-1"
                            role="button">
                            <span class="has-text-weight-bold">İlaç seç</span>
                            
                        </a>
                    </template>
  <b-dropdown-item custom aria-role="listitem">
                      <b-input v-model="searchTerm" placeholder="Arama terimi" expanded />
                  </b-dropdown-item>
                    <b-dropdown-item
                v-for="(menu, index) in filteredData"
                :key="index"
                :value="menu" aria-role="listitem">
                <div class="media">
                    
                    <div class="media-content">
                        
                        <h3>{{menu.commercialName}}</h3>
                    </div>
                </div>
            </b-dropdown-item>

            
                </b-dropdown>

      </div> -->
      <p @click="showMedicine = !showMedicine">İlaç ekle</p> 
      <SetMedicine v-if="showMedicine"></SetMedicine>
      <hr>
       <div v-if="patientDrugs.length" >
                    <h3 class="has-text-weight-bold has-text-left is-size-4">Mevcut ilaçlar</h3>  
                    <hr class="mt-1">  
                    <p class="has-text-left">Eklenen ilaç bilgilerinizi ve eklenme tarihini aşağıdaki tablolarda görebilirsiniz </p>
                    <div class="mt-2 is-flex is-align-items-baseline "
                  v-for="drug in this.patientDrugs"
                  v-bind:key="drug._id">
                  
                  <div class="is-flex is-flex-direction-column">
                    
                    <p class="has-text-left">Eklendiği tarih - <b-tag class="is-medium">{{drug.createdAt | moment('Do MMMM YYYY, h:mm a')}}</b-tag></p>
                  <b-table class="mr-6 my-4 has-text-left"  :data="drug.drugs" :columns="drugColumns"></b-table>
                  </div>
                 
                  </div> 
                   
                   </div>
      <div v-if="treatmentInfo.length > 0">
        <hr>   
      <div v-if="treatmentInfo[0].treatmentType == 'INSULIN' ">
        <h3  class="has-text-left has-text-weight-bold is-size-4 mr-2 " >Mevcut İnsülin Bilgileri</h3>
    <hr>
    <p class="has-text-left" v-if="!treatmentInfo[0]"> Henüz insülin doz aralıkları ile ilgili bir ekleme yapılmamış.  </p>
    <div class=" range-table">
    
    <div class="" v-if="treatmentInfo[0].regime.length && treatmentInfo[0].regime.at(-1).standartRanges" >
    <div class="is-flex mb-2">
      <div class="is-flex is-align-items-baseline mr-2">
        <p class="mr-2">Hızlı İnsülini (Hızlı Salınımlı)</p>
      <b-tag class="is-medium">{{treatmentInfo[0].regime.at(-1).frInsulin}}</b-tag>
      </div>
      <div class="is-flex is-align-items-baseline">
       <p class="mr-2">Gece İnsülini (Yavaş Salınımlı)</p>
      <b-tag class="is-medium" >{{treatmentInfo[0].regime.at(-1).srInsulin}}</b-tag>
     </div>
    </div>
      <b-table class=" "  :data="standartData" :columns="standartColumns">
      
    </b-table>
    <p class="has-text-left my-6">Insulin dozunuzu tokluk kan şekerinizdeki 160'ın üzerindeki her  20 mg/dl için 1 ünite arttırınız.</p>
    </div>
      </div>
    </div>
   
      </div>
       
    </div>

    
    </div>
      <div class="mt-6 is-flex is-align-self-center ">
          <b-button @click="navigateToProfile" class="mr-2 is-outlined is-info">‹ Profile dön </b-button>
          <b-button @click="navigateToNotesEdit" class=" is-outlined is-info"> Notlara git ›</b-button>
        </div>
    </div>
    
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import SetMedicine from "@/components/SetMedicine.vue";
export default {
  components: {
    SetMedicine
    },
  name: "TreatmentInfo",
  data() {
    return {
      radioButton: "",
        drugInfo:{
            dayTime:null,
            mealStatus:null,
            drugDose:null,
            drugName:null,
            addedRole:"PATIENT"
        }, 
      selection:null,
      showMedicine:false,
      searchTerm:'',
      glyLow:null,
      glyHigh:null,
      insulinValue:null,
      insulinRanges: [],
      complication:"",
      id: this.$route.params.id,
              drugsList: [
            {
      "commercialName": "Minidiab 5 mg tb ",
      "genericName": "Glipizid ",
      "dailyDose": "2.5-40 mg ",
      "dailyTime": "Günde 2 kez kahvaltıda ve akşam yemeğinde "
    },
    {
      "commercialName": "Glucotrol XL 2.5 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Glucotrol XL 5 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Glucotrol XL 10 mg tb ",
      "genericName": "Glipizid Kontrollü Salınımlı Formu ",
      "dailyDose": "5-20 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce "
    },
    {
      "commercialName": "Diamicron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Betanorm 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Oramikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glumikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glikron 80 mg tb  ",
      "genericName": "Gliklazid ",
      "dailyDose": "80-240 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Dismicron MR 30 mg tb ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Dismicron MR 60 mg tb",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Betanorm MR",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Efikas MR ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Hipoglis 30 mg tb ",
      "genericName": "Gliklazid Modifiye Salınımlı Formu ",
      "dailyDose": "30-90 mg ",
      "dailyTime": "Günde 1 kez kahvaltıda veya kahvaltıdan önce"
    },
    {
      "commercialName": "Gliben",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Dianorm 5 mg",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diyaben 3 mg tb ",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diyaben 5 mg tb ",
      "genericName": "Glibenklamid ",
      "dailyDose": "1.25-20 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Amory",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diaglin",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Diameprid",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glimax",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glirid",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 1 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 2 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 3 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Sanprid 4 mg tb  ",
      "genericName": "Glimepirid ",
      "dailyDose": "1-9 mg",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde) "
    },
    {
      "commercialName": "Glutril 25 mg tb  ",
      "genericName": "Glibornurid ",
      "dailyDose": "12.5-75 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Glurenorm 30 mg tb ",
      "genericName": "Glikuidon  ",
      "dailyDose": "15-120 mg ",
      "dailyTime": "Günde 1-2 kez kahvaltıda (ve gerekirse akşam yemeğinde)"
    },
    {
      "commercialName": "Diafree",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navanorm",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 0.5 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 1 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Navada 2 mg tb  ",
      "genericName": "Repaglinid ",
      "dailyDose": "0.5-16 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce "
    },
    {
      "commercialName": "Dialix",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "teglix 120 mg",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "İncuria ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Starlix 120 mg  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Starlix 180 mg  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Naglid 60 mg tb  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Naglid 120 mg tb  ",
      "genericName": "Nateglinid ",
      "dailyDose": "60-360 mg",
      "dailyTime": "Günde 3 kez yemeklerden hemen önce"
    },
    {
      "commercialName": "Glucophage",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 500 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Metfull eff 1000 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Diaformin",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glange",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Gluforce",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Matofin 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Matofin 1000 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Gluformin retard ",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glukofen retard 850 mg",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Glukofen 1000 mg ",
      "genericName": "Metformin ",
      "dailyDose": "500-2550 mg ",
      "dailyTime": "Günde 1-3 kez yemekte veya tok"
    },
    {
      "commercialName": "Diaformin XR",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Glifor SR ",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Matofin XR 500 mg tb ",
      "genericName": "Metformin Uzun Salınımlı  ",
      "dailyDose": "500-2000 mg  ",
      "dailyTime": "Günde 1 kez yemekte veya tok tercihen akşam"
    },
    {
      "commercialName": "Actos 15 mg",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Actos 30 mg",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 15 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 30 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dialic 45 mg ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Dropia",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Glifix",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Pioforce",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piogtan",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 15 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 30 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Piondia 45 mg tb ",
      "genericName": "Pioglitazon ",
      "dailyDose": "15-45 mg ",
      "dailyTime": "Günde 1 kez yemekten bağımsız"
    },
    {
      "commercialName": "Acanis",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Acnor",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Arokan ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glucar",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glucobay",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glynose 50 mg tb ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glynose 100 mg tb ",
      "genericName": "Akarboz ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 3 kez yemeklerde ilk lokma ile birlikte"
    },
    {
      "commercialName": "Glyset 100 mg tb ",
      "genericName": "Miglitol ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 1-3 kez yemeğin başlangıcında"
    },
    {
      "commercialName": "Glyset 50 mg tb ",
      "genericName": "Miglitol ",
      "dailyDose": "25-300 mg ",
      "dailyTime": "Günde 1-3 kez yemeğin başlangıcında"
    }
    
    ],

      columns:[
                    {
                        field: 'id',
                        label: 'Aralık',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'lower',
                        label: 'Alt sınır (mg/dl)',
                    },
                    {
                        field: 'upper',
                        label: 'Üst sınır (mg/dl)',
                    },
                    {
                        field: 'value',
                        label: 'Değer (Unit)',
                        centered: true
                    },
                    
                ],
                drugColumns:[
                    
                    {
                        field: 'drugName.commercialName',
                        label: 'İlaç adı',
                        centered: true
                    },
                    {
                        field: 'dayTime',
                        label: 'Zaman',
                        centered: true
                    },
                    {
                        field: 'mealStatus',
                        label: 'Açlık durumu',
                        centered: true
                    },
                    {
                        field: 'drugDose',
                        label: 'Doz/adet',
                        centered: true
                    },
                    
                    {
                        field: 'addedBy',
                        label: 'Ekleyen',
                        centered: true
                    },
                    
                ] ,
                 standartColumns:[
                    {
                        field: 'dayTime',
                        label: 'Zaman',
                        centered: true
                    },
                     {
                        field: 'mealStatus',
                        label: 'Açlık durumu',
                        centered: true
                    },
                    
                    
                    {
                        field: 'lower',
                        label: 'Alt sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'upper',
                        label: 'Üst sınır (mg/dl)',
                        centered: true
                    },
                    {
                        field: 'value',
                        label: 'Değer (Unit)',
                        centered: true
                    },
                    
                    
                ] ,
                standartData:[
                    {
                        lower: 70,
                        upper: 160,
                        value: 8,
                        dayTime:"Sabah",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 70,
                        upper: 160,
                        value: 8,
                        dayTime:"Öğle",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 70,
                        upper: 160,
                        value: 8,
                        dayTime:"Akşam",
                        mealStatus:"Açlık"
                    },
                    {
                        lower: 70,
                        upper: 160,
                        value: 20,
                        dayTime:"Gece",
                        mealStatus:"Açlık"
                    },
                    
                ]
      
    };
  },
  computed:{
            ...mapState(['treatmentInfo','patientDrugs']),
              filteredData() {
                return this.drugsList.filter((item) => item.commercialName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >= 0);
            },
        },
  methods:{
    ...mapActions(['getTreatmentInfo',/* 'getTreatmentInfoForDoctor' *//* 'deleteChronic' */'getPatientDrugsById']),
    navigateToProfile(){
                this.$router.push({path:`/user/${this.id}`})
            },
    navigateToNotesEdit() {
      this.$router.push({ path: `/user/patient/${this.id}/info-edit` });
    },
    async updateTreatmentInfo(){

      
        const treatmentType = this.radioButton;
        const patientId = this.id;
      
      
      console.log("tt",treatmentType,this.treatmentInfo[0].treatmentType)
      
      if(treatmentType==this.treatmentInfo[0].treatmentType){
        console.log("check",treatmentType,this.treatmentInfo)
        this.alreadyExists();
        
      } 
      else if (treatmentType){
        
this.$store.dispatch("updateTreatmentInfo", {patientId,treatmentType}),

this.showTreatmentInfoUpdateConfirmation();
      }else {
        this.showLengthWarning();    
        
      }
            
          
          
    },
    async addTreatmentInfo(){

      
        const treatmentType = this.radioButton;
        const patientId = this.id;
      
      
      console.log(treatmentType)
      
      if(treatmentType){
        this.$store.dispatch("addTreatmentInfo", {patientId,treatmentType});
        
        
      } else {
        this.showLengthWarning();    
      }
            
          
          
    },
    clearSelection(){
      this.radioButton = "";
    },
    setInsulinNumbers(){
      this.insulinRanges.push(
        {
          low:this.glyLow,
          high:this.glyHigh,
          value:this.insulinValue
        }
      
      )
      this.glyLow = null,
      this.glyHigh = null,
      this.insulinValue = null
      console.log(this.insulinRanges)
    },
    handleTreatment(){
      console.log(this.radioButton)
    },
    handleSelection(){
      alert(this.complication)
    },
    alreadyExists(){
      this.$buefy.toast.open({
                    message: 'Seçtiğiniz değer ile mevcut değer aynı. Lütfen farklı bir değer seçiniz.',
                    type: 'is-warning',
                    duration: 3000
                })
    },
    showLengthWarning(){
      this.$buefy.toast.open({
                    message: 'Lütfen bir değer seçiniz.',
                    type: 'is-warning',
                    duration: 3000
                })
    },
    showTreatmentInfoUpdateConfirmation(){
      this.$buefy.toast.open({
                    message: 'Değer başarıyla güncellendi.',
                    type: 'is-success',
                    duration: 3000
                })
    },
    logTI(){
      console.log("log",this.treatmentInfo.length)
    },
    async load(){
      await this.getTreatmentInfo();
      await this.getPatientDrugsById();
      setTimeout(() => {
        this.logTI();
      }, 1000); 
    }
  },
  created(){
    this.load()
    /* this.getTreatmentInfoForDoctor(); */
    
  }
};
</script>

<style scoped>
.text {
  text-align: left;
}

.chosen-the{
  width: 80%;
}

.treatment {
  margin-top:70px;
  padding-left:160px ;
  padding-right:160px ;
  margin-bottom:80px ;
}

@media screen and (max-width: 1023px) {
  .treatment {
padding-left:5%;
  padding-right:5%;
    margin-right:5%;
    
  }
}

@media screen and (max-width: 768px) {
  .buttons {
width: 98%;
    
  }
}


@media screen and (min-width: 1023px) {
  .range-table {
    width: 80%;
    justify-content: center;
    margin-right:0px;
  }
}


@media screen and (min-width: 1023px) {
  .treatment > div{
    margin-top:10px;
    
  }
}
</style>
