<template>
    <div  class=" main mt-6  mx-4-desktop px-2 my-4 is-flex is-flex-direction-row-desktop is-flex-direction-column  is-align-items-flex-star is-justify-content-flex-start is-flex-wrap-wra">
        <b-loading  v-model="isLoading" :can-cancel="true">
                <b-icon
                    pack="fas"
                    icon="sync-alt"
                    size="is-large"
                    custom-class="fa-spin">
                </b-icon>
            </b-loading>
        <div class="mt-5-desktop box pr-0 pl-1 mb-2 is-flex full-width is-flex-direction-column is-justify-content-space-betwee is-align-content-baseline">
       <div class="is-flex  mt-4">
      
        <div class="is-flex is-flex-direction-column mr-4 pr-4  mb-2 ">
          <div class="is-flex is-align-content-baselin mb-2 pl-2">
                <div >
<figure class="image  is-64x64">
              <v-gravatar
                class="is-rounded"
                v-bind:email="patientData.data.email"
              />
            </figure>
      </div >
      <div class="is-flex is-flex-direction-column  ">
        <h3 class="ml-2 mt-2 mr-1 has-text-left is-size-4 has-text-weight-bold">{{patientData.data.name}} {{patientData.data.surname}}</h3>
           <div class="is-flex is-align-items-baseline">
        
        <b-tag v-if="patientData.data.age" class="mx-2 ml-2 is-medium">{{patientData.data.age}} yaş</b-tag>
        <b-tag v-if="patientData.data.heights.length" class="mx-2 is-medium">{{patientData.data.heights[0].height}} cm</b-tag>
        <b-tag v-if="patientData.data.weights.length" class="mx-2 is-medium">{{patientData.data.weights[0].weight}} kg</b-tag>
        
        </div>
      </div>
          
          <hr class="mt-0 ">
          </div>
         
        <!-- <b-button class="mt-1 is-align-self-flex-en m-2 is-info">Randevu ekle</b-button> -->

        </div>
               </div>
        
        
        </div>
      
        <!-- <div class="is-flex up is-flex-wrap-wrap mb-2"> -->
          <div class="chronics full-width  mb-2 box is-flex-grow-1">
            <h3 class="ml- mt-2 mr-1 is-size-4 has-text-weight-bold has-text-left">Kronik hastalıklar</h3>
        <hr>
        <p  class="has-text-left" v-if="!chronicsForDoctor.length">Henüz bir kronik hastalık eklenmemiş.</p>
        <div class="is-flex is-align-items-baseline "
                  v-for="chronic in chronicsForDoctor"
                  v-bind:key="chronic._id">
          <b-tag class="is-danger mb-2" size="is-medium" >{{ chronic.value }}</b-tag>
        </div>
        <hr>
       <div class="is-flex">
         <div class="is-flex is-flex-direction-column mr-4">
          <p class="has-text-left">Anne</p>
        <p  class="has-text-left has-text-weight-light" v-if="!motherChronicsForDoctor.length">Hasta annesine ait kronik hastalık eklememiş.</p>
        <div class="is-flex is-align-items-baseline "
                  v-for="chronic in motherChronicsForDoctor"
                  v-bind:key="chronic._id">
          <b-tag class="is-danger is-light mb-2" size="is-medium" >{{ chronic.value }}</b-tag>
        </div>
        </div>
        <div class="is-flex is-flex-direction-column" >
          <p class="has-text-left">Baba</p>
        <p  class="has-text-left has-text-weight-light" v-if="!fatherChronicsForDoctor.length">Hasta babasına ait kronik hastalık eklememiş.</p>
        <div class="is-flex is-align-items-baseline "
                  v-for="chronic in fatherChronicsForDoctor"
                  v-bind:key="chronic._id">
          <b-tag class="is-danger is-light mb-2" size="is-medium" >{{ chronic.value }}</b-tag>
        </div>
        </div>
       </div>
          </div>
         <!--  <div>
            <h3>Aşağıdakilerden biri mevcut mu?</h3>
          </div> -->
          <div class="comps full-width  mb-2 box is-flex-grow-1">
            <h3 class="ml- mt-2 mr-1 is-size-4 has-text-weight-bold has-text-left">Komplikasyonlar</h3>
        <hr>
        <p  class="has-text-left" v-if="!compsForDoctor.length">Henüz bir komplikasyon eklenmemiş.</p>
        <div class="is-flex is-align-items-baseline is-flex-wrap-wrap comp-list "
                  v-for="comp in compsForDoctor"
                  v-bind:key="comp._id">
          
            <b-tag class="is-warning mb-2" size="is-medium" >{{ comp.value }}</b-tag>
          
        </div>
          </div>
          <div class="comps full-width  mb-2 box is-flex-grow-1">
            <h3 class="ml- mt-2 mr-1 is-size-4 has-text-weight-bold has-text-left">Diğer poliklinik ziyaretleri</h3>
        <hr>
        <!-- <p  class="has-text-left" v-if="tInfoForDoctor && !tInfoForDoctor.eye.length && !tInfoForDoctor.heart.length && !tInfoForDoctor.kidney.length && !tInfoForDoctor.brain.length ">Henüz poliklinik ziyareti bilgisi eklenmemiş.</p> -->
        <!-- <div class="is-flex is-align-items-baseline "
                  v-for="comp in compsForDoctor"
                  v-bind:key="comp._id">
          <b-tag class="is-danger mb-2" size="is-medium" >{{ comp.value }}</b-tag>
        </div> -->
        <div class="is-flex mb-2">
          <p class="mr-2">Nöroloji</p>
         <b-tag v-if="tInfoForDoctor.length && tInfoForDoctor[0].brain.length" class="ml-2 mb-2 is-medium">{{tInfoForDoctor[0].brain.at(-1).date | moment('MMMM YYYY')}}</b-tag>
         <p class="has-text-left" v-else> - Henüz bir ekleme yapılmamış</p>
        </div>
        <div class="is-flex mb-2">
          <p class="mr-2">Nefroloji</p>
        <b-tag v-if="tInfoForDoctor.length && tInfoForDoctor[0].kidney.length" class="ml-2 mb-2 is-medium">{{tInfoForDoctor[0].kidney.at(-1).date | moment('MMMM YYYY')}}</b-tag>
       <p class="has-text-left" v-else> - Henüz bir ekleme yapılmamış</p>
        </div>
        <div class="is-flex mb-2">
          <p class="mr-2">Kardiyoloji</p>       
        <b-tag v-if="tInfoForDoctor.length && tInfoForDoctor[0].heart.length" class="ml-2 mb-2 is-medium">{{tInfoForDoctor[0].heart.at(-1).date | moment('MMMM YYYY')}}</b-tag>
        <p class="has-text-left" v-else> - Henüz bir ekleme yapılmamış</p>
        </div>
        <div class="is-flex mb-2">
          <p class="mr-2">Oftalmoloji</p>
        <b-tag v-if="tInfoForDoctor.length && tInfoForDoctor[0].eye.length" class="ml-2 mb-2 is-medium">{{tInfoForDoctor[0].eye.at(-1).date | moment('MMMM YYYY')}}</b-tag>
        <p class="has-text-left" v-else> - Henüz bir ekleme yapılmamış</p>
        </div>
          </div>
          <div class="notes  mt-0 box mb-2 full-width is-flex-grow-1">
          <h3 class="ml- mt-1 mr-1 is-size-4 has-text-left has-text-weight-bold">Notlar</h3>
          <hr>
          <p  class="has-text-left" v-if="!patientNotesForDoctor.data.length">Henüz bir not eklenmemiş.</p>
        <div class="is-flex is-align-items-baseline "
                  v-for="note in patientNotesForDoctor.data"
                  v-bind:key="note._id">
          <p class="is-danger mb-2 has-text-left multiline-text" size="is-medium" >{{ note.content }}</p>
        </div>
        </div>
      <div v-if="!tInfoForDoctor.length" class="box mb-2">
        <h3 class="ml- mt-1 mr-1 is-size-4 has-text-left has-text-weight-bold">Tedavi biçimi</h3>
        <hr>
        <p class="has-text-left" >Henüz bir tedavi biçimi eklenmemiş.</p>
      </div>
          
       <div v-if="tInfoForDoctor.length" class="treatment-info box  mb-2 full-width">
         <!-- <p>{{tInfoForDoctor}}</p> -->
          <div class="is-flex is-flex-direction-column is-align-items-flex-start">
          <h3 class="ml- mt-1 mr-1 is-size-4 has-text-weight-bold">Tedavi biçimi</h3>
          <div class="is-flex  medicine-buttons  ">
            <b-button class="ml-2" @click="navigateToSetDose" v-if='tInfoForDoctor && tInfoForDoctor[0].treatmentType=="INSULIN"||"INSULIN,ORAL ANTIDIYABETIK"  '  type="is-text">Insulin dozu ayarla/ görüntüle</b-button>
          <b-button class="ml-2" @click="navigateToSetMedicine" v-if='tInfoForDoctor && tInfoForDoctor[0].treatmentType=="INSULIN"||"INSULIN,ORAL ANTIDIYABETIK"  '  type="is-text">İlaç bilgisi ekle/görüntüle</b-button>
          </div>
        </div>
        <hr>
        <div class="is-flex is-align-items-baseline">
          <b-tag class="is-success mb-2" size="is-medium" v-if="tInfoForDoctor" >{{ tInfoForDoctor[0].treatmentType }}</b-tag>
        
        
        </div>
        <hr >
       <!--  <b-table v-if="tInfoForDoctor && tInfoForDoctor[0].dose.length" :data="tInfoForDoctor[0].dose" :columns="columns"></b-table> -->
        
        </div> 
        <div class="last-readings box  mb-2 full-width">
          <div class="is-flex is-flex-direction-column ">
          <h3 class="ml- mt-1 mr-1 is-size-4 has-text-weight-bold has-text-left">En son ölçüm değerleri</h3>
        <div class="is-flex">
          <b-button type="is-text" @click="getPatientData">Verileri getir</b-button>
        <b-button type="is-text" @click="navigateToSeePatientData">Kan şekeri değerlerini gör</b-button>
        </div>
        </div>
        <hr class="my-2">
        <div >
          <b-notification
            v-if="readings24hour.data && readings24hour.data.length == 0"
            class="has-text-left"
            type="is-warning"
            has-icon
            aria-close-label="Close notification"
            role="alert">
            {{readings24hour.message}}
        </b-notification>
          <div class="mt-2 is-flex is-align-items-baseline "
                 
                  v-for="reading in readings24hour.data"
                  v-bind:key="reading.id">
          <b-tag class="is- mb-2 mr-2" size="is-medium" >{{ reading.date | moment('Do MMMM YYYY, h:mm a') }}</b-tag>        
          <!-- <b-tag class="is-danger mb-2" size="is-medium" >{{ reading.dayTime }}</b-tag>
          <b-tag class="is-danger mb-2" size="is-medium" >{{ reading.mealStatus }}</b-tag> -->
          <b-tag class="is-danger mb-2" size="is-medium" >{{ reading.value }}</b-tag>
        </div>
        <!-- <p>{{readings24hour.data}}</p> -->
        </div>
        
        
        </div>
        
        <div class="meetings-with-me box   ">
          <div>
          <h3 class="ml- mt-1 mr-1 is-size-4 has-text-weight-bold has-text-left">Benimle olan görüşmeler</h3>
        </div>
        <hr>
        <div class="is-flex is-flex-direction-column "
                  v-for="meeting in doctorPatientMeetings.data"
                  v-bind:key="meeting._id">
                   <div class="is-flex is-align-items-baseline " >
                    <router-link class="has-text-left is-size-5-desktop is-info mb- mr-1 has-text-black" :to="`/doctor-meeting/${meeting._id}`"><b-tag  class=" mb-2 mr-2" size="is-medium" >  {{ meeting.startsAt | moment('Do MMMM YYYY, h:mm a')}}</b-tag><b-tag v-if="meeting.startsAt > new Date().toISOString()" class="is-success mb-2 mr-2" size="is-medium" >Aktif</b-tag>
          <b-tag v-if="meeting.startsAt < new Date().toISOString()" class="is-danger mb-2 mr-2" size="is-medium" >Geçmiş</b-tag></router-link>
                  
                   </div>
                  <!-- <div class="is-flex is-flex-direction-column" v-for="s in meeting.suggestions"
                  v-bind:key="s._id">
 <b-tag  class=" mb-2 mr-2" size="is-medium" >  {{s.suggestion}}</b-tag>
                   </div> -->
         
          
          

          <!-- <b-tag class="is-danger mb-2" size="is-medium" >{{ meeting.value }}</b-tag> -->
        </div>
        </div>
       <!-- </div> -->
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
/* import SetDose from '@/components/SetDose.vue'; */

export default {
  name: 'PatientForDoctor',
  components:{
    /* SetDose */
  },
  data() {
    return {
      duration:3000,
      isLoading: true,
      progressBar: true,
      patientId: this.$route.params.patientId,
      doctorId: this.$route.params.id,
      columns:[
                    {
                        field: 'id',
                        label: 'Aralık',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'lower',
                        label: 'Alt sınır (mg/dl)',
                    },
                    {
                        field: 'upper',
                        label: 'Üst sınır (mg/dl)',
                    },
                    {
                        field: 'value',
                        label: 'Değer (Unit)',
                        centered: true
                    },
                    
                ]
        
      
    };
  },
  computed:{
            ...mapState(['patientData','chronicsForDoctor','compsForDoctor', 'patientNotesForDoctor','tInfoForDoctor','readings24hour','doctorPatientMeetings','motherChronicsForDoctor','fatherChronicsForDoctor']),
              doctorFullName: function() {
            return this.doctorPatientMeetings.data[0].doctorId.name + ' ' + this.doctorPatientMeetings.data[0].doctorId.surname;
        },
           patientFullName: function() {
            return this.doctorPatientMeetings.data[0].patientId.name + ' ' + this.doctorPatientMeetings.data[0].patientId.surname;
        },
        },
        methods:{
            ...mapActions(['getPatientDataById','chronicsForDoctorByPatientId','compsForDoctorByPatientId','getPatientNotesbyIdForDoctor','getTreatmentInfoForDoctor','getMeetingsByDoctorAndPatientId','setMeetingDoctorName','setPatientName','motherChronicsForDoctorByPatientId','fatherChronicsForDoctorByPatientId']),
    getPatientData(){
                console.log(this.patientId)
                this.$store.dispatch('get24hourGlycosisDataById', this.patientId)
            },
            async navigateToSetDose(){ 
              this.$router.push({ path: `/user/doctor/${this.doctorId}/patient/${this.patientId}/set-dose` }) 
              await this.$store.dispatch("setMeetingDoctorName", this.doctorFullName);
                await this.$store.dispatch("setPatientName", this.patientFullName);
            },
            async navigateToSetMedicine(){ 
              this.$router.push({ path: `/user/doctor/${this.doctorId}/patient/${this.patientId}/set-medicine` }) 
              await this.$store.dispatch("setMeetingDoctorName", this.doctorFullName);
                await this.$store.dispatch("setPatientName", this.patientFullName);
            },
             navigateToSeePatientData(){ 
              this.$router.push({ path: `/user/doctor/${this.doctorId}/patient/${this.patientId}/reading` })
              },
              isLoaded(){
        this.isLoading = false
        console.log("load finished", this.isLoading)
    },
            
            
            },
            
           
  async created(){
    await this.getPatientDataById();
    await this.chronicsForDoctorByPatientId();
    await this.motherChronicsForDoctorByPatientId();
    await this.fatherChronicsForDoctorByPatientId();
    await this.compsForDoctorByPatientId();
    await this.getPatientNotesbyIdForDoctor();
    await this.getTreatmentInfoForDoctor();
    await this.getMeetingsByDoctorAndPatientId();
    await this.isLoaded();
    

  }
}
</script>

<style scoped>
.chronics {
  min-height: 10vh;
}

/* .not {
  max-height: 10vh;
} */

/* .full-width{
  min-width: 100%;
} */


@media screen and (max-width: 415px) {
  .main {
    width: 98vw;
  }
}

@media screen and (max-width: 415px) {
  .full-width {
    min-width: 96%;
  }
}

@media screen and (max-width: 415px) {
  .medicine-buttons{
  flex-direction: column;
}
}




.main{
  background-color: rgba(255, 255, 255, 0.947);
  /* filter: brightness(95%); */
  
  flex-basis: 48%;
  
}
.comp-list {
  flex-basis: 40%;
}
</style>