<template>
    <div class="admin mt-6 mx-6-desktop mx-2  is-flex is-flex-wrap-wrap is-flex-direction- is-align-items-flex-start  ">
     
                
    <b-button @click="navigateToMeetings" class="mb-2 mx-2">Görüşmeler</b-button>
    <b-button @click="navigateToDoctors" class="mb-2 mx-2">Doktorlar</b-button>
    <b-button @click="navigateToPatients" class="mb-2 mx-2">Hastalar</b-button>
    <b-button @click="navigateToRooms" class="mb-2 mx-2">Odalar</b-button>
    
    
    
    </div>    
</template>

<script>

 export default {
        name: 'Admin',
        data(){
          return{
              text:'',
              isMeetingsOpen: true,
              isDoctorsOpen: true,
              isPatientsOpen: true,
          }; 
        },
        components: {
            
        },
        methods:{
          navigateToMeetings(){
            this.$router.push({path:'/admin/visits'})
          },
          navigateToPatients(){
            this.$router.push({path:'/admin/patients'})
          },
          navigateToDoctors(){
            this.$router.push({path:'/admin/doctors'})
          },
          navigateToRooms(){
            this.$router.push({path:'/admin/rooms'})
          },
        }
        
    }
</script>

<style scoped>
.admin {
position:relative;
top:3rem;
margin: 0 auto;
flex-basis: 50%;

/* background-color: blue; */
overflow:auto;

}
</style>