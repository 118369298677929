import { render, staticRenderFns } from "./Appointment1.vue?vue&type=template&id=69ac2482&scoped=true&class=marg&"
import script from "./Appointment1.vue?vue&type=script&lang=js&"
export * from "./Appointment1.vue?vue&type=script&lang=js&"
import style0 from "./Appointment1.vue?vue&type=style&index=0&id=69ac2482&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69ac2482",
  null
  
)

export default component.exports