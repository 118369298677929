<template>
    <div class=" is-flex is-flex-direction-column notes px-5-desktop mx-6-desktop mx-4">
        <h3 class="is-size-4 has-text-left my-2">Genel Notlar</h3>
          <div class="is-flex is-flex-direction-column is-justify-content-space-between is-align-items-baseline">
            <div class="my-2">
            <p class="is-size-5 has-text-left ">Burada belirteceğiniz notlar görüşme öncesi otomatik olarak doktorunuza iletilecektir.</p>
           
          </div> 
          <div class="is-flex my-2">
            <div >
              <b-input type="textfield" v-model="patientNote" multiline></b-input>
            <p class="is-size-6 my-2 has-text-left ml-3 multiline-text">Not: {{patientNote}}</p>
            </div>
            <b-button class="mx-2" type="is-info" @click="addGeneralNote" outlined>Ekle</b-button>
            <b-button type="is-info"  @click="$router.go(-1)" outlined>Geri Dön</b-button>
          </div>
          </div>  
          <div class="mt-6 is-flex is-align-self-center  ">
          <b-button @click="navigateToTreatmentInfo"  class="mx-2 is-outlined is-info">‹ Tedavi bilgilerime dön</b-button>
          <b-button @click="navigateToChronicsEdit" class="mx-2 is-outlined is-info">Kronik hastalıklara git ›</b-button>
        </div>
    </div>
</template>

<script>

export default {
  name: 'GeneralNotesEdit',
  data(){
     return {

            patientNote:'',
            id: this.$route.params.id,
        
     } 
  },
  methods: {
    navigateToTreatmentInfo() {
      this.$router.push({ path: `/user/${this.id}/treatment-info` });
    },
    navigateToChronicsEdit(){
              this.$router.push({path:`/user/patient/${this.id}/chronic-edit`})
            },  
  toastNoteConfirmation(){
          this.$buefy.toast.open({
                    message: "Notunuz başarıyla eklendi",
                    type: 'is-success',
                    duration: 2000
                })
        },
  
  async addGeneralNote(){
      

      
        const content =  this.patientNote;
        const patientId = this.id;
      

   if(parseInt(this.patientNote.length) == 0){
        this.showNoteLengthWarning();
      } else {
          this.$store.dispatch("addPatientNote", {patientId,content})

            this.toastNoteConfirmation();
            this.patientNote='';
    }
      },
showNoteLengthWarning(){
          this.$buefy.toast.open({
                    message: "Lütfen bir not giriniz",
                    type: 'is-danger',
                    duration: 3000
                })
        },

      
  },
  
  
};
</script>

<style >

.notes {
  margin-top: 80px;
}

</style>
