<template>
  <div class="is-flex is-flex-direction-column">
    
            <div class="is-flex is-justify-content-space-between">
              <div class="is-flex is-align-items-baseline">
                <h3 class="is-size-5" @click="navigateToTreatmentInfo">Tedavi şekli ve bilgileri</h3>
                  <span class="icon is-info ml-2 " @click="navigateToTreatmentInfo">
                    <i class="fas fa-edit edit-icon"></i>
                  </span>
              </div>
              <div>
                <b-button
                  type="is-text"
                  class="is-small ml-1"
                  @click="isHiddenTreatment = !isHiddenTreatment"
                  ><span v-if="isHiddenTreatment">Göster</span>
                  <span v-if="!isHiddenTreatment">Gizle</span></b-button
                >
              </div>
            </div>
            <Transition>
            <div class="is-flex is-justify-content-space-between">
              <div>
                <div v-if="!isHiddenTreatment">
                  <div v-if="!treatmentInfo.length">
                    Henüz bir ekleme yapmadınız.
                  </div>
                    <b-tag v-if="treatmentInfo.length" class="is-success" size="is-medium">{{treatmentInfo[0].treatmentType}}</b-tag>
                </div>
              </div>
            </div>
    </Transition>
          </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
    data(){
     return {

        id: this.$route.params.id,
        isHiddenTreatment: true
     } 
  },
computed:{
 ...mapState(['treatmentInfo'])   
},
methods:{
    ...mapActions(['getTreatmentInfo',]),
    navigateToTreatmentInfo() {
      this.$router.push({ path: `/user/${this.id}/treatment-info` });
    },
},
created(){
    this.getTreatmentInfo();
}
}
</script>

<style>
.edit-icon {
  color: #167cf0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>